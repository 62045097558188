
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch, NavLink,  } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment,  } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image, ModalFooter } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText, FileMedicalFill, Coin, Tree, Globe2,  ShieldShaded, CircleFill, Pen, People, ChatSquareQuote, ChatLeftQuote, BlockquoteRight, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, CaretRight, ChevronRight, ChevronCompactRight, CaretRightFill, ArrowRightShort,  ArrowRightCircle, PlayCircle, PauseCircle, GeoAlt, HeartFill, QuestionCircleFill, MicFill, ReceiptCutoff, Bricks } from 'react-bootstrap-icons'

import { BrowserView, MobileView } from 'react-device-detect';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Axios from 'axios'
import { render } from '@testing-library/react';

import
 {supremeCourtSearch, whiteHouseSearch, billFeedModal, 
  createHouseFloorToday, createSenateFloorToday, createTrendingBills,
  createNationalAeronauticsAndSpaceAdministration, 
  createEnvironmentalProtectionAgency, createDepartmentOfHomelandSecurity,
  createDepartmentOfVeteransAffairs, createDepartmentOfEducation,
  createDepartmentOfHousingAndUrbanDevelopment, 
  createDepartmentOfHealthAndHumanServices, createDepartmentOfLabor
, createDepartmentOfCommerce, createDepartmentOfAgriculture,
createDepartmentOfTheInterior, createDepartmentOfJustice,
createDepartmentOfTransportation, createDepartmentOfDefense,
createDepartmentOfTreasury, createDepartmentOfEnergy,
createDepartmentOfState, createBillsEnrolledMobile,
createBillsEnrolled, createNewLaws, createGAOReports,
createExecutiveOrders, createPresidentialProclamations, createCongressionalHearings,
createCongressionalBudgetOfficeMobile, createCongressionalBudgetOffice,
createHouseCommitteeOversightAndReform,
createHouseCommitteeOnNaturalResources,
createHouseCommitteeOnTheJudiciary,
createHouseCommitteeOnHouseAdministration,
createHouseCommitteeOnHomelandSecurity,
createHouseCommitteeOnForeignAffairs,
createHouseCommitteeOnFinancialServices,
createHouseCommitteeOnEthics,
createHouseCommitteeOnEnergyAndCommerce,
createHouseCommitteeOnEducationAndLabor,
createHouseCommitteeOnTheBudget,
createHouseCommitteeOnArmedServices,
createHouseCommitteeOnAppropriations,
createHouseCommitteeOnAgriculture,
createReports,
createBillFeedTwoMobile,
createBillFeedTwo,
createBillFeed, createWhiteHouseFeedMobile
, createWhiteHouseFeed, createWhiteHouseDocuments, 
createBillsPresentedFeed, createCourtAppealsMobile,
createCourtAppeals, createUSCourtNewsFeedMobile,
createUSCourtNewsFeed, saveToCollection,
createFullBillFeed, ListItem, Speak, feedText, variableTest, myMethod, myMethod2, createEconomicIndicators, 
createSecuritiesAndExchangeCommissionPressReleases, createNationWeatherServices, createCDCNewsroom,
createSecuritiesAndExchangeCommissionSpeechesAndStatements, createNationalScienceFoundation, createHouseCommitteeOnIntelligence,
 createUNTopStories, createCDCOutbreaks, createCDCTravelNotices, createFoodRecalls, createFoodSafety, createFederalElectionCommissionNews, createElectionAssistancCommissioneNews, 
 createBillStatus, CreateFeedItem, CreateFeedItemWithDocButton, CreateFeedItemWithLawButton, CreateFeedItemGAOReports,
CreateFeedItemCongressionalReports,
CreateFeedItemPresidentialDocuments,
CreateFeedItemExecutiveOrders,
CreateFeedItemCourtAppeals,
CreateFeedItemEconomicIndicators,
CreateFeedItemBillUpdates,
createBIllItem,
CreateFeedItemSupremeCourtArguments,
CreateFeedItemSupremeCourtOpinions,
CreateFeedItemTest,
CreateFeedItemBillUpdatesFlip,
CreateFeedItemWithDocumentFlip,
CreateFeedItemWithDocButtonDateFix,
CreateFeedItemWithLawButtonFlip,
CreateFeedItemCanadaDateFix,
CreateFeedItemHouseOfCommons,
CreateFeedItemCanada,
CreateFeedItemMexico,
CreateFeedAPIRecentCacheMexico,
CreateFeedAPI} from '../Functions'
import MexicoDesktopNavbar from './MexicoDesktopNavbar';
import ChamberNews from './Legislative/ChamberNews';
import SenateNews from './Legislative/SenateNews';
import PresidentUpdates from './Executive/PresidentUpdates';
import AgricultureAgency from './Executive/AgricultureAgency';
import WelfareAgency from './Executive/WelfareAgency';
import SupremeCourtNews from '../Canada/Judicial/SupremeCourtNews';
import SEAgency from './Executive/SeAgency';
import SecturAgency from './Executive/SecturAgency';
import SHCPAgency from './Executive/SHCPAgency';
import ConaforAgency from './Executive/ConaforAgency';
import ConaquaAgency from './Executive/ConaquaAgency';
import SCTAgency from './Executive/SCTAgency';
import INMAgency from './Executive/INMAgency';
import SedenaAgency from './Executive/SedenaAgency';
import SemarAgency from './Executive/SemarAgency';
import SREAgency from './Executive/SREAgency';
import ConadeAgency from './Executive/ConadeAgency';
import ConadisAgency from './Executive/ConadisAgency';
import STPSAgency from './Executive/STPSAgency';
import NewMexicoHomeMobile from '../States/NewMexicoHomeMobile';
import MexicoHomeMobile from './MexicoHomeMobile';
import SedatuAgency from './Executive/SedatuAgency';
import GenerateFeedRecentMexico from '../GenerateFeedRecentMexico';
import GenerateFeed from '../GenerateFeed';







const CustomModal = ({ isOpen, onClose, title, content }) => (
  <Modal id='fullBillModal' show={isOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={onClose}>
    <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
    {content}
  </Modal>
);






var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 60000;

//https://evening-plains-21303.herokuapp.com/







  








export const MexicoHome = () =>   {
  render()
  
 
  {

  
  const [show, setShow] = useState(true);
  
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [fullscreen, setFullScreen] = useState(true);
  const [statesModalIsOpen, setStatesModalIsOpen] = useState(false)
  
  const [fullBillModalIsOpen, setFullBillModalIsOpen] = useState(false)
  const [billHelpModalIsOpen, setBillHelpModalIsOpen] = useState(false)

  const [reportModalIsOpen, setReportModalIsOpen] = useState(false)


  const [whiteHouseFeedModalIsOpen, setWhiteHouseFeedModalIsOpen] = useState(false)
  const [whiteHouseDocsModalIsOpen, setWhiteHouseDocsModalIsOpen] = useState(false)


  const [courtNewsModalIsOpen, setCourtNewsModalIsOpen] = useState(false)
  const [courtModalIsOpen, setCourtModalIsOpen] = useState(false)
  const [courtOpinionsModalIsOpen, setCourtOpinionsModalIsOpen] = useState(false)
  const [courtAppealsModalIsOpen, setCourtAppealsModalIsOpen] = useState(false)

  const [newLawsModalIsOpen, setNewLawsModalIsOpen] = useState(false)

  const [billsEnrolledModalIsOpen, setBillsEnrolledModalIsOpen] = useState(false)
  const [nsfModalIsOpen, setNSFModalIsOpen] = useState(false)
  const [legislativePageBillsEnrolledModalIsOpen, setLegislativePageBillsEnrolledModalIsOpen] = useState(false)
  const [secSAndSModalIsOpen, setSECSAndSModalIsOpen] = useState(false)
  const [economicIndicatorsModalIsOpen, setEconomicIndicatorsModalIsOpen] = useState(false)
  const [departmentOfStateModalIsOpen, setDepartmentOfStateModalIsOpen] = useState(false)
  const [departmentOfTreasuryModalIsOpen, setDepartmentOfTreasuryModalIsOpen] = useState(false)
  const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
  const [departmentOfJusticeModalIsOpen, setDepartmentOfJusticeModalIsOpen] = useState(false)
  const [departmentOfTheInteriorModalIsOpen, setDepartmentOfTheInteriorModalIsOpen] = useState(false)
  const [departmentOfAgricultureModalIsOpen, setDepartmentOfAgricultureModalIsOpen] = useState(false)
  const [departmentOfCommerceModalIsOpen, setDepartmentOfCommerceModalIsOpen] = useState(false)
  const [departmentOfLaborModalIsOpen, setDepartmentOfLaborModalIsOpen] = useState(false)
  const [departmentOfHealthAndHumanServicesModalIsOpen, setDepartmentOfHealthAndHumanServicesModalIsOpen] = useState(false)
  const [departmentOfHousingAndUrbanDevelopmentModalIsOpen, setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen] = useState(false)
  const [departmentOfTransportationModalIsOpen, setDepartmentOfTransportationModalIsOpen] = useState(false)
  const [departmentOfEnergyModalIsOpen, setDepartmentOfEnergyModalIsOpen] = useState(false)
  const [departmentOfEducationModalIsOpen, setDepartmentOfEducationModalIsOpen] = useState(false)
  const [departmentOfVeteransAffairsModalIsOpen, setDepartmentOfVeteransAffairsModalIsOpen] = useState(false)
  const [departmentOfHomelandSecurityModalIsOpen, setDepartmentOfHomelandSecurityModalIsOpen] = useState(false)
  const [epaModalIsOpen, setEPAModalIsOpen] = useState(false)
  const [nasaModalIsOpen, setNASAModalIsOpen] = useState(false)
  const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
  const [trendingBillsOptionOne, setTrendingBillsOptionOneModalIsOpen ] = useState(false)
  const [trendingBillsOptionTwo, setTrendingBillsOptionTwoModalIsOpen ] = useState(false)
  const [trendingBillsOptionThree, setTrendingBillsOptionThreeModalIsOpen ] = useState(false)
  const [trendingBillsOptionFour, setTrendingBillsOptionFourModalIsOpen ] = useState(false)
  const [trendingBillsOptionFive, setTrendingBillsOptionFiveModalIsOpen ] = useState(false)
  const [trendingBillsOptionSix, setTrendingBillsOptionSixModalIsOpen ] = useState(false)
  const [trendingBillsOptionSeven, setTrendingBillsOptionSevenModalIsOpen ] = useState(false)
  const [trendingBillsOptionEight, setTrendingBillsOptionEightModalIsOpen ] = useState(false)
  const [trendingBillsOptionNine, setTrendingBillsOptionNineModalIsOpen ] = useState(false)
  const [trendingBillsOptionTen, setTrendingBillsOptionTenModalIsOpen ] = useState(false)
  const [trendingBillsOptionEleven, setTrendingBillsOptionElevenModalIsOpen ] = useState(false)
  const [trendingBillsOptionTweleve, setTrendingBillsOptionTweleveModalIsOpen ] = useState(false)
  const [trendingBillsOptionThirteen, setTrendingBillsOptionThirteenModalIsOpen ] = useState(false)
  const [trendingBillsOptionFourteen, setTrendingBillsOptionFourteenModalIsOpen ] = useState(false)
  const [trendingBillsOptionFifteen, setTrendingBillsOptionFifteenModalIsOpen ] = useState(false)
  const [goaReportsModalIsOpen, setGAOReportsModalIsOpen] = useState(false)
    const [houseComitteeOnAgricultureModalIsOpen, setHouseComitteeOnAgricultureModalIsOpen] = useState(false)
    const [houseCommitteeOnAppropriationsModalIsOpen, setHouseCommitteeOnAppropriationsModalIsOpen] = useState(false)
    const [houseCommitteeOnArmedServicesModalIsOpen, setHouseCommitteeOnOnArmedServicesModalIsOpen] = useState(false)
    const [houseCommitteeOnTheBudgetModalIsOpen, setHouseCommitteeOnTheBudgetModalIsOpen] = useState(false)
    const [houseCommitteeOnEnergyAndCommerceModalIsOpen, setHouseCommitteeOnEnergyAndCommerceModalIsOpen] = useState(false)
    const [houseCommitteeOnEducationAndLaborModalIsOpen, setHouseCommitteeOnEducationAndLaborModalIsOpen] = useState(false)
    const [houseCommitteeOnEthicsModalIsOpen, setHouseCommitteeOnEthicsModalIsOpen] = useState(false)
    const [houseCommitteeOnFinancialServicesModalIsOpen, setHouseCommitteeOnFinancialServicesModalIsOpen] = useState(false)
    const [houseCommitteeOnForeignAffairsModalIsOpen, setHouseCommitteeOnForeignAffairsModalIsOpen] = useState(false)
    const [houseCommitteeOnHomelandSecurityModalIsOpen, setHouseCommitteeOnHomelandSecurityModalIsOpen] = useState(false)
    const [houseCommitteeOnHouseAdministrationModalIsOpen, setHouseCommitteeOnHouseAdministrationModalIsOpen] = useState(false)
    const [houseCommitteeOnTheJudiciaryModalIsOpen, setHouseCommitteeOnTheJudiciaryModalIsOpen] = useState(false)
    const [houseCommitteeOnNaturalResourcesModalIsOpen, setHouseCommitteeOnNaturalResourcesModalIsOpen] = useState(false)
    const [houseCommitteeOnOversightAndReformModalIsOpen, setHouseCommitteeOnOversightAndReformModalIsOpen] = useState(false)
    const [congressionalBudgetOfficeModalIsOpen, setCongressionalBudgetOfficeModalIsOpen] = useState(false)
    const [presidentialProclamationsModalIsOpen, setPresidentialProclamationsModalIsOpen] = useState(false)
    const [cdcNewsroomModalIsOpen, setCDCNewsroomModalIsOpen] = useState(false)
    const [executiveOrdersModalIsOpen, setExecutiveOrdersModalIsOpen] = useState(false)
    const [unTopStoriesModalIsOpen, setUNTopStoriesModalIsOpen] = useState(false)
    const [houseCommitteeOnIntelligenceModalIsOpen, setHouseCommitteeOnIntelligenceModalIsOpen] = useState(false)
    const [travelNoticesModalIsOpen, setTravelNoticesModalIsOpen] = useState(false)
  
    const [foodRecallsModalIsOpen, setFoodRecallsModalIsOpen] = useState(false)
    const [foodSafetyModalIsOpen, setFoodSafetyModalIsOpen] = useState(false)

    const [outbreaksModalIsOpen, setOutbreaksModalIsOpen] = useState(false)
    const [congressionalHearingsModalIsOpen, setCongressionalHearingsModalIsOpen] = useState(false)

    const [houseFloorModalIsOpen, setHouseFloorModalIsOpen] = useState(false)

    const [billStatusModalIsOpen, setBillStatusModalIsOpen] = useState(false)
    const [fecModalIsOpen, setFECModalIsOpen] = useState(false)
    const [eacModalIsOpen, setEACModalIsOpen] = useState(false)


  const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false)
  const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)
  const [secPressModalIsOpen, setSECPressModalIsOpen] = useState(false)

  const [alertModalIsOpen, setAlertModalIsOpen] = useState(true)


  const [declarationModalIsOpen, setDeclarationModalIsOpen] = useState(false)
  const [constitutionModalIsOpen, setConstitutionModalIsOpen] = useState(false)
  const [billOfRightsModalIsOpen, setBillOfRightsModalIsOpen] = useState(false)
 
  const [modalInfo, setModalInfo] = useState({
    isOpen: false,
    title: '',
    content: null
  });

  const openModal = (title, content) => {
    setModalInfo({
      isOpen: true,
      title,
      content
    });
  };

  const closeModal = () => {
    setModalInfo({
      isOpen: false,
      title: '',
      content: null
    });
  };
   




  function BranchesTab() {
  

    return (
      <>
   
      <div id='branchesTab'>

      <Row id='branchesRow'>

{/*Health Column */}
<Col id='homeColumn'>

<h3 id='branchHeading' onClick={() =>
                            openModal('Mexico Recents',
                              <GenerateFeedRecentMexico/>)}> Recents</h3>
<div  id='recent'></div> 
{CreateFeedAPIRecentCacheMexico('https://api.govglance.org/posts/recent/schema?limit=1&skip=0&schema=mexico', 'recent', 
                'Mexico Recents', 'N/A', 
                'N/A', 'Doc', GenerateFeedRecentMexico)}
</Col>

<Col id='homeColumn'>
<h3 id='branchHeading' >  </h3>
<div  id='recent2'></div> 
{CreateFeedAPIRecentCacheMexico('https://api.govglance.org/posts/recent/schema?limit=1&skip=1&schema=mexico', 'recent2', 
                'Mexico Recents', 'N/A', 
                'N/A', 'Doc', GenerateFeedRecentMexico)}
</Col>
<Col id='homeColumn'>
<h3 id='branchHeading' >  </h3>
<div  id='recent3'></div> 
{CreateFeedAPIRecentCacheMexico('https://api.govglance.org/posts/recent/schema?limit=1&skip=2&schema=mexico', 'recent3', 
                'Mexico Recents', 'N/A', 
                'N/A', 'Doc', GenerateFeedRecentMexico)}
</Col>

                



{/*Placeholder2 Column */}

</Row>
        <Row id='branchesRow'>

            {/*Legislative Column */}
          <Col id='homeColumn'>

              <Row id="homePageBranchRow">

                
           
                <h3 id='branchHeading' onClick={() => window.open('/mexico/legislative', "_self")}> <Pen></Pen> <b id='branchHeadingBold'>Legislative</b><ChevronRight id='clickChevron'></ChevronRight></h3>
               {/*
                <Button size='md' id='congressButton' variant='primary' onClick={(e) => {
                  e.preventDefault();
                  window.open('https://www.congress.gov/', "_blank")
                }} >congress.gov</Button>


                <Button size='md' id='dailyDigestButton' variant='primary' onClick={(e) => {
                  e.preventDefault();
                  window.open('https://www.congress.gov/congressional-record', "_blank")
                }} >Congressional Record</Button>

              */}

            
            </Row>
 
            {/*Legislative Desktop Carousel*/}
            
  
          <Carousel id='homeCarousel' controls={false}  >

          <Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
              <div id='legislativeCon'>
   
          <Col id='legislativeColRight'>
            <div id='columnLefttPadding'>



            <h4 id='feedHeadingBillsEnrolled' onClick={() => setFECModalIsOpen(true)}><b>Senate </b></h4>
<div id='senate'></div>
                           
{CreateFeedAPI('&skip=0&schema=mexico&table=senado_de_la_republica&order_by=created_at', 'senate', 
                            'Senate', 'Legislative', 
                            'N/A', 'Doc', GenerateFeed)}


<Modal id='mobileFullBillModal' show={fecModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() =>  setFECModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Senate</h3></Modal.Header>
<GenerateFeed url='&skip=0&schema=mexico&table=senado_de_la_republica&order_by=created_at'
                                name='Senate' branch='Legislative' topic='N/A' buttonName='Doc' />
</Modal>
            </div>
          </Col>

  </div>

</Carousel.Item>


<Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
              <div id='legislativeCon'>
   
          <Col id='legislativeColRight'>
            <div id='columnLefttPadding'>



            <h4 id='feedHeadingBillsEnrolled' onClick={() => setNSFModalIsOpen(true)}><b>Chamber </b></h4>
<div id='chamber'></div>
                           
{CreateFeedAPI('&skip=0&schema=mexico&table=chamber_of_deputies&order_by=created_at', 'chamber', 
                            'Chamber', 'Legislative', 
                            'N/A', 'Doc', GenerateFeed)}


<Modal id='mobileFullBillModal' show={nsfModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() =>  setNSFModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Chamber</h3></Modal.Header>
<GenerateFeed url='&skip=0&schema=mexico&table=chamber_of_deputies&order_by=created_at'
                                name='Chamber' branch='Legislative' topic='N/A' buttonName='Doc' />
</Modal>
            </div>
          </Col>

  </div>

</Carousel.Item>  



        
        

            <Carousel.Item interval={carouselInterval} >

<div id='legislativeCon'>



  <Col id='legislativeColRight'>
    <div id='columnLefttPadding'>
    


    <Button id='viewFullLegislativePageButton' size='lg' href='/mexico/legislative' >View all Legislative news</Button>
 

    </div>
  </Col>
















</div>

</Carousel.Item>
      

          
                </Carousel>
          </Col>


          {/*Executive Column */}
          <Col id='homeColumn'>

          <Row id="homePageBranchRow">
                <h3 id='branchHeading' onClick={() => window.open('/mexico/executive', "_self")}><People></People> <b id='branchHeadingBold'>Executive</b><ChevronRight id='clickChevron'></ChevronRight></h3>
            
           
            </Row>
            
            <Carousel id='homeCarousel' controls={false}  >

            <Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
              <div id='legislativeCon'>
   
          <Col id='legislativeColRight'>
            <div id='columnLefttPadding'>



            <h4 id='feedHeadingBillsEnrolled' onClick={() => setDepartmentOfStateModalIsOpen(true)}><b>President Newsroom </b></h4>
<div id='president'></div>
                        
                        {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
                        {CreateFeedAPI('&skip=0&schema=mexico&table=presidente&order_by=created_at', 'president', 
                            'President Newsroom', 'Executive', 
                            'N/A', 'Doc', GenerateFeed)}


<Modal id='mobileFullBillModal' show={departmentOfStateModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfStateModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>President Newsroom</h3></Modal.Header>
<GenerateFeed url='&skip=0&schema=mexico&table=presidente&order_by=created_at'
                                name='President Newsroom' branch='Executive' topic='N/A' buttonName='Doc' />
</Modal>
            </div>
          </Col>

  </div>

</Carousel.Item>

<Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
              <div id='legislativeCon'>
   
          <Col id='legislativeColRight'>
            <div id='columnLefttPadding'>



            <h4 id='feedHeadingBillsEnrolled' onClick={() => setFullBillModalIsOpen(true)}><b>Secretaría de Agricultura y Desarrollo Rural </b></h4>
<div  id='executiveNews'></div>
                        
{CreateFeedAPI('&skip=0&schema=mexico&table=agricultura&order_by=created_at', 'executiveNews', 
                            'Secretaría de Agricultura y Desarrollo Rural', 'Legislative', 
                            'Environment', 'Doc', GenerateFeed)}


<Modal id='mobileFullBillModal' show={fullBillModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFullBillModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Secretaría de Agricultura y Desarrollo Rural</h3></Modal.Header>
<GenerateFeed url='&skip=0&schema=mexico&table=agricultura&order_by=created_at'
                                name='Secretaría de Agricultura y Desarrollo Rural' branch='Legislative' topic='N/A' buttonName='Doc' />
</Modal>
            </div>
          </Col>

  </div>

</Carousel.Item>

<Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
              <div id='legislativeCon'>
   
          <Col id='legislativeColRight'>
            <div id='columnLefttPadding'>



            <h4 id='feedHeadingBillsEnrolled' onClick={() => setTrendingBillsModalIsOpen(true)}><b>Secretaría de Bienestar </b></h4>
<div  id='welfare'></div>
                        
{CreateFeedAPI('&skip=0&schema=mexico&table=bienestar&order_by=created_at', 'welfare', 
                            'Secretaría de Bienestar', 'Legislative', 
                            'N/A', 'Doc', GenerateFeed)}




<Modal id='mobileFullBillModal' show={trendingBillsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Secretaría de Bienestar</h3></Modal.Header>
<GenerateFeed url='&skip=0&schema=mexico&table=bienestar&order_by=created_at'
                                name='Secretaría de Bienestar' branch='Legislative' topic='N/A' buttonName='Doc'/>
</Modal>
            </div>
          </Col>

  </div>

</Carousel.Item>



            <Carousel.Item interval={carouselInterval} >

<div id='legislativeCon'>



  <Col id='legislativeColRight'>
    <div id='columnLefttPadding'>
    


    <Button id='viewFullLegislativePageButton' size='lg' href='/mexico/executive' >View all Executive news</Button>
 

    </div>
  </Col>
















</div>

</Carousel.Item>
      

          
                </Carousel>
            {/*Executive Desktop Carousel*/}

    
          </Col>


            {/*Judical Column */}
            <Col id='homeColumn'>


               <Row id="homePageBranchRow">
                <h3 id='branchHeading' onClick={() => window.open('/mexico/judicial', "_self")}><svg xmlns="http://www.w3.org/2000/svg" height="27.54px" viewBox="0 -960 960 960" width="27.54px" fill="#FFFFFF"><path d="M160-120v-80h480v80H160Zm226-194L160-540l84-86 228 226-86 86Zm254-254L414-796l86-84 226 226-86 86Zm184 408L302-682l56-56 522 522-56 56Z"/></svg><b id='branchHeadingBold'>Judicial</b><ChevronRight id='clickChevron'></ChevronRight></h3>
              
            
             </Row>
            
            {/*Judical Desktop Carousel*/}
            <Carousel id='homeCarousel' controls={false}  >


            <Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
              <div id='legislativeCon'>
   
          <Col id='legislativeColRight'>
            <div id='columnLefttPadding'>



            <h4 id='feedHeadingBillsEnrolled' onClick={() => setTrendingBillsOptionNineModalIsOpen(true)}><b>Supreme Court </b></h4>
            <div id='sc'></div>
                        
            {CreateFeedAPI('&skip=0&schema=mexico&table=suprema_corte_de_justicia_de_la_nacion&order_by=created_at', 'sc', 
                            'Supreme Court', 'Judicial', 
                            'N/A', 'Doc', GenerateFeed)}

<Modal id='mobileFullBillModal' show={trendingBillsOptionNine}
size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionNineModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Supreme Court News</h3></Modal.Header>
<GenerateFeed url='&skip=0&schema=mexico&table=suprema_corte_de_justicia_de_la_nacion&order_by=created_at'
                                name='Supreme Court' branch='Judicial' topic='N/A' buttonName='Doc'/>
</Modal>
            </div>
          </Col>

  </div>

</Carousel.Item>

<Carousel.Item interval={carouselInterval} >

<div id='legislativeCon'>



  <Col id='legislativeColRight'>
    <div id='columnLefttPadding'>
    


    <Button id='viewFullLegislativePageButton' size='lg' href='/mexico/judicial' >View all Judicial news</Button>
 

    </div>
  </Col>

</div>

</Carousel.Item>

    </Carousel>
       
          </Col>
          </Row>

          <Row id='topicsRow'>
{/*Economy Column */}
 <Col id='topicColumn'>

                        <Row id="topicPageBranchRow">

                        <h3 id='branchHeading' onClick={() => window.open('/mexico/economy', "_self")}> <Coin></Coin> <b id='branchHeadingBold'>Economy</b><ChevronRight id='clickChevron'></ChevronRight></h3>


                          </Row>
<Carousel id='homeCarousel' controls={false} >

<Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
              <div id='legislativeCon'>
   
          <Col id='legislativeColRight'>
            <div id='columnLefttPadding'>



            <h4 id='feedHeadingBillsEnrolled' onClick={() => setTrendingBillsOptionTenModalIsOpen(true)}><b>Secretaría de Economía </b></h4>
            <div id='se'></div>
                        
            {CreateFeedAPI('&skip=0&schema=mexico&table=se&order_by=created_at', 'se', 
                            'Secretaría de Economía', 'Executive', 
                            'Economy', 'Doc', GenerateFeed)}

<Modal id='mobileFullBillModal' show={trendingBillsOptionTen} 
size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionTenModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Secretaría de Economía</h3></Modal.Header>
<GenerateFeed url='&skip=0&schema=mexico&table=se&order_by=created_at'
                                name='Secretaría de Economía' branch='Executive' topic='Economy' buttonName='Doc'/>
</Modal>
            </div>
          </Col>

  </div>

</Carousel.Item>

<Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
              <div id='legislativeCon'>
   
          <Col id='legislativeColRight'>
            <div id='columnLefttPadding'>



            <h4 id='feedHeadingBillsEnrolled' onClick={() => setTrendingBillsOptionElevenModalIsOpen(true)}><b>Secretaría de Turismo </b></h4>
            <div id='sectur'></div>
                        
            {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
            {CreateFeedAPI('&skip=0&schema=mexico&table=sectur&order_by=created_at', 'sectur', 
                            'Secretaría de Turismo', 'Executive', 
                            'Economy', 'Doc', GenerateFeed)}

<Modal id='mobileFullBillModal' show={trendingBillsOptionEleven} 
size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionElevenModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Secretaría de Turismo</h3></Modal.Header>
<GenerateFeed url='&skip=0&schema=mexico&table=sectur&order_by=created_at'
                                name='Secretaría de Turismo' branch='Executive' topic='Economy' buttonName='Doc'/>
</Modal>
            </div>
          </Col>

  </div>

</Carousel.Item>

<Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
              <div id='legislativeCon'>
   
          <Col id='legislativeColRight'>
            <div id='columnLefttPadding'>



            <h4 id='feedHeadingBillsEnrolled' onClick={() => setDepartmentOfEnergyModalIsOpen(true)}><b>Secretaría de Hacienda y Crédito Público </b></h4>
            <div id='shcp'></div>
                        
            {CreateFeedAPI('&skip=0&schema=mexico&table=shcp&order_by=created_at', 'shcp', 
                            'Secretaría de Hacienda y Crédito Público', 'Executive', 
                            'Economy', 'Doc', GenerateFeed)}

<Modal id='mobileFullBillModal' show={departmentOfEnergyModalIsOpen} 
size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfEnergyModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Secretaría de Hacienda y Crédito Público</h3></Modal.Header>
<GenerateFeed url='&skip=0&schema=mexico&table=shcp&order_by=created_at'
                                name='Secretaría de Hacienda y Crédito Público' branch='Executive' topic='Economy' buttonName='Doc' />
</Modal>
            </div>
          </Col>

  </div>

</Carousel.Item>

<Carousel.Item interval={carouselInterval} >

<div id='legislativeCon'>



  <Col id='legislativeColRight'>
    <div id='columnLefttPadding'>
    


    <Button id='viewFullLegislativePageButton' size='lg' href='/mexico/economy' >View all Economy news</Button>
 



    





    </div>
  </Col>
















</div>

</Carousel.Item>



 </Carousel>
                  </Col>

{/*Environment Column */}
<Col id='topicColumn'>

                        <Row id="topicPageBranchRow">

                        <h3 id='branchHeading' onClick={() => window.open('/mexico/environment', "_self")}> <Tree></Tree> <b id='branchHeadingBold'>Environment</b><ChevronRight id='clickChevron'></ChevronRight></h3>


                          </Row>

                          <Carousel id='homeCarousel' controls={false} >

                          <Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
              <div id='legislativeCon'>
   
          <Col id='legislativeColRight'>
            <div id='columnLefttPadding'>



            <h4 id='feedHeadingBillsEnrolled' onClick={() => setFullBillModalIsOpen(true)}><b>Secretaría de Agricultura y Desarrollo Rural</b></h4>

            <div id='executiveNews2'></div>
                        
                        {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
                        {CreateFeedAPI('&skip=0&schema=mexico&table=agricultura&order_by=created_at', 'executiveNews2', 
                            'Secretaría de Agricultura y Desarrollo Rural', 'Legislative', 
                            'Environment', 'Doc', GenerateFeed)}



            </div>
          </Col>

  </div>

</Carousel.Item>

<Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
              <div id='legislativeCon'>
   
          <Col id='legislativeColRight'>
            <div id='columnLefttPadding'>



            <h4 id='feedHeadingBillsEnrolled' onClick={() => setTrendingBillsOptionFiveModalIsOpen(true)}><b>Comisión Nacional Forestal</b></h4>
<div id='conafor'></div>
                  
                        {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
                        {CreateFeedAPI('&skip=0&schema=mexico&table=conafor&order_by=created_at', 'conafor', 
                            'Comisión Nacional Forestal', 'Executive', 
                            'Environment', 'Doc', GenerateFeed)}

<Modal id='mobileFullBillModal' show={trendingBillsOptionFive} 
size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionFiveModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Comisión Nacional Forestal</h3></Modal.Header>
<GenerateFeed url='&skip=0&schema=mexico&table=conafor&order_by=created_at'
                                name='Comisión Nacional Forestal' branch='Executive' topic='Environment' buttonName='Doc' />
</Modal>


            </div>
          </Col>

  </div>

</Carousel.Item>


<Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
              <div id='legislativeCon'>
   
          <Col id='legislativeColRight'>
            <div id='columnLefttPadding'>



            <h4 id='feedHeadingBillsEnrolled' onClick={() => setTrendingBillsOptionFourModalIsOpen(true)}><b>Comisión Nacional del Agua</b></h4>
<div id='conagua'></div>
{CreateFeedAPI('&skip=0&schema=mexico&table=conagua&order_by=created_at', 'conagua', 
                            'Comisión Nacional del Agua', 'Executive', 
                            'Environment', 'Doc', GenerateFeed)}

<Modal id='mobileFullBillModal' show={trendingBillsOptionFour} 
size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionFourModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Comisión Nacional del Agua</h3></Modal.Header>
<GenerateFeed url='&skip=0&schema=mexico&table=conagua&order_by=created_at'
                                name='Comisión Nacional del Agua' branch='Executive' topic='Environment' buttonName='Doc' />
</Modal>


            </div>
          </Col>

  </div>

</Carousel.Item>

<Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
              <div id='legislativeCon'>
   
          <Col id='legislativeColRight'>
            <div id='columnLefttPadding'>



            <h4 id='feedHeadingBillsEnrolled' onClick={() => setTrendingBillsOptionTweleveModalIsOpen(true)}><b>Secretaría de Comunicaciones y Transportes</b></h4>
<div id='sct'></div>

{/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
{CreateFeedAPI('&skip=0&schema=mexico&table=sct&order_by=created_at', 'sct', 
                            'Secretaría de Comunicaciones y Transportes', 'Executive', 
                            'Environment', 'Doc', GenerateFeed)}

<Modal id='mobileFullBillModal' show={trendingBillsOptionNine} 
size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionNineModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Secretaría de Comunicaciones y Transportes</h3></Modal.Header>
<GenerateFeed url='&skip=0&schema=mexico&table=sct&order_by=created_at'
                                name='Secretaría de Comunicaciones y Transportes' branch='Executive' topic='Environment' buttonName='Doc' />
</Modal>




            </div>
          </Col>

  </div>

</Carousel.Item>

<Carousel.Item interval={carouselInterval} >

<div id='legislativeCon'>



  <Col id='legislativeColRight'>
    <div id='columnLefttPadding'>
    


    <Button id='viewFullLegislativePageButton' size='lg' href='/mexico/environment' >View all Environment news</Button>
 



    





    </div>
  </Col>
















</div>

</Carousel.Item>



 </Carousel>
                          </Col>
{/*Foreign Affairs Column */}
<Col id='topicColumn'>

<Row id="topicPageBranchRow">

<h3 id='branchHeading' onClick={() => window.open('/mexico/foreignaffairs', "_self")}> <Globe2></Globe2> <b id='branchHeadingBold'>Foreign Affairs</b><ChevronRight id='clickChevron'></ChevronRight></h3>


  </Row>
  <Carousel id='homeCarousel' controls={false} >


  <Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
              <div id='legislativeCon'>
   
          <Col id='legislativeColRight'>
            <div id='columnLefttPadding'>



            <h4 id='feedHeadingBillsEnrolled' onClick={() => setTrendingBillsOptionSevenModalIsOpen(true)}><b>Instituto Nacional de Migración</b></h4>
<div id='inm'></div>
{CreateFeedAPI('&skip=0&schema=mexico&table=inm&order_by=created_at', 'inm', 
                            'Instituto Nacional de Migración', 'Executive', 
                            'Foreign Affairs', 'Doc', GenerateFeed)}

<Modal id='mobileFullBillModal' show={trendingBillsOptionSeven} 
size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionSevenModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Instituto Nacional de Migración</h3></Modal.Header>
<GenerateFeed url='&skip=0&schema=mexico&table=inm&order_by=created_at'
                                name='Instituto Nacional de Migración' branch='Executive' topic='Foreign Affairs' buttonName='Doc' />
</Modal>





            </div>
          </Col>

  </div>

</Carousel.Item>

<Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
              <div id='legislativeCon'>
   
          <Col id='legislativeColRight'>
            <div id='columnLefttPadding'>



            <h4 id='feedHeadingBillsEnrolled' onClick={() => setTrendingBillsOptionThirteenModalIsOpen(true)}><b>Secretaría de la Defensa Nacional</b></h4>
<div id='sedena'></div>
                       
{CreateFeedAPI('&skip=0&schema=mexico&table=sedena&order_by=created_at', 'sedena', 
                            'Secretaría de la Defensa Nacional', 'Executive', 
                            'Foreign Affairs', 'Doc', GenerateFeed)}

<Modal id='mobileFullBillModal' show={trendingBillsOptionThirteen} 
size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionThirteenModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Secretaría de la Defensa Nacional</h3></Modal.Header>
<GenerateFeed url='&skip=0&schema=mexico&table=sedena&order_by=created_at'
                                name='Secretaría de la Defensa Nacional' branch='Executive' topic='Foreign Affairs' buttonName='Doc' />
</Modal>






            </div>
          </Col>

  </div>

</Carousel.Item>

<Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
              <div id='legislativeCon'>
   
          <Col id='legislativeColRight'>
            <div id='columnLefttPadding'>



            <h4 id='feedHeadingBillsEnrolled' onClick={() => setDepartmentOfCommerceModalIsOpen(true)}><b>Secretaría de Marina</b></h4>
<div id='semar'></div>
                     
{CreateFeedAPI('&skip=0&schema=mexico&table=semar&order_by=created_at', 'semar', 
                             'Secretaría de Marina', 'Executive', 
                             'N/A', 'Doc', GenerateFeed)}
 
 <Modal id='mobileFullBillModal' show={departmentOfCommerceModalIsOpen} 
 size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfCommerceModalIsOpen(false)}>
 <Modal.Header id='mobileBillModalHeader' closeButton><h3>Secretaría de Marina</h3></Modal.Header>
 <GenerateFeed url='&skip=0&schema=mexico&table=semar&order_by=created_at'
                                 name='Secretaría de Marina' branch='Executive' topic='N/A' buttonName='Doc' />
 </Modal>







            </div>
          </Col>

  </div>

</Carousel.Item>

<Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
              <div id='legislativeCon'>
   
          <Col id='legislativeColRight'>
            <div id='columnLefttPadding'>



            <h4 id='feedHeadingBillsEnrolled' onClick={() => setDepartmentOfHealthAndHumanServicesModalIsOpen(true)}><b>Secretaría de Relaciones Exteriores</b></h4>
<div id='sre'></div>
{CreateFeedAPI('&skip=0&schema=mexico&table=sre&order_by=created_at', 'sre', 
                             'Secretaría de Relaciones Exteriores', 'Executive', 
                             'Foreign Affairs', 'Doc', GenerateFeed)}
 
 <Modal id='mobileFullBillModal' show={departmentOfHealthAndHumanServicesModalIsOpen} 
 size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfHealthAndHumanServicesModalIsOpen(false)}>
 <Modal.Header id='mobileBillModalHeader' closeButton><h3>Secretaría de Relaciones Exteriores</h3></Modal.Header>
 <GenerateFeed url='&skip=0&schema=mexico&table=sre&order_by=created_at'
                                 name='Secretaría de Relaciones Exteriores' branch='Executive' topic='Foreign Affairs' buttonName='Doc' />
 </Modal>








            </div>
          </Col>

  </div>

</Carousel.Item>


<Carousel.Item interval={carouselInterval} >

<div id='legislativeCon'>



  <Col id='legislativeColRight'>
    <div id='columnLefttPadding'>
    


    <Button id='viewFullLegislativePageButton' size='lg' href='/mexico/foreignaffairs' >View all Foreign Affairs news</Button>
 



    





    </div>
  </Col>
















</div>

</Carousel.Item>






 </Carousel>
  </Col>

            </Row>

            <Row id='topicsRow'>

              {/*Health Column */}
 <Col id='topicColumn'>

<Row id="topicPageBranchRow">

<h3 onClick={() => window.open('/mexico/health', "_self")} id='branchHeading' > <FileMedicalFill></FileMedicalFill> <b id='branchHeadingBold'>Health</b><ChevronRight id='clickChevron'></ChevronRight></h3>


  </Row>
  <Carousel  controls={false} >


  <Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
              <div id='legislativeCon'>
   
          <Col id='legislativeColRight'>
            <div id='columnLefttPadding'>



            <h4 id='feedHeadingBillsEnrolled' onClick={() => setDepartmentOfHealthAndHumanServicesModalIsOpen(true)}><b>Secretaría de Relaciones Exteriores</b></h4>
<div id='sre2'></div>
{CreateFeedAPI('&skip=0&schema=mexico&table=sre&order_by=created_at', 'sre2', 
                             'Secretaría de Relaciones Exteriores', 'Executive', 
                             'Foreign Affairs', 'Doc', GenerateFeed)}
 
 <Modal id='mobileFullBillModal' show={departmentOfHealthAndHumanServicesModalIsOpen} 
 size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfHealthAndHumanServicesModalIsOpen(false)}>
 <Modal.Header id='mobileBillModalHeader' closeButton><h3>Secretaría de Relaciones Exteriores</h3></Modal.Header>
 <GenerateFeed url='&skip=0&schema=mexico&table=sre&order_by=created_at'
                                 name='Secretaría de Relaciones Exteriores' branch='Executive' topic='Foreign Affairs' buttonName='Doc' />
 </Modal>








            </div>
          </Col>

  </div>

</Carousel.Item>

<Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
              <div id='legislativeCon'>
   
          <Col id='legislativeColRight'>
            <div id='columnLefttPadding'>


            <h4 id='feedHeadingBillsEnrolled' onClick={() => setTrendingBillsOptionTwoModalIsOpen(true)}><b>Comisión Nacional de Cultura Física y Deporte</b></h4>
<div id='conade'></div>
                      
{CreateFeedAPI('&skip=0&schema=mexico&table=conade&order_by=created_at', 'conade', 
                            'Comisión Nacional de Cultura Física y Deporte', 'Executive', 
                            'Health', 'Doc', GenerateFeed)}



<Modal id='mobileFullBillModal' show={trendingBillsOptionTwo} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionTwoModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Comisión Nacional de Cultura Física y Deporte</h3></Modal.Header>
<GenerateFeed url='&skip=0&schema=mexico&table=conade&order_by=created_at'
                                name='Comisión Nacional de Cultura Física y Deporte' branch='Executive' topic='Health' buttonName='Doc' />
</Modal>









            </div>
          </Col>

  </div>

</Carousel.Item>

<Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
              <div id='legislativeCon'>
   
          <Col id='legislativeColRight'>
            <div id='columnLefttPadding'>

            <h4 id='feedHeadingBillsEnrolled' onClick={() => setTrendingBillsOptionThreeModalIsOpen(true)}><b>Consejo Nacional para el Desarrollo y la Inclusión de las Personas con Discapacidad</b></h4>
<div id='conadis'></div>

{/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
                      
{CreateFeedAPI('&skip=0&schema=mexico&table=conadis&order_by=created_at', 'conadis', 
                            'Consejo Nacional para el Desarrollo y la Inclusión de las Personas con Discapacidad', 'Executive', 
                            'Health', 'Doc', GenerateFeed)}


<Modal id='mobileFullBillModal' show={trendingBillsOptionThree} 
size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionThreeModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Consejo Nacional para el Desarrollo y la Inclusión de las Personas con Discapacidad</h3></Modal.Header>
<GenerateFeed url='&skip=0&schema=mexico&table=conadis&order_by=created_at'
                                name='Consejo Nacional para el Desarrollo y la Inclusión de las Personas con Discapacidad' branch='Executive' topic='Health' buttonName='Doc' />
</Modal>










            </div>
          </Col>

  </div>

</Carousel.Item>

<Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
              <div id='legislativeCon'>
   
          <Col id='legislativeColRight'>
            <div id='columnLefttPadding'>

            <h4 id='feedHeadingBillsEnrolled' onClick={() => setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen(true)}><b>Secretaría del Trabajo y Previsión Social</b></h4>
<div id='stps'></div>
{CreateFeedAPI('&skip=0&schema=mexico&table=stps&order_by=created_at', 'stps', 
                            'Secretaría del Trabajo y Previsión Social', 'Executive', 
                            'Health', 'Doc', GenerateFeed)}
<Modal id='mobileFullBillModal' show={departmentOfHousingAndUrbanDevelopmentModalIsOpen} 
size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Secretaría del Trabajo y Previsión Social</h3></Modal.Header>
<GenerateFeed url='&skip=0&schema=mexico&table=stps&order_by=created_at'
                                name='Secretaría del Trabajo y Previsión Social' branch='Executive' topic='Health' buttonName='Doc' />
</Modal>











            </div>
          </Col>

  </div>

</Carousel.Item>

<Carousel.Item interval={carouselInterval} >

<div id='legislativeCon'>



  <Col id='legislativeColRight'>
    <div id='columnLefttPadding'>
    


    <Button id='viewFullLegislativePageButton' size='lg' href='/mexico/health' >View all Science and Tech news</Button>
 



    





    </div>
  </Col>


</div>

</Carousel.Item>


 </Carousel>



  </Col>

  <Col id='topicColumn'>

<Row id="topicPageBranchRow">

<h3 id='branchHeading' > <ReceiptCutoff></ReceiptCutoff> <b id='branchHeadingBold'>Elections</b><ChevronRight id='clickChevron'></ChevronRight></h3>


  </Row>
  <Carousel id='healthCarousel' controls={false} >




  <Carousel.Item interval={carouselInterval} >

<div id='legislativeCon'>



  <Col id='legislativeColRight'>
    <div id='columnLefttPadding'>
    


    <Button id='viewFullLegislativePageButton' size='lg'  >Coming Soon</Button>
 



    





    </div>
  </Col>
















</div>

</Carousel.Item>



 </Carousel>

 




  </Col>
  <Col id='topicColumn'>

<Row id="topicPageBranchRow">

<h3 id='branchHeading' onClick={() => window.open('/mexico/science&tech', "_self")}> <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-rocket-takeoff" viewBox="0 0 16 16">
  <path d="M9.752 6.193c.599.6 1.73.437 2.528-.362.798-.799.96-1.932.362-2.531-.599-.6-1.73-.438-2.528.361-.798.8-.96 1.933-.362 2.532Z"/>
  <path d="M15.811 3.312c-.363 1.534-1.334 3.626-3.64 6.218l-.24 2.408a2.56 2.56 0 0 1-.732 1.526L8.817 15.85a.51.51 0 0 1-.867-.434l.27-1.899c.04-.28-.013-.593-.131-.956a9.42 9.42 0 0 0-.249-.657l-.082-.202c-.815-.197-1.578-.662-2.191-1.277-.614-.615-1.079-1.379-1.275-2.195l-.203-.083a9.556 9.556 0 0 0-.655-.248c-.363-.119-.675-.172-.955-.132l-1.896.27A.51.51 0 0 1 .15 7.17l2.382-2.386c.41-.41.947-.67 1.524-.734h.006l2.4-.238C9.005 1.55 11.087.582 12.623.208c.89-.217 1.59-.232 2.08-.188.244.023.435.06.57.093.067.017.12.033.16.045.184.06.279.13.351.295l.029.073a3.475 3.475 0 0 1 .157.721c.055.485.051 1.178-.159 2.065Zm-4.828 7.475.04-.04-.107 1.081a1.536 1.536 0 0 1-.44.913l-1.298 1.3.054-.38c.072-.506-.034-.993-.172-1.418a8.548 8.548 0 0 0-.164-.45c.738-.065 1.462-.38 2.087-1.006ZM5.205 5c-.625.626-.94 1.351-1.004 2.09a8.497 8.497 0 0 0-.45-.164c-.424-.138-.91-.244-1.416-.172l-.38.054 1.3-1.3c.245-.246.566-.401.91-.44l1.08-.107-.04.039Zm9.406-3.961c-.38-.034-.967-.027-1.746.163-1.558.38-3.917 1.496-6.937 4.521-.62.62-.799 1.34-.687 2.051.107.676.483 1.362 1.048 1.928.564.565 1.25.941 1.924 1.049.71.112 1.429-.067 2.048-.688 3.079-3.083 4.192-5.444 4.556-6.987.183-.771.18-1.345.138-1.713a2.835 2.835 0 0 0-.045-.283 3.078 3.078 0 0 0-.3-.041Z"/>
  <path d="M7.009 12.139a7.632 7.632 0 0 1-1.804-1.352A7.568 7.568 0 0 1 3.794 8.86c-1.102.992-1.965 5.054-1.839 5.18.125.126 3.936-.896 5.054-1.902Z"/>
</svg> <b id='branchHeadingBold'>Science & Tech</b><ChevronRight id='clickChevron'></ChevronRight></h3>


  </Row>
  <Carousel id='healthCarousel' controls={false} >

  <Carousel.Item interval={carouselInterval} >

<div id='legislativeCon'>



  <Col id='legislativeColRight'>
    <div id='columnLefttPadding'>
    


    <Button id='viewFullLegislativePageButton' size='lg'  >Coming Soon</Button>
 



    





    </div>
  </Col>
















</div>

</Carousel.Item>






 </Carousel>

 




  </Col>



       {/*Placeholder2 Column */}

              </Row>

              <Row id='topicsRow'>

{/*Health Column */}
<Col id='topicColumn'>

<Row id="topicPageBranchRow">

<h3 onClick={() => window.open('/mexico/defense', "_self")} id='branchHeading' ><ShieldShaded/> <b id='branchHeadingBold'>Defense</b><ChevronRight id='clickChevron'></ChevronRight></h3>


</Row>
<Carousel  controls={false} >


<Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
<div id='legislativeCon'>

<Col id='legislativeColRight'>
<div id='columnLefttPadding'>



<h4 id='presidentialFeedHeading' onClick={() => setTrendingBillsOptionThirteenModalIsOpen(true)}><b>Secretaría de la Defensa Nacional</b></h4>
                          <div id='sedena2'></div>
                          
                                     
  {CreateFeedAPI('&skip=0&schema=mexico&table=sedena&order_by=created_at', 'sedena2', 
                             'Secretaría de la Defensa Nacional', 'Executive', 
                             'Foreign Affairs', 'Doc', GenerateFeed)}
 

</div>
</Col>

</div>

</Carousel.Item>



<Carousel.Item interval={carouselInterval} >

<div id='legislativeCon'>



<Col id='legislativeColRight'>
<div id='columnLefttPadding'>



<Button id='viewFullLegislativePageButton' size='lg' href='/mexico/defense' >View all Defense news</Button>










</div>
</Col>


</div>

</Carousel.Item>


</Carousel>



</Col>

<Col id='topicColumn'>

<Row id="topicPageBranchRow">

<h3 onClick={() => window.open('/mexico/infrastructure', "_self")} id='branchHeading' >  <b id='branchHeadingBold'><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-building" viewBox="0 0 16 16">
  <path d="M4 2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zM4 5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zM7.5 5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zM4.5 8a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5z"/>
  <path d="M2 1a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1zm11 0H3v14h3v-2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5V15h3z"/>
</svg> Infrastructure</b><ChevronRight id='clickChevron'></ChevronRight></h3>


</Row>
<Carousel  controls={false} >


<Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
<div id='legislativeCon'>

<Col id='legislativeColRight'>
<div id='columnLefttPadding'>



<h4 id='presidentialFeedHeading' onClick={() => setTrendingBillsOptionFifteenModalIsOpen(true)}><b>Secretaría de Comunicaciones y Transportes</b></h4>
                          <div id='sct2'></div>
                          
                          {CreateFeedAPI('&skip=0&schema=mexico&table=sct&order_by=created_at', 'sct2', 
                            'Secretaría de Comunicaciones y Transportes', 'Executive', 
                            'Environment', 'Doc', GenerateFeed)}

<Modal id='mobileFullBillModal' show={trendingBillsOptionFifteen} 
size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionFifteenModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Secretaría de Comunicaciones y Transportes</h3></Modal.Header>
<GenerateFeed url='&skip=0&schema=mexico&table=sct&order_by=created_at'
                                name='Secretaría de Comunicaciones y Transportes' branch='Executive' topic='Environment' buttonName='Doc' />
</Modal>









</div>
</Col>

</div>

</Carousel.Item>

<Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
<div id='legislativeCon'>

<Col id='legislativeColRight'>
<div id='columnLefttPadding'>



<h4 id='presidentialFeedHeading'onClick={() => setTrendingBillsOptionFourteenModalIsOpen(true)}><b>Secretaría de Desarrollo Agrario, Territorial y Urbano</b></h4>
                        <div id='sedatu'></div>
                          
                       
  {CreateFeedAPI('&skip=0&schema=mexico&table=sedatu&order_by=created_at', 'sedatu', 
                            'Secretaría de Desarrollo Agrario, Territorial y Urbano', 'Executive', 
                            'Environment', 'Doc', GenerateFeed)}

<Modal id='mobileFullBillModal' show={trendingBillsOptionFourteen} 
size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionFourteenModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Secretaría de Desarrollo Agrario, Territorial y Urbano</h3></Modal.Header>
<GenerateFeed url='&skip=0&schema=mexico&table=sedatu&order_by=created_at'
                                name='Secretaría de Desarrollo Agrario, Territorial y Urbano' branch='Executive' topic='Environment' buttonName='Doc' />
</Modal>








</div>
</Col>

</div>

</Carousel.Item>

<Carousel.Item interval={carouselInterval} >

<div id='legislativeCon'>



<Col id='legislativeColRight'>
<div id='columnLefttPadding'>



<Button id='viewFullLegislativePageButton' size='lg' href='/mexico/infrastructure' >View all Infrastructure news</Button>
</div>
</Col>


</div>

</Carousel.Item>


</Carousel>






</Col>
<Col id='topicColumn'>

<Row id="topicPageBranchRow">
</Row>
<Carousel id='healthCarousel' controls={false} >
</Carousel>






</Col>



{/*Placeholder2 Column */}

</Row>


              <Row id="topicPageBranchRow">


  <Col id='topicColumn'>










  </Col>
  </Row>

  <Row id='branchesRow'>

{/*Health Column */}
<Col id='homeColumn'>
<Carousel id='healthCarousel' controls={false} >
</Carousel>
</Col>

<Col id='homeColumn'>
<Carousel id='healthCarousel' controls={false} >
</Carousel>
</Col>
<Col id='homeColumn'>




<Carousel id='healthCarousel' controls={false} >

</Carousel>






</Col>

                



{/*Placeholder2 Column */}

</Row>


<br></br>
  
  <h5 id='branchHeadingPicker'><GeoAlt/>   Change country <ChevronRight id='clickChevron'></ChevronRight></h5>

  <Col>
  <Button id='selectHomeCountryDesktop'   onClick={() => window.open('/canada', "_self")}>
        🇨🇦 Canada
      </Button>

      <Button id='selectHomeCountryDesktop'   onClick={() => window.open('/mexico', "_self")}>
      🇲🇽 Mexico
      </Button>

      <Button id='selectHomeCountryDesktop' onClick={() => window.open('/uk', "_self")}>
        🇬🇧 United Kingdom
      </Button>

      <Button id='selectHomeCountryDesktop' onClick={() => window.open('/us', "_self")}>
        🇺🇸 United States
      </Button>
      </Col>

                     <br></br>
   
      </div>
      </>
    );
  }





  
  
  

  

  
  //onClick={linkToBill()}
  return (
    
    <>
    
      <div className="App">
   
    




      <BrowserView>
      <div id='homePage'>
        


        <div id='homebg'>

     

          

         <MexicoDesktopNavbar/>
       

            <BranchesTab />
            

            {/*onClick={() => } */}
           
           
        
   
         


          
            
              
             
              
            
          
            
            
          <Modal id='aboutModal' show={aboutModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAboutModalIsOpen(false)}>
            <div id='removedAlert'></div>
            <Modal.Header id='aboutModalHeader' closeButton><h3>About</h3></Modal.Header>
  <ModalBody id='aboutInfo'><p id='aboutParagraph'>Gov Glance was created to drive civic engagement and support a 
              healthier democracy by helping citizens get official Government news in an easy-to-navigate centralized location. 
              There will never be any opinion or political analysis(That's your job), only information from official Government 
              sources. Gov Glance is structured after the U.S. government that is broken into three branches Legislative, 
              Executive, and Judicial. To learn more about the structure and functions of The U.S. Government go to <a href='https://www.usa.gov/branches-of-government'>https://www.usa.gov/branches-of-government</a>.
              </p>

              <p id='aboutParagraph'>
              Gov Glance Foundation Inc. is a non-profit that was created to operate govglance.org and continue to create educational tools that help people learn about the workings of society
              </p>
            <br></br>

            <h3>Privacy</h3>

            <br></br>

              <p id='aboutParagraph'>Gov Glance does not collect any personal user information. 
              Features like collection save news items directly to your browser's local storage for your convenience
              and is not shared with Gov Glance servers. We do not collect, store, or send personal information to Google LLC, 
              but we do use some Google services to deliver our app to you. For any questions into Google’s privacy practices 
              here is a link to their privacy policy: <a href='https://policies.google.com/privacy?hl=en-US#intro'>https://policies.google.com/privacy?hl=en-US#intro</a>

              <br></br><br></br>

              For site analytics we use plausible.io an open source privacy friendly analytics provider. <a href="https://plausible.io/about" target="_blank">https://plausible.io/about</a>
            
            </p>
            <br></br>


            <h3>Contact</h3>

            <p id='aboutParagraph'>
                      Contact us via email: <a href='mailto:contact@govglance.org'>contact@govglance.org</a>

            </p>

            <br></br>


            
            </ModalBody>
          </Modal>
 
     <footer id='homeFooter'>
      <Navbar>
        <Row id='footerNav'>
      <Nav variant='pills'>
        
     <Nav.Link id='footerContent'>Gov Glance Foundation Inc.
</Nav.Link>
<Nav.Item>
<Nav.Link id='footerContent' href='mailto:contact@govglance.org'>Contact</Nav.Link>
</Nav.Item>
<Nav.Item>
<Nav.Link id='footerContent' onClick={(e) => {
                   e.preventDefault();
                   window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                 }}> <HeartFill id='donateIcon'/>    Donate</Nav.Link>
</Nav.Item>
<Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/about', "_self")}} id='footerContent'>Privacy</Nav.Link>
    
     
 </Nav>
 </Row>
 </Navbar>
      </footer>

      


    
          </div>
  

    </div>
      </BrowserView>
     

      <MobileView> 
        
    <MexicoHomeMobile/>
                      

     
      </MobileView>
        </div>
      


       
    </>

     
    
  );







}
}


export default MexicoHome;

import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch,  } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment,  } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image, ModalFooter } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText,  ShieldShaded, CircleFill, Pen, People, ChatSquareQuote, ChatLeftQuote, BlockquoteRight, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, CaretRight, ChevronRight, ChevronCompactRight, CaretRightFill, ArrowRightShort,  ArrowRightCircle, PlayCircle, PauseCircle, HeartFill, Link45deg } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'

import { render } from '@testing-library/react';
import FullBillFeed from './FullBillFeed';
import FullWhiteHouseFeed from './FullWhiteHouseFeed';
import FullUSCourtNews from './FullUSCourtNews';
import FullWhiteHouseDocuments from './FullWhiteHouseDocuments';
import DailyDigest from './DailyDigest';
import State from './State';
import Illinois from './state/Illinois';
import CollectionPage from './CollectionPage';
import NewLaws from './NewLaws';
import BillsEnrolled from './BillsEnrolled';
import DepartmentOfState from './DepartmentOfState';
import DepartmentOfTreasury from './DepartmentOfTreasury';
import CourtAppeals from './CourtAppeals';
import Reports from './Reports';
import DepartmentOfDefense from './DepartmentOfDefense';
import DepartmentOfJustice from './DepartmentOfJustice';
import DepartmentOfTheInterior from './DepartmentOfTheInterior';
import DepartmentOfAgriculture from './DepartmentOfAgriculture';
import DepartmentOfCommerce from './DepartmentOfCommerce';
import DepartmentOfLabor from './DepartmentOfLabor';
import DepartmentOfHealthAndHumanServices from './DepartmentOfHealthAndHumanServices';
import DepartmentOfHousingAndUrbanDevelopment from './DepartmentOfHousingAndUrbanDevelopment';
import DepartmentOfTransportation from './DepartmentOfTransportation';
import DepartmentOfEnergy from './DepartmentOfEnergy';
import DepartmentOfEducation from './DepartmentOfEducation';
import DepartmentOfVeteranAffairs from './DepartmentOfVeteranAffairs';
import DepartmentOfHomelandSecurity from './DepartmentOfHomelandSecurity';
import EnvironmentalProtectionAgency from './EnvironmentalProtectionAgency';
import NationalAeronauticsAndSpaceAdministration from './NationalAeronauticsAndSpaceAdministration';
import TrendingBills from './TrendingBills';
import GAOReports from './GAOReports';
import LegislativeBranch from './LegislativeBranch';
import HouseComitteeOnAgriculture from './HouseComitteeOnAgriculture';
import HouseCommitteeOnAppropriations from './HouseCommitteeOnArmedServices';
import HouseCommitteeOnOversightAndReform from './HouseCommitteeOnOversightAndReform';

import App from './App';
import Overlay from 'react-overlays/esm/Overlay';
import HouseCommitteeOnArmedServices from './HouseCommitteeOnArmedServices';
import HouseCommitteeOnTheBudget from './HouseCommitteeOnTheBudget';
import HouseCommitteeOnEducationAndLabor from './HouseCommitteeOnEducationAndLabor';
import HouseFloor from './HouseFloor';
import HouseCommitteeOnEnergyAndCommerce from './HouseCommitteeOnEnergyAndCommerce';
import HouseCommitteeOnEthics from './HouseCommitteeOnEthics';
import CongressionalHearings from './CongressionalHearings';
import HouseCommitteeOnFinacialServices from './HouseCommitteeOnFinacialServices';
import HouseCommitteeOnForeignAffairs from './HouseCommitteeOnForeignAffairs';
import HouseCommitteeOnHomelandSecurity from './HouseCommitteeOnHomelandSecurity';
import HouseCommitteeOnTheJudiciary from './HouseCommitteeOnTheJudiciary';
import HouseCommitteeOnNaturalResources from './HouseCommitteeOnNaturalResources';
import HouseCommitteeOnHouseAdministration from './HouseCommitteeOnHouseAdministration';
import CongressionalBudgetOffice from './CongressionalBudgetOffice';
import PresidentialProclamations from './PresidentialProclamations';
import ExecutiveOrders from './ExecutiveOrders';
import{ whiteHouseSearch, createPresidentialProclamations, createExecutiveOrders, createWhiteHouseDocuments, createWhiteHouseFeed,
    createWhiteHouseFeedMobile,createDepartmentOfState,
    createDepartmentOfDefense, 
    createDepartmentOfJustice, createDepartmentOfTheInterior,
    createDepartmentOfAgriculture, createDepartmentOfCommerce,
    createDepartmentOfCommerceMobile, createDepartmentOfLabor,
    createDepartmentOfHealthAndHumanServices, createDepartmentOfHousingAndUrbanDevelopment, createDepartmentOfEducation
    ,  createDepartmentOfVeteransAffairs, createDepartmentOfHomelandSecurity
    , createEnvironmentalProtectionAgency,
    createDepartmentOfTreasury, createNationalScienceFoundation, 
     createEnvironmentalProtectionAgencyMobile, createNationalAeronauticsAndSpaceAdministration, Speak, createFederalRegisterDefenseDepartment, 
     createFederalRegisterStateDepartment, createFederalRegisterDefense,
    CreateFeedItem, CreateFeedItemPresidentialDocuments, CreateExecutiveOrders,
    CreateFeedItemExecutiveOrders,
    CreateFeedItemBillUpdates,
    CreateFeedItemExecutiveOffice,
    CreateFeedItemNoDate,
    CreateFeedItemBillWithDescription,
    CreateFeedAPI,
    CreateFeedItemExecutiveOrdersCache,  } from './Functions'
import NationalScienceFoundation from './NationalScienceFoundation';
import FederalRegisterDefenseDepartment from './FederalRegisterDefenseDepartment';
import FederalRegisterStateDepartment from './FederalRegisterStateDepartment';
import FederalRegisterTreasuryDepartment from './FederalRegisterTreasuryDepartment';
import MobileOffCanvas from './MobileOffCanvas';
import MobileTopNavbar from './MobileTopNavbar';
import BillUpdates from './BillUpdates';
import CouncilOfEconomicAdvisers from './CouncilOfEconomicAdvisers';
import CouncilOnEnvironmentQuality from './CouncilOnEnvironmentQuality';
import DomesticPolicyCouncil from './DomesticPolicyCouncil';
import GenderPolicyCouncil from './GenderPolicyCouncil';
import NationalEconomicCouncil from './NationalEconomicCouncil';
import ArmyDocuments from './ArmyDocuments';
import NavyDocuments from './NavyDocuments';

import CIADocuments from './CIADocuments';
import TSADocuments from './TSADocuments';
import SecretService from './SecretService';
import FEMADocuments from './FEMADocuments';
import CoastGuardDocuments from './CoastGuardDocuments';
import HomelandSecurityDocuments from './HomelandSecurityDocuments';
import DepartmentOfCommerceDocuments from './DepartmentOfCommerceDocuments';
import CensusBureau from './CensusBureau';
import EconomicAnalysisBureau from './EconomicAnalysisBureau';
import EconomicDevelopmentAdministration from './EconomicDevelopmentAdministration';
import DoSPress from './DoSPress';
import ArmsControl from './ArmsControl';
import DepartmentOfAgricultureDocuments from './DepartmentOfAgricultureDocuments';
import DepartmentOfEducationDocs from './DepartmentOfEducationDocs';
import DepartmentOfEnergyDocs from './DepartmentOfEnergyDocs';
import DepartmentOfHousingAndUrbanDevelopmentDocs from './DepartmentOfHousingAndUrbanDevelopmentDocs';
import DepartmentOfHealthAndHumanServicesDocs from './DepartmentOfHealthAndHumanServicesDocs';
import DepartmentOfTheInteriorDocs from './DepartmentOfTheInteriorDocs';
import DepartmentOfJusticeDocs from './DepartmentOfJusticeDocs';
import DepartmentOfLaborDocs from './DepartmentOfLaborDocs';
import DepartmentOfTreasuryDocs from './DepartmentOfTreasuryDocs';
import DepartmentOfVeteransAffairsDocs from './DepartmentOfVeteransAffairsDocs';
import DepartmentOfTransportationDocs from './DepartmentOfTransportationDocs';
import CommercialSpaceTransportationOffice from './CommercialSpaceTransportationOffice';
import EnergyInformationAdministration from './EnergyInformationAdministration';
import FederalEnergyRegulatoryCommission from './FederalEnergyRegulatoryCommission';
import MobileBackButton from './MobileBackButton';

import GenerateFeed from './GenerateFeed';

import PresidentDisplay from './PresidentDisplay';
import VicePresidentDisplay from './VicePresidentDisplay';
import PresidentDisplayOnPage from './PresidentDisplayOnPage';
import DesktopNavbar from './DesktopNavbar';
import VicePresidentDisplayOnPage from './VicePresidentDisplayOnPage';
import WhiteHousePageMobile from './WhiteHousePageMobile';
import PresidentDisplayOnPageExec from './PresidentDisplayOnPageExec';
import VicePresidentDisplayOnPageExec from './VicePresidentDisplayOnPageExec';
const CustomModal = ({ isOpen, onClose, title, content }) => (
  <Modal id='fullBillModal' show={isOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={onClose}>
    <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
    {content}
  </Modal>
);


var response = '';

var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 30000;


export const WhiteHousePage = () =>   {
    render()

    {
        const [show, setShow] = useState(true);

        const [fullscreen, setFullScreen] = useState(true);
      
      
        const [fullBillModalIsOpen, setFullBillModalIsOpen] = useState(false)
      
        const [reportModalIsOpen, setReportModalIsOpen] = useState(false)
      
      
        const [whiteHouseFeedModalIsOpen, setWhiteHouseFeedModalIsOpen] = useState(false)
        const [whiteHouseDocsModalIsOpen, setWhiteHouseDocsModalIsOpen] = useState(false)
      
      
        const [courtNewsModalIsOpen, setCourtNewsModalIsOpen] = useState(false)
        const [courtModalIsOpen, setCourtModalIsOpen] = useState(false)
      
        const [courtAppealsModalIsOpen, setCourtAppealsModalIsOpen] = useState(false)
      
        const [newLawsModalIsOpen, setNewLawsModalIsOpen] = useState(false)
      
        const [billsEnrolledModalIsOpen, setBillsEnrolledModalIsOpen] = useState(false)
      
        const [legislativePageBillsEnrolledModalIsOpen, setLegislativePageBillsEnrolledModalIsOpen] = useState(false)
      
      
        const [departmentOfStateModalIsOpen, setDepartmentOfStateModalIsOpen] = useState(false)
        const [departmentOfTreasuryModalIsOpen, setDepartmentOfTreasuryModalIsOpen] = useState(false)
        const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
        const [departmentOfJusticeModalIsOpen, setDepartmentOfJusticeModalIsOpen] = useState(false)
        const [departmentOfTheInteriorModalIsOpen, setDepartmentOfTheInteriorModalIsOpen] = useState(false)
        const [departmentOfAgricultureModalIsOpen, setDepartmentOfAgricultureModalIsOpen] = useState(false)
        const [departmentOfCommerceModalIsOpen, setDepartmentOfCommerceModalIsOpen] = useState(false)
        const [departmentOfLaborModalIsOpen, setDepartmentOfLaborModalIsOpen] = useState(false)
        const [departmentOfHealthAndHumanServicesModalIsOpen, setDepartmentOfHealthAndHumanServicesModalIsOpen] = useState(false)
        const [departmentOfHousingAndUrbanDevelopmentModalIsOpen, setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen] = useState(false)
        const [departmentOfTransportationModalIsOpen, setDepartmentOfTransportationModalIsOpen] = useState(false)
        const [departmentOfEnergyModalIsOpen, setDepartmentOfEnergyModalIsOpen] = useState(false)
        const [departmentOfEducationModalIsOpen, setDepartmentOfEducationModalIsOpen] = useState(false)
        const [departmentOfVeteransAffairsModalIsOpen, setDepartmentOfVeteransAffairsModalIsOpen] = useState(false)
        const [departmentOfHomelandSecurityModalIsOpen, setDepartmentOfHomelandSecurityModalIsOpen] = useState(false)
        const [epaModalIsOpen, setEPAModalIsOpen] = useState(false)
        const [nsfModalIsOpen, setNSFModalIsOpen] = useState(false)
        const [nasaModalIsOpen, setNASAModalIsOpen] = useState(false)
        const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
        const [goaReportsModalIsOpen, setGAOReportsModalIsOpen] = useState(false)
        const [executiveOrdersModalIsOpen, setExecutiveOrdersModalIsOpen] = useState(false)
        const [presidentialProclamationsModalIsOpen, setPresidentialProclamationsModalIsOpen] = useState(false)
        const [statesModalIsOpen, setStatesModalIsOpen] = useState(false)
      
      
        const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false)
        const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)
      
      
        const [federalRegisterDefenseDepartmentModalIsOpen, setFederalRegisterDefenseDepartmentModalIsOpen] = useState(false)
  const [federalRegisterStateDepartmentModalIsOpen, setFederalRegisterStateDepartmentModalIsOpen] = useState(false)
        const [declarationModalIsOpen, setDeclarationModalIsOpen] = useState(false)
        const [constitutionModalIsOpen, setConstitutionModalIsOpen] = useState(false)
        const [billOfRightsModalIsOpen, setBillOfRightsModalIsOpen] = useState(false)
        const [whiteHouseBreifingModalIsOpen, setWhiteHouseBreifingModalIsOpen] = useState(false)
        const [presidentialDocumentsModalIsOpen, setPresidentialDocumentsModalIsOpen] = useState(false)
        const [stateDocumentsModalIsOpen, setStateDocumentsModalIsOpen] = useState(false)
        const [defenseDocumentsModalIsOpen, setDefenseDocumentsModalIsOpen] = useState(false)
        const [economicAdModalIsOpen, setEconomicAdModalIsOpen] = useState(false)
        const [environmentQualityModalIsOpen, setEnvironmentQualityModalIsOpen] = useState(false)
        const [domesticPolicyModalIsOpen, setDomesticPolicyModalIsOpen] = useState(false)
        const [genderPolicyModalIsOpen, setGenderPolicyModalIsOpen] = useState(false)
        const [nationalEconomicModalIsOpen, setNationalEconomicModalIsOpen] = useState(false)
        const [armyModalIsOpen, setArmyModalIsOpen] = useState(false)
        const [navyModalIsOpen, setNavyModalIsOpen] = useState(false)
        const [marinesModalIsOpen, setMarinesModalIsOpen] = useState(false)
        const [spaceForceModalIsOpen, setSpaceForceModalIsOpen] = useState(false)
        const [airForceModalIsOpen, setAirForceModalIsOpen] = useState(false)
        const [nationalGuardModalIsOpen, setNationalGuardModalIsOpen] = useState(false)
        const [ciaModalIsOpen, setCIAModalIsOpen] = useState(false)
        const [nsaModalIsOpen, setNSAModalIsOpen] = useState(false)

        const [coastGuardModalIsOpen, setCoastGuardModalIsOpen] = useState(false)
        const [femaModalIsOpen, setFEMAModalIsOpen] = useState(false)
        const [secretServiceModalIsOpen, setSecretServiceModalIsOpen] = useState(false)
        const [tsaModalIsOpen, setTSAModalIsOpen] = useState(false)
        const [immigrationModalIsOpen, setImmigrationModalIsOpen] = useState(false)
        const [usCustomsModalIsOpen, setUSCustomsModalIsOpen] = useState(false)
        const [enforcementsModalIsOpen, setEnforcemnetsModalIsOpen] = useState(false)
        const [homelandDocsModalIsOpen, setHomelandDocsModalIsOpen] = useState(false)

        const [
          number1ModalIsOpen, setNumber1ModalIsOpen,
          number2ModalIsOpen, setNumber2ModalIsOpen,
          number3ModalIsOpen, setNumber3ModalIsOpen,
          number4ModalIsOpen, setNumber4ModalIsOpen,
          number5ModalIsOpen, setNumber5ModalIsOpen,
          number6ModalIsOpen, setNumber6ModalIsOpen,
          number7ModalIsOpen, setNumber7ModalIsOpen,
          number8ModalIsOpen, setNumber8ModalIsOpen,
          number9ModalIsOpen, setNumber9ModalIsOpen,
          number10ModalIsOpen, setNumber10ModalIsOpen,
          number11ModalIsOpen, setNumber11ModalIsOpen,
          number12ModalIsOpen, setNumber12ModalIsOpen,
          number13ModalIsOpen, setNumber13ModalIsOpen,
          number14ModalIsOpen, setNumber14ModalIsOpen,
          number15ModalIsOpen, setNumber15ModalIsOpen,
          number16ModalIsOpen, setNumber16ModalIsOpen,
          number17ModalIsOpen, setNumber17ModalIsOpen,
          number18ModalIsOpen, setNumber18ModalIsOpen,
          number19ModalIsOpen, setNumber19ModalIsOpen,
          number20ModalIsOpen, setNumber20ModalIsOpen,
          number21ModalIsOpen, setNumber21ModalIsOpen,
          number22ModalIsOpen, setNumber22ModalIsOpen,
          number23ModalIsOpen, setNumber23ModalIsOpen,
          number24ModalIsOpen, setNumber24ModalIsOpen,
          number25ModalIsOpen, setNumber25ModalIsOpen,
        ] = useState(false);
        // const [ModalIsOpen, setModalIsOpen] = useState(false)
        const [commerceDocsModalIsOpen, setCommerceDocsModalIsOpen] = useState(false)
        const [censusBureauModalIsOpen, setCensusBureauModalIsOpen] = useState(false)
        const [economicAnalysisModalIsOpen, setEconomicAnalysisModalIsOpen] = useState(false)
        const [ecoDevelopmentModalIsOpen, setEconDevelopmentModalIsOpen] = useState(false)
        const [counterterrorismModalIsOpen, setCounterterrorismModalIsOpen] = useState(false)
        const [democracyHumanRightsLaborModalIsOpen, setDemocracyHumanRightsLaborModalIsOpen] = useState(false)
        const [dOSPressModalIsOpen, setDOSPressModalIsOpen] = useState(false)
        const [diplomaticSecurityModalIsOpen, setDiplomaticSecurityModalIsOpen] = useState(false)
        const [armsControlModalIsOpen, setArmsControlModalIsOpen] = useState(false)
        const [agDocsModalIsOpen, setAgDocsModalIsOpen] = useState(false)
        const [eduDocumentsModalIsOpen, setEduDocumentsModalIsOpen] = useState(false)
        const [modalInfo, setModalInfo] = useState({
          isOpen: false,
          title: '',
          content: null
        });
      
        const openModal = (title, content) => {
          setModalInfo({
            isOpen: true,
            title,
            content
          });
        };
      
        const closeModal = () => {
          setModalInfo({
            isOpen: false,
            title: '',
            content: null
          });
        };
   
    
    var branch = "all";
  
    return(

       <>
    <BrowserView>
       <div id='homePage'>

     

<div id='homebg'>

  <DesktopNavbar/>
      {/* <div id='sectionHeading'>
    
    <h3 id='branchHeading'><People></People> Executive

      <Button size='md' id='navbarAboutButtonDesktop' variant='primary' onClick={(e) => {
        e.preventDefault();
        window.open('https://www.whitehouse.gov/', "_blank")
      }} >whitehouse.gov</Button>



    <Form id='whiteHouseSearchForm'>
    
        <Form.Group className="mb-3 d-flex" >

          <Form.Control id="congressSearch" placeholder="Search whitehouse.gov"
            type="text" />
<Button variant="primary" id='navbarAboutButtonDesktop' onClick={() => whiteHouseSearch()}>
          Search
        </Button>
        </Form.Group>

       
        


  
     
    </Form>
    </h3>
    
    </div> */}
<div style={{ position: 'relative' }}>

<div id='profileImage' style={{ width: '150px', height: '150px', borderRadius: '100%', overflow: 'hidden',  marginLeft: '40px' }}>
<Image id='profileImage-image' src='/whiteHouse.png' />
</div>


<div style={{ position: 'absolute', top: '0', left: '250px', display: 'flex', flexDirection: 'column' }}>
<div style={{ color: 'white', fontSize: '24px', fontWeight: 'bold' }}>

</div>
<div  style={{ color: 'white', fontSize: '20px' }}> 
<span> <h3  >White House </h3></span>
<br></br>
<div style={{ color: 'white', fontSize: '20px' }}>
<span> The White House is where the President and First Family of the United States live and work — but it’s also the People’s House, where we hope all Americans feel a sense of inclusion and belonging.</span>
<br></br>
<div style={{ color: 'white', fontSize: '18px' }}>

<span><Link style={{color: '#21cff5'}} onClick={(e) => {
e.preventDefault();
window.open("https://www.whitehouse.gov/about-the-white-house/", "_blank");
}}>
https://www.whitehouse.gov/about-the-white-house/
</Link></span>  

</div>
</div>

</div>
</div>


</div>
<br></br>

<CustomModal isOpen={modalInfo.isOpen} onClose={closeModal} title={modalInfo.title} content={modalInfo.content} />

    <div id='branchPage'>
        <Container class='bgcolor' >
          <Container id='carouselBufferTwo'>
           
            <Row>

              <Col id='legislativeColLeft'>
                <div id='columnLeftPadding'>

                <h4 id='feedHeadingBillsEnrolled'><b>President</b></h4>
                  
               
                  <PresidentDisplayOnPageExec/>
                
                
          
                </div>
              </Col>

              <Col id='legislativeColRight'>
                <div id='columnRightPadding'>

                <h4 id='feedHeadingBillsEnrolled'><b>Vice President</b></h4>
                  
               
                  <VicePresidentDisplayOnPageExec/>
            
                </div>
              </Col>


            </Row>


          </Container>
        </Container>
        </div>
             
        <div id='branchPage'>
        <Container class='bgcolor' >
          <Container id='carouselBufferTwo'>
           
            <Row>

              <Col id='legislativeColLeft'>
                <div id='columnLeftPadding'>

                  <h4 id='whBreifingFeedHeading' onClick={() => setWhiteHouseFeedModalIsOpen(true)}><b>Briefing Room</b></h4>
                  <div id='wh'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
                            {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=the_white_house&order_by=created_at', 'wh', 
                            'White House Briefing Room', 'Executive', 
                            'N/A', '', GenerateFeed)}

                          <Modal id='whiteHouseFeedModal' show={whiteHouseBreifingModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setWhiteHouseBreifingModalIsOpen(false)}>
                            <Modal.Header id='whiteHouseFeedModalHeader' closeButton><h3>White House Briefing Room</h3></Modal.Header>
                            <GenerateFeed url='&skip=0&schema=united_states_of_america&table=the_white_house&order_by=created_at' 
                            name='White House Briefing Room' branch='Executive' topic='N/A' buttonName='' />
                          </Modal>
                </div>
              </Col>

              <Col id='legislativeColRight'>
                <div id='columnRightPadding'>
                  <h4 id='presidentialFeedHeading' onClick={() => setWhiteHouseDocsModalIsOpen(true)}><b>Presidential Documents</b></h4>
                  <div id='whdocs'></div>
                          
                          {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=presidential_documents&order_by=created_at', 'whdocs', 
                            'Presidential Documents', 'Executive', 
                            'N/A', 'Document', GenerateFeed)}

                          <Modal id='whiteHouseDocsModal' show={presidentialDocumentsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setPresidentialDocumentsModalIsOpen(false)}>
                            <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Presidential Documents</h3></Modal.Header>
                            <GenerateFeed url='&skip=0&schema=united_states_of_america&table=compilation_of_presidential_documents&order_by=created_at' 
                            name='Presidential Documents' branch='Executive' topic='N/A' buttonName='Document' />
                          </Modal>
                </div>
              </Col>


            </Row>


          </Container>
        </Container>
     
      </div>

      <div id='branchPage'>

<Container class='bgcolor' >

<Container id='carouselBufferOne'>

<Row>
<Col id='legislativeColLeft'>
<div id='columnLefttPadding'>

<h4 id='presidentialFeedHeading' onClick={() => setExecutiveOrdersModalIsOpen(true)}><b> Executive Orders</b></h4>
<div id='executiveOrders'></div>
        
        {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=president_executive_orders&order_by=created_at', 'executiveOrders', 
                            'Executive Orders', 'Executive', 
                            'N/A', '', GenerateFeed)}
        <Modal id='whiteHouseDocsModal' show={executiveOrdersModalIsOpen} 
        size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() =>setExecutiveOrdersModalIsOpen(false)}>
          <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Executive Orders</h3></Modal.Header>
          <GenerateFeed url='&skip=0&schema=united_states_of_america&table=president_executive_orders&order_by=created_at' 
                            name='Executive Orders' branch='Executive' topic='N/A' buttonName='' />
        </Modal>

</div>
</Col>



<Col id='legislativeColRight'>
<div id='columnRighttPadding'>

<h4 id='presidentialFeedHeading'><b> Presidential Proclamations</b></h4>
<div id='presidentialProclomations'>
                </div>
                {CreateFeedItemExecutiveOrdersCache('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bcorrection%5D=0&conditions%5Bpresident%5D=&conditions%5Bpresidential_document_type%5D=proclamation&conditions%5Bsigning_date%5D%5Byear%5D=&conditions%5Btype%5D%5B%5D=PRESDOCU', 'presidentialProclomations', 'Presidential Proclamations', 'Executive', 'N/A', PresidentialProclamations)}


       
               
<Modal id='whiteHouseDocsModal' show={presidentialProclamationsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() =>setPresidentialProclamationsModalIsOpen(false)}>
  <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Presidential Proclamations</h3></Modal.Header>
  <PresidentialProclamations />
</Modal>
</div>
</Col>













</Row>





</Container>
</Container>

</div>

<div id='branchPage'>
  <Container className='bgcolor'>
    <Container id='carouselBufferOne'>
      <Row>
        <Col id='legislativeColLeft'>
          <div id='columnLefttPadding'>
            <h4
              id='departmentOfDefenseHeadingInCol'
              
              onClick={(e) => {
                e.preventDefault();
                window.open('/us/executiveoffice', "_self");
              }}
            >
              <b> Executive Office of the President</b>
              <ChevronRight id='clickChevron'></ChevronRight>
            </h4>

            <Carousel id='carouselInColumn' controls={false}>



              <Carousel.Item interval={carouselInterval}>
                <Row>
                  <Col>
                  <h4 id='whBreifingFeedHeading' onClick={() => setNumber1ModalIsOpen(true)}><b>Council of Economic Advisers	</b></h4>
                          <div id='councilOfEconomicAd'></div>
                    
                          {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=council_of_economic_advisers&order_by=created_at', 
                          'councilOfEconomicAd', 
                            'Council of Economic Advisers', 'Executive', 
                            'Economy', 'Document', GenerateFeed)}

                          <Modal id='whiteHouseDocsModal' show={number1ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
                          centered onHide={() => setNumber1ModalIsOpen(false)}>
                            <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Council of Economic Advisers</h3></Modal.Header>
                            <GenerateFeed url='&skip=0&schema=united_states_of_america&table=council_of_economic_advisers&order_by=created_at' 
                            name='Council of Economic Advisers' branch='Executive' topic='Economy' buttonName='Document' />
                          </Modal>
                  </Col>
                  <Col>
                  <h4 id='presidentialFeedHeading' onClick={() => setNumber2ModalIsOpen(true)} ><b>Council on Environmental Quality</b></h4>
                          <div id='councilOnEnvironment'></div>
                     
                          {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=council_on_environmental_quality&order_by=created_at', 
                          'councilOnEnvironment', 
                            'Council on Environmental Quality', 'Executive', 
                            'Environment', 'Document', GenerateFeed)}

                          <Modal id='whiteHouseDocsModal' show={number2ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
                          centered onHide={() => setNumber2ModalIsOpen(false)}>
                            <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Council on Environmental Quality</h3></Modal.Header>
                            <GenerateFeed url='&skip=0&schema=united_states_of_america&table=council_on_environmental_quality&order_by=created_at' 
                            name='Council on Environmental Quality' branch='Executive' topic='Environment' buttonName='Document' />
                          </Modal>

                  </Col>
                </Row>
              </Carousel.Item>
              <Carousel.Item interval={carouselInterval}>
<Row>
<Col>
<h4 id='whBreifingFeedHeading' onClick={() => setDomesticPolicyModalIsOpen(true)}><b>Domestic Policy Council</b></h4>
                          <div id='domesticPolicyCouncil'></div>
                          
                          {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=domestic_policy_council&order_by=created_at', 
                                             'domesticPolicyCouncil', 
                                               'Domestic Policy Council', 'Executive', 
                                               'N/A', 'Document', GenerateFeed)}
                        
                                             <Modal id='whiteHouseDocsModal' show={domesticPolicyModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
                                             centered onHide={() => setDomesticPolicyModalIsOpen(false)}>
                                               <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Domestic Policy Council</h3></Modal.Header>
                                               <GenerateFeed url='&skip=0&schema=united_states_of_america&table=domestic_policy_council&order_by=created_at' 
                                               name='Domestic Policy Council' branch='Executive' topic='N/A' buttonName='Document' />
                                             </Modal>
                    </Col>
                    <Col>
                    <h4 id='presidentialFeedHeading' onClick={() =>
                    openModal('Executive Orders',
                      <GenderPolicyCouncil/>)}><b>Gender Policy Council</b></h4>
                          <div id='genderPolicyCouncil'></div>
                          
                          {CreateFeedItemExecutiveOffice('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/executive_office_of_the_president/Gender_Policy_Council.xml', 'genderPolicyCouncil', 'Gender Policy Council', 'Executive', 'N/A', GenderPolicyCouncil)}


</Col>
                    </Row>
</Carousel.Item>  

                     <Carousel.Item interval={carouselInterval}>
<Row>

<Col>

<div id='legislativeConInCol'>

<Col>
<div id='viewFullPageButton'>
<Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/executiveoffice' >View all Executive Office news</Button>
</div>
</Col>

</div>
</Col>
</Row>


</Carousel.Item>
            </Carousel>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>
   

       
<br></br><br></br>
      



      <Modal id='aboutModal' show={aboutModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAboutModalIsOpen(false)}>
      <div id='removedAlert'></div>
      <Modal.Header id='aboutModalHeader' closeButton><h3>About</h3></Modal.Header>
<ModalBody id='aboutInfo'><p id='aboutParagraph'>Gov Glance was created to drive civic engagement and support a 
        healthier democracy by helping citizens get official Government news in an easy-to-navigate centralized location. 
        There will never be any opinion or political analysis(That's your job), only information from official Government 
        sources. Gov Glance is structured after the U.S. government that is broken into three branches Legislative, 
        Executive, and Judicial. To learn more about the structure and functions of The U.S. Government go to <a href='https://www.usa.gov/branches-of-government'>https://www.usa.gov/branches-of-government</a>.
        </p>

        <p id='aboutParagraph'>
        Gov Glance Foundation Inc. is a non-profit that was created to operate govglance.org and continue to create educational tools that help people learn about the workings of society
        </p>
      <br></br>

      <h3>Privacy</h3>

      <br></br>

        <p id='aboutParagraph'>Gov Glance does not collect any personal user information. 
        Features like collection save news items directly to your browser's local storage for your convenience
        and is not shared with Gov Glance servers. We do not collect, store, or send personal information to Google LLC, 
        but we do use some Google services to deliver our app to you. For any questions into Google’s privacy practices 
        here is a link to their privacy policy: <a href='https://policies.google.com/privacy?hl=en-US#intro'>https://policies.google.com/privacy?hl=en-US#intro</a>

        <br></br><br></br>

        For site analytics we use plausible.io an open source privacy friendly analytics provider. <a href="https://plausible.io/about" target="_blank">https://plausible.io/about</a>
      
      </p>
      <br></br>


      <h3>Contact</h3>

      <p id='aboutParagraph'>
                Contact us via email: <a href='mailto:contact@govglance.org'>contact@govglance.org</a>

      </p>

      <br></br>


      
      </ModalBody>
    </Modal>

    <footer id='homeFooter'>
<Navbar>
  <Row id='footerNav'>
<Nav variant='pills'>
  
<Nav.Link id='footerContent'>Gov Glance Foundation Inc.
</Nav.Link>
<Nav.Item>
<Nav.Link id='footerContent' href='mailto:contact@govglance.org'>Contact</Nav.Link>
</Nav.Item>
<Nav.Item>
<Nav.Link id='footerContent' onClick={(e) => {
             e.preventDefault();
             window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
           }}> <HeartFill id='donateIcon'/>    Donate</Nav.Link>
</Nav.Item>
<Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/about', "_self")}} id='footerContent'>Privacy</Nav.Link>


</Nav>
</Row>
</Navbar>
</footer>



</div>
   </div>
    


       
       </BrowserView>
       <MobileView>
  <WhiteHousePageMobile/>
          </MobileView>
          </>
  )
}
 }



  






export default WhiteHousePage;


import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch,  } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment,  } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image, ModalFooter } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText,  ShieldShaded, CircleFill, Pen, People, ChatSquareQuote, ChatLeftQuote, BlockquoteRight, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, CaretRight, ChevronRight, ChevronCompactRight, CaretRightFill, ArrowRightShort,  ArrowRightCircle, PlayCircle, PauseCircle, Link45deg } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Axios from 'axios'
import { render } from '@testing-library/react';


import DepartmentOfAgriculture from './DepartmentOfAgriculture';

import{ whiteHouseSearch, createPresidentialProclamations, createExecutiveOrders, createWhiteHouseDocuments, createWhiteHouseFeed,
    createWhiteHouseFeedMobile,createDepartmentOfState,
    createDepartmentOfDefense, 
    createDepartmentOfJustice, createDepartmentOfTheInterior,
    createDepartmentOfAgriculture, createDepartmentOfCommerce,
    createDepartmentOfCommerceMobile, createDepartmentOfLabor,
    createDepartmentOfHealthAndHumanServices, createDepartmentOfHousingAndUrbanDevelopment, createDepartmentOfEducation
    ,  createDepartmentOfVeteransAffairs, createDepartmentOfHomelandSecurity
    , createEnvironmentalProtectionAgency,
    createDepartmentOfTreasury, createNationalScienceFoundation, 
     createEnvironmentalProtectionAgencyMobile, createNationalAeronauticsAndSpaceAdministration, Speak, createFederalRegisterDefenseDepartment, 
     createFederalRegisterStateDepartment, createFederalRegisterDefense,
    CreateFeedItem, CreateFeedItemPresidentialDocuments, CreateExecutiveOrders,
    CreateFeedItemExecutiveOrders,
    CreateFeedItemBillUpdates,
    CreateFeedItemExecutiveOffice,
    CreateFeedItemBillWithDescription,
    CreateFeedAPIDepartmentUS,  } from './Functions'

import MobileOffCanvas from './MobileOffCanvas';
import MobileTopNavbar from './MobileTopNavbar';
import BillUpdates from './BillUpdates';

import DepartmentOfAgricultureDocuments from './DepartmentOfAgricultureDocuments';
import AgriculturalMarketingService from './AgriculturalMarketingService';
import AgriculturalResearchService from './AgriculturalResearchService';
import AnimalAndPlantHealthInspectionService from './AnimalAndPlantHealthInspectionService';
import CommodityCreditCorporation from './CommodityCreditCorporation';
import EconomicResearchService from './EconomicResearchService';
import FarmServiceAgency from './FarmServiceAgency';
import FederalCropInsuranceCorporation from './FederalCropInsuranceCorporation';
import FoodAndNutritionServices from './FoodAndNutritionServices';
import FoodSafetyAndInspectionService from './FoodSafetyAndInspectionService';
import ForeignAgriculturalService from './ForeignAgriculturalService';
import ForestService from './ForestService';
import NationalAgriculturalLibrary from './NationalAgriculturalLibrary';
import NationalAgriculturalStatisticsServices from './NationalAgriculturalStatisticsServices';
import NationalInstituteOfFoodAndAgriculture from './NationalInstituteOfFoodAndAgriculture';
import NaturalResourcesConservation from './NaturalResourcesConservation';
import RiskManagementAgency from './RiskManagementAgency';
import RuralBusinessCooperativeService from './RuralBusinessCooperativeService';
import RuralHousingService from './RuralHousingService';
import RuralUtilitesService from './RuralUtilitesService';
import ProcurementAndPropertyManagment from './ProcurementAndPropertyManagment';
import AdvocacyAndOutreachOffice from './AdvocacyAndOutreachOffice';
import USCodexOffice from './USCodexOffice';
import PartnershipsAndPublicEngagement from './PartnershipsAndPublicEngagement';
import GenerateFeedDepartment from './GenerateFeedDepartment';



var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 30000;



  

 


  

export const AgricultureMobile= () =>   {
    render()
    
    {
        const [show, setShow] = useState(true);

        
        const [fullscreen, setFullScreen] = useState(true);
      
      
        const [fullBillModalIsOpen, setFullBillModalIsOpen] = useState(false)
      
        const [reportModalIsOpen, setReportModalIsOpen] = useState(false)
      
      
        const [whiteHouseFeedModalIsOpen, setWhiteHouseFeedModalIsOpen] = useState(false)
        const [whiteHouseDocsModalIsOpen, setWhiteHouseDocsModalIsOpen] = useState(false)
      
      
        const [courtNewsModalIsOpen, setCourtNewsModalIsOpen] = useState(false)
        const [courtModalIsOpen, setCourtModalIsOpen] = useState(false)
      
        const [courtAppealsModalIsOpen, setCourtAppealsModalIsOpen] = useState(false)
      
        const [newLawsModalIsOpen, setNewLawsModalIsOpen] = useState(false)
      
        const [billsEnrolledModalIsOpen, setBillsEnrolledModalIsOpen] = useState(false)
      
        const [legislativePageBillsEnrolledModalIsOpen, setLegislativePageBillsEnrolledModalIsOpen] = useState(false)
      
      
        const [departmentOfStateModalIsOpen, setDepartmentOfStateModalIsOpen] = useState(false)
        const [departmentOfTreasuryModalIsOpen, setDepartmentOfTreasuryModalIsOpen] = useState(false)
     
        const [departmentOfJusticeModalIsOpen, setDepartmentOfJusticeModalIsOpen] = useState(false)
        const [departmentOfTheInteriorModalIsOpen, setDepartmentOfTheInteriorModalIsOpen] = useState(false)
        const [departmentOfAgricultureModalIsOpen, setDepartmentOfAgricultureModalIsOpen] = useState(false)
        const [departmentOfCommerceModalIsOpen, setDepartmentOfCommerceModalIsOpen] = useState(false)
        const [departmentOfLaborModalIsOpen, setDepartmentOfLaborModalIsOpen] = useState(false)
        const [departmentOfHealthAndHumanServicesModalIsOpen, setDepartmentOfHealthAndHumanServicesModalIsOpen] = useState(false)
        const [departmentOfHousingAndUrbanDevelopmentModalIsOpen, setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen] = useState(false)
        const [departmentOfTransportationModalIsOpen, setDepartmentOfTransportationModalIsOpen] = useState(false)
        const [departmentOfEnergyModalIsOpen, setDepartmentOfEnergyModalIsOpen] = useState(false)
        const [departmentOfEducationModalIsOpen, setDepartmentOfEducationModalIsOpen] = useState(false)
        const [departmentOfVeteransAffairsModalIsOpen, setDepartmentOfVeteransAffairsModalIsOpen] = useState(false)
        const [departmentOfHomelandSecurityModalIsOpen, setDepartmentOfHomelandSecurityModalIsOpen] = useState(false)
        const [epaModalIsOpen, setEPAModalIsOpen] = useState(false)
        const [nsfModalIsOpen, setNSFModalIsOpen] = useState(false)
        const [nasaModalIsOpen, setNASAModalIsOpen] = useState(false)
        const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
        const [goaReportsModalIsOpen, setGAOReportsModalIsOpen] = useState(false)
        const [executiveOrdersModalIsOpen, setExecutiveOrdersModalIsOpen] = useState(false)
        const [presidentialProclamationsModalIsOpen, setPresidentialProclamationsModalIsOpen] = useState(false)
        const [statesModalIsOpen, setStatesModalIsOpen] = useState(false)
      
      
        const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false)
        const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)
      
      
        const [federalRegisterDefenseDepartmentModalIsOpen, setFederalRegisterDefenseDepartmentModalIsOpen] = useState(false)
  const [federalRegisterStateDepartmentModalIsOpen, setFederalRegisterStateDepartmentModalIsOpen] = useState(false)
        const [declarationModalIsOpen, setDeclarationModalIsOpen] = useState(false)
        const [constitutionModalIsOpen, setConstitutionModalIsOpen] = useState(false)
        const [billOfRightsModalIsOpen, setBillOfRightsModalIsOpen] = useState(false)

        const [economicAdModalIsOpen, setEconomicAdModalIsOpen] = useState(false)
        const [environmentQualityModalIsOpen, setEnvironmentQualityModalIsOpen] = useState(false)
        const [domesticPolicyModalIsOpen, setDomesticPolicyModalIsOpen] = useState(false)
        const [genderPolicyModalIsOpen, setGenderPolicyModalIsOpen] = useState(false)
        const [nationalEconomicModalIsOpen, setNationalEconomicModalIsOpen] = useState(false)
        const [securityModalIsOpen, setSecurityModalIsOpen] = useState(false)
        const [budgetModalIsOpen, setBudgetModalIsOpen] = useState(false)
        const [drugModalIsOpen, setDrugModalIsOpen] = useState(false)
        const [publicModalIsOpen, setPublicModalIsOpen] = useState(false)
        const [scienceModalIsOpen, setScienceModalIsOpen] = useState(false)
        const [cyberModalIsOpen, setCyberModalIsOpen] = useState(false)
        const [presidentialModalIsOpen, setPresidentialModalIsOpen] = useState(false)

        const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
        const [defenseDocumentsModalIsOpen, setDefenseDocumentsModalIsOpen] = useState(false)
        const [armyModalIsOpen, setArmyModalIsOpen] = useState(false)
        const [navyModalIsOpen, setNavyModalIsOpen] = useState(false)
        const [marinesModalIsOpen, setMarinesModalIsOpen] = useState(false)
        const [spaceForceModalIsOpen, setSpaceForceModalIsOpen] = useState(false)
        const [airForceModalIsOpen, setAirForceModalIsOpen] = useState(false)
        const [nationalGuardModalIsOpen, setNationalGuardModalIsOpen] = useState(false)
        const [ciaModalIsOpen, setCIAModalIsOpen] = useState(false)
        const [nsaModalIsOpen, setNSAModalIsOpen] = useState(false)
        const [armyCorpsModalIsOpen, setArmyCorpsModalIsOpen] = useState(false)
        const [showBioModal, setBioModal] = useState(false);
        const [showMissionModal, setMissionModal] = useState(false);
        const [modalInfo, setModalInfo] = useState({
          isOpen: false,
          title: '',
          content: null
        });
      
        const openModal = (title, content) => {
          setModalInfo({
            isOpen: true,
            title,
            content
          });
        };
      
        const closeModal = () => {
          setModalInfo({
            isOpen: false,
            title: '',
            content: null
          });
        };

        var mobileCarouselInterval = 60000;
        const CustomModal = ({ isOpen, onClose, title, content }) => (
          <Modal id='fullBillModal' show={isOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={onClose}>
            <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
            {content}
          </Modal>
        );
    var branch = "all";
  
    return(
       <>
   <MobileTopNavbar/>
  
  
  
  
  <div id='homebg'>

               
  
  <div id="feedDisplayDepartment" style={{ padding: '10px', borderRadius: '18px' }}>
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div id="profileImageOnPageDepartment">
                <Image id="profileImage-imageOnPageDepartment" src="/departmentOfAgriculture.svg" />
              </div>
              <div style={{ marginLeft: '10px', color: 'white', fontSize: '18px', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                <h5 id="branchHeadingMobile" > Department of Agriculture</h5>
              </div>
            </div>
            <Dropdown drop="down-centered">
              <Dropdown.Toggle id="searchCountry"><Link45deg /></Dropdown.Toggle>
              <Dropdown.Menu id="docsDropdown" drop="down-centered">
                <Dropdown.Item id="docsDropdownItem" onClick={(e) => { e.preventDefault(); window.open("https://www.usda.gov/", "_blank") }}>
                  Official Site
                </Dropdown.Item>

                <Dropdown.Item id="docsDropdownItem" onClick={(e) => { e.preventDefault(); window.open("https://www.govinfo.gov/content/pkg/STATUTE-12/pdf/STATUTE-12-Pg387.pdf", "_blank") }}>
                  Establishing Act
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div id="billText" style={{ color: 'white', fontSize: '16px', marginTop: '10px' }}>
          
          The U.S. Department of Agriculture (USDA) develops and executes policy on farming, agriculture, and food. Its aims include meeting the needs of farmers and ranchers, promoting agricultural trade and production, assuring food safety, protecting forests and other natural resources, fostering rural prosperity, and ending hunger in America and abroad.
          </div>

          <div style={{height: '20px',}}></div>
          {/* <div id="billText" style={{ color: 'white', fontSize: '16px', marginTop: '10px' }}>
          <b>Establishing Act</b>
          </div>
          <div id="billText" style={{ color: 'white', fontSize: '16px', marginTop: '10px' }}>
         
          Be it enacted by the Senate and House of Representatives of the United
States of America in Congress assembled, That there is hereby established at the seat of Governmen...
          </div> */}
<Button id='presidentViewMoreButton' onClick={() =>  setBioModal(true)}>  <Files size={20}></Files> Read More</Button>
        </div>

       
        <Modal id='fullBillModal'  size="xl" aria-labelledby="contained-modal-title-vcenter" show={showBioModal} onHide={() => setBioModal(false)}>
         <Modal.Header id='billModalHeader' closeButton><h3> Department of Agriculture</h3></Modal.Header>
       
         <div id='fullBillFeed' >
      
         <p>The U.S. Department of Agriculture (USDA) develops and executes policy on farming, agriculture, and food. Its aims include meeting the needs of farmers and ranchers, promoting agricultural trade and production, assuring food safety, protecting forests and other natural resources, fostering rural prosperity, and ending hunger in America and abroad.</p>

<p>The USDA employs nearly 100,000 people and has an annual budget of approximately $150 billion. It consists of 16 agencies, including the Animal and Plant Health Inspection Service, the Food and Nutrition Service, and the Forest Service. The bulk of the department’s budget goes towards mandatory programs that provide services required by law, such as programs designed to provide nutrition assistance, promote agricultural exports, and conserve our environment.</p>

<p>The USDA also plays an important role in overseas aid programs by providing surplus foods to developing countries.</p>

<p>The United States Secretary of Agriculture administers the USDA.</p>

<br></br>
<Link style={{color: '#21cff5'}} onClick={(e) => {
     e.preventDefault();
     window.open("https://www.whitehouse.gov/about-the-white-house/our-government/the-executive-branch/#:~:text=DEPARTMENT%20OF%20AGRICULTURE,administers%20the%20USDA.", "_blank");
   }}>
     https://www.whitehouse.gov/about-the-white-house/our-government/the-executive-branch/#:~:text=DEPARTMENT%20OF%20AGRICULTURE,administers%20the%20USDA.
     </Link>
 </div>

       </Modal>   

<Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setDepartmentOfDefenseModalIsOpen(true)}>News</h5>




<div class='courtNewsMobile' id='agricultureDepartment'></div>

{CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/executive-xml/agriculture.xml	', 'agricultureDepartment', 'Department of Agriculture', 'Executive', 'Environment', DepartmentOfAgriculture)}

  <Modal id='whiteHouseDocsModal' show={departmentOfDefenseModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfDefenseModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Agriculture</h3></Modal.Header>
                  <DepartmentOfAgriculture />
                </Modal>

  </Col>

  <Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setDefenseDocumentsModalIsOpen(true)}>Documents</h5>


  <div class='courtNewsMobile' id='frDefense'></div>

  {CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=agriculture-department', 'frDefense', 'Department of Agriculture Documents', 'Executive', 'Environment', DepartmentOfAgricultureDocuments)}

</Col>
              
                <Modal id='whiteHouseDocsModal' show={defenseDocumentsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDefenseDocumentsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Agriculture Documents</h3></Modal.Header>
                  <DepartmentOfAgricultureDocuments />
                </Modal>


                <Col>
  <Link  id='pageLink' to="/us/advocacy">
    <h5 id='presidentialFeedHeadingMobile'>Advocacy and Outreach Office<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false} >
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Advocacy and Outreach Office',
              <GenerateFeedDepartment url='&table_name=environment&agency=Advocacy%20and%20Outreach%20Office'
              name='Advocacy and Outreach Office' branch='Executive' topic='Agriculture' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defAoo'></div>
          {CreateFeedAPIDepartmentUS('&table_name=environment&agency=Advocacy%20and%20Outreach%20Office', 'defAoo',
            'Advocacy and Outreach Office Documents', 'Executive',
            'Agriculture', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/advocacy' >View all Advocacy and Outreach Office news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/agricultural-marketing-service">
    <h5 id='presidentialFeedHeadingMobile'>Agricultural Marketing Service<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false} >
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Agricultural Marketing Service',
              <GenerateFeedDepartment url='&table_name=environment&agency=Agricultural%20Marketing%20Service'
              name='Agricultural Marketing Service' branch='Executive' topic='Agriculture' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defAms'></div>
          {CreateFeedAPIDepartmentUS('&table_name=environment&agency=Agricultural%20Marketing%20Service', 'defAms',
            'Agricultural Marketing Service Documents', 'Executive',
            'Agriculture', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/agricultural-marketing' >View all Agricultural Marketing Service news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/agricultural-research-service">
    <h5 id='presidentialFeedHeadingMobile'>Agricultural Research Service<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false} >
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Agricultural Research Service',
              <GenerateFeedDepartment url='&table_name=environment&agency=Agricultural%20Research%20Service'
              name='Agricultural Research Service' branch='Executive' topic='Agriculture' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defArs'></div>
          {CreateFeedAPIDepartmentUS('&table_name=environment&agency=Agricultural%20Research%20Service', 'defArs',
            'Agricultural Research Service Documents', 'Executive',
            'Agriculture', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/agricultural-research' >View all Agricultural Research Service news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/animal-plant-health">
    <h5 id='presidentialFeedHeadingMobile'>Animal and Plant Health Inspection Service<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false} >
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Animal and Plant Health Inspection Service',
              <GenerateFeedDepartment url='&table_name=environment&agency=Animal%20and%20Plant%20Health%20Inspection%20Service'
              name='Animal and Plant Health Inspection Service' branch='Executive' topic='Agriculture' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defAphis'></div>
          {CreateFeedAPIDepartmentUS('&table_name=environment&agency=Animal%20and%20Plant%20Health%20Inspection%20Service', 'defAphis',
            'Animal and Plant Health Inspection Service Documents', 'Executive',
            'Agriculture', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/animal-plant-health' >View all Animal and Plant Health Inspection Service news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/commodity-credit">
    <h5 id='presidentialFeedHeadingMobile'>Commodity Credit Corporation<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false} >
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Commodity Credit Corporation',
              <GenerateFeedDepartment url='&table_name=environment&agency=Commodity%20Credit%20Corporation'
              name='Commodity Credit Corporation' branch='Executive' topic='Agriculture' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defCcc'></div>
          {CreateFeedAPIDepartmentUS('&table_name=environment&agency=Commodity%20Credit%20Corporation', 'defCcc',
            'Commodity Credit Corporation Documents', 'Executive',
            'Agriculture', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/commodity-credit' >View all Commodity Credit Corporation news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/cooperative-state-research">
    <h5 id='presidentialFeedHeadingMobile'>Cooperative State Research, Education, and Extension Service<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false} >
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Cooperative State Research, Education, and Extension Service',
              <GenerateFeedDepartment url='&table_name=environment&agency=Cooperative%20State%20Research%2C%20Education%2C%20and%20Extension%20Service'
              name='Cooperative State Research, Education, and Extension Service' branch='Executive' topic='Agriculture' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defCsrees'></div>
          {CreateFeedAPIDepartmentUS('&table_name=environment&agency=Cooperative%20State%20Research%2C%20Education%2C%20and%20Extension%20Service', 'defCsrees',
            'Cooperative State Research, Education, and Extension Service Documents', 'Executive',
            'Agriculture', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/cooperative-state-research' >View all Cooperative State Research, Education, and Extension Service news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/economic-analysis-staff">
    <h5 id='presidentialFeedHeadingMobile'>Economic Analysis Staff<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false} >
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Economic Analysis Staff',
              <GenerateFeedDepartment url='&table_name=environment&agency=Economic%20Analysis%20Staff'
              name='Economic Analysis Staff' branch='Executive' topic='Agriculture' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defEas'></div>
          {CreateFeedAPIDepartmentUS('&table_name=environment&agency=Economic%20Analysis%20Staff', 'defEas',
            'Economic Analysis Staff Documents', 'Executive',
            'Agriculture', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/economic-analysis' >View all Economic Analysis Staff news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/economic-research-service">
    <h5 id='presidentialFeedHeadingMobile'>Economic Research Service<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false} >
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Economic Research Service',
              <GenerateFeedDepartment url='&table_name=environment&agency=Economic%20Research%20Service'
              name='Economic Research Service' branch='Executive' topic='Agriculture' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defErs'></div>
          {CreateFeedAPIDepartmentUS('&table_name=environment&agency=Economic%20Research%20Service', 'defErs',
            'Economic Research Service Documents', 'Executive',
            'Agriculture', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/economic-research' >View all Economic Research Service news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/farm-service-agency">
    <h5 id='presidentialFeedHeadingMobile'>Farm Service Agency<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false} >
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Farm Service Agency',
              <GenerateFeedDepartment url='&table_name=environment&agency=Farm%20Service%20Agency'
              name='Farm Service Agency' branch='Executive' topic='Agriculture' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defFsa'></div>
          {CreateFeedAPIDepartmentUS('&table_name=environment&agency=Farm%20Service%20Agency', 'defFsa',
            'Farm Service Agency Documents', 'Executive',
            'Agriculture', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/farm-service' >View all Farm Service Agency news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/federal-crop-insurance">
    <h5 id='presidentialFeedHeadingMobile'>Federal Crop Insurance Corporation<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false} >
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Federal Crop Insurance Corporation',
              <GenerateFeedDepartment url='&table_name=environment&agency=Federal%20Crop%20Insurance%20Corporation'
              name='Federal Crop Insurance Corporation' branch='Executive' topic='Agriculture' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defFcic'></div>
          {CreateFeedAPIDepartmentUS('&table_name=environment&agency=Federal%20Crop%20Insurance%20Corporation', 'defFcic',
            'Federal Crop Insurance Corporation Documents', 'Executive',
            'Agriculture', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/federal-crop-insurance' >View all Federal Crop Insurance Corporation news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/food-consumer-service">
    <h5 id='presidentialFeedHeadingMobile'>Food and Consumer Service<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false} >
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Food and Consumer Service',
              <GenerateFeedDepartment url='&table_name=environment&agency=Food%20and%20Consumer%20Service'
              name='Food and Consumer Service' branch='Executive' topic='Agriculture' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defFcs'></div>
          {CreateFeedAPIDepartmentUS('&table_name=environment&agency=Food%20and%20Consumer%20Service', 'defFcs',
            'Food and Consumer Service Documents', 'Executive',
            'Agriculture', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/food-consumer-service' >View all Food and Consumer Service news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/food-nutrition-service">
    <h5 id='presidentialFeedHeadingMobile'>Food and Nutrition Service<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false} >
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Food and Nutrition Service',
              <GenerateFeedDepartment url='&table_name=environment&agency=Food%20and%20Nutrition%20Service'
              name='Food and Nutrition Service' branch='Executive' topic='Agriculture' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defFns'></div>
          {CreateFeedAPIDepartmentUS('&table_name=environment&agency=Food%20and%20Nutrition%20Service', 'defFns',
            'Food and Nutrition Service Documents', 'Executive',
            'Agriculture', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/food-nutrition-service' >View all Food and Nutrition Service news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/food-safety-inspection">
    <h5 id='presidentialFeedHeadingMobile'>Food Safety and Inspection Service<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false} >
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Food Safety and Inspection Service',
              <GenerateFeedDepartment url='&table_name=environment&agency=Food%20Safety%20and%20Inspection%20Service'
              name='Food Safety and Inspection Service' branch='Executive' topic='Agriculture' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defFsis'></div>
          {CreateFeedAPIDepartmentUS('&table_name=environment&agency=Food%20Safety%20and%20Inspection%20Service', 'defFsis',
            'Food Safety and Inspection Service Documents', 'Executive',
            'Agriculture', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/food-safety-inspection' >View all Food Safety and Inspection Service news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/foreign-agricultural">
    <h5 id='presidentialFeedHeadingMobile'>Foreign Agricultural Service<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false} >
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Foreign Agricultural Service',
              <GenerateFeedDepartment url='&table_name=environment&agency=Foreign%20Agricultural%20Service'
              name='Foreign Agricultural Service' branch='Executive' topic='Agriculture' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defFas'></div>
          {CreateFeedAPIDepartmentUS('&table_name=environment&agency=Foreign%20Agricultural%20Service', 'defFas',
            'Foreign Agricultural Service Documents', 'Executive',
            'Agriculture', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/foreign-agricultural' >View all Foreign Agricultural Service news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/forest-service">
    <h5 id='presidentialFeedHeadingMobile'>Forest Service<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false} >
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Forest Service',
              <GenerateFeedDepartment url='&table_name=environment&agency=Forest%20Service'
              name='Forest Service' branch='Executive' topic='Agriculture' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defFs'></div>
          {CreateFeedAPIDepartmentUS('&table_name=environment&agency=Forest%20Service', 'defFs',
            'Forest Service Documents', 'Executive',
            'Agriculture', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/forest-service' >View all Forest Service news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/national-agricultural-library">
    <h5 id='presidentialFeedHeadingMobile'>National Agricultural Library<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false} >
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('National Agricultural Library',
              <GenerateFeedDepartment url='&table_name=environment&agency=National%20Agricultural%20Library'
              name='National Agricultural Library' branch='Executive' topic='Agriculture' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defNal'></div>
          {CreateFeedAPIDepartmentUS('&table_name=environment&agency=National%20Agricultural%20Library', 'defNal',
            'National Agricultural Library Documents', 'Executive',
            'Agriculture', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/national-agricultural-library' >View all National Agricultural Library news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/national-agricultural-statistics">
    <h5 id='presidentialFeedHeadingMobile'>National Agricultural Statistics Service<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false} >
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('National Agricultural Statistics Service',
              <GenerateFeedDepartment url='&table_name=environment&agency=National%20Agricultural%20Statistics%20Service'
              name='National Agricultural Statistics Service' branch='Executive' topic='Agriculture' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defNass'></div>
          {CreateFeedAPIDepartmentUS('&table_name=environment&agency=National%20Agricultural%20Statistics%20Service', 'defNass',
            'National Agricultural Statistics Service Documents', 'Executive',
            'Agriculture', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/national-agricultural-statistics' >View all National Agricultural Statistics Service news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/nifa">
    <h5 id='presidentialFeedHeadingMobile'>National Institute of Food and Agriculture<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false} >
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('National Institute of Food and Agriculture',
              <GenerateFeedDepartment url='&table_name=environment&agency=National%20Institute%20of%20Food%20and%20Agriculture'
              name='National Institute of Food and Agriculture' branch='Executive' topic='Agriculture' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defNifa'></div>
          {CreateFeedAPIDepartmentUS('&table_name=environment&agency=National%20Institute%20of%20Food%20and%20Agriculture', 'defNifa',
            'National Institute of Food and Agriculture Documents', 'Executive',
            'Agriculture', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/nifa' >View all National Institute of Food and Agriculture news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/natural-resources-conservation">
    <h5 id='presidentialFeedHeadingMobile'>Natural Resources Conservation Service<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false} >
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Natural Resources Conservation Service',
              <GenerateFeedDepartment url='&table_name=environment&agency=Natural%20Resources%20Conservation%20Service'
              name='Natural Resources Conservation Service' branch='Executive' topic='Agriculture' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defNrcs'></div>
          {CreateFeedAPIDepartmentUS('&table_name=environment&agency=Natural%20Resources%20Conservation%20Service', 'defNrcs',
            'Natural Resources Conservation Service Documents', 'Executive',
            'Agriculture', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/natural-resources-conservation' >View all Natural Resources Conservation Service news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/operations-office">
    <h5 id='presidentialFeedHeadingMobile'>Operations Office<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false} >
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Operations Office',
              <GenerateFeedDepartment url='&table_name=environment&agency=Operations%20Office'
              name='Operations Office' branch='Executive' topic='Agriculture' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defOps'></div>
          {CreateFeedAPIDepartmentUS('&table_name=environment&agency=Operations%20Office', 'defOps',
            'Operations Office Documents', 'Executive',
            'Agriculture', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/operations-office' >View all Operations Office news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/partnerships-public-engagement">
    <h5 id='presidentialFeedHeadingMobile'>Partnerships and Public Engagement Office<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false} >
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Partnerships and Public Engagement Office',
              <GenerateFeedDepartment url='&table_name=environment&agency=Partnerships%20and%20Public%20Engagement%20Office'
              name='Partnerships and Public Engagement Office' branch='Executive' topic='Agriculture' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defPpeo'></div>
          {CreateFeedAPIDepartmentUS('&table_name=environment&agency=Partnerships%20and%20Public%20Engagement%20Office', 'defPpeo',
            'Partnerships and Public Engagement Office Documents', 'Executive',
            'Agriculture', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/partnerships-public-engagement' >View all Partnerships and Public Engagement Office news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/procurement-property-management">
    <h5 id='presidentialFeedHeadingMobile'> Office of Procurement and Property Management<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false} >
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Procurement and Property Management, Office of',
              <GenerateFeedDepartment url='&table_name=environment&agency=Procurement%20and%20Property%20Management%2C%20Office%20of'
              name='Office of Procurement and Property Management' branch='Executive' topic='Agriculture' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defPpm'></div>
          {CreateFeedAPIDepartmentUS('&table_name=environment&agency=Procurement%20and%20Property%20Management%2C%20Office%20of', 'defPpm',
            'Procurement and Property Management, Office of Documents', 'Executive',
            'Agriculture', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/procurement-property-management' >View all Procurement and Property Management, Office of news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/risk-management-agency">
    <h5 id='presidentialFeedHeadingMobile'>Risk Management Agency<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false} >
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Risk Management Agency',
              <GenerateFeedDepartment url='&table_name=environment&agency=Risk%20Management%20Agency'
              name='Risk Management Agency' branch='Executive' topic='Agriculture' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defRma'></div>
          {CreateFeedAPIDepartmentUS('&table_name=environment&agency=Risk%20Management%20Agency', 'defRma',
            'Risk Management Agency Documents', 'Executive',
            'Agriculture', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/risk-management' >View all Risk Management Agency news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/rural-business-cooperative">
    <h5 id='presidentialFeedHeadingMobile'>Rural Business-Cooperative Service<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false} >
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Rural Business-Cooperative Service',
              <GenerateFeedDepartment url='&table_name=environment&agency=Rural%20Business-Cooperative%20Service'
              name='Rural Business-Cooperative Service' branch='Executive' topic='Agriculture' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defRbcs'></div>
          {CreateFeedAPIDepartmentUS('&table_name=environment&agency=Rural%20Business-Cooperative%20Service', 'defRbcs',
            'Rural Business-Cooperative Service Documents', 'Executive',
            'Agriculture', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/rural-business-cooperative' >View all Rural Business-Cooperative Service news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/rural-housing-community-development">
    <h5 id='presidentialFeedHeadingMobile'>Rural Housing and Community Development Service<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false} >
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Rural Housing and Community Development Service',
              <GenerateFeedDepartment url='&table_name=environment&agency=Rural%20Housing%20and%20Community%20Development%20Service'
              name='Rural Housing and Community Development Service' branch='Executive' topic='Agriculture' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defRhcds'></div>
          {CreateFeedAPIDepartmentUS('&table_name=environment&agency=Rural%20Housing%20and%20Community%20Development%20Service', 'defRhcds',
            'Rural Housing and Community Development Service Documents', 'Executive',
            'Agriculture', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/rural-housing-community-development' >View all Rural Housing and Community Development Service news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/rural-housing">
    <h5 id='presidentialFeedHeadingMobile'>Rural Housing Service<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false} >
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Rural Housing Service',
              <GenerateFeedDepartment url='&table_name=environment&agency=Rural%20Housing%20Service'
              name='Rural Housing Service' branch='Executive' topic='Agriculture' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defRhs'></div>
          {CreateFeedAPIDepartmentUS('&table_name=environment&agency=Rural%20Housing%20Service', 'defRhs',
            'Rural Housing Service Documents', 'Executive',
            'Agriculture', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/rural-housing' >View all Rural Housing Service news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/rural-telephone-bank">
    <h5 id='presidentialFeedHeadingMobile'>Rural Telephone Bank<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false} >
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Rural Telephone Bank',
              <GenerateFeedDepartment url='&table_name=environment&agency=Rural%20Telephone%20Bank'
              name='Rural Telephone Bank' branch='Executive' topic='Agriculture' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defRtb'></div>
          {CreateFeedAPIDepartmentUS('&table_name=environment&agency=Rural%20Telephone%20Bank', 'defRtb',
            'Rural Telephone Bank Documents', 'Executive',
            'Agriculture', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/rural-telephone-bank' >View all Rural Telephone Bank news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/rural-utilities">
    <h5 id='presidentialFeedHeadingMobile'>Rural Utilities Service<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false} >
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Rural Utilities Service',
              <GenerateFeedDepartment url='&table_name=environment&agency=Rural%20Utilities%20Service'
              name='Rural Utilities Service' branch='Executive' topic='Agriculture' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defRus'></div>
          {CreateFeedAPIDepartmentUS('&table_name=environment&agency=Rural%20Utilities%20Service', 'defRus',
            'Rural Utilities Service Documents', 'Executive',
            'Agriculture', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/rural-utilities' >View all Rural Utilities Service news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/transportation-office">
    <h5 id='presidentialFeedHeadingMobile'>Transportation Office<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false} >
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Transportation Office',
              <GenerateFeedDepartment url='&table_name=environment&agency=Transportation%20Office'
              name='Transportation Office' branch='Executive' topic='Agriculture' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defTo'></div>
          {CreateFeedAPIDepartmentUS('&table_name=environment&agency=Transportation%20Office', 'defTo',
            'Transportation Office Documents', 'Executive',
            'Agriculture', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/transportation-office' >View all Transportation Office news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/us-codex">
    <h5 id='presidentialFeedHeadingMobile'>U.S. Codex Office<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false} >
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('U.S. Codex Office',
              <GenerateFeedDepartment url='&table_name=environment&agency=U.S.%20Codex%20Office'
              name='U.S. Codex Office' branch='Executive' topic='Agriculture' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defCodex'></div>
          {CreateFeedAPIDepartmentUS('&table_name=environment&agency=U.S.%20Codex%20Office', 'defCodex',
            'U.S. Codex Office Documents', 'Executive',
            'Agriculture', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/us-codex' >View all U.S. Codex Office news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>


<br></br>
          
          
            <br></br><br></br><br></br><br></br><br></br><br></br><Navbar id='navbarMobileBottom' fixed="bottom">    

          


       
<div id='changeBranchDropdownMobile'>

           <MobileOffCanvas/>
       
            </div>

           


           
 
             
 
 
 
 
 
 
</Navbar>
          </div>

          </>
  )
}
 }



  






export default AgricultureMobile;



import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch, } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment, } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText, ShieldShaded, PlayCircle, PauseCircle, HeartFill, Pen, People, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, Coin, Tree, ChevronRight } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import AddNoteModal from './AddNoteModal';
import Axios from 'axios'
import { render } from '@testing-library/react';
import EconomyMobile from './EconomyMobile';
import FullBillFeed from './FullBillFeed';
import FullWhiteHouseFeed from './FullWhiteHouseFeed';
import FullUSCourtNews from './FullUSCourtNews';
import FullWhiteHouseDocuments from './FullWhiteHouseDocuments';
import DailyDigest from './DailyDigest';
import State from './State';
import Illinois from './state/Illinois';
import CollectionPage from './CollectionPage';
import NewLaws from './NewLaws';
import BillsEnrolled from './BillsEnrolled';
import DepartmentOfState from './DepartmentOfState';
import DepartmentOfTreasury from './DepartmentOfTreasury';
import CourtAppeals from './CourtAppeals';
import Reports from './Reports';
import DepartmentOfDefense from './DepartmentOfDefense';
import DepartmentOfJustice from './DepartmentOfJustice';
import DepartmentOfTheInterior from './DepartmentOfTheInterior';
import DepartmentOfAgriculture from './DepartmentOfAgriculture';
import DepartmentOfCommerce from './DepartmentOfCommerce';
import DepartmentOfLabor from './DepartmentOfLabor';
import DepartmentOfHealthAndHumanServices from './DepartmentOfHealthAndHumanServices';
import DepartmentOfHousingAndUrbanDevelopment from './DepartmentOfHousingAndUrbanDevelopment';
import DepartmentOfTransportation from './DepartmentOfTransportation';
import DepartmentOfEnergy from './DepartmentOfEnergy';
import DepartmentOfEducation from './DepartmentOfEducation';
import DepartmentOfVeteranAffairs from './DepartmentOfVeteranAffairs';
import DepartmentOfHomelandSecurity from './DepartmentOfHomelandSecurity';
import EnvironmentalProtectionAgency from './EnvironmentalProtectionAgency';
import NationalAeronauticsAndSpaceAdministration from './NationalAeronauticsAndSpaceAdministration';
import TrendingBills from './TrendingBills';
import GAOReports from './GAOReports';
import LegislativeBranch from './LegislativeBranch';
import HouseComitteeOnAgriculture from './HouseComitteeOnAgriculture';
import HouseCommitteeOnAppropriations from './HouseCommitteeOnArmedServices';

import App from './App';
import Overlay from 'react-overlays/esm/Overlay';
import SecuritiesAndExchangeCommissionPressReleases from './SecuritiesAndExchangeCommissionPressReleases';
import SecuritiesAndExchangeCommissionSpeechesAndStatments from './SecuritiesAndExchangeCommissionSpeechesAndStatments';
import BureauOfEconomicAnalysis from './BureauOfEconomicAnalysis';
import EconomicIndicators from './EconomicIndicators';
import HouseCommitteeOnTheBudget from './HouseCommitteeOnTheBudget';
import HouseCommitteeOnEnergyAndCommerce from './HouseCommitteeOnEnergyAndCommerce';
import HouseCommitteeOnEducationAndLabor from './HouseCommitteeOnEducationAndLabor';
import HouseCommitteeOnFinacialServices from './HouseCommitteeOnFinacialServices';
import CongressionalBudgetOffice from './CongressionalBudgetOffice';
import HouseCommitteeOnSmallBusiness from './HouseCommitteeOnSmallBusiness';
import { createBillFeedTwoMobile, createBIllItem, Speak, CreateFeedItem, CreateFeedItemEconomicIndicators, CreateFeedItemExecutiveOffice, CreateFeedAPI } from './Functions';
import DesktopNavbar from './DesktopNavbar';
import GDPUpdates from './GDPUpdates';
import InflationUpdates from './InflationUpdates';
import WageGrowth from './WageGrowth';
import BureauOfLaborStatistics from './BureauOfLaborStatistics';
import IRSDocuments from './IRSDocuments';
import GovernmentContracts from './GovernmentContracts';
import CouncilOfEconomicAdvisers from './CouncilOfEconomicAdvisers';
import NationalEconomicCouncil from './NationalEconomicCouncil';
import OfficeOfManagmentAndBudget from './OfficeOfManagmentAndBudget';
import GenerateFeed from './GenerateFeed';



var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 60000;





  

 


  


//https://evening-plains-21303.herokuapp.com/
function ListItem(props) {
  // Correct! There is no need to specify the key here:
  return <Card>{props.value}</Card>;
}
function createFullBillFeed() {

  function NumberList(props) {
    const numbers = props.numbers;
    const listItems = numbers.map((number) =>
      // Correct! Key should be specified inside the array.
      <ListItem key={number.toString()} value={number} />
    );
    return (

      <Container><Card>{listItems}</Card></Container>

    );
  }
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];


  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.govinfo.gov/rss/bills.xml');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const linkChunk = links.toString().substring(36);
      //console.log(billTitle[99])
      const viewBillLink = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");
      //console.log(viewBillLink)


      ReactDOM.render(
        <NumberList numbers={billTitle} />,
        document.getElementById('root')
      );

      //render(<BillFeed />);



    }













    //inner html <br>





  })();
}

function testmoney() {
  
}








function saveToCollection(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionNote, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName ){
    
  
  var noteModal = true
     collectionCounter = localStorage.getItem('collectionNumber')
     //console.log(collectionCounter)
   collectionCounter++;
     //console.log(collectionCounter)
   
   var collectionTitles = []
    var collectionDetailLinks = []
   var collectionDocLinks = []
   var collectionNote = []
   
   //collectionTitles.push(saveCollectionTitle)
   //collectionDetailLinks.push(saveCollectionDetailLink)
   //collectionDocLinks.push(saveCollectionDocLink)
   
   
   
    
     
   
    
     
     var title = 'title'.concat(collectionCounter)
     var detailLink = 'detailLink'.concat(collectionCounter)
     var docLink = 'docLink'.concat(collectionCounter)
     var itemNote = 'itemNote'.concat(collectionCounter)
     var itemDate = 'itemDate'.concat(collectionCounter)
     var feedName = 'feedName'.concat(collectionCounter)
     var branchName = 'branchName'.concat(collectionCounter)
     var topicName = 'topicName'.concat(collectionCounter)
 
   
   
     localStorage.setItem(title,saveCollectionTitle)
     
     localStorage.setItem(detailLink,saveCollectionDetailLink)
    
     localStorage.setItem(docLink,saveCollectionDocLink)
     localStorage.setItem(itemNote,saveCollectionNote)
 
     localStorage.setItem(itemDate,saveCollectionDate)
     localStorage.setItem(feedName,saveCollectionFeedName)
     localStorage.setItem(branchName,saveCollectionBranchName)
     localStorage.setItem(topicName,saveCollectionTopicName)
   
     localStorage.setItem('collectionNumber', collectionCounter)
    
     function saveNote() {
   
       var searchItem = document.getElementById('noteModal').value
       
     
       var indexStore = localStorage.getItem('collectionNumber')
       var itemNote = 'itemNote'.concat(collectionCounter)
       
 
       
       localStorage.setItem(itemNote,searchItem)
 
     window.location.reload()
     
       //Override note value null -> "input"
     
     
       
     
     
     
     
     }
 
   //return collectionCounter;
 
 return(
  render(
     <>
     <AddNoteModal></AddNoteModal>
 
     
     
                             </>
   )
 )
   
     //[x] localStorage.getItem('collectionNumber')
     //[x] localStorage.getItem(saveCollectionNote)
 
   
  
     //<Button onClick={() => { noteModal = false; console.log(localStorage.getItem(saveCollectionNote)) }}>test</Button>
   
     //onClick={() => saveNote()}
   
   }





function createCongressionalBudgetOffice() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const billContent = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.cbo.gov/publications/all/rss.xml');






    feed.items.forEach(item => {


      billContent.push(item.content);
      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));


      feed.item = item.title + ':' + item.link;

    });



    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]




      ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[0]}</Card.Title>
      
      <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
      <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[0], billLink[0], null, pubDate[0], null, "Congressional Budget Office", "Legislative", "Economy")




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('congressionalBudgetOffice'));



    
















      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}

//Bills Presented to President
//Currently dorment
function createBillsPresentedFeed() {
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const feedContent = [];

  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.congress.gov/rss/presented-to-president.xml');

    feed.items.forEach(item => {

      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      feedContent.push(item.content);
      //console.log(billTitle)
      feed.item = item.title + ':' + item.link;

    });


    for (let index = 0; index < billTitle.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const content = feedContent[index];

      const linkChunk = links.toString().substring(36);

      const viewBillLink = "https://www.congress.gov/rss/presented-to-president.xml"



      const BillFeed = () => (
        <div id='legislativeBills'>
          <Container>

            <Card.Title id='billText'>{titles}</Card.Title>
         
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.location.href = billLink[index]
            }}><InfoCircle></InfoCircle> Details</Button>


          </Container>

          <br></br>

        </div>


      );
      ReactDOM.render(<BillFeed />, document.getElementById('presented'));

      //render(<BillFeed />);



    }

  })();





}


//White House Feed
function createWhiteHouseFeed() {
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const feedContent = [];
  const pubDate = [];

  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.whitehouse.gov/feed/');

    feed.items.forEach(item => {

      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      feedContent.push(item.content.replace('&#8230;', '...'));
      feed.item = item.title + ':' + item.link;

    });


    for (let index = 0; index < billTitle.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const content = feedContent[index]
      const linkChunk = links.toString().substring(36);
      const date = pubDate[index].toString().substring(0, 25)

      const viewBillLink = "https://www.govinfo.gov/rss/dcpd.xml"

      const BillFeed = () => (
        <div id='legislativeBills'>
          <Container id='feedContainerNormalView' >

            <Card.Title id='billText'>{titles}</Card.Title>
            
            <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blanl")
            }}><InfoCircle></InfoCircle> Details</Button>

            <Button id='addToCollectionButton' onClick={() => saveToCollection(titles, billLink[index], null, null)




            }><FolderPlus></FolderPlus></Button>


          </Container>

          <br></br>

        </div>


      );
      ReactDOM.render(<BillFeed />, document.getElementById('wh'));

      //render(<BillFeed />);



    }

  })();





}

function createWhiteHouseFeedMobile() {
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const feedContent = [];
  const pubDate = [];

  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.whitehouse.gov/feed/');

    feed.items.forEach(item => {

      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      feedContent.push(item.content.replace('&#8230;', '...'));
      feed.item = item.title + ':' + item.link;

    });


    for (let index = 0; index < billTitle.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const content = feedContent[index]
      const linkChunk = links.toString().substring(36);
      const date = pubDate[index].toString().substring(0, 25)

      const viewBillLink = "https://www.govinfo.gov/rss/dcpd.xml"

      const BillFeed = () => (
        <div id='legislativeBills'>
          <Container id='feedContainerNormalView' >

            <Card.Title id='billText'>{titles}</Card.Title>

            <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blanl")
            }}><InfoCircle></InfoCircle> Details</Button>

            <Button id='addToCollectionButton' onClick={() => saveToCollection(titles, billLink[index], null, null)




            }><FolderPlus></FolderPlus></Button>


          </Container>

          <br></br>

        </div>


      );
      ReactDOM.render(<BillFeed />, document.getElementById('wh'));



      //render(<BillFeed />);



    }

  })();





}
//Legislative Bills
function createBillFeed() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];

  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.govinfo.gov/rss/bills.xml');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billTitle.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];

      const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      const viewBillLink = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");
      //console.log(viewBillLink)
      const BillFeed = () => (
        <div id='legislativeBills'>
          <Container>

            <Card.Title id='billText'>{titles}</Card.Title>
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blank")
            }}><InfoCircle></InfoCircle> Details</Button>
            <Button id='viewBillButton' onClick={(e) => {
              e.preventDefault();
              window.open(viewBillLink, "_blank")
            }}><FileText></FileText> View Bill</Button>

          </Container>

          <br></br>

        </div>


      );
      ReactDOM.render(<BillFeed />, document.getElementById('bills'));

      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}

function createBillFeedTwo() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.govinfo.gov/rss/bills.xml');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml"));

      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[index]}</Card.Title><Card.Body id='billBodyText'>{pubDate[0]}</Card.Body><Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[index], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button> <Button id='viewBillButton' onClick={(e) => {
        e.preventDefault();
        window.open(viewBillLink[index], "_blank")
      }}><FileText></FileText> View Bill</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], viewBillLink[index], null)




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('bills'));





      ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[index - 2]}</Card.Title><Card.Body id='billBodyText'>{pubDate[1]}</Card.Body><Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[index - 2], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button> <Button id='viewBillButton' onClick={(e) => {
        e.preventDefault();
        window.open(viewBillLink[index - 2], "_blank")
      }}><FileText></FileText> View Bill</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index - 2], billLink[index - 2], viewBillLink[index - 2], null)




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('bills2'));














      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}

function createReports() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.govinfo.gov/rss/crpt.xml');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));


      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[index]}</Card.Title><Card.Body id='billBodyText'>{pubDate[0]}</Card.Body><Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[index], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button> <Button id='viewBillButton' onClick={(e) => {
        e.preventDefault();
        
        window.open(viewBillLink[index], "_blank")
      }}><FileText></FileText> View Report</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], viewBillLink[index], null)




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('reports'));





      ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[index - 2]}</Card.Title><Card.Body id='billBodyText'>{pubDate[1]}</Card.Body><Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[index - 2], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button> <Button id='viewBillButton' onClick={(e) => {
        e.preventDefault();
        window.open(viewBillLink[index - 2], "_blank")
      }}><FileText></FileText> View Report</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index - 2], billLink[index - 2], viewBillLink[index - 2], null)




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('reports2'));














      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}


function createHouseCommitteeOnSmallBusiness() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://docs.house.gov/Committee/RSS.ashx?Code=SM00');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      //console.log('ETHICS: ' + billTitle)
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));


      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[0]}</Card.Title>
      <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
      <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[0], billLink[0], null, pubDate[0], null, "House Committee on Small Business", "Legislative", "Economy")




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('houseCommitteeOnSmallBusiness'));



      
















      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}


function createSecuritiesAndExchangeCommissionPressReleases() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.sec.gov/news/pressreleases.rss');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));


      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[0]}</Card.Title>
      <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
      <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[0], billLink[0], null, pubDate[0], null, "SEC Press Releases", "N/A", "Economy")




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('secPR'));





 














      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}

function createEconomicIndicators() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.govinfo.gov/rss/econi.xml');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);

      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));


      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView'>
        <Card.Title id='billText'>{billTitle[billTitle.length -2]}</Card.Title>
        <Card.Body id='billBodyText'>{pubDate[billTitle.length -2]}</Card.Body>
        <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[billTitle.length - 2], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>
        <Button id='viewBillButton' onClick={(e) => {
              e.preventDefault();
              window.open(viewBillLink[billTitle.length - 2],"_blank")
            }}><FileText></FileText> Document</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[billTitle.length - 2], billLink[billTitle.length - 2], viewBillLink[billTitle.length - 2], pubDate[billTitle.length -2], null, "Economic Indicators", "N/A", "Economy")




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('economicIndicators'));





     














      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}

function createSecuritiesAndExchangeCommissionSpeechesAndStatements() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.sec.gov/news/speeches-statements.rss');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      

      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));


      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView'>
        <Card.Title id='billText'>{billTitle[0]}</Card.Title>
        <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
        <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[0], billLink[0], null, pubDate[0], null, "SEC Speeches and Statements", "N/A", "Economy")




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('sec'));


















      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}



function createDepartmentOfTreasury() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://home.treasury.gov/system/files/126/ofac.xml');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));

      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[99]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView' >
        <Card.Title id='billText'>{billTitle[index]}</Card.Title>
        <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
        <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[index], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, pubDate[index], null, "Department of Treasury", "Executive", "Economy" )



        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('treasuryDepartment'));





      














      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}


function createDepartmentOfCommerce() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.commerce.gov/feeds/news');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));

      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = billsFeed.length; index >= 0; index--) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[99]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView' >
        <Card.Title id='billText'>{billTitle[index]}</Card.Title>
        <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body><Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[index], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, pubDate[0], null, "Department of Commerce", "Executive", "Economy")




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('commerceDepartment'));




















      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}




function createDepartmentOfLabor() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.dol.gov/rss/releases.xml');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      pubDate.push(item.pubDate.toString().substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));

      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[99]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView' >
        <Card.Title id='billText'>{billTitle[index]}</Card.Title>
        <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
        <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[index], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, pubDate[0], null, "Department of Labor", "Executive", "Economy")




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('laborDepartment'));





   














      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}



function createHouseCommitteeOnTheBudget() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://docs.house.gov/Committee/RSS.ashx?Code=BU00');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));


      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView'>
        <Card.Title id='billText'>{billTitle[0]}</Card.Title>
        <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
        <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[0], billLink[0], null, pubDate[0], null, "House Committee on the Budget", "Legislative", "Economy")




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('houseCommitteeOnTheBudget'));





     














      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}

function createHouseCommitteeOnEnergyAndCommerce() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://docs.house.gov/Committee/RSS.ashx?Code=IF00');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));


      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView'>
        <Card.Title id='billText'>{billTitle[0]}</Card.Title>
        <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
        <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[0], billLink[0], null, pubDate[0], null, "House Committee on Energy and Commerce", "Legislative", "Economy")




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('houseCommitteeOnEnergyAndCommmerce'));





      














      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}

function createHouseCommitteeOnEducationAndLabor() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://docs.house.gov/Committee/RSS.ashx?Code=ED00');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));


      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView'>
        <Card.Title id='billText'>{billTitle[0]}</Card.Title>
        <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
        <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[0], billLink[0], null, pubDate[0], null, "House Committee on Education and Labor", "Legislative", "Economy")




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('houseCommitteeOnEducationAndLabor'));



















      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}

function createHouseCommitteeOnFinancialServices() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://docs.house.gov/Committee/RSS.ashx?Code=BA00');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      //console.log('ETHICS: ' + billTitle)
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));


      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView'>
        <Card.Title id='billText'>{billTitle[0]}</Card.Title>
        <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
        <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[0], billLink[0], null, pubDate[0], null, "House Committee on Financial Services", "Legislative", "Economy")




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('houseCommitteeOnFinancialServices'));




















      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}










export const Economy = () =>
 {
   render()
   
   {
  const [show, setShow] = useState(true);

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [fullscreen, setFullScreen] = useState(true);


  const [fullBillModalIsOpen, setFullBillModalIsOpen] = useState(false)

  const [reportModalIsOpen, setReportModalIsOpen] = useState(false)


  const [whiteHouseFeedModalIsOpen, setWhiteHouseFeedModalIsOpen] = useState(false)
  const [whiteHouseDocsModalIsOpen, setWhiteHouseDocsModalIsOpen] = useState(false)


  const [courtNewsModalIsOpen, setCourtNewsModalIsOpen] = useState(false)
  const [courtModalIsOpen, setCourtModalIsOpen] = useState(false)

  const [courtAppealsModalIsOpen, setCourtAppealsModalIsOpen] = useState(false)

  const [newLawsModalIsOpen, setNewLawsModalIsOpen] = useState(false)

  const [billsEnrolledModalIsOpen, setBillsEnrolledModalIsOpen] = useState(false)

  const [legislativePageBillsEnrolledModalIsOpen, setLegislativePageBillsEnrolledModalIsOpen] = useState(false)


  const [departmentOfStateModalIsOpen, setDepartmentOfStateModalIsOpen] = useState(false)
  const [departmentOfTreasuryModalIsOpen, setDepartmentOfTreasuryModalIsOpen] = useState(false)
  const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
  const [departmentOfJusticeModalIsOpen, setDepartmentOfJusticeModalIsOpen] = useState(false)
  const [departmentOfTheInteriorModalIsOpen, setDepartmentOfTheInteriorModalIsOpen] = useState(false)
  const [departmentOfAgricultureModalIsOpen, setDepartmentOfAgricultureModalIsOpen] = useState(false)
  const [departmentOfCommerceModalIsOpen, setDepartmentOfCommerceModalIsOpen] = useState(false)
  const [departmentOfLaborModalIsOpen, setDepartmentOfLaborModalIsOpen] = useState(false)
  const [departmentOfHealthAndHumanServicesModalIsOpen, setDepartmentOfHealthAndHumanServicesModalIsOpen] = useState(false)
  const [departmentOfHousingAndUrbanDevelopmentModalIsOpen, setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen] = useState(false)
  const [departmentOfTransportationModalIsOpen, setDepartmentOfTransportationModalIsOpen] = useState(false)
  const [departmentOfEnergyModalIsOpen, setDepartmentOfEnergyModalIsOpen] = useState(false)
  const [departmentOfEducationModalIsOpen, setDepartmentOfEducationModalIsOpen] = useState(false)
  const [departmentOfVeteransAffairsModalIsOpen, setDepartmentOfVeteransAffairsModalIsOpen] = useState(false)
  const [departmentOfHomelandSecurityModalIsOpen, setDepartmentOfHomelandSecurityModalIsOpen] = useState(false)
  const [epaModalIsOpen, setEPAModalIsOpen] = useState(false)
  const [nasaModalIsOpen, setNASAModalIsOpen] = useState(false)
  const [beaModalIsOpen, setBEAModalIsOpen] = useState(false)
  const [secPressModalIsOpen, setSECPressModalIsOpen] = useState(false)

  const [gdpUpdatesModalIsOpen, setGDPUpdatesModalIsOpen] = useState(false)
  const [inflationUpdatesModalIsOpen, setInflationUpdatesModalIsOpen] = useState(false)
  const [wageGrowthModalIsOpen, setWageGrowthModalIsOpen] = useState(false)
  const [bureauOfLaborStatisticsModalIsOpen, setBureauOfLaborStatisticsModalIsOpen] = useState(false)
  const [irsDocumentsModalIsOpen, setIRSDocumentsModalIsOpen] = useState(false)
  const [governmentContractsModalIsOpen, setGovernmentContractsModalIsOpen] = useState(false)


    const [secSAndSModalIsOpen, setSECSAndSModalIsOpen] = useState(false)
  const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
  const [goaReportsModalIsOpen, setGAOReportsModalIsOpen] = useState(false)

  const [houseComitteeOnAgricultureModalIsOpen, setHouseComitteeOnAgricultureModalIsOpen] = useState(false)
  const [houseCommitteeOnAppropriationsModalIsOpen, setHouseCommitteeOnAppropriationsModalIsOpen] = useState(false)
  const [houseCommitteeOnArmedServicesModalIsOpen, setHouseCommitteeOnOnArmedServicesModalIsOpen] = useState(false)
  const [houseCommitteeOnTheBudgetModalIsOpen, setHouseCommitteeOnTheBudgetModalIsOpen] = useState(false)
  const [houseCommitteeOnEnergyAndCommerceModalIsOpen, setHouseCommitteeOnEnergyAndCommerceModalIsOpen] = useState(false)
  const [houseCommitteeOnEducationAndLaborModalIsOpen, setHouseCommitteeOnEducationAndLaborModalIsOpen] = useState(false)
  const [houseCommitteeOnEthicsModalIsOpen, setHouseCommitteeOnEthicsModalIsOpen] = useState(false)
  const [houseCommitteeOnFinancialServicesModalIsOpen, setHouseCommitteeOnFinancialServicesModalIsOpen] = useState(false)
  const [houseCommitteeOnForeignAffairsModalIsOpen, setHouseCommitteeOnForeignAffairsModalIsOpen] = useState(false)
  const [houseCommitteeOnHomelandSecurityModalIsOpen, setHouseCommitteeOnHomelandSecurityModalIsOpen] = useState(false)
  const [houseCommitteeOnHouseAdministrationModalIsOpen, setHouseCommitteeOnHouseAdministrationModalIsOpen] = useState(false)
  const [houseCommitteeOnTheJudiciaryModalIsOpen, setHouseCommitteeOnTheJudiciaryModalIsOpen] = useState(false)
  const [houseCommitteeOnNaturalResourcesModalIsOpen, setHouseCommitteeOnNaturalResourcesModalIsOpen] = useState(false)
  const [congressionalBudgetOfficeModalIsOpen, setCongressionalBudgetOfficeModalIsOpen] = useState(false)
  const [houseCommitteeOnSmallBusinessModalIsOpen, setHouseCommitteeOnSmallBusinessModalIsOpen] = useState(false)


  const [statesModalIsOpen, setStatesModalIsOpen] = useState(false)
  const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false)
  const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)



  const [declarationModalIsOpen, setDeclarationModalIsOpen] = useState(false)
  const [constitutionModalIsOpen, setConstitutionModalIsOpen] = useState(false)
  const [billOfRightsModalIsOpen, setBillOfRightsModalIsOpen] = useState(false)


  const [
    number1ModalIsOpen, setNumber1ModalIsOpen,
    number2ModalIsOpen, setNumber2ModalIsOpen,
    number3ModalIsOpen, setNumber3ModalIsOpen,
    number4ModalIsOpen, setNumber4ModalIsOpen,
    number5ModalIsOpen, setNumber5ModalIsOpen,
    number6ModalIsOpen, setNumber6ModalIsOpen,
    number7ModalIsOpen, setNumber7ModalIsOpen,
    number8ModalIsOpen, setNumber8ModalIsOpen,
    number9ModalIsOpen, setNumber9ModalIsOpen,
    number10ModalIsOpen, setNumber10ModalIsOpen,
    number11ModalIsOpen, setNumber11ModalIsOpen,
    number12ModalIsOpen, setNumber12ModalIsOpen,
    number13ModalIsOpen, setNumber13ModalIsOpen,
    number14ModalIsOpen, setNumber14ModalIsOpen,
    number15ModalIsOpen, setNumber15ModalIsOpen,
    number16ModalIsOpen, setNumber16ModalIsOpen,
    number17ModalIsOpen, setNumber17ModalIsOpen,
    number18ModalIsOpen, setNumber18ModalIsOpen,
    number19ModalIsOpen, setNumber19ModalIsOpen,
    number20ModalIsOpen, setNumber20ModalIsOpen,
    number21ModalIsOpen, setNumber21ModalIsOpen,
    number22ModalIsOpen, setNumber22ModalIsOpen,
    number23ModalIsOpen, setNumber23ModalIsOpen,
    number24ModalIsOpen, setNumber24ModalIsOpen,
    number25ModalIsOpen, setNumber25ModalIsOpen,
  ] = useState(false);

  return(
    <>
      <BrowserView>
        <div id='homePage'>

    

        <div id='homebg'>

        <DesktopNavbar/>     

            <div id='sectionHeading'>
             <Row>
            
              <h3 id='branchHeading' ><Coin></Coin> <b>Economy</b></h3>
       
            </Row>
            </div>
                     
            <div id='branchPage'>
              <Container class='bgcolor' >
                <Container id='carouselBufferThree'>

                  <Row>

                  <Col id='legislativeColLeft'>
                      <div id='columnRightPadding'>

                        <h4 id='departmentOfStateHeading' onClick={() => setNumber1ModalIsOpen(true)}><b>SEC Press Releases</b></h4>

                        <div  id='secPR'></div>
                        {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=sec_updates&order_by=created_at', 'secPR', 
                            'SEC Press Releases', 'Executive', 
                            'Economy', 'Document', GenerateFeed)}

                          <Modal id='whiteHouseDocsModal' show={number1ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
                          centered onHide={() => setNumber1ModalIsOpen(false)}>
                            <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>SEC Press Releases</h3></Modal.Header>
                            <GenerateFeed url='&skip=0&schema=united_states_of_america&table=sec_updates&order_by=created_at' 
                            name='SEC Press Releases' branch='Executive' topic='Economy' buttonName='Document' />
                          </Modal>



                      </div>
                    </Col>
                   
                    <Col id='legislativeColRight'>
                      <div id='columnLefttPadding'>
                        <h4 id='presidentialFeedHeading' onClick={() => setNumber2ModalIsOpen(true)}><b>SEC Speeches and Statements</b></h4>
                        <div class='whdocs' id='sec'></div>
             

                        {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=sec_speeches_statements&order_by=created_at', 'sec', 
                            'SEC Speeches and Statements', 'Executive', 
                            'Economy', 'Document', GenerateFeed)}

                          <Modal id='whiteHouseDocsModal' show={number2ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
                          centered onHide={() => setNumber2ModalIsOpen(false)}>
                            <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>SEC Speeches and Statements</h3></Modal.Header>
                            <GenerateFeed url='&skip=0&schema=united_states_of_america&table=sec_speeches_statements&order_by=created_at' 
                            name='SEC Speeches and Statements' branch='Executive' topic='Economy' buttonName='Document' />
                          </Modal>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Container>
            </div>


            <div id='branchPage'>
              <Container class='bgcolor' >
                <Container id='carouselBufferThree'>

                  <Row>

                  <Col id='legislativeColLeft'>
                      <div id='columnLefttPadding'>

                      <h4 id='feedHeadingBillsEnrolled' onClick={() => setNumber3ModalIsOpen(true)} ><b>GDP Updates</b></h4>
                        <div id='gdpUpdates'></div>
                        
                        {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=gdp&order_by=created_at', 'gdpUpdates', 
                            'GDP Updates', 'Executive', 
                            'Economy', 'Document', GenerateFeed)}

                          <Modal id='whiteHouseDocsModal' show={number3ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
                          centered onHide={() => setNumber3ModalIsOpen(false)}>
                            <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>GDP Updates</h3></Modal.Header>
                            <GenerateFeed url='&skip=0&schema=united_states_of_america&table=gdp&order_by=created_at' 
                            name='GDP Updates' branch='Executive' topic='Economy' buttonName='Document' />
                          </Modal>
                      </div>
                    </Col>

                    

                    <Col id='legislativeColRight'>
                      <div id='columnRightPadding'>

                      <h4 id='feedHeadingBillsEnrolled' onClick={() => setNumber4ModalIsOpen(true)} ><b>Inflation Updates</b></h4>
                        <div id='inflationUpdates'></div>
                        
                        {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=inflation_updates&order_by=created_at', 
                        'inflationUpdates', 
                            'Inflation Updates', 'Executive', 
                            'Economy', 'Document', GenerateFeed)}

                          <Modal id='whiteHouseDocsModal' show={number4ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
                          centered onHide={() => setNumber4ModalIsOpen(false)}>
                            <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Inflation Updates</h3></Modal.Header>
                            <GenerateFeed url='&skip=0&schema=united_states_of_america&table=inflation_updates&order_by=created_at' 
                            name='Inflation Updates' branch='Executive' topic='Economy' buttonName='Document' />
                          </Modal>
                      </div>
                    </Col>



                  </Row>



                </Container>
              </Container>
            </div>

            <div id='branchPage'>
              <Container class='bgcolor' >
                <Container id='carouselBufferThree'>

                  <Row>

                    

                

                    <Col id='legislativeColLeft'>
                      <div id='columnLefttPadding'>

                      <h4 id='feedHeadingBillsEnrolled' onClick={() => setNumber5ModalIsOpen(true)} ><b>Wage Growth</b></h4>
                        <div id='wageGrowth'></div>
                        
                        {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=wage_growth&order_by=created_at', 
                        'wageGrowth', 
                            'Wage Growth', 'Executive', 
                            'Economy', 'Document', GenerateFeed)}

                          <Modal id='whiteHouseDocsModal' show={number5ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
                          centered onHide={() => setNumber5ModalIsOpen(false)}>
                            <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Wage Growth</h3></Modal.Header>
                            <GenerateFeed url='&skip=0&schema=united_states_of_america&table=wage_growth&order_by=created_at' 
                            name='Wage Growth' branch='Executive' topic='Economy' buttonName='Document' />
                          </Modal>
                      </div>
                    </Col>


                    <Col id='legislativeColRight'>
                      <div id='columnRightPadding'>

                      <h4 id='feedHeadingBillsEnrolled'><b>Bureau Of Labor Statistics</b></h4>
                        <div id='bureauOfLaborStatistics'></div>
                        


                        {CreateFeedItem('https://stats.bls.gov/feed/bls_latest.rss', 'bureauOfLaborStatistics',"Bureau Of Labor Statistics", "N/A", "Economy", BureauOfEconomicAnalysis)}




                       
                        <Modal id='fullBillModal' show={bureauOfLaborStatisticsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setBureauOfLaborStatisticsModalIsOpen(false)}>
                          <Modal.Header id='billModalHeader' closeButton><h3>Bureau Of Labor Statistics</h3></Modal.Header>
                          <BureauOfLaborStatistics/>
                        </Modal>

                     

                    




                      </div>
                    </Col>


                  </Row>



                </Container>
              </Container>
            </div>

            <div id='branchPage'>
              <Container class='bgcolor' >
                <Container id='carouselBufferThree'>

                  <Row>

                    

                    

                    <Col id='legislativeColLeft'>
                      <div id='columnLefttPadding'>

                      <h4 id='feedHeadingBillsEnrolled'><b>IRS Documents</b></h4>
                        <div id='irsDocuments'></div>
                        



                        {CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions[agencies][]=internal-revenue-service', 'irsDocuments',"IRS Documents", "N/A", "Economy", IRSDocuments)}




               
                        <Modal id='fullBillModal' show={irsDocumentsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setIRSDocumentsModalIsOpen(false)}>
                          <Modal.Header id='billModalHeader' closeButton><h3>IRS Documents</h3></Modal.Header>
                          <IRSDocuments/>
                        </Modal>


                     


                      </div>
                    </Col>

                    <Col id='legislativeColRight'>
                      <div id='columnRightPadding'>

                      <h4 id='feedHeadingBillsEnrolled'><b>Government Contracts</b></h4>
                        <div id='governmentContracts'></div>
                        


                        {CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions[term]="Federal+Acquisition+Regulations"','governmentContracts',"Government Contracts", "N/A", "Economy", GovernmentContracts)}




                        <Modal id='fullBillModal' show={governmentContractsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setGovernmentContractsModalIsOpen(false)}>
                          <Modal.Header id='billModalHeader' closeButton><h3>Government Contracts</h3></Modal.Header>
                          <GovernmentContracts/>
                        </Modal>

                     

                    




                      </div>
                    </Col>



                  </Row>



                </Container>
              </Container>
            </div>

            <div id='branchPage'>
              <Container class='bgcolor' >
                <Container id='carouselBufferThree'>

                  <Row>

                    

                 

                    <Col id='legislativeColLeft'>
                      <div id='columnLefttPadding'>

                      <h4 id='departmentOfStateHeading' onClick={() => window.open('/us/departmentofcommerce', "_self")}><b>Department of Commerce</b><ChevronRight id='clickChevron'></ChevronRight></h4>

<div class='whdocs' id='commerceDepartment'></div>


{CreateFeedItem('https://www.commerce.gov/feeds/news', 'commerceDepartment', 'Department of Commerce', 'Executive', 'Economy', DepartmentOfCommerce)}
            
<Modal id='whiteHouseDocsModal' show={departmentOfCommerceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfCommerceModalIsOpen(false)}>
  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Commerce</h3></Modal.Header>
  <DepartmentOfCommerce />
</Modal>
                       


                      </div>
                    </Col>


                    <Col id='legislativeColRight'>
                      <div id='columnRightPadding'>

                      <h4 id='presidentialFeedHeading' onClick={() => window.open('/us/departmentoftreasury', "_self")}><b>Department of Treasury</b><ChevronRight id='clickChevron'></ChevronRight></h4>
                        <div class='whdocs' id='treasuryDepartment'></div>
                        

                        {CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=state-department', 'treasuryDepartment', 'Department of Treasury', 'Executive', 'Economy', DepartmentOfTreasury)}


                       
                        <Modal id='whiteHouseDocsModal' show={departmentOfTreasuryModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfTreasuryModalIsOpen(false)}>
                          <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Treasury</h3></Modal.Header>
                          <DepartmentOfTreasury />
                        </Modal>

                       




                      </div>
                    </Col>


                  </Row>



                </Container>
              </Container>
            </div>



            <div id='branchPage'>
              <Container class='bgcolor' >
                <Container id='carouselBufferThree'>

                  <Row>

                    

                    

                    <Col id='legislativeColLeft'>
                      <div id='columnLefttPadding'>

                      <h4 id='departmentOfStateHeading' onClick={() => window.open('/us/departmentoflabor', "_self")}><b>Department of Labor</b><ChevronRight id='clickChevron'></ChevronRight></h4>

<div class='whdocs' id='laborDepartment'></div>


{CreateFeedItem('https://www.dol.gov/rss/releases.xml', 'laborDepartment', 'Department of Labor', 'Executive', 'Economy', DepartmentOfLabor)}

<Modal id='whiteHouseDocsModal' show={departmentOfLaborModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfLaborModalIsOpen(false)}>
  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Labor</h3></Modal.Header>
  <DepartmentOfLabor />
</Modal>


                      


                      </div>
                    </Col>


                    <Col id='legislativeColRight'>
                      <div id='columnRightPadding'>

                      <h4 id='feedHeadingBillsEnrolled'><b>House Committee on Energy and Commerce</b></h4>
                        <div id='houseCommitteeOnEnergyAndCommmerce'></div>
               
                        {CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=IF00', 'houseCommitteeOnEnergyAndCommmerce', 'House Committee on Energy and Commerce', 'Legislative', 'Economy', HouseCommitteeOnEnergyAndCommerce)}





                        <Modal id='fullBillModal' show={houseCommitteeOnEnergyAndCommerceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHouseCommitteeOnEnergyAndCommerceModalIsOpen(false)}>
                          <Modal.Header id='billModalHeader' closeButton><h3>House Committee on Energy and Commerce</h3></Modal.Header>
                          <HouseCommitteeOnEnergyAndCommerce />
                        </Modal>




                      </div>
                    </Col>


                  </Row>



                </Container>
              </Container>
            </div>

            <div id='branchPage'>
              <Container class='bgcolor' >
                <Container id='carouselBufferThree'>

                  <Row>

                  

                  

                    <Col id='legislativeColLeft'>
                      <div id='columnLefttPadding'>

                      <h4 id='feedHeadingBillsEnrolled'><b>House Committee on the Budget</b></h4>
                            <div id='houseCommitteeOnTheBudget'></div>
                            {CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=BU00', 'houseCommitteeOnTheBudget', 'House Committee on the Budget', 'Legislative', 'Economy', HouseCommitteeOnTheBudget)}

                            <Modal id='fullBillModal' show={houseCommitteeOnTheBudgetModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHouseCommitteeOnTheBudgetModalIsOpen(false)}>
                              <Modal.Header id='billModalHeader' closeButton><h3>House Committee on the Budget</h3></Modal.Header>
                              <HouseCommitteeOnTheBudget/>
                            </Modal>


                     


                      </div>
                    </Col>

                    <Col id='legislativeColRight'>
                      <div id='columnRightPadding'>

                      <h4 id='feedHeadingBillsEnrolled'><b>House Committee on Education and Labor</b></h4>
                            <div id='houseCommitteeOnEducationAndLabor'></div>
                      
                            {CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=ED00', 'houseCommitteeOnEducationAndLabor', 'House Committee on Education and Labor', 'Legislative', 'Economy', HouseCommitteeOnEducationAndLabor)}


                            <Modal id='fullBillModal' show={houseCommitteeOnEducationAndLaborModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHouseCommitteeOnEducationAndLaborModalIsOpen(false)}>
                              <Modal.Header id='billModalHeader' closeButton><h3>House Committee on Education and Labor</h3></Modal.Header>
                              <HouseCommitteeOnEducationAndLabor />
                            </Modal>

                    




                      </div>
                    </Col>



                  </Row>



                </Container>
              </Container>
            </div>

            <div id='branchPage'>
              <Container class='bgcolor' >
                <Container id='carouselBufferThree'>

                  <Row>

                    

                   

                    <Col id='legislativeColLeft'>
                      <div id='columnLefttPadding'>

                      <h4 id='feedHeadingBillsEnrolled'><b>House Committee on Financial Services</b></h4>
                        <div id='houseCommitteeOnFinancialServices'></div>
                        



                        {CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=BA00', 'houseCommitteeOnFinancialServices', 'House Committee on Financial Services', 'Legislative', 'Economy', HouseCommitteeOnFinacialServices)}



                        <Modal id='fullBillModal' show={houseCommitteeOnFinancialServicesModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHouseCommitteeOnFinancialServicesModalIsOpen(false)}>
                          <Modal.Header id='billModalHeader' closeButton><h3>House Committee on Financial Services</h3></Modal.Header>
                          <HouseCommitteeOnFinacialServices />
                        </Modal>


                     


                      </div>
                    </Col>


                    <Col id='legislativeColRight'>
                      <div id='columnRightPadding'>

                      <h4 id='feedHeadingBillsEnrolled'><b>House Committee on Small Business</b></h4>
                        <div id='houseCommitteeOnSmallBusiness'></div>
                        


                        {CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=SM00', 'houseCommitteeOnSmallBusiness', 'House Committee on Small Business', 'Legislative', 'Economy', HouseCommitteeOnSmallBusiness)}




                        <Modal id='fullBillModal' show={houseCommitteeOnSmallBusinessModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHouseCommitteeOnSmallBusinessModalIsOpen(false)}>
                          <Modal.Header id='billModalHeader' closeButton><h3>House Committee on Business</h3></Modal.Header>
                          <HouseCommitteeOnSmallBusiness />
                        </Modal>

                     

                    




                      </div>
                    </Col>


                  </Row>



                </Container>
              </Container>
            </div>

            <div id='branchPage'>
              <Container class='bgcolor' >
                <Container id='carouselBufferThree'>

                  <Row>

                   

                  

                    <Col id='legislativeColRight'>
                      <div id='columnRightPadding'>

                      <h4 id='feedHeadingBillsEnrolled'><b>Congressional Budget Office</b></h4>
                        <div id='congressionalBudgetOffice'></div>
                  
                        {CreateFeedItem('https://www.cbo.gov/publications/all/rss.xml', 'congressionalBudgetOffice', 'Congressional Budget Office', 'Legislative', 'Economy', CongressionalBudgetOffice)}

                        <Modal id='fullBillModal' show={congressionalBudgetOfficeModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCongressionalBudgetOfficeModalIsOpen(false)}>
                          <Modal.Header id='billModalHeader' closeButton><h3>Congressional Budget Office</h3></Modal.Header>
                          <CongressionalBudgetOffice />
                        </Modal>

                        




                      </div>
                    </Col>


                    <Col id='legislativeColRight'>
                      <div id='columnRightPadding'>

                      <h4 id='whBreifingFeedHeading'><b>Council of Economic Advisers	</b></h4>
                          <div id='councilOfEconomicAd'></div>
                    
                            {CreateFeedItemExecutiveOffice('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/executive_office_of_the_president/Council_of_Economic_Advisers.xml', 'councilOfEconomicAd', 'Council of Economic Advisers', 'Executive', 'N/A', CouncilOfEconomicAdvisers)}



                        <Modal id='fullBillModal' show={houseCommitteeOnSmallBusinessModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHouseCommitteeOnSmallBusinessModalIsOpen(false)}>
                          <Modal.Header id='billModalHeader' closeButton><h3>House Committee on Business</h3></Modal.Header>
                          <HouseCommitteeOnSmallBusiness />
                        </Modal>

                     

                    




                      </div>
                    </Col>


                  </Row>



                </Container>
              </Container>
            </div>


            <div id='branchPage'>
              <Container class='bgcolor' >
                <Container id='carouselBufferThree'>

                  <Row>

                   

                   

                    <Col id='legislativeColRight'>
                      <div id='columnRightPadding'>

                      <h4 id='whBreifingFeedHeading'><b>National Economic Council</b></h4>
                          <div id='nationalEconomicCouncil'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
                            {CreateFeedItemExecutiveOffice('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/executive_office_of_the_president/National_Economic_Council.xml', 'nationalEconomicCouncil', 'National Economic Council', 'Executive', 'N/A', NationalEconomicCouncil)}

                  
                        <Modal id='fullBillModal' show={congressionalBudgetOfficeModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCongressionalBudgetOfficeModalIsOpen(false)}>
                          <Modal.Header id='billModalHeader' closeButton><h3>Congressional Budget Office</h3></Modal.Header>
                          <CongressionalBudgetOffice />
                        </Modal>

                        




                      </div>
                    </Col>


                    <Col id='legislativeColRight'>
                      <div id='columnRightPadding'>

                      <h4 id='presidentialFeedHeading'><b>Office of Management and Budget	</b></h4>
                          <div id='officeOfManagmentAndBudget'></div>
                          
                          {CreateFeedItemExecutiveOffice('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/executive_office_of_the_president/Office_of_Management_and_Budget.xml', 'officeOfManagmentAndBudget', 'Office of Management and Budget', 'Executive', 'N/A', OfficeOfManagmentAndBudget)}



                        <Modal id='fullBillModal' show={houseCommitteeOnSmallBusinessModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHouseCommitteeOnSmallBusinessModalIsOpen(false)}>
                          <Modal.Header id='billModalHeader' closeButton><h3>House Committee on Business</h3></Modal.Header>
                          <HouseCommitteeOnSmallBusiness />
                        </Modal>

                     

                    




                      </div>
                    </Col>


                  </Row>



                </Container>
              </Container>
            </div>


     

            

         

         
            

            <Modal id='aboutModal' show={aboutModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAboutModalIsOpen(false)}>
            <div id='removedAlert'></div>
            <Modal.Header id='aboutModalHeader' closeButton><h3>About</h3></Modal.Header>
  <ModalBody id='aboutInfo'><p id='aboutParagraph'>Gov Glance was created to drive civic engagement and support a 
              healthier democracy by helping citizens get official Government news in an easy-to-navigate centralized location. 
              There will never be any opinion or political analysis(That's your job), only information from official Government 
              sources. Gov Glance is structured after the U.S. government that is broken into three branches Legislative, 
              Executive, and Judicial. To learn more about the structure and functions of The U.S. Government go to <a href='https://www.usa.gov/branches-of-government'>https://www.usa.gov/branches-of-government</a>.
              </p>

              <p id='aboutParagraph'>
              Gov Glance Foundation Inc. is a non-profit that was created to operate govglance.org and continue to create educational tools that help people learn about the workings of society
              </p>
            <br></br>

            <h3>Privacy</h3>

            <br></br>

              <p id='aboutParagraph'>Gov Glance does not collect any personal user information. 
              Features like collection save news items directly to your browser's local storage for your convenience
              and is not shared with Gov Glance servers. We do not collect, store, or send personal information to Google LLC, 
              but we do use some Google services to deliver our app to you. For any questions into Google’s privacy practices 
              here is a link to their privacy policy: <a href='https://policies.google.com/privacy?hl=en-US#intro'>https://policies.google.com/privacy?hl=en-US#intro</a>

              <br></br><br></br>

              For site analytics we use plausible.io an open source privacy friendly analytics provider. <a href="https://plausible.io/about" target="_blank">https://plausible.io/about</a>
            
            </p>
            <br></br>


            <h3>Contact</h3>

            <p id='aboutParagraph'>
                      Contact us via email: <a href='mailto:contact@govglance.org'>contact@govglance.org</a>

            </p>

            <br></br>


            
            </ModalBody>
          </Modal>
 
       <footer id='homeFooter'>
      <Navbar>
        <Row id='footerNav'>
      <Nav variant='pills'>
        
     <Nav.Link id='footerContent'>Gov Glance Foundation Inc.
</Nav.Link>
<Nav.Item>
<Nav.Link id='footerContent' href='mailto:contact@govglance.org'>Contact</Nav.Link>
</Nav.Item>
<Nav.Item>
<Nav.Link id='footerContent' onClick={(e) => {
                   e.preventDefault();
                   window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                 }}> <HeartFill id='donateIcon'/>    Donate</Nav.Link>
</Nav.Item>
<Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/about', "_self")}} id='footerContent'>Privacy</Nav.Link>
    
     
 </Nav>
 </Row>
 </Navbar>
      </footer>
{/*
            <div id='branchPage'>
              <Container class='bgcolor' >
                <Container id='carouselBufferThree'>

                  <Row>

                    <Col id='legislativeColLeft'>
                      <div id='columnLefttPadding'>

                      <h4 id='feedHeadingBillsEnrolled'><b>BEA</b></h4>
                        <div id='bea'></div>
                        <div id='bea2'></div>



                        {createBureauOfEconomicAnalysis()}




                        <h6 id='viewMoreTag'>

                          <div class="d-grid gap-2"> <Button size='lg' id='expandButtonEnrolledBills' variant='primary' onClick={() => setHouseCommitteeOnFinancialServicesModalIsOpen(true)} > <Files size={20}></Files> View More</Button></div>
                        </h6>
                        <Modal id='fullBillModal' show={houseCommitteeOnFinancialServicesModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHouseCommitteeOnFinancialServicesModalIsOpen(false)}>
                          <Modal.Header id='billModalHeader' closeButton><h3>House Committee on Financial Services</h3></Modal.Header>
                          <HouseCommitteeOnFinacialServices />
                        </Modal>


                     


                      </div>
                    </Col>

                





                  </Row>



                </Container>
              </Container>
            </div>
                    */}
           






</div>

         </div>
          

              </BrowserView>

              <MobileView>

 <EconomyMobile/>

              </MobileView>
              
    </>
  )
}
 }



  






export default Economy;
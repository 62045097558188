
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch, NavLink,  } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment,  } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image, ModalFooter } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText, FileMedicalFill, Coin, Tree, Globe2,  ShieldShaded, CircleFill, Pen, People, ChatSquareQuote, ChatLeftQuote, BlockquoteRight, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, CaretRight, ChevronRight, ChevronCompactRight, CaretRightFill, ArrowRightShort,  ArrowRightCircle, PlayCircle, PauseCircle, GeoAlt, HeartFill, QuestionCircleFill, MicFill, ReceiptCutoff } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import '../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import { render } from '@testing-library/react';
import
 {supremeCourtSearch, whiteHouseSearch, billFeedModal, 
  createHouseFloorToday, createSenateFloorToday, createTrendingBills,
  createNationalAeronauticsAndSpaceAdministration, 
  createEnvironmentalProtectionAgency, createDepartmentOfHomelandSecurity,
  createDepartmentOfVeteransAffairs, createDepartmentOfEducation,
  createDepartmentOfHousingAndUrbanDevelopment, 
  createDepartmentOfHealthAndHumanServices, createDepartmentOfLabor
, createDepartmentOfCommerce, createDepartmentOfAgriculture,
createDepartmentOfTheInterior, createDepartmentOfJustice,
createDepartmentOfTransportation, createDepartmentOfDefense,
createDepartmentOfTreasury, createDepartmentOfEnergy,
createDepartmentOfState, createBillsEnrolledMobile,
createBillsEnrolled, createNewLaws, createGAOReports,
createExecutiveOrders, createPresidentialProclamations, createCongressionalHearings,
createCongressionalBudgetOfficeMobile, createCongressionalBudgetOffice,
createHouseCommitteeOversightAndReform,
createHouseCommitteeOnNaturalResources,
createHouseCommitteeOnTheJudiciary,
createHouseCommitteeOnHouseAdministration,
createHouseCommitteeOnHomelandSecurity,
createHouseCommitteeOnForeignAffairs,
createHouseCommitteeOnFinancialServices,
createHouseCommitteeOnEthics,
createHouseCommitteeOnEnergyAndCommerce,
createHouseCommitteeOnEducationAndLabor,
createHouseCommitteeOnTheBudget,
createHouseCommitteeOnArmedServices,
createHouseCommitteeOnAppropriations,
createHouseCommitteeOnAgriculture,
createReports,
createBillFeedTwoMobile,
createBillFeedTwo,
createBillFeed, createWhiteHouseFeedMobile
, createWhiteHouseFeed, createWhiteHouseDocuments, 
createBillsPresentedFeed, createCourtAppealsMobile,
createCourtAppeals, createUSCourtNewsFeedMobile,
createUSCourtNewsFeed, saveToCollection,
createFullBillFeed, ListItem, Speak, feedText, variableTest, myMethod, myMethod2, createEconomicIndicators, 
createSecuritiesAndExchangeCommissionPressReleases, createNationWeatherServices, createCDCNewsroom,
createSecuritiesAndExchangeCommissionSpeechesAndStatements, createNationalScienceFoundation, createHouseCommitteeOnIntelligence,
 createUNTopStories, createCDCOutbreaks, createCDCTravelNotices, createFoodRecalls, createFoodSafety, createFederalElectionCommissionNews, createElectionAssistancCommissioneNews, 
 createBillStatus, CreateFeedItem, CreateFeedItemWithDocButton, CreateFeedItemWithLawButton, CreateFeedItemGAOReports,
CreateFeedItemCongressionalReports,
CreateFeedItemPresidentialDocuments,
CreateFeedItemExecutiveOrders,
CreateFeedItemCourtAppeals,
CreateFeedItemEconomicIndicators,
CreateFeedItemBillUpdates,
createBIllItem,
CreateFeedItemSupremeCourtArguments,
CreateFeedItemSupremeCourtOpinions,
CreateFeedItemTest,
CreateFeedItemBillUpdatesFlip,
CreateFeedItemWithDocumentFlip,
CreateFeedItemWithDocButtonDateFix,
CreateFeedItemWithLawButtonFlip,
CreateFeedItemHouseOfCommons,
CreateFeedItemUK,
CreateFeedAPI} from '../../Functions'

import UKBillUpdates from './UKBillUpdates';
import HouseOfLordsResearch from './HouseOfLordsResearch';
import UKDesktopNavbar from '../UKDesktopNavbar';
import LegislativeBranchMobile from '../../LegislativeBranchMobile';
import UKLegislativeMobile from './UKLegislativeMobile';
import GenerateFeed from '../../GenerateFeed';
const CustomModal = ({ isOpen, onClose, title, content }) => (
  <Modal id='fullBillModal' show={isOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={onClose}>
    <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
    {content}
  </Modal>
);
export const UKLegislative = () =>   {
  render()

 
  {

  const [reportModalIsOpen, setReportModalIsOpen] = useState(false)
  const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
  const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false)
  const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)
  const [showBioModal, setBioModal] = useState(false);
  const [trendingBillsOptionOneModalIsOpen, setTrendingBillsOptionOneModalIsOpen] = useState(false)
  const [modalInfo, setModalInfo] = useState({
    isOpen: false,
    title: '',
    content: null
  });

  const openModal = (title, content) => {
    setModalInfo({
      isOpen: true,
      title,
      content
    });
  };

  const closeModal = () => {
    setModalInfo({
      isOpen: false,
      title: '',
      content: null
    });
  };
  return (
    
    <>
    
      <div className="App">

      <BrowserView>
      <div id='homePage'>
        


        <div id='homebg'>

     

          

         <UKDesktopNavbar/>
       

         <div style={{ position: 'relative' }}>

<div id='profileImage' style={{ width: '150px', height: '150px', borderRadius: '100%', overflow: 'hidden',  marginLeft: '40px' }}>
<Image id='profileImage-image' src='/ukLegislative.png' />
</div>


<div style={{ position: 'absolute', top: '0', left: '250px', display: 'flex', flexDirection: 'column' }}>
<div style={{ color: 'white', fontSize: '24px', fontWeight: 'bold' }}>

</div>
<div  style={{ color: 'white', fontSize: '20px' }}> 
<span> <h3  >Legislative Branch </h3></span>
<br></br>
<div style={{ color: 'white', fontSize: '20px' }}>
<span> 
The business of Parliament takes place in two Houses: the House of Commons and the House of Lords. Their work is similar: making laws (legislation), checking the work of the government (scrutiny), and debating current issues.</span>

<br></br>
<div style={{ color: 'white', fontSize: '18px' }}>

  <span><Link style={{color: '#21cff5'}} onClick={(e) => {
     e.preventDefault();
     window.open("https://www.parliament.uk/about/how/role/system/", "_blank");
   }}>
     https://www.parliament.uk/about/how/role/system/
     </Link></span> 

     <Button id='whDocsViewMoreButton' onClick={() =>  setBioModal(true)}>  <Files size={20}></Files> Read More</Button>
</div>
</div>

</div>
</div>


</div>
<br></br>
<br></br><br></br>
<Modal id='fullBillModal'  size="xl" aria-labelledby="contained-modal-title-vcenter" show={showBioModal} onHide={() => setBioModal(false)}>
         <Modal.Header id='billModalHeader' closeButton><h3> The Legislative Branch</h3></Modal.Header>
       
         <div id='fullBillFeed'>
        <p><strong>The Two-House System</strong></p>
        <p>The Parliament conducts its business through two chambers: the <strong>House of Commons</strong> and the <strong>House of Lords</strong>. Both chambers engage in legislating, scrutinizing government actions, and debating current issues.</p>
        
        <p><strong>The House of Commons</strong></p>
        <p>The House of Commons consists of publicly elected Members of Parliament (MPs). The party with the most MPs typically forms the government.</p>

        <p>MPs debate significant political matters and proposals for new laws. It serves as a primary arena for government ministers, such as the Prime Minister and Chancellor, and major political party leaders.</p>

        <p>The Commons has the exclusive authority to decide on financial Bills, including proposed taxes. While the House of Lords can review these Bills, it lacks the power to block or amend them.</p>
        
        <p><strong>The House of Lords</strong></p>
        <p>The House of Lords serves as the Parliament's second chamber, operating independently and complementing the elected House of Commons. It shares responsibilities in legislation, shaping laws, and scrutinizing government activities.</p>
        
        <br></br>
        <Link onClick={(e) => {
            e.preventDefault();
            window.open("https://www.parliament.uk/about/how/role/system/", "_blank");
        }}>
            https://www.parliament.uk/about/how/role/system/
        </Link>
    </div>

       </Modal>
            <CustomModal isOpen={modalInfo.isOpen} onClose={closeModal} title={modalInfo.title} content={modalInfo.content} />
           
            <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>

                          <h4 id='feedHeadingBillsEnrolled' onClick={() =>
                            openModal('UK Bill Updates',
                              <GenerateFeed
                                url='&skip=0&schema=united_kingdom&table=all_bills&order_by=created_at'
                                name='UK Bill Updates' branch='Legislative' topic='N/A' buttonName='Bill' />)}><b>Bill Updates</b></h4>
                          <div id='billUpdates'></div>
                          
                                    {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
                                    {CreateFeedAPI('&skip=0&schema=united_kingdom&table=all_bills&order_by=created_at', 'billUpdates', 
                            'Bill Updates', 'Legislative', 
                            'N/A', 'Bill', GenerateFeed)}
                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                  
                        <h4 id='feedHeadingBillsEnrolled' onClick={() => setTrendingBillsOptionOneModalIsOpen(true)}><b>House of Lords Research </b></h4>
                          <div id='lordsResearch'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
                          {CreateFeedItemUK('https://lordslibrary.parliament.uk/type/research-briefing/feed/',
              'lordsResearch', 'House of Lords Research', 'Legislative', 'N/A', HouseOfLordsResearch)}


  <Modal id='mobileFullBillModal' show={trendingBillsOptionOneModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionOneModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>House of Lords Research</h3></Modal.Header>
<HouseOfLordsResearch/>
</Modal>
                        </div>
                      </Col>

            


                    </Row>


                  </Container>
                </Container>
             
              </div>

           
        
   
         


          
            
              
             
              
            
          
            
            
          <Modal id='aboutModal' show={aboutModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAboutModalIsOpen(false)}>
            <div id='removedAlert'></div>
            <Modal.Header id='aboutModalHeader' closeButton><h3>About</h3></Modal.Header>
  <ModalBody id='aboutInfo'><p id='aboutParagraph'>Gov Glance was created to drive civic engagement and support a 
              healthier democracy by helping citizens get official Government news in an easy-to-navigate centralized location. 
              There will never be any opinion or political analysis(That's your job), only information from official Government 
              sources. Gov Glance is structured after the U.S. government that is broken into three branches Legislative, 
              Executive, and Judicial. To learn more about the structure and functions of The U.S. Government go to <a href='https://www.usa.gov/branches-of-government'>https://www.usa.gov/branches-of-government</a>.
              </p>

              <p id='aboutParagraph'>
              Gov Glance Foundation Inc. is a non-profit that was created to operate govglance.org and continue to create educational tools that help people learn about the workings of society
              </p>
            <br></br>

            <h3>Privacy</h3>

            <br></br>

              <p id='aboutParagraph'>Gov Glance does not collect any personal user information. 
              Features like collection save news items directly to your browser's local storage for your convenience
              and is not shared with Gov Glance servers. We do not collect, store, or send personal information to Google LLC, 
              but we do use some Google services to deliver our app to you. For any questions into Google’s privacy practices 
              here is a link to their privacy policy: <a href='https://policies.google.com/privacy?hl=en-US#intro'>https://policies.google.com/privacy?hl=en-US#intro</a>

              <br></br><br></br>

              For site analytics we use plausible.io an open source privacy friendly analytics provider. <a href="https://plausible.io/about" target="_blank">https://plausible.io/about</a>
            
            </p>
            <br></br>


            <h3>Contact</h3>

            <p id='aboutParagraph'>
                      Contact us via email: <a href='mailto:contact@govglance.org'>contact@govglance.org</a>

            </p>

            <br></br>


            
            </ModalBody>
          </Modal>
 
     <footer id='homeFooter'>
      <Navbar>
        <Row id='footerNav'>
      <Nav variant='pills'>
        
     <Nav.Link id='footerContent'>Gov Glance Foundation Inc.
</Nav.Link>
<Nav.Item>
<Nav.Link id='footerContent' href='mailto:contact@govglance.org'>Contact</Nav.Link>
</Nav.Item>
<Nav.Item>
<Nav.Link id='footerContent' onClick={(e) => {
                   e.preventDefault();
                   window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                 }}> <HeartFill id='donateIcon'/>    Donate</Nav.Link>
</Nav.Item>
<Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/about', "_self")}} id='footerContent'>Privacy</Nav.Link>
    
     
 </Nav>
 </Row>
 </Navbar>
      </footer>

      


    
          </div>
  

    </div>
      </BrowserView>
     

      <MobileView> 
        
        
         <UKLegislativeMobile/>             

     
      </MobileView>
        </div>
      


       
    </>

     
    
  );







}
}


export default UKLegislative;

import App from "./App";
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Image, ModalFooter } from 'react-bootstrap';
import { ArrowsAngleExpand, InfoCircle, FileText, PauseCircle, PlayCircle, FolderPlus, ChevronRight, Files, Folder, Pen, ChatLeftQuote } from 'react-bootstrap-icons'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'

import Axios from 'axios'
import { render } from '@testing-library/react';
import FullBillFeed from "./FullBillFeed";
import TrendingBills from "./TrendingBills";
import BillsEnrolled from "./BillsEnrolled";
import CollectionPage from "./CollectionPage";
import NewLaws from "./NewLaws";
import GAOReports from "./GAOReports";
import Reports from "./Reports";
import HouseComitteeOnAgriculture from "./HouseComitteeOnAgriculture"
import HouseCommitteeOnAppropriations from './HouseCommitteeOnArmedServices';
import HouseCommitteeOnTheBudget from "./HouseCommitteeOnTheBudget";
import HouseCommitteeOnArmedServices from "./HouseCommitteeOnArmedServices";
import HouseFloor from "./HouseFloor";
import HouseCommitteeOnEducationAndLabor from "./HouseCommitteeOnEducationAndLabor";
import HouseCommitteeOnEnergyAndCommerce from "./HouseCommitteeOnEnergyAndCommerce";
import HouseCommitteeOnEthics from "./HouseCommitteeOnEthics";
import HouseCommitteeOnFinacialServices from "./HouseCommitteeOnFinacialServices";
import HouseCommitteeOnForeignAffairs from "./HouseCommitteeOnForeignAffairs";
import HouseCommitteeOnHomelandSecurity from "./HouseCommitteeOnHomelandSecurity";
import HouseCommitteeOnHouseAdministration from "./HouseCommitteeOnHouseAdministration";
import HouseCommitteeOnTheJudiciary from "./HouseCommitteeOnTheJudiciary";
import HouseCommitteeOnNaturalResources from "./HouseCommitteeOnNaturalResources";
import HouseCommitteeOnOversightAndReform from "./HouseCommitteeOnOversightAndReform";
import CongressionalBudgetOffice from "./CongressionalBudgetOffice";
import HouseCommitteeOnRules from "./HouseCommitteeOnRules";
import HouseCommitteeOnScienceSpaceAndTechnology from "./HouseCommitteeOnScienceSpaceAndTechnology";
import ExecutiveOrders from "./ExecutiveOrders";
import PresidentialProclamations from "./PresidentialProclamations";
import HouseCommitteeOnTransportationAndInfrastructure from "./HouseCommitteeOnTransportationAndInfrastructure";
import HouseCommitteeOnSmallBusiness from "./HouseCommitteeOnSmallBusiness";
import CongressionalHearings from "./CongressionalHearings";
import{ ListItem, createFullBillFeed, saveToCollection, createHouseCommitteeOversightAndReform, createHouseCommitteeOnRules,
    createHouseCommitteeOnSmallBusiness, createHouseCommitteeOnScienceSpaceAndTechnology,
    createHouseCommitteeOnTransportationAndInfrastructure,
    createHouseCommitteeOnVeteransAffairs,
    createCongressionalBudgetOffice, createBillFeedTwo,
    createBillFeedTwoMobile, createReports, createHouseCommitteeOnAgriculture,
     createHouseCommitteeOnAppropriations, createHouseCommitteeOnArmedServices,
     createHouseCommitteeOnTheBudget, createHouseCommitteeOnEducationAndLabor,
    createHouseCommitteeOnEnergyAndCommerce, createHouseCommitteeOnEthics,
     createHouseCommitteeOnFinancialServices, createHouseCommitteeOnForeignAffairs, createHouseCommitteeOnHomelandSecurity,
    createHouseCommitteeOnHouseAdministration, 
    createHouseCommitteeOnTheJudiciary, createHouseCommitteeOnNaturalResources, 
     createHouseCommitteeOnNaturalResourcesMobile, createGAOReports,
     createNewLaws, createBillsEnrolled
    , createBillsEnrolledMobile, createTrendingBills
    , createHouseFloorToday, congressSearch, Speak, createGAOReportsMobile, createCongressionalHearings, createBillStatus, CreateFeedItem,
    CreateFeedItemBillUpdates, CreateFeedItemWithDocButton, CreateFeedItemWithLawButton,
    CreateFeedItemGAOReports, CreateFeedItemCongressionalReports, CreateFeedItemWithDocumentFlip, CreateFeedItemWithLawButtonFlip, CreateFeedItemWithDocButtonDateFix, CreateFeedAPI} from './Functions'
import BillStatus from "./BillStatus";
import BillUpdates from "./BillUpdates";
import HouseCommitteeOnVeteransAffairs from "./HouseCommitteeOnVeteransAffairs";
import MobileOffCanvas from "./MobileOffCanvas";
import MobileTopNavbar from "./MobileTopNavbar";
import HouseFeed from "./HouseFeed";
import HouseComitteeOnAgricultureMaterials from "./HouseComitteeOnAgricultureMaterials";
import SenateFeed from "./SenateFeed";
import SenateAgricultureNutritionAndForestryCommittee from "./SenateAgricultureNutritionAndForestryCommittee";
import SenateAppropriationCommittee from "./SenateAppropriationCommittee";
import GenerateFeed from "./GenerateFeed";
import RepresenativesDisplay from "./RepresenativesDisplay";
import SenateDisplay from "./SenateDisplay";
import HouseOfRepsDisplay from "./HouseOfRepsDisplay";


    var response=''

var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 60000;


  class PlayUpdateButtonMobile extends React.Component { 
      constructor(props) {
        super(props);
        this.state = {
          isCardView: false,
        }
      } 
      
      render() {
        return (
          <Button  id='navbarUpdateButtonMobile' size='lg'  onClick={()=>{this.setState({ isCardView: !this.state.isCardView }); Speak(response);  }}  >
            { this.state.isCardView                                                   
              ? <PauseCircle size={30}   />
              : <PlayCircle size={30}  />
            }
            &nbsp;&nbsp;Update (Beta)
          </Button>
        );
      }
      
    }
  
   
  
  
    
  class PlayUpdateButton extends React.Component { 
    constructor(props) {
      super(props);
      this.state = {
        isCardView: false,
      }
    } 
    
    render() {
      return (
        <Button id='navbarCollectionButtonDesktop' size='lg'  onClick={()=>{this.setState({ isCardView: !this.state.isCardView }); Speak(response);  }}  >
          { this.state.isCardView                                                   
            ? <PauseCircle  />
            : <PlayCircle />
          }
          &nbsp;&nbsp;Update (Beta)
        </Button>
      );
    }
    
  }

export const LegislativeBranchMobile = () =>   {
    render()
    
    {
  
  
        const [show, setShow] = useState(true);

        const [modalIsOpen, setModalIsOpen] = useState(false)
        const [fullscreen, setFullScreen] = useState(true);
      
      
        const [fullBillModalIsOpen, setFullBillModalIsOpen] = useState(false)
      
        const [reportModalIsOpen, setReportModalIsOpen] = useState(false)
      
      
        const [whiteHouseFeedModalIsOpen, setWhiteHouseFeedModalIsOpen] = useState(false)
        const [whiteHouseDocsModalIsOpen, setWhiteHouseDocsModalIsOpen] = useState(false)
      
      
        const [courtNewsModalIsOpen, setCourtNewsModalIsOpen] = useState(false)
        const [courtModalIsOpen, setCourtModalIsOpen] = useState(false)
      
        const [courtAppealsModalIsOpen, setCourtAppealsModalIsOpen] = useState(false)
      
        const [newLawsModalIsOpen, setNewLawsModalIsOpen] = useState(false)
      
        const [billsEnrolledModalIsOpen, setBillsEnrolledModalIsOpen] = useState(false)
      
        const [legislativePageBillsEnrolledModalIsOpen, setLegislativePageBillsEnrolledModalIsOpen] = useState(false)
      
      
        const [departmentOfStateModalIsOpen, setDepartmentOfStateModalIsOpen] = useState(false)
        const [departmentOfTreasuryModalIsOpen, setDepartmentOfTreasuryModalIsOpen] = useState(false)
        const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
        const [departmentOfJusticeModalIsOpen, setDepartmentOfJusticeModalIsOpen] = useState(false)
        const [departmentOfTheInteriorModalIsOpen, setDepartmentOfTheInteriorModalIsOpen] = useState(false)
        const [departmentOfAgricultureModalIsOpen, setDepartmentOfAgricultureModalIsOpen] = useState(false)
        const [departmentOfCommerceModalIsOpen, setDepartmentOfCommerceModalIsOpen] = useState(false)
        const [departmentOfLaborModalIsOpen, setDepartmentOfLaborModalIsOpen] = useState(false)
        const [departmentOfHealthAndHumanServicesModalIsOpen, setDepartmentOfHealthAndHumanServicesModalIsOpen] = useState(false)
        const [departmentOfHousingAndUrbanDevelopmentModalIsOpen, setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen] = useState(false)
        const [departmentOfTransportationModalIsOpen, setDepartmentOfTransportationModalIsOpen] = useState(false)
        const [departmentOfEnergyModalIsOpen, setDepartmentOfEnergyModalIsOpen] = useState(false)
        const [departmentOfEducationModalIsOpen, setDepartmentOfEducationModalIsOpen] = useState(false)
        const [departmentOfVeteransAffairsModalIsOpen, setDepartmentOfVeteransAffairsModalIsOpen] = useState(false)
        const [departmentOfHomelandSecurityModalIsOpen, setDepartmentOfHomelandSecurityModalIsOpen] = useState(false)
        const [epaModalIsOpen, setEPAModalIsOpen] = useState(false)
        const [nasaModalIsOpen, setNASAModalIsOpen] = useState(false)
        const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
        const [goaReportsModalIsOpen, setGAOReportsModalIsOpen] = useState(false)
          const [houseCommitteeOnAppropriationsModalIsOpen, setHouseCommitteeOnAppropriationsModalIsOpen] = useState(false)
        const [houseComitteeOnAgricultureModalIsOpen, setHouseComitteeOnAgricultureModalIsOpen] = useState(false)
          const [houseCommitteeOnArmedServicesModalIsOpen, setHouseCommitteeOnOnArmedServicesModalIsOpen] = useState(false)
          const [houseCommitteeOnTheBudgetModalIsOpen, setHouseCommitteeOnTheBudgetModalIsOpen] = useState(false)
          const [houseFloorModalIsOpen, setHouseFloorModalIsOpen] = useState(false)
          const [houseCommitteeOnEnergyAndCommerceModalIsOpen, setHouseCommitteeOnEnergyAndCommerceModalIsOpen] = useState(false)
          const [houseCommitteeOnEducationAndLaborModalIsOpen, setHouseCommitteeOnEducationAndLaborModalIsOpen] = useState(false)
          const [houseCommitteeOnEthicsModalIsOpen, setHouseCommitteeOnEthicsModalIsOpen] = useState(false)
          const [houseCommitteeOnFinancialServicesModalIsOpen, setHouseCommitteeOnFinancialServicesModalIsOpen] = useState(false)
          const [houseCommitteeOnForeignAffairsModalIsOpen, setHouseCommitteeOnForeignAffairsModalIsOpen] = useState(false)
          const [houseCommitteeOnHomelandSecurityModalIsOpen, setHouseCommitteeOnHomelandSecurityModalIsOpen] = useState(false)
          const [houseCommitteeOnHouseAdministrationModalIsOpen, setHouseCommitteeOnHouseAdministrationModalIsOpen] = useState(false)
          const [houseCommitteeOnTheJudiciaryModalIsOpen, setHouseCommitteeOnTheJudiciaryModalIsOpen] = useState(false)
          const [houseCommitteeOnNaturalResourcesModalIsOpen, setHouseCommitteeOnNaturalResourcesModalIsOpen] = useState(false)
          const [houseCommitteeOnOversightAndReformModalIsOpen, setHouseCommitteeOnOversightAndReformModalIsOpen] = useState(false)
          const [congressionalBudgetOfficeModalIsOpen, setCongressionalBudgetOfficeModalIsOpen] = useState(false)
          const [houseCommitteeOnRulesModalIsOpen, setHouseCommitteeOnRulesModalIsOpen] = useState(false)
          const [houseCommitteeOnScienceSpaceAndTechnologyModalIsOpen, setHouseCommitteeOnScienceSpaceAndTechnologyModalIsOpen] = useState(false)
          const [houseCommitteeOnSmallBusinessModalIsOpen, setHouseCommitteeOnSmallBusinessModalIsOpen] = useState(false)
          const [houseCommitteeOnTransportationAndInfrastructureModalIsOpen, setHouseCommitteeOnTransportationAndInfrastructureModalIsOpen] = useState(false)
          const [houseCommitteeOnVeteransAffairsModalIsOpen, setHouseCommitteeOnVeteransAffairsModalIsOpen] = useState(false)
          const [congressionalHearingsModalIsOpen, setCongressionalHearingsModalIsOpen] = useState(false)
          
          const [trendingBillsOptionOne, setTrendingBillsOptionOneModalIsOpen ] = useState(false)
          const [trendingBillsOptionTwo, setTrendingBillsOptionTwoModalIsOpen ] = useState(false)
          const [trendingBillsOptionThree, setTrendingBillsOptionThreeModalIsOpen ] = useState(false)
          const [trendingBillsOptionFour, setTrendingBillsOptionFourModalIsOpen ] = useState(false)
          const [trendingBillsOptionFive, setTrendingBillsOptionFiveModalIsOpen ] = useState(false)
          const [trendingBillsOptionSix, setTrendingBillsOptionSixModalIsOpen ] = useState(false)
          const [trendingBillsOptionSeven, setTrendingBillsOptionSevenModalIsOpen ] = useState(false)
          const [trendingBillsOptionEight, setTrendingBillsOptionEightModalIsOpen ] = useState(false)
          const [trendingBillsOptionNine, setTrendingBillsOptionNineModalIsOpen ] = useState(false)
          const [trendingBillsOptionTen, setTrendingBillsOptionTenModalIsOpen ] = useState(false)
          const [trendingBillsOptionEleven, setTrendingBillsOptionElevenModalIsOpen ] = useState(false)
          const [trendingBillsOptionTweleve, setTrendingBillsOptionTweleveModalIsOpen ] = useState(false)
          const [trendingBillsOptionThirteen, setTrendingBillsOptionThirteenModalIsOpen ] = useState(false)
          const [lawsModalIsOpen, setLawsModalIsOpen] = useState(false)
        
          const [congressionalReportsModalIsOpen, setCongressionalReportsModalIsOpen] = useState(false)
            
        const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false)
        const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)
        const [statesModalIsOpen, setStatesModalIsOpen] = useState(false)
      
      
        const [declarationModalIsOpen, setDeclarationModalIsOpen] = useState(false)
        const [constitutionModalIsOpen, setConstitutionModalIsOpen] = useState(false)
        const [billOfRightsModalIsOpen, setBillOfRightsModalIsOpen] = useState(false)
        const [showBioModal, setBioModal] = useState(false);
    
        const [billStatusModalIsOpen, setBillStatusModalIsOpen] = useState(false)
  
    return(
       <>
    <MobileTopNavbar/>
  
  
  
  
  <div id='homebg'>
 

{/*Legislative Mobile Carousel*/}
<div id="feedDisplayDepartment" style={{ padding: '10px', borderRadius: '18px' }}>
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div id="profileImageOnPageDepartment">
        <Image id="profileImage-imageOnPageDepartment" src="/legislativeBranch.png" />
      </div>
      <div style={{ marginLeft: '10px', color: 'white', fontSize: '18px', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
      {/* <Pen></Pen> */}
        <h5 id='branchHeadingMobile'>Legislative Branch</h5>
      </div>
    </div>
    <div style={{ marginLeft: 'auto' }}>
     
    </div>
  </div> 
  <div id="billText" style={{ color: 'white', fontSize: '16px', marginTop: '10px' }}>
  Established by Article I of the Constitution, the Legislative Branch consists of the House of Representatives and the Senate, which together form the United States Congress.
  <Button id='presidentViewMoreButton' onClick={() =>  setBioModal(true)}>  <Files size={20}></Files> Read More</Button>
  <br></br>
  <br></br>
  <div style={{ display: 'flex', justifyContent: 'center' }}>
  <Link style={{color: '#21cff5'}} onClick={(e) => { e.preventDefault(); window.open("https://www.whitehouse.gov/about-the-white-house/our-government/the-legislative-branch/", "_blank") }} id='pageLink'><h5 id='presidentialFeedHeadingMobile'>Official Site</h5></Link>
  </div>
  </div>
</div>
{/*onClick={() => setModalIsOpen(true)}*/}






<Modal id='fullBillModal'  size="xl" aria-labelledby="contained-modal-title-vcenter" show={showBioModal} onHide={() => setBioModal(false)}>
         <Modal.Header id='billModalHeader' closeButton><h3> The Legislative Branch</h3></Modal.Header>
       
         <div id='fullBillFeed' >
      
         <p>Established by Article I of the Constitution, the Legislative Branch consists of the House of Representatives and the Senate, which together form the United States Congress. The Constitution grants Congress the sole authority to enact legislation and declare war, the right to confirm or reject many Presidential appointments, and substantial investigative powers.</p>

<p>The House of Representatives is made up of 435 elected members, divided among the 50 states in proportion to their total population. In addition, there are 6 non-voting members, representing the District of Columbia, the Commonwealth of Puerto Rico, and four other territories of the United States: American Samoa, Guam, the U.S. Virgin Islands, and the Commonwealth of Northern Mariana Islands. The presiding officer of the chamber is the Speaker of the House, elected by the Representatives. He or she is third in the line of succession to the Presidency.</p>

<p>Members of the House are elected every two years and must be 25 years of age, a U.S. citizen for at least seven years, and a resident of the state (but not necessarily the district) they represent.</p>

<p>The House has several powers assigned exclusively to it, including the power to initiate revenue bills, impeach federal officials, and elect the President in the case of an Electoral College tie.</p>

<p>The Senate is composed of 100 Senators, 2 for each state. Until the ratification of the 17th Amendment in 1913, Senators were chosen by state legislatures, not by popular vote. Since then, they have been elected to six-year terms by the people of each state. Senators’ terms are staggered so that about one-third of the Senate is up for reelection every two years. Senators must be 30 years of age, U.S. citizens for at least nine years, and residents of the state they represent.</p>

<p>The Vice President of the United States serves as President of the Senate and may cast the decisive vote in the event of a tie in the Senate.</p>

<p>The Senate has the sole power to confirm those of the President’s appointments that require consent, and to provide advice and consent to ratify treaties. There are, however, two exceptions to this rule: the House must also approve appointments to the Vice Presidency and any treaty that involves foreign trade. The Senate also tries impeachment cases for federal officials referred to it by the House.</p>

<p>In order to pass legislation and send it to the President for his or her signature, both the House and the Senate must pass the same bill by majority vote. If the President vetoes a bill, they may override his veto by passing the bill again in each chamber with at least two-thirds of each body voting in favor.</p>

<p><strong>The Legislative Process</strong></p>

<p>The first step in the legislative process is the introduction of a bill to Congress. Anyone can write it, but only members of Congress can introduce legislation. Some important bills are traditionally introduced at the request of the President, such as the annual federal budget. During the legislative process, however, the initial bill can undergo drastic changes.</p>

<p>After being introduced, a bill is referred to the appropriate committee for review. There are 17 Senate committees, with 70 subcommittees, and 23 House committees, with 104 subcommittees. The committees are not set in stone, but change in number and form with each new Congress as required for the efficient consideration of legislation. Each committee oversees a specific policy area, and the subcommittees take on more specialized policy areas. For example, the House Committee on Ways and Means includes subcommittees on Social Security and Trade.</p>

<p>A bill is first considered in a subcommittee, where it may be accepted, amended, or rejected entirely. If the members of the subcommittee agree to move a bill forward, it is reported to the full committee, where the process is repeated again. Throughout this stage of the process, the committees and subcommittees call hearings to investigate the merits and flaws of the bill. They invite experts, advocates, and opponents to appear before the committee and provide testimony, and can compel people to appear using subpoena power if necessary.</p>

<p>If the full committee votes to approve the bill, it is reported to the floor of the House or Senate, and the majority party leadership decides when to place the bill on the calendar for consideration. If a bill is particularly pressing, it may be considered right away. Others may wait for months or never be scheduled at all.</p>

<p>When the bill comes up for consideration, the House has a very structured debate process. Each member who wishes to speak only has a few minutes, and the number and kind of amendments are usually limited. In the Senate, debate on most bills is unlimited — Senators may speak to issues other than the bill under consideration during their speeches, and any amendment can be introduced. Senators can use this to filibuster bills under consideration, a procedure by which a Senator delays a vote on a bill — and by extension its passage — by refusing to stand down. A supermajority of 60 Senators can break a filibuster by invoking cloture, or the cession of debate on the bill, and forcing a vote. Once debate is over, the votes of a simple majority pass the bill.</p>

<p>A bill must pass both houses of Congress before it goes to the President for consideration. Though the Constitution requires that the two bills have the exact same wording, this rarely happens in practice. To bring the bills into alignment, a Conference Committee is convened, consisting of members from both chambers. The members of the committee produce a conference report, intended as the final version of the bill. Each chamber then votes again to approve the conference report. Depending on where the bill originated, the final text is then enrolled by either the Clerk of the House or the Secretary of the Senate, and presented to the Speaker of the House and the President of the Senate for their signatures. The bill is then sent to the President.</p>

<p>When receiving a bill from Congress, the President has several options. If the President agrees substantially with the bill, he or she may sign it into law, and the bill is then printed in the Statutes at Large. If the President believes the law to be bad policy, he or she may veto it and send it back to Congress. Congress may override the veto with a two-thirds vote of each chamber, at which point the bill becomes law and is printed.</p>

<p>There are two other options that the President may exercise. If Congress is in session and the President takes no action within 10 days, the bill becomes law. If Congress adjourns before 10 days are up and the President takes no action, then the bill dies and Congress may not vote to override. This is called a pocket veto, and if Congress still wants to pass the legislation, they must begin the entire process anew.</p>

<p><strong>Powers of Congress</strong></p>

<p>Congress, as one of the three coequal branches of government, is ascribed significant powers by the Constitution. All legislative power in the government is vested in Congress, meaning that it is the only part of the government that can make new laws or change existing laws. Executive Branch agencies issue regulations with the full force of law, but these are only under the authority of laws enacted by Congress. The President may veto bills Congress passes, but Congress may also override a veto by a two-thirds vote in both the Senate and the House of Representatives.</p>

<p>Article I of the Constitution enumerates the powers of Congress and the specific areas in which it may legislate. Congress is also empowered to enact laws deemed “necessary and proper” for the execution of the powers given to any part of the government under the Constitution.</p>

<p>Part of Congress’s exercise of legislative authority is the establishment of an annual budget for the government. To this end, Congress levies taxes and tariffs to provide funding for essential government services. If enough money cannot be raised to fund the government, then Congress may also authorize borrowing to make up the difference. Congress can also mandate spending on specific items: legislatively directed spending, commonly known as “earmarks,” specifies funds for a particular project, rather than for a government agency.</p>

<p>Both chambers of Congress have extensive investigative powers, and may compel the production of evidence or testimony toward whatever end they deem necessary. Members of Congress spend much of their time holding hearings and investigations in committee. Refusal to cooperate with a congressional subpoena can result in charges of contempt of Congress, which could result in a prison term.</p>

<p>The Senate maintains several powers to itself: It consents to the ratification of treaties by a two-thirds supermajority vote and confirms the appointments of the President by a majority vote. The consent of the House of Representatives is also necessary for the ratification of trade agreements and the confirmation of the Vice President.</p>

<p>Congress also holds the sole power to declare war.</p>

<p><strong>Government Oversight</strong></p>

<p>Oversight of the executive branch is an important Congressional check on the President’s power and a balance against his or her discretion in implementing laws and making regulations.</p>

<p>One primary way that Congress conducts oversight is through hearings. The House Committee on Oversight and Government Reform and the Senate Committee on Homeland Security and Government Affairs are both devoted to overseeing and reforming government operations, and each committee conducts oversight in its policy area.</p>

<p>Congress also maintains an investigative organization, the Government Accountability Office (GAO). Founded in 1921 as the General Accounting Office, its original mission was to audit the budgets and financial statements sent to Congress by the Secretary of the Treasury and the Director of the Office of Management and Budget. Today, the GAO audits and generates reports on every aspect of the government, ensuring that taxpayer dollars are spent with the effectiveness and efficiency that the American people deserve.</p>

<p>The Executive Branch also polices itself: Sixty-four Inspectors General, each responsible for a different agency, regularly audit and report on the agencies to which they are attached.</p>

<br></br>
<Link style={{color: '#21cff5'}} onClick={(e) => {
     e.preventDefault();
     window.open("https://www.whitehouse.gov/about-the-white-house/our-government/the-legislative-branch/", "_blank");
   }}>
     https://www.whitehouse.gov/about-the-white-house/our-government/the-legislative-branch/
     </Link>
 </div>

       </Modal>






<Modal show={modalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setModalIsOpen(false)}>
<Modal.Header id='iframeModalHeader' closeButton>Congressional Record</Modal.Header>
<Card id='iframeColMobile'>
  <iframe src='https://www.congress.gov/congressional-record' height='1000px' width='410px' frameBorder='0'><div id='modalFeedMobile'></div></iframe></Card></Modal>






<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() => setFullBillModalIsOpen(true)}>Bill Updates</h5>

 <div class='courtNewsMobile' id='bills'></div>
                    {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=congressional_bills&order_by=created_at', 'bills', 
                            'Bill Updates', 'Legislative', 
                            'N/A', 'Bill', GenerateFeed)}
                    <Modal id='fullBillModal' show={fullBillModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFullBillModalIsOpen(false)}>
                      <Modal.Header id='billModalHeader' closeButton><h3>Bill Updates</h3></Modal.Header>
                      <GenerateFeed url='&skip=0&schema=united_states_of_america&table=congressional_bills&order_by=created_at' 
                            name='Bill Updates' branch='Legislative' topic='N/A' buttonName='Bill' />
                    </Modal>
</Col>



<Col>
    <h5 id='presidentialFeedHeadingMobile'onClick={() => setBillsEnrolledModalIsOpen(true)} >Bills Enrolled (Sent to President)</h5>
   
   <div class='courtNewsMobile' id='billsEnrolled'></div>
                   {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=congressional_bills_enrolled&order_by=created_at', 
                   'billsEnrolled', 
                   'Bills Enrolled', 'Legislative', 
                   'N/A', 'Bill', GenerateFeed)}
             <Modal id='fullBillModal' show={billsEnrolledModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setBillsEnrolledModalIsOpen(false)}>
                     <Modal.Header id='billModalHeader' closeButton><h3>Bills Enrolled (Sent to President)</h3></Modal.Header>
                     <GenerateFeed
 url='&skip=0&schema=united_states_of_america&table=congressional_bills_enrolled&order_by=created_at'
 name='Bills Enrolled'
 branch='Legislative'
 topic='N/A'
 buttonName='Bill'
/>
                   </Modal>



    </Col>



    <Col>
    <h5 id='presidentialFeedHeadingMobile'onClick={() => setLawsModalIsOpen(true)}>Laws</h5>

<div class='courtNewsMobile' id='newLaws'></div>
{CreateFeedAPI('&skip=0&schema=united_states_of_america&table=public_and_private_laws&order_by=created_at', 'newLaws', 
                            'Laws', 'Legislative', 
                            'N/A', 'Law', GenerateFeed)}
<Modal id='fullBillModal' show={lawsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setLawsModalIsOpen(false)}>
                          <Modal.Header id='billModalHeader' closeButton><h3>Laws</h3></Modal.Header>
                          <GenerateFeed url='&skip=0&schema=united_states_of_america&table=public_and_private_laws&order_by=created_at' 
                            name='Laws' branch='Legislative' topic='N/A' buttonName='Law' />
                        </Modal>


 
    </Col>








<Col>
  <h5 id='presidentialFeedHeadingMobile'onClick={() => setCongressionalHearingsModalIsOpen(true)}>Congressional Hearings</h5>

<div class='courtNewsMobile' id='congressionalHearings'></div>
{CreateFeedAPI('&skip=0&schema=united_states_of_america&table=congressional_hearings&order_by=created_at', 'congressionalHearings', 
                           'Congressional Hearings', 'Legislative', 
                           'N/A', 'Hearing', GenerateFeed)}

<Modal id='fullBillModal' show={congressionalHearingsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
centered onHide={() => setCongressionalHearingsModalIsOpen(false)}>
                     <Modal.Header id='billModalHeader' closeButton><h3>Congressional Hearings</h3></Modal.Header>
                     <GenerateFeed url='&skip=0&schema=united_states_of_america&table=congressional_bills&order_by=created_at' 
                           name='Congressional Hearings' branch='Legislative' topic='N/A' buttonName='Hearing' />
                   </Modal>
</Col>












<Modal show={modalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setModalIsOpen(false)}>
<Modal.Header id='iframeModalHeader' closeButton>Congressional Record</Modal.Header>
<Card id='iframeColMobile'><iframe src='https://www.congress.gov/congressional-record' height='1000px' width='410px' frameBorder='0'><div id='modalFeedMobile'></div></iframe></Card></Modal>









  <Col>
    <h5 id='presidentialFeedHeadingMobile'>Government Accountability Office Reports</h5>
    {CreateFeedItemGAOReports('https://www.gao.gov/rss/reports.xml', 'gaoReports', 'Government Accountability Office Reports', 'Legislative', 'N/A', GAOReports)}

    <div class='courtNewsMobile' id='gaoReports'></div>

    
  </Col>




<Modal id='mobileFullBillModal' show={goaReportsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setGAOReportsModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Government Accountability Office Reports</h3></Modal.Header>
<GAOReports />
</Modal>






  <Col>
    <h5 id='presidentialFeedHeadingMobile' onClick={() => setCongressionalReportsModalIsOpen(true)}>Congressional Reports</h5>
                   
                   <div class='courtNewsMobile'id='reports'></div>
                                         {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=congressional_reports&order_by=created_at', 'reports', 
                                             'Congressional Reports', 'Legislative', 
                                             'N/A', 'Report', GenerateFeed)}   
                                     
                 
                                     <Modal id='fullBillModal' show={reportModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
                                     centered onHide={() => setReportModalIsOpen(false)}>
                                           <Modal.Header id='billModalHeader' closeButton><h3>Congressional Reports</h3></Modal.Header>
                                           <GenerateFeed url='&skip=0&schema=united_states_of_america&table=private_and_public_laws&order_by=created_at' 
                                             name='Congressional Reports' branch='Legislative' topic='N/A' buttonName='Report' />
                                         </Modal>

    </Col>

 

                 

   
          
          <Modal id='fullBillModal' show={congressionalBudgetOfficeModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCongressionalBudgetOfficeModalIsOpen(false)}>
            <Modal.Header id='billModalHeader' closeButton><h3>Congressional Budget Office</h3></Modal.Header>
            <CongressionalBudgetOffice />
          </Modal>

          <h5 onClick={() => window.open('/us/house', "_self")} id='presidentialFeedHeadingMobile'>House<ChevronRight id='clickChevron'></ChevronRight></h5>
             
             <Col>
                          <Carousel controls={false} >
                        
               
                          <Carousel.Item interval={mobileCarouselInterval}>
             
             <div id='repCarouselDisplay'>
             
             <Col>
        
             <Row id = 'mobileFeedHeadingRow'><h5 id='billFeedHeadingMobileHomeHome' >Representatives</h5></Row>
             <HouseOfRepsDisplay/>
            
           
             </Col>
             
             </div>
             
             
             
             </Carousel.Item> 
             
             <Carousel.Item interval={mobileCarouselInterval}>
             
                
             <div id='repCarouselDisplay'>
             
             <Col>
             <div id='viewFullPageButton'>
             <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/house' >View House Committees</Button>
             </div>
             </Col>
             
             </div>
             
             
             
             </Carousel.Item>
                             </Carousel>
             </Col>
             
             <h5 onClick={() => window.open('/us/senate', "_self")} id='presidentialFeedHeadingMobile'>Senate<ChevronRight id='clickChevron'></ChevronRight></h5>
                        
             <Col>
             <Carousel controls={false} >
             
             <Carousel.Item interval={mobileCarouselInterval}>
             
             <div id='repCarouselDisplay'>
             
             <Col>
        
             <Row id = 'mobileFeedHeadingRow'><h5 id='billFeedHeadingMobileHomeHome' >Senators</h5></Row>
             <SenateDisplay/>
            
           
             </Col>
             
             </div>
             
             
             
             </Carousel.Item>
             
             <Carousel.Item interval={mobileCarouselInterval}>
             
             <div id='repCarouselDisplay'>
             
             <Col>
             <div id='viewFullPageButton'>
             <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/senate' >View Senate Committees</Button>
             </div>
             </Col>
             
             </div>
             
             
             
             </Carousel.Item>
             
             
             </Carousel>
             
             </Col>
             <Col>
             
                        </Col>
                 
      

                        <br></br>     <br></br>  <br></br><Navbar id='navbarMobileBottom' fixed="bottom">  

 


<div id='navbarSpacer'></div><div id='navbarSpacer'></div>





       
<div id='changeBranchDropdownMobile'>

           <MobileOffCanvas/>
              
            </div>

           


           
 
             
 
 
 
 
 
 
</Navbar>
</div>



</>
)
}}
export default LegislativeBranchMobile;




import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch, NavLink,  } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment,  } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image, ModalFooter } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText, FileMedicalFill, Coin, Tree, Globe2,  ShieldShaded, CircleFill, Pen, People, ChatSquareQuote, ChatLeftQuote, BlockquoteRight, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, CaretRight, ChevronRight, ChevronCompactRight, CaretRightFill, ArrowRightShort,  ArrowRightCircle, PlayCircle, PauseCircle, GeoAlt, HeartFill, QuestionCircleFill, MicFill, ReceiptCutoff } from 'react-bootstrap-icons'

import { BrowserView, MobileView } from 'react-device-detect';
import '../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Axios from 'axios'
import { render } from '@testing-library/react';
import
 { CreateFeedAPI,
   CreateFeedAPIDepartmentCanada,
CreateFeedItem,
CreateFeedItemCanadaDateFix} from '../../Functions'
import CanadaDesktopNavbar from '../CanadaDesktopNavbar';
import CanadaHomeMobile from '../CanadaHomeMobile';
import CanadaExecutiveBranchMobile from './CanadaExecutiveBranchMobile';
import CanadaPMMedia from './CanadaPMMedia';
import ExecutiveNewsCanada from './ExecutiveNewsCanada';
import GenerateFeed from '../../GenerateFeed';
import GenerateFeedDepartmentCanada from '../../GenerateFeedDepartmentCanada';

var collectionCounter = 0
var carouselInterval = 30000;
const CustomModal = ({ isOpen, onClose, title, content }) => (
  <Modal id='fullBillModal' show={isOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={onClose}>
    <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
    {content}
  </Modal>
);
var mobileCarouselInterval = 60000;
export const CanadaExecutive = () =>   {
  render()
  
 
  {
  const [billsEnrolledModalIsOpen, setBillsEnrolledModalIsOpen] = useState(false)
  const [fullBillModalIsOpen, setFullBillModalIsOpen] = useState(false)
  const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
  const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)
  const [showBioModal, setBioModal] = useState(false);
  const [modalInfo, setModalInfo] = useState({
    isOpen: false,
    title: '',
    content: null
  });

  const openModal = (title, content) => {
    setModalInfo({
      isOpen: true,
      title,
      content
    });
  };

  const closeModal = () => {
    setModalInfo({
      isOpen: false,
      title: '',
      content: null
    });
  };
  return (
    
    <>
    
      <div className="App">
      <BrowserView>
      <div id='homePage'>
        <div id='homebg'>
         <CanadaDesktopNavbar/>
         <div style={{ position: 'relative' }}>

<div id='profileImageIcon' style={{ width: '150px', height: '150px',  marginLeft: '40px' }}>
<People id='profileImage-imageicon' ></People>
</div>


<div style={{ position: 'absolute', top: '0', left: '250px', display: 'flex', flexDirection: 'column' }}>
<div style={{ color: 'white', fontSize: '24px', fontWeight: 'bold' }}>

</div>
<div  style={{ color: 'white', fontSize: '20px' }}> 
<span> <h3  >Executive Branch </h3></span>
<br></br>
<div style={{ color: 'white', fontSize: '20px' }}>
<span> 
The executive branch is composed of the Monarch (represented by the Governor General), the Prime Minister and Cabinet.</span>
<br></br>
<div style={{ color: 'white', fontSize: '18px' }}>

  <span><Link style={{color: '#21cff5'}} onClick={(e) => {
     e.preventDefault();
     window.open("https://www.ourcommons.ca/Members/en/ministries", "_blank");
   }}>
     https://www.ourcommons.ca/Members/en/ministries
     </Link></span> 

     <Button id='whDocsViewMoreButton' onClick={() =>  setBioModal(true)}>  <Files size={20}></Files> Read More</Button>
</div>
</div>

</div>
</div>



<Modal id='fullBillModal'  size="xl" aria-labelledby="contained-modal-title-vcenter" show={showBioModal} onHide={() => setBioModal(false)}>
         <Modal.Header id='billModalHeader' closeButton><h3> The Executive Branch</h3></Modal.Header>
       
         <div id='fullBillFeed' >
      
         <p>The executive branch is composed of the Monarch (represented by the Governor General), the Prime Minister and Cabinet.</p>

<p>The Governor General is the Monarch’s representative in Canada. They have important constitutional responsibilities, including signing bills into law, summoning and dissolving Parliament, and swearing in the Prime Minister and Cabinet.</p>


<p>The Prime Minister is the Head of Government. They traditionally play many roles including political party leader, Cabinet leader and MP. The Prime Minister determines the government’s agenda, guides policy development and selects members of the Cabinet.</p>



<p>The Cabinet develops policies to govern the country and introduces bills to transform these policies into law. Most Cabinet ministers oversee a government department. They report to Parliament and explain their department’s activities. They also provide direction to their departments on the development and implementation of government policies.</p>

<br></br>
<Link onClick={(e) => {
     e.preventDefault();
     window.open("https://www.ourcommons.ca/Members/en/ministries", "_blank");
   }}>
     https://www.ourcommons.ca/Members/en/ministries
     </Link>
 </div>

       </Modal>

</div>
<br></br>
<br></br><br></br>
<Modal id='mobileFullBillModal' show={fullBillModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFullBillModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Prime Minister's Office</h3></Modal.Header>
<GenerateFeed url='&schema=canada&table=prime_minister&order_by=created_at' 
                            name='Prime Ministers Office' branch='Executive' topic='N/A' buttonName='Doc' />
</Modal>

            <div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColLeft'>
          <div id='columnLefttPadding'>
            <h4 id='departmentOfDefenseHeadingInCol' onClick={() => setFullBillModalIsOpen(true)}>
              <b>Prime Minister's Office</b>
            </h4>

            <div id='executiveNews'></div>
            {CreateFeedAPI('&schema=canada&table=prime_minister&order_by=created_at', 'executiveNews', 'Prime Minister Office', 'Executive', 'N/A', 'Doc', GenerateFeed)}

            <Modal id='mobileFullBillModal' show={fullBillModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFullBillModalIsOpen(false)}>
              <Modal.Header id='mobileBillModalHeader' closeButton><h3>Prime Minister's Office</h3></Modal.Header>
              <GenerateFeed url='&schema=canada&table=prime_minister&order_by=created_at' name='Prime Ministers Office' branch='Executive' topic='N/A' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <Link id='pageLink' to="/canada/revenue-agency">
              <h4 id='departmentOfDefenseHeadingInCol'>
                <b>Canada Revenue Agency</b><ChevronRight id='clickChevron'></ChevronRight>
              </h4>
            </Link>

            <Carousel id='carouselInColumn' controls={false}>
              <Carousel.Item interval={carouselInterval}>
                <h4 id='departmentOfJusticeHeading' onClick={() =>
                  openModal('Canada Revenue Agency',
                    <GenerateFeedDepartmentCanada url='&table=agencies&order_by=created_at&filter_column=agency_name&filter_string=Canada%20Revenue%20Agency'
                      name='Canada Revenue Agency' branch='Executive' topic='Economy' buttonName='Doc' />)}><b>News</b></h4>
                <div id='cra'></div>
                {CreateFeedAPIDepartmentCanada('&table=agencies&order_by=created_at&filter_column=agency_name&filter_string=Canada%20Revenue%20Agency', 'cra', 'Canada Revenue Agency', 'Executive', 'Economy', 'Doc', GenerateFeedDepartmentCanada)}
              </Carousel.Item>

              <Carousel.Item interval={carouselInterval}>
                <div id='legislativeConInCol'>
                  <Col>
                    <div id='viewFullPageButton'>
                      <Button id='viewFullLegislativePageButtonMobile' size='lg'>View all Canada Revenue Agency news</Button>
                    </div>
                  </Col>
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColLeft'>
          <div id='columnLefttPadding'>
            <Link id='pageLink' to="/canada/impact-assessment-agency">
              <h4 id='departmentOfDefenseHeadingInCol'>
                <b>Impact Assessment Agency of Canada</b><ChevronRight id='clickChevron'></ChevronRight>
              </h4>
            </Link>

            <Carousel id='carouselInColumn' controls={false}>
              <Carousel.Item interval={carouselInterval}>
                <h4 id='departmentOfJusticeHeading' onClick={() =>
                  openModal('Impact Assessment Agency of Canada',
                    <GenerateFeedDepartmentCanada url='&table=agencies&order_by=created_at&filter_column=agency_name&filter_string=Impact%20Assessment%20Agency%20of%20Canada'
                      name='Impact Assessment Agency of Canada' branch='Executive' topic='Environment' buttonName='Doc' />)}><b>News</b></h4>
                <div id='impactAssessment'></div>
                {CreateFeedAPIDepartmentCanada('&table=agencies&order_by=created_at&filter_column=agency_name&filter_string=Impact%20Assessment%20Agency%20of%20Canada', 'impactAssessment', 'Impact Assessment Agency of Canada', 'Executive', 'Environment', 'Doc', GenerateFeedDepartmentCanada)}
              </Carousel.Item>

              <Carousel.Item interval={carouselInterval}>
                <div id='legislativeConInCol'>
                  <Col>
                    <div id='viewFullPageButton'>
                      <Button id='viewFullLegislativePageButtonMobile' size='lg'>View all Impact Assessment Agency of Canada news</Button>
                    </div>
                  </Col>
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </Col>

        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <Link id='pageLink' to="/canada/food-inspection-agency">
              <h4 id='departmentOfDefenseHeadingInCol'>
                <b>Canadian Food Inspection Agency</b><ChevronRight id='clickChevron'></ChevronRight>
              </h4>
            </Link>

            <Carousel id='carouselInColumn' controls={false}>
              <Carousel.Item interval={carouselInterval}>
                <h4 id='departmentOfJusticeHeading' onClick={() =>
                  openModal('Canadian Food Inspection Agency',
                    <GenerateFeedDepartmentCanada url='&table=agencies&order_by=created_at&filter_column=agency_name&filter_string=Canadian%20Food%20Inspection%20Agency'
                      name='Canadian Food Inspection Agency' branch='Executive' topic='Health' buttonName='Doc' />)}><b>News</b></h4>
                <div id='foodInspection'></div>
                {CreateFeedAPIDepartmentCanada('&table=agencies&order_by=created_at&filter_column=agency_name&filter_string=Canadian%20Food%20Inspection%20Agency', 'foodInspection', 'Canadian Food Inspection Agency', 'Executive', 'Health', 'Doc', GenerateFeedDepartmentCanada)}
              </Carousel.Item>

              <Carousel.Item interval={carouselInterval}>
                <div id='legislativeConInCol'>
                  <Col>
                    <div id='viewFullPageButton'>
                      <Button id='viewFullLegislativePageButtonMobile' size='lg'>View all Canadian Food Inspection Agency news</Button>
                    </div>
                  </Col>
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColLeft'>
          <div id='columnLefttPadding'>
            <Link id='pageLink' to="/canada/atlantic-opportunities-agency">
              <h4 id='departmentOfDefenseHeadingInCol'>
                <b>Atlantic Canada Opportunities Agency</b><ChevronRight id='clickChevron'></ChevronRight>
              </h4>
            </Link>

            <Carousel id='carouselInColumn' controls={false}>
              <Carousel.Item interval={carouselInterval}>
                <h4 id='departmentOfJusticeHeading' onClick={() =>
                  openModal('Atlantic Canada Opportunities Agency',
                    <GenerateFeedDepartmentCanada url='&table=agencies&order_by=created_at&filter_column=agency_name&filter_string=Atlantic%20Canada%20Opportunities%20Agency'
                      name='Atlantic Canada Opportunities Agency' branch='Executive' topic='Economy' buttonName='Doc' />)}><b>News</b></h4>
                <div id='atlanticOpportunities'></div>
                {CreateFeedAPIDepartmentCanada('&table=agencies&order_by=created_at&filter_column=agency_name&filter_string=Atlantic%20Canada%20Opportunities%20Agency', 'atlanticOpportunities', 'Atlantic Canada Opportunities Agency', 'Executive', 'Economy', 'Doc', GenerateFeedDepartmentCanada)}
              </Carousel.Item>

              <Carousel.Item interval={carouselInterval}>
                <div id='legislativeConInCol'>
                  <Col>
                    <div id='viewFullPageButton'>
                      <Button id='viewFullLegislativePageButtonMobile' size='lg'>View all Atlantic Canada Opportunities Agency news</Button>
                    </div>
                  </Col>
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </Col>

        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <Link id='pageLink' to="/canada/feddev-ontario">
              <h4 id='departmentOfDefenseHeadingInCol'>
                <b>Federal Economic Development Agency for Southern Ontario</b><ChevronRight id='clickChevron'></ChevronRight>
              </h4>
            </Link>

            <Carousel id='carouselInColumn' controls={false}>
              <Carousel.Item interval={carouselInterval}>
                <h4 id='departmentOfJusticeHeading' onClick={() =>
                  openModal('Federal Economic Development Agency for Southern Ontario',
                    <GenerateFeedDepartmentCanada url='&table=agencies&order_by=created_at&filter_column=agency_name&filter_string=Federal%20Economic%20Development%20Agency%20for%20Southern%20Ontario'
                      name='Federal Economic Development Agency for Southern Ontario' branch='Executive' topic='Economy' buttonName='Doc' />)}><b>News</b></h4>
                <div id='feddevOntario'></div>
                {CreateFeedAPIDepartmentCanada('&table=agencies&order_by=created_at&filter_column=agency_name&filter_string=Federal%20Economic%20Development%20Agency%20for%20Southern%20Ontario', 'feddevOntario', 'Federal Economic Development Agency for Southern Ontario', 'Executive', 'Economy', 'Doc', GenerateFeedDepartmentCanada)}
              </Carousel.Item>

              <Carousel.Item interval={carouselInterval}>
                <div id='legislativeConInCol'>
                  <Col>
                    <div id='viewFullPageButton'>
                      <Button id='viewFullLegislativePageButtonMobile' size='lg'>View all Federal Economic Development Agency for Southern Ontario news</Button>
                    </div>
                  </Col>
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColLeft'>
          <div id='columnLefttPadding'>
            <Link id='pageLink' to="/canada/border-services-agency">
              <h4 id='departmentOfDefenseHeadingInCol'>
                <b>Canada Border Services Agency</b><ChevronRight id='clickChevron'></ChevronRight>
              </h4>
            </Link>

            <Carousel id='carouselInColumn' controls={false}>
              <Carousel.Item interval={carouselInterval}>
                <h4 id='departmentOfJusticeHeading' onClick={() =>
                  openModal('Canada Border Services Agency',
                    <GenerateFeedDepartmentCanada url='&table=agencies&order_by=created_at&filter_column=agency_name&filter_string=Canada%20Border%20Services%20Agency'
                      name='Canada Border Services Agency' branch='Executive' topic='Defense' buttonName='Doc' />)}><b>News</b></h4>
                <div id='borderServices'></div>
                {CreateFeedAPIDepartmentCanada('&table=agencies&order_by=created_at&filter_column=agency_name&filter_string=Canada%20Border%20Services%20Agency', 'borderServices', 'Canada Border Services Agency', 'Executive', 'Defense', 'Doc', GenerateFeedDepartmentCanada)}
              </Carousel.Item>

              <Carousel.Item interval={carouselInterval}>
                <div id='legislativeConInCol'>
                  <Col>
                    <div id='viewFullPageButton'>
                      <Button id='viewFullLegislativePageButtonMobile' size='lg'>View all Canada Border Services Agency news</Button>
                    </div>
                  </Col>
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </Col>

        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <Link id='pageLink' to="/canada/transportation-agency">
              <h4 id='departmentOfDefenseHeadingInCol'>
                <b>Canadian Transportation Agency</b><ChevronRight id='clickChevron'></ChevronRight>
              </h4>
            </Link>

            <Carousel id='carouselInColumn' controls={false}>
              <Carousel.Item interval={carouselInterval}>
                <h4 id='departmentOfJusticeHeading' onClick={() =>
                  openModal('Canadian Transportation Agency',
                    <GenerateFeedDepartmentCanada url='&table=agencies&order_by=created_at&filter_column=agency_name&filter_string=Canadian%20Transportation%20Agency'
                      name='Canadian Transportation Agency' branch='Executive' topic='Infrastructure' buttonName='Doc' />)}><b>News</b></h4>
                <div id='transportation'></div>
                {CreateFeedAPIDepartmentCanada('&table=agencies&order_by=created_at&filter_column=agency_name&filter_string=Canadian%20Transportation%20Agency', 'transportation', 'Canadian Transportation Agency', 'Executive', 'Infrastructure', 'Doc', GenerateFeedDepartmentCanada)}
              </Carousel.Item>

              <Carousel.Item interval={carouselInterval}>
                <div id='legislativeConInCol'>
                  <Col>
                    <div id='viewFullPageButton'>
                      <Button id='viewFullLegislativePageButtonMobile' size='lg'>View all Canadian Transportation Agency news</Button>
                    </div>
                  </Col>
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColLeft'>
          <div id='columnLefttPadding'>
            <Link id='pageLink' to="/canada/northern-economic-development">
              <h4 id='departmentOfDefenseHeadingInCol'>
                <b>Canadian Northern Economic Development Agency</b><ChevronRight id='clickChevron'></ChevronRight>
              </h4>
            </Link>

            <Carousel id='carouselInColumn' controls={false}>
              <Carousel.Item interval={carouselInterval}>
                <h4 id='departmentOfJusticeHeading' onClick={() =>
                  openModal('Canadian Northern Economic Development Agency',
                    <GenerateFeedDepartmentCanada url='&table=agencies&order_by=created_at&filter_column=agency_name&filter_string=Canadian%20Northern%20Economic%20Development%20Agency'
                      name='Canadian Northern Economic Development Agency' branch='Executive' topic='Economy' buttonName='Doc' />)}><b>News</b></h4>
                <div id='northernEconomicDevelopment'></div>
                {CreateFeedAPIDepartmentCanada('&table=agencies&order_by=created_at&filter_column=agency_name&filter_string=Canadian%20Northern%20Economic%20Development%20Agency', 'northernEconomicDevelopment', 'Canadian Northern Economic Development Agency', 'Executive', 'Economy', 'Doc', GenerateFeedDepartmentCanada)}
              </Carousel.Item>

              <Carousel.Item interval={carouselInterval}>
                <div id='legislativeConInCol'>
                  <Col>
                    <div id='viewFullPageButton'>
                      <Button id='viewFullLegislativePageButtonMobile' size='lg'>View all Canadian Northern Economic Development Agency news</Button>
                    </div>
                  </Col>
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </Col>

        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <Link id='pageLink' to="/canada/financial-consumer-agency">
              <h4 id='departmentOfDefenseHeadingInCol'>
                <b>Financial Consumer Agency of Canada</b><ChevronRight id='clickChevron'></ChevronRight>
              </h4>
            </Link>

            <Carousel id='carouselInColumn' controls={false}>
              <Carousel.Item interval={carouselInterval}>
                <h4 id='departmentOfJusticeHeading' onClick={() =>
                  openModal('Financial Consumer Agency of Canada',
                    <GenerateFeedDepartmentCanada url='&table=agencies&order_by=created_at&filter_column=agency_name&filter_string=Financial%20Consumer%20Agency%20of%20Canada'
                      name='Financial Consumer Agency of Canada' branch='Executive' topic='Economy' buttonName='Doc' />)}><b>News</b></h4>
                <div id='financialConsumer'></div>
                {CreateFeedAPIDepartmentCanada('&table=agencies&order_by=created_at&filter_column=agency_name&filter_string=Financial%20Consumer%20Agency%20of%20Canada', 'financialConsumer', 'Financial Consumer Agency of Canada', 'Executive', 'Economy', 'Doc', GenerateFeedDepartmentCanada)}
              </Carousel.Item>

              <Carousel.Item interval={carouselInterval}>
                <div id='legislativeConInCol'>
                  <Col>
                    <div id='viewFullPageButton'>
                      <Button id='viewFullLegislativePageButtonMobile' size='lg'>View all Financial Consumer Agency of Canada news</Button>
                    </div>
                  </Col>
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColLeft'>
          <div id='columnLefttPadding'>
            <Link id='pageLink' to="/canada/public-health-agency">
              <h4 id='departmentOfDefenseHeadingInCol'>
                <b>Public Health Agency of Canada</b><ChevronRight id='clickChevron'></ChevronRight>
              </h4>
            </Link>

            <Carousel id='carouselInColumn' controls={false}>
              <Carousel.Item interval={carouselInterval}>
                <h4 id='departmentOfJusticeHeading' onClick={() =>
                  openModal('Public Health Agency of Canada',
                    <GenerateFeedDepartmentCanada url='&table=agencies&order_by=created_at&filter_column=agency_name&filter_string=Public%20Health%20Agency%20of%20Canada'
                      name='Public Health Agency of Canada' branch='Executive' topic='Health' buttonName='Doc' />)}><b>News</b></h4>
                <div id='publicHealth'></div>
                {CreateFeedAPIDepartmentCanada('&table=agencies&order_by=created_at&filter_column=agency_name&filter_string=Public%20Health%20Agency%20of%20Canada', 'publicHealth', 'Public Health Agency of Canada', 'Executive', 'Health', 'Doc', GenerateFeedDepartmentCanada)}
              </Carousel.Item>

              <Carousel.Item interval={carouselInterval}>
                <div id='legislativeConInCol'>
                  <Col>
                    <div id='viewFullPageButton'>
                      <Button id='viewFullLegislativePageButtonMobile' size='lg'>View all Public Health Agency of Canada news</Button>
                    </div>
                  </Col>
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </Col>

        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <Link id='pageLink' to="/canada/space-agency">
              <h4 id='departmentOfDefenseHeadingInCol'>
                <b>Canadian Space Agency</b><ChevronRight id='clickChevron'></ChevronRight>
              </h4>
            </Link>

            <Carousel id='carouselInColumn' controls={false}>
              <Carousel.Item interval={carouselInterval}>
                <h4 id='departmentOfJusticeHeading' onClick={() =>
                  openModal('Canadian Space Agency',
                    <GenerateFeedDepartmentCanada url='&table=agencies&order_by=created_at&filter_column=agency_name&filter_string=Canadian%20Space%20Agency'
                      name='Canadian Space Agency' branch='Executive' topic='Science & Tech' buttonName='Doc' />)}><b>News</b></h4>
                <div id='spaceAgency'></div>
                {CreateFeedAPIDepartmentCanada('&table=agencies&order_by=created_at&filter_column=agency_name&filter_string=Canadian%20Space%20Agency', 'spaceAgency', 'Canadian Space Agency', 'Executive', 'Science & Tech', 'Doc', GenerateFeedDepartmentCanada)}
              </Carousel.Item>

              <Carousel.Item interval={carouselInterval}>
                <div id='legislativeConInCol'>
                  <Col>
                    <div id='viewFullPageButton'>
                      <Button id='viewFullLegislativePageButtonMobile' size='lg'>View all Canadian Space Agency news</Button>
                    </div>
                  </Col>
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

           
          <Modal id='aboutModal' show={aboutModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAboutModalIsOpen(false)}>
            <div id='removedAlert'></div>
            <Modal.Header id='aboutModalHeader' closeButton><h3>About</h3></Modal.Header>
  <ModalBody id='aboutInfo'><p id='aboutParagraph'>Gov Glance was created to drive civic engagement and support a 
              healthier democracy by helping citizens get official Government news in an easy-to-navigate centralized location. 
              There will never be any opinion or political analysis(That's your job), only information from official Government 
              sources. Gov Glance is structured after the U.S. government that is broken into three branches Legislative, 
              Executive, and Judicial. To learn more about the structure and functions of The U.S. Government go to <a href='https://www.usa.gov/branches-of-government'>https://www.usa.gov/branches-of-government</a>.
              </p>

              <p id='aboutParagraph'>
              Gov Glance Foundation Inc. is a non-profit that was created to operate govglance.org and continue to create educational tools that help people learn about the workings of society
              </p>
            <br></br>

            <h3>Privacy</h3>

            <br></br>

              <p id='aboutParagraph'>Gov Glance does not collect any personal user information. 
              Features like collection save news items directly to your browser's local storage for your convenience
              and is not shared with Gov Glance servers. We do not collect, store, or send personal information to Google LLC, 
              but we do use some Google services to deliver our app to you. For any questions into Google’s privacy practices 
              here is a link to their privacy policy: <a href='https://policies.google.com/privacy?hl=en-US#intro'>https://policies.google.com/privacy?hl=en-US#intro</a>

              <br></br><br></br>

              For site analytics we use plausible.io an open source privacy friendly analytics provider. <a href="https://plausible.io/about" target="_blank">https://plausible.io/about</a>
            
            </p>
            <br></br>


            <h3>Contact</h3>

            <p id='aboutParagraph'>
                      Contact us via email: <a href='mailto:contact@govglance.org'>contact@govglance.org</a>

            </p>
            <br></br>
            </ModalBody>
          </Modal>
     <footer id='homeFooter'>
      <Navbar>
        <Row id='footerNav'>
      <Nav variant='pills'>
     <Nav.Link id='footerContent'>Gov Glance Foundation Inc.
</Nav.Link>
<Nav.Item>
<Nav.Link id='footerContent' href='mailto:contact@govglance.org'>Contact</Nav.Link>
</Nav.Item>
<Nav.Item>
<Nav.Link id='footerContent' onClick={(e) => {
                   e.preventDefault();
                   window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                 }}> <HeartFill id='donateIcon'/>    Donate</Nav.Link>
</Nav.Item>
<Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/about', "_self")}} id='footerContent'>Privacy</Nav.Link>
 </Nav>
 </Row>
 </Navbar>
      </footer>

          </div>
    </div>
      </BrowserView>
     

      <MobileView> 
                      <CanadaExecutiveBranchMobile/>
      </MobileView>
        </div>
    </>
  );







}
}


export default CanadaExecutive;

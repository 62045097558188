
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch, NavLink,  } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment, lazy  } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image, ModalFooter, Spinner } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText, FileMedicalFill, Coin, Tree, Globe2,  ShieldShaded, CircleFill, Pen, People, ChatSquareQuote, ChatLeftQuote, BlockquoteRight, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, CaretRight, ChevronRight, ChevronCompactRight, CaretRightFill, ArrowRightShort,  ArrowRightCircle, PlayCircle, PauseCircle, GeoAlt, HeartFill, QuestionCircleFill, MicFill, ReceiptCutoff } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import '../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import { render } from '@testing-library/react';
import
 {CreateFeedItem,
CreateFeedItemHouseOfCommons,
CreateFeedAPI} from '../../Functions'
import CanadaDesktopNavbar from '../CanadaDesktopNavbar';
import BillUpdatesCanada from './BillUpdatesCanada';
import CanadaLegislativeBranchMobile from './CanadaLegislativeBranchMobile';
import HouseOfCommons from './HouseOfCommons';
import GenerateFeed from '../../GenerateFeed';
import { Suspense } from 'react';
var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 60000;

const HeavyDependencyComponent = lazy(() => import('../../GenerateFeed'));
const CustomModal = ({ isOpen, onClose, title, content }) => (
  <Modal id='fullBillModal' show={isOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={onClose}>
    <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
    {content}
  </Modal>
);

export const CanadaLegislative = () =>   {
  const [modalInfo, setModalInfo] = useState({
    isOpen: false,
    title: '',
    content: null
  });

  const openModal = (title, content) => {
    setModalInfo({
      isOpen: true,
      title,
      content
    });
  };

  const closeModal = () => {
    setModalInfo({
      isOpen: false,
      title: '',
      content: null
    });
  };
  render()
  
 
  {

  
  const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)
  const [showBioModal, setBioModal] = useState(false);
  return (
    
    <>
    
      <div className="App">
      <BrowserView>
      <div id='homePage'>
        <div id='homebg'>
         <CanadaDesktopNavbar/>
         <div style={{ position: 'relative' }}>

<div id='profileImage' style={{ width: '150px', height: '150px', borderRadius: '100%', overflow: 'hidden',  marginLeft: '40px' }}>
<Image id='profileImage-image' src='/canadaExecutive.png' />
</div>


<div style={{ position: 'absolute', top: '0', left: '250px', display: 'flex', flexDirection: 'column' }}>
<div style={{ color: 'white', fontSize: '24px', fontWeight: 'bold' }}>

</div>
<div  style={{ color: 'white', fontSize: '20px' }}> 
<span> <h3  >Legislative Branch </h3></span>
<br></br>
<div style={{ color: 'white', fontSize: '20px' }}>
<span> 
Parliament is Canada’s federal legislature. It includes the Monarch (represented by the Governor General), the Senate and the House of Commons. Members of the Senate and House of Commons propose, review and pass bills, which then become laws.</span>
<br></br>
<div style={{ color: 'white', fontSize: '18px' }}>

  <span><Link style={{color: '#21cff5'}} onClick={(e) => {
     e.preventDefault();
     window.open("https://www.parl.ca/", "_blank");
   }}>
     https://www.parl.ca/
     </Link></span> 

     <Button id='whDocsViewMoreButton' onClick={() =>  setBioModal(true)}>  <Files size={20}></Files> Read More</Button>
</div>
</div>

</div>
</div>
<br></br>
<br></br>
<br></br>
<Modal id='fullBillModal'  size="xl" aria-labelledby="contained-modal-title-vcenter" show={showBioModal} onHide={() => setBioModal(false)}>
         <Modal.Header id='billModalHeader' closeButton><h3> The Legislative Branch</h3></Modal.Header>
       
         <div id='fullBillFeed' >
      
         <p>Parliament is Canada’s federal legislature. It includes the Monarch (represented by the Governor General), the Senate and the House of Commons. Members of the Senate and House of Commons propose, review and pass bills, which then become laws. To become law, all bills must be adopted by the Senate and the House of Commons in identical form and receive Royal Assent from the Governor General or a representative.</p>

<p>The Senate: Senators are appointed by the Governor General, on the advice of the Prime Minister, to represent Canada’s provinces and territories. Senators introduce bills, refine legislation, propose amendments to bills, and investigate and report on important issues.</p>

<p>The House of Commons: Members of Parliament (MPs) are elected by Canadians to represent different areas of the country called constituencies. MPs spend much of their time debating, voting, participating in committees and representing the concerns of their constituents. Like senators, they also introduce bills, refine legislation, and suggest amendments to Senate bills.</p>


<p><b>How Parliament Works</b></p>


<p>Parliament passes laws that affect all Canadians. In this section, you will learn more about the Senate, the House of Commons, and how bills become laws.</p>

<p><b>People in Parliament</b></p>

<p>Parliament is composed of the Governor General, the Senate and the House of Commons. Discover the work of Canada’s parliamentarians, as well as the people who support them in important political and non-political roles.</p>



<p><b>Canada’s System of Government</b></p>

<p>Parliament is only one part of Canada’s system of government. In this section, you will find out more about the people and institutions that contribute to governance across the country.</p>


<br></br>
<Link onClick={(e) => {
     e.preventDefault();
     window.open("https://www.parl.ca/", "_blank");
   }}>
     https://www.parl.ca/
     </Link>
 </div>

       </Modal>

</div>
                     
            <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                    <Row>
                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>
                          <h4 id='feedHeadingBillsEnrolled' onClick={() =>
                            openModal('Bill Updates',
                            <GenerateFeed url='&skip=0&schema=canada&table=bills&order_by=created_at' 
                            name='Bill Updates' branch='Legislative' topic='N/A' buttonName='Bill' /> )}><b>Bill Updates</b></h4>
                          <div id='billUpdates'></div>
                                    {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
                                    {CreateFeedAPI('&skip=0&schema=canada&table=bills&order_by=created_at', 'billUpdates', 
                            'Bill Updates', 'Legislative', 
                            'N/A', 'Bills', GenerateFeed)}
                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                  
                        <h4 id='feedHeadingBillsEnrolled' onClick={() =>
                            openModal('House of Commons',
                             <HouseOfCommons/> )}><b>House of Commons</b></h4>
                          <div id='hOC'></div>
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
    {CreateFeedItemHouseOfCommons('https://gov-glance-canada.nyc3.digitaloceanspaces.com/legislative-xml/house_of_commons_newsroom/house_of_commons_newsroom.xml', 'hOC', 'House of Commons News', 'Legislative', 'N/A', HouseOfCommons)}
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Container>
              </div>

              <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                    <Row>
                    <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                        <h4 id='feedHeadingBillsEnrolled' onClick={() =>
                            openModal('Standing Committee on Veterans Affairs',
                              <HeavyDependencyComponent
                                url='&skip=0&schema=canada&table=acva&order_by=created_at'
                                name='Standing Committee on Veterans Affairs' 
                                branch='Legislative' topic='N/A' buttonName='Document' />)}><b>Standing Committee on Veterans' Affairs </b></h4>
                          <div id='committee1'></div>
                          
                                    {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
                                    {CreateFeedAPI('&skip=0&schema=canada&table=acva&order_by=created_at', 'committee1',
                            'Standing Committee on Veterans Affairs', 'Legislative',
                            'N/A', 'Document', GenerateFeed)}
                        </div>
                      </Col>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>

                        <h4 id='feedHeadingBillsEnrolled' onClick={() =>
                            openModal('Standing Committee on Agriculture and Agri-Food',
                              <HeavyDependencyComponent
                                url='&skip=0&schema=canada&table=agri&order_by=created_at'
                                name='Standing Committee on Agriculture and Agri-Food' branch='Legislative' topic='N/A' buttonName='Document' />)}><b>Standing Committee on Agriculture and Agri-Food </b></h4>
                          <div id='committee2'></div>
                          
                          {CreateFeedAPI('&skip=0&schema=canada&table=agri&order_by=created_at', 'committee2',
                            'Standing Committee on Agriculture and Agri-Food', 'Legislative',
                            'N/A', 'Document', GenerateFeed)}
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Container>
              </div>
           
              <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                    <Row>
                    <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                  
                        <h4 id='feedHeadingBillsEnrolled' onClick={() =>
                            openModal('Special Committee on the Canada–People’s Republic of China Relationship',
                              <HeavyDependencyComponent
                                url='&skip=0&schema=canada&table=cacn&order_by=created_at'
                                name='Special Committee on the Canada–People’s Republic of China Relationship' 
                                branch='Legislative' topic='N/A' 
                                buttonName='Document' />)}><b>Special Committee on the Canada–People’s Republic of China Relationship </b></h4>
                          <div id='committee3'></div>
                          
                                    {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
                                    {CreateFeedAPI('&skip=0&schema=canada&table=cacn&order_by=created_at', 'committee3',
                            'Special Committee on the Canada–People’s Republic of China Relationship', 'Legislative',
                            'N/A', 'Document', GenerateFeed)}
                        </div>
                      </Col>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>

                        <h4 id='feedHeadingBillsEnrolled'onClick={() =>
                            openModal('Standing Committee on International Trade',
                              <HeavyDependencyComponent
                                url='&skip=0&schema=canada&table=ciit&order_by=created_at'
                                name='Standing Committee on International Trade' 
                                branch='Legislative' topic='N/A' 
                                buttonName='Document' />)} ><b>Standing Committee on International Trade </b></h4>
                          <div id='committee4'></div>
                          
                                    {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
                                    {CreateFeedAPI('&skip=0&schema=canada&table=ciit&order_by=created_at', 'committee4',
                            'Standing Committee on International Trade', 'Legislative',
                            'N/A', 'Document', GenerateFeed)}
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Container>
              </div>

              <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                    <Row>
                    <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                  
                        <h4 id='feedHeadingBillsEnrolled'onClick={() =>
                            openModal('Standing Committee on Environment and Sustainable Development',
                              <HeavyDependencyComponent
                                url='&skip=0&schema=canada&table=envi&order_by=created_at'
                                name='Standing Committee on Environment and Sustainable Development' 
                                branch='Legislative' topic='N/A' 
                                buttonName='Document' />)}><b>Standing Committee on Environment and Sustainable Development </b></h4>
                          <div id='committee5'></div>
                          
                          {CreateFeedAPI('&skip=0&schema=canada&table=envi&order_by=created_at', 'committee5',
                            'Standing Committee on Environment and Sustainable Development', 'Legislative',
                            'N/A', 'Document', GenerateFeed)}
                        </div>
                      </Col>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>

                        <h4 id='feedHeadingBillsEnrolled' onClick={() =>
                            openModal('Standing Committee on Citizenship and Immigration',
                              <HeavyDependencyComponent
                                url='&skip=0&schema=canada&table=cimm&order_by=created_at'
                                name='Standing Committee on Citizenship and Immigration' 
                                branch='Legislative' topic='N/A' 
                                buttonName='Document' />)}><b>Standing Committee on Citizenship and Immigration</b></h4>
                          <div id='committee6'></div>
                          
                          {CreateFeedAPI('&skip=0&schema=canada&table=cimm&order_by=created_at', 'committee6',
                            'Standing Committee on Citizenship and Immigration', 'Legislative',
                            'N/A', 'Document', GenerateFeed)}
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Container>
              </div>
              <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                    <Row>
                    <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                  
                        <h4 id='feedHeadingBillsEnrolled' onClick={() =>
                            openModal('Standing Committee on Finance',
                              <HeavyDependencyComponent
                                url='&skip=0&schema=canada&table=fina&order_by=created_at'
                                name='Standing Committee on Finance' 
                                branch='Legislative' topic='N/A' 
                                buttonName='Document' />)}><b>Standing Committee on Finance</b></h4>
                          <div id='committee7'></div>

                          {CreateFeedAPI('&skip=0&schema=canada&table=fina&order_by=created_at', 'committee7',
                            'Standing Committee on Finance', 'Legislative',
                            'N/A', 'Document', GenerateFeed)}
                        </div>
                      </Col>
                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>

                        <h4 id='feedHeadingBillsEnrolled' onClick={() =>
                            openModal('Standing Committee on Indigenous and Northern Affairs',
                              <HeavyDependencyComponent
                                url='&skip=0&schema=canada&table=inan&order_by=created_at'
                                name='Standing Committee on Indigenous and Northern Affairs' 
                                branch='Legislative' topic='N/A' 
                                buttonName='Document' />)}><b>Standing Committee on Indigenous and Northern Affairs </b></h4>
                          <div id='committee8'></div>
                          
                          {CreateFeedAPI('&skip=0&schema=canada&table=inan&order_by=created_at', 'committee8',
                            'Standing Committee on Indigenous and Northern Affairs', 'Legislative',
                            'N/A', 'Document', GenerateFeed)}
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Container>
              </div>
              <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>
                    <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                  
                        <h4 id='feedHeadingBillsEnrolled' onClick={() =>
                            openModal('Standing Committee on Industry and Technology',
                              <HeavyDependencyComponent
                                url='&skip=0&schema=canada&table=indu&order_by=created_at'
                                name='Standing Committee on Industry and Technology' 
                                branch='Legislative' topic='N/A' 
                                buttonName='Document' />)} ><b>Standing Committee on Industry and Technology </b></h4>
                          <div id='committee9'></div>
                          
                          {CreateFeedAPI('&skip=0&schema=canada&table=indu&order_by=created_at', 'committee9',
                            'Standing Committee on Industry and Technology', 'Legislative',
                            'N/A', 'Document', GenerateFeed)}
                        </div>
                      </Col>
                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>

                        <h4 id='feedHeadingBillsEnrolled' onClick={() =>
                            openModal('Standing Committee on National Defence',
                              <HeavyDependencyComponent
                                url='&skip=0&schema=canada&table=nddn&order_by=created_at'
                                name='Standing Committee on National Defence' 
                                branch='Legislative' topic='N/A' 
                                buttonName='Document' />)}><b>Standing Committee on National Defence </b></h4>
                          <div id='committee10'></div>
                          
                          {CreateFeedAPI('&skip=0&schema=canada&table=nddn&order_by=created_at', 'committee10',
                            'Standing Committee on National Defence', 'Legislative',
                            'N/A', 'Document', GenerateFeed)}
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Container>
              </div>

              <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                    <Row>
                    <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                  
                        <h4 id='feedHeadingBillsEnrolled' onClick={() =>
                            openModal('Standing Committee on Government Operations and Estimates',
                              <HeavyDependencyComponent
                                url='&skip=0&schema=canada&table=oggo&order_by=created_at'
                                name='Standing Committee on Government Operations and Estimates' 
                                branch='Legislative' topic='N/A' 
                                buttonName='Document' />)}><b>Standing Committee on Government Operations and Estimates </b></h4>
                          <div id='committee11'></div>
                          
                          {CreateFeedAPI('&skip=0&schema=canada&table=oggo&order_by=created_at', 'committee11',
                            'Standing Committee on Government Operations and Estimates', 'Legislative',
                            'N/A', 'Document', GenerateFeed)}
                        </div>
                      </Col>
                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>

                        <h4 id='feedHeadingBillsEnrolled' onClick={() =>
                            openModal('Standing Committee on Natural Resources',
                              <HeavyDependencyComponent
                                url='&skip=0&schema=canada&table=rnnr&order_by=created_at'
                                name='Standing Committee on Natural Resources' 
                                branch='Legislative' topic='N/A' 
                                buttonName='Document' />)}><b>Standing Committee on Natural Resources </b></h4>
                          <div id='committee*'></div>
                          
                          {CreateFeedAPI('&skip=0&schema=canada&table=oggo&order_by=created_at', 'committee*',
                            'Standing Committee on Natural Resources', 'Legislative',
                            'N/A', 'Document', GenerateFeed)}
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Container>
              </div>
              <Suspense fallback={<Spinner animation="grow" variant="light" />}>
                      <CustomModal isOpen={modalInfo.isOpen} onClose={closeModal} title={modalInfo.title} content={modalInfo.content} />
                    </Suspense>

          <Modal id='aboutModal' show={aboutModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAboutModalIsOpen(false)}>
            <div id='removedAlert'></div>
            <Modal.Header id='aboutModalHeader' closeButton><h3>About</h3></Modal.Header>
  <ModalBody id='aboutInfo'><p id='aboutParagraph'>Gov Glance was created to drive civic engagement and support a 
              healthier democracy by helping citizens get official Government news in an easy-to-navigate centralized location. 
              There will never be any opinion or political analysis(That's your job), only information from official Government 
              sources. Gov Glance is structured after the U.S. government that is broken into three branches Legislative, 
              Executive, and Judicial. To learn more about the structure and functions of The U.S. Government go to <a href='https://www.usa.gov/branches-of-government'>https://www.usa.gov/branches-of-government</a>.
              </p>

              <p id='aboutParagraph'>
              Gov Glance Foundation Inc. is a non-profit that was created to operate govglance.org and continue to create educational tools that help people learn about the workings of society
              </p>
            <br></br>

            <h3>Privacy</h3>

            <br></br>

              <p id='aboutParagraph'>Gov Glance does not collect any personal user information. 
              Features like collection save news items directly to your browser's local storage for your convenience
              and is not shared with Gov Glance servers. We do not collect, store, or send personal information to Google LLC, 
              but we do use some Google services to deliver our app to you. For any questions into Google’s privacy practices 
              here is a link to their privacy policy: <a href='https://policies.google.com/privacy?hl=en-US#intro'>https://policies.google.com/privacy?hl=en-US#intro</a>

              <br></br><br></br>

              For site analytics we use plausible.io an open source privacy friendly analytics provider. <a href="https://plausible.io/about" target="_blank">https://plausible.io/about</a>
            
            </p>
            <br></br>


            <h3>Contact</h3>

            <p id='aboutParagraph'>
                      Contact us via email: <a href='mailto:contact@govglance.org'>contact@govglance.org</a>

            </p>

            <br></br>


            
            </ModalBody>
          </Modal>
 
     <footer id='homeFooter'>
      <Navbar>
        <Row id='footerNav'>
      <Nav variant='pills'>
        
     <Nav.Link id='footerContent'>Gov Glance Foundation Inc.
</Nav.Link>
<Nav.Item>
<Nav.Link id='footerContent' href='mailto:contact@govglance.org'>Contact</Nav.Link>
</Nav.Item>
<Nav.Item>
<Nav.Link id='footerContent' onClick={(e) => {
                   e.preventDefault();
                   window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                 }}> <HeartFill id='donateIcon'/>    Donate</Nav.Link>
</Nav.Item>
<Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/about', "_self")}} id='footerContent'>Privacy</Nav.Link>
    
     
 </Nav>
 </Row>
 </Navbar>
      </footer>
          </div>
    </div>
      </BrowserView>
      <MobileView> 
                      <CanadaLegislativeBranchMobile/>
      </MobileView>
        </div>
      


       
    </>

     
    
  );







}
}


export default CanadaLegislative;

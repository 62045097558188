
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch,  } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment,  } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image, ModalFooter } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, Search, InfoCircle, FileText, FileMedicalFill, Coin, Tree, Globe2, GeoAlt,  ShieldShaded, CircleFill, Pen, People, ChatSquareQuote, HeartFill, ChatLeftQuote, BlockquoteRight, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, CaretRight, ChevronRight, ChevronCompactRight, CaretRightFill, ArrowRightShort,  ArrowRightCircle, PlayCircle, PauseCircle, App } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import '../App.css'
import 'bootstrap/dist/css/bootstrap.min.css'

import { render } from '@testing-library/react';
import CollectionPage from '../CollectionPage';
import NavbarSearchButton from '../NavbarSearchButton';

export const ProvinceDesktopNavbar = ({province, provinceFlag}) =>   {
  render()
 
  {
  const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false)
  const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)
  const [declarationModalIsOpen, setDeclarationModalIsOpen] = useState(false)
  const [constitutionModalIsOpen, setConstitutionModalIsOpen] = useState(false)
  const [billOfRightsModalIsOpen, setBillOfRightsModalIsOpen] = useState(false)
  const [statesModalIsOpen, setStatesModalIsOpen] = useState(false)
  return (
    
    <>
    
      <div className="App">
      <BrowserView>
      <div id='homePage'>
        <div>
        <Navbar id='navbar' > 
        <Navbar.Brand onClick={(e) => {
                        e.preventDefault();
                        window.open('/', "_self")}} id='desktopLogo' >
                    {<Image id = 'govGlanceLogo'  width='80px' 
                      src='/favicon.ico'></Image>}
              </Navbar.Brand>       
<Col>
          <Row id='realNavbarTop'>
                <Nav>
              <Nav.Link id='navbarAboutDesktop' ><Button id='navbarAboutButtonDesktop' onClick={(e) => {
                        e.preventDefault();
                        window.open('/', "_self") }} >Home
                        </Button></Nav.Link>
       
                <Nav.Link id='navbarAboutDesktop'><Button id='navbarAboutButtonDesktop' onClick={(e) => {
                   e.preventDefault();
                   window.open('/about', "_self")}}> About</Button></Nav.Link>
                <Nav.Link id='navbarAboutDesktop'><Button id='navbarAboutButtonDesktop' onClick={(e) => {
                        e.preventDefault();
                        window.open('/collection', "_self") }}><Folder></Folder> Collection</Button></Nav.Link>

               <Nav.Link id='navbarAboutDesktop'><Button id='navbarAboutButtonDesktop' onClick={(e) => {
                        e.preventDefault();
                        window.open('https://apps.apple.com/us/app/gov-glance-news/id6448072618', "_blank") }}><svg id='appIconsDesktop' xmlns="http://www.w3.org/2000/svg" class="ionicon" height={24} fill='white' viewBox="0 0 512 512"><path d="M256 32C132.26 32 32 132.26 32 256s100.26 224 224 224 224-100.26 224-224S379.74 32 256 32zm-85 321.89a15.48 15.48 0 01-13.46 7.65 14.91 14.91 0 01-7.86-2.16 15.48 15.48 0 01-5.6-21.21l15.29-25.42a8.73 8.73 0 017.54-4.3h2.26c11.09 0 18.85 6.67 21.11 13.13zm129.45-50l-100.13.11h-66.55a15.46 15.46 0 01-15.51-16.15c.32-8.4 7.65-14.76 16-14.76h48.24l57.19-97.35-18.52-31.55C217 137 218.85 127.52 226 123a15.57 15.57 0 0121.87 5.17l9.9 16.91h.11l9.91-16.91A15.58 15.58 0 01289.6 123c7.11 4.52 8.94 14 4.74 21.22l-18.52 31.55-18 30.69-39.09 66.66v.11h57.61c7.22 0 16.27 3.88 19.93 10.12l.32.65c3.23 5.49 5.06 9.26 5.06 14.75a13.82 13.82 0 01-1.17 5.17zm77.75.11h-27.11v.11l19.82 33.71a15.8 15.8 0 01-5.17 21.53 15.53 15.53 0 01-8.08 2.27A15.71 15.71 0 01344.2 354l-29.29-49.86-18.2-31L273.23 233a38.35 38.35 0 01-.65-38c4.64-8.19 8.19-10.34 8.19-10.34L333 273h44.91c8.4 0 15.61 6.46 16 14.75A15.65 15.65 0 01378.23 304z"/></svg>
                        iOS</Button></Nav.Link>
<Nav.Link id='navbarAboutDesktop'><Button id='navbarAboutButtonDesktop' onClick={(e) => {
                        e.preventDefault();
                        window.open('https://play.google.com/store/apps/details?id=com.govglance1&hl=en_US&gl=US', "_blank") }}><svg id='appIconsDesktop' xmlns="http://www.w3.org/2000/svg" class="ionicon" fill='white' height={24} viewBox="0 0 512 512"><path d="M48 59.49v393a4.33 4.33 0 007.37 3.07L260 256 55.37 56.42A4.33 4.33 0 0048 59.49zM345.8 174L89.22 32.64l-.16-.09c-4.42-2.4-8.62 3.58-5 7.06l201.13 192.32zM84.08 472.39c-3.64 3.48.56 9.46 5 7.06l.16-.09L345.8 338l-60.61-57.95zM449.38 231l-71.65-39.46L310.36 256l67.37 64.43L449.38 281c19.49-10.77 19.49-39.23 0-50z"/>
                        </svg>Android</Button></Nav.Link>
<Nav.Link id='navbarAboutDesktop'><Button id='navbarAboutButtonDesktop' onClick={(e) => {
                        e.preventDefault();
                        window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                      }}><HeartFill id='donateIcon'/>    Donate</Button></Nav.Link>
<Nav.Link id='navbarAboutDesktop'><NavbarSearchButton/></Nav.Link>
                      
<Nav.Link id='navbarAboutDesktop'><Button id='navbarAboutButtonDesktop'  onClick={() => setStatesModalIsOpen(true)}  >Provinces</Button>
  </Nav.Link>

                </Nav>
              </Row>
              <Row id='testRow'>

              <Navbar id='bottomNavbar' >
          <Nav variant='pills' className="mr-auto">
                  <Nav.Link id ='navbarAboutDesktop' >
                  <Dropdown drop="down-centered">

<Dropdown.Toggle id='navbarAboutButtonDesktop'>
  🇨🇦
</Dropdown.Toggle>

<Dropdown.Menu id="docsDropdown" drop="down-centered">
<Dropdown.Item
id="docsDropdownItem"
onClick={(e) => {
  e.preventDefault();
  window.open('/canada', "_self");
}}
>
🇨🇦 Canada
</Dropdown.Item>
<Dropdown.Item
id="docsDropdownItem"
onClick={(e) => {
  e.preventDefault();
  window.open('/mexico', "_self");
}}
>
🇲🇽 Mexico
</Dropdown.Item>

<Dropdown.Item
id="docsDropdownItem"
onClick={(e) => {
  e.preventDefault();
  window.open('/uk', "_self");
}}
>
🇬🇧 United Kingdom
</Dropdown.Item>


<Dropdown.Item
id="docsDropdownItem"
onClick={(e) => {
  e.preventDefault();
  window.open('/us', "_self");
}}
>
🇺🇸 United States
</Dropdown.Item>
</Dropdown.Menu>
</Dropdown>
                  </Nav.Link>

                  <Nav.Link id='navbarAboutDesktop' ><Image id='provinceFlag' src={provinceFlag}></Image></Nav.Link>
          
                  <Nav.Link id='navbarAboutDesktop' ><b id='branchHeadingBold'>{province}</b></Nav.Link>
                  <Modal id='fullBillModal' show={statesModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setStatesModalIsOpen(false)}>
              <Modal.Header id='billModalHeader' closeButton><h3 id='branchHeading' > <GeoAlt></GeoAlt> <b id='branchHeadingBold'> States</b></h3>
</Modal.Header>

<div id='homebg'>  
                      <Container>
                      <Col>
                      <Row id='statesRow'>
                  <Button size='sm' id='statesModalButtons'  onClick={()  => {
                        
                        window.open('/canada/alberta', '_self')
                      }}> <Image id='stateFlagMobile'  src='/albertaFlag.png'></Image>Alberta</Button>
                        <Button size='sm' id='statesModalButtons'  onClick={()  => {
                        
                        window.open('/canada/britishcolumbia', '_self')
                      }}><Image id='stateFlagMobile'  src='/britishColumbiaFlag.png'></Image>British Columbia</Button>
                       <Button size='sm' id='statesModalButtons'  onClick={()  => {
                        
                        window.open('/canada/manitoba', '_self')
                      }}> <Image id='stateFlagMobile'  src='/manitobaFlag.png'></Image>Manitoba</Button>
                      </Row>
                      <Row>
                          <Button size='sm' id='statesModalButtons'  onClick={()  => {
                        
                        window.open('/canada/newbrunswick', '_self')
                      }}> <Image id='stateFlagMobile'  src='/newBrunswickFlag.png'></Image>New Brunswick</Button>
                     
                     <Button size='sm' id='statesModalButtons'  onClick={()  => {
                        
                        window.open('/canada/newfoundland&labrador', '_self')
                      }}> <Image id='stateFlagMobile'  src='/newfoundlandAndLabradorFlag.png'></Image>Newfoundland and Labrador</Button>
                     
                     <Button size='sm' id='statesModalButtons'  onClick={()  => {
                        
                        window.open('/canada/northwestterritories', '_self')
                      }}> <Image id='stateFlagMobile'  src='/northwestTerritoriesFlag.png'></Image>Northwest Territories</Button>
                     </Row>
                     <Row>
                     <Button size='sm' id='statesModalButtons'  onClick={()  => {
                        
                        window.open('/canada/novascotia', '_self')
                      }}> <Image id='stateFlagMobile'  src='/novaScotiaFlag.png'></Image>Nova Scotia</Button>
                      <Button size='sm' id='statesModalButtons'  onClick={()  => {
                        
                        window.open('/canada/nunavut', '_self')
                      }}> <Image id='stateFlagMobile'  src='/nunavutFlag.png'></Image>Nunavut</Button>
                       <Button size='sm' id='statesModalButtons'  onClick={()  => {
                        
                        window.open('/canada/ontario', '_self')
                      }}> <Image id='stateFlagMobile'  src='/ontarioFlag.png'></Image>Ontario</Button>
                      </Row>
                      <Row>
                       <Button size='sm' id='statesModalButtons'  onClick={()  => {
                        
                        window.open('/canada/princeedwardisland', '_self')
                      }}> <Image id='stateFlagMobile'  src='/princeEdwardIslandFlag.png'></Image>Prince Edward Island</Button>
                       <Button size='sm' id='statesModalButtons'  onClick={()  => {
                        
                        window.open('/canada/quebec', '_self')
                      }}> <Image id='stateFlagMobile'  src='/quebecFlag.png'></Image>Quebec</Button>
                      </Row>
                      <Row>
                       <Button size='sm' id='statesModalButtons'  onClick={()  => {
                        
                        window.open('/canada/saskatchewan', '_self')
                      }}> <Image id='stateFlagMobile'  src='/saskatchewanFlag.png'></Image>Saskatchewan</Button>
                   
                       <Button size='sm' id='statesModalButtons'  onClick={()  => {
                        
                        window.open('/canada/yukon', '_self')
                      }}> <Image id='stateFlagMobile'  src='/yukonFlag.png'></Image>Yukon</Button>
                      </Row>
           
                     
                     
                     
                   
                     </Col>
                     </Container>
                     </div>

             
            </Modal>
</Nav>
</Navbar>
</Row>
</Col>
        </Navbar>

          <Modal id='aboutModal'  show={declarationModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDeclarationModalIsOpen(false)}>
            <div id='removedAlert'></div>
            
            <Modal.Header id='aboutModalHeader' closeButton ><h3>Declaration of Independence</h3></Modal.Header>


            <iframe src='https://gov-glance-congress-dailydigest.glitch.me/Declaration%20of%20Independence%20.html' height='800px' width='1138px' frameBorder='0'></iframe>

            <Modal.Footer><a href='https://www.archives.gov/founding-docs/declaration-transcript'>https://www.archives.gov/founding-docs/declaration-transcript</a></Modal.Footer>

          </Modal>


          <Modal id='aboutModal' show={constitutionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setConstitutionModalIsOpen(false)}>
            <div id='removedAlert'></div>
            <Modal.Header id='aboutModalHeader' closeButton><h3>Constitution</h3></Modal.Header>

            <iframe src='https://evening-plains-21303.herokuapp.com/https://constitution.congress.gov/constitution/' height='800px' width='1138px' frameBorder='0'></iframe>
            <Modal.Footer><a href='https://www.archives.gov/founding-docs/constitution-transcript'>https://www.archives.gov/founding-docs/constitution-transcript</a>
              <a href='https://constitution.congress.gov/constitution/'>https://constitution.congress.gov/constitution/</a>
            </Modal.Footer>
          </Modal>

          <Modal id='aboutModal' show={billOfRightsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setBillOfRightsModalIsOpen(false)}>
            <div id='removedAlert'></div>
            <Modal.Header id='aboutModalHeader' closeButton><h3>Bill of Rights</h3></Modal.Header>

            <iframe src='https://gov-glance-congress-dailydigest.glitch.me/The%20U%20S%20Bill%20of%20Rights.html' height='800px' width='1138px' frameBorder='0'></iframe>

            <Modal.Footer><a href='https://www.archives.gov/founding-docs/bill-of-rights-transcript'>https://www.archives.gov/founding-docs/bill-of-rights-transcript</a></Modal.Footer>

          </Modal>
   







          <Modal id='aboutModal' show={aboutModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAboutModalIsOpen(false)}>
            <div id='removedAlert'></div>
            <Modal.Header id='aboutModalHeader' closeButton><h3>About</h3></Modal.Header>
            <ModalBody id='aboutInfo'><p id='aboutParagraph'>Gov Glance is a civic engagement tool designed to help citizens 
                  get official government news in an easy-to-navigate centralized location. 
              There will never be any opinion or political analysis(That's your job), only information from official government 
              sources.
              </p>

              <p id='aboutParagraph'>
              Gov Glance Foundation Inc. is a non-profit dedicated to creating free educational tools and resources. <a id='aboutLink' href='https://govglance.foundation/'>https://govglance.foundation/</a>
              </p>
             
              <Button id='allPageButtonMobile' onClick={(e) => {
                        e.preventDefault();
                        window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                      }}><HeartFill id='donateIcon'/>    Donate</Button>
              
            <br></br>

            <h3>Privacy</h3>

            <br></br>

            
            <p id='aboutParagraph'>Gov Glance does not collect any personal user information. 
              Features like collection save news items directly to your browser's local storage for your convenience
              and is not shared with Gov Glance servers. We do not collect, store, or send personal information to Google LLC, 
              but we do use some Google services to deliver our app to you. For any questions into Google’s privacy practices 
              here is a link to their privacy policy: <a href='https://policies.google.com/privacy?hl=en-US#intro'>https://policies.google.com/privacy?hl=en-US#intro</a>

              <br></br><br></br>

              For site analytics we use plausible.io an open source privacy friendly analytics provider. <a href="https://plausible.io/about" target="_blank">https://plausible.io/about</a>
            
            </p>
            <br></br>


            <h3>Contact</h3>

            <p id='aboutParagraph'>
                      Contact us via email: <a href='mailto:contact@govglance.org'>contact@govglance.org</a>

            </p>

            <br></br>


            
            </ModalBody>
          </Modal>

          

          <Modal id='collectionsModal' show={collectionsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionsModalIsOpen(false)}>
            <div id='removedAlert'></div>
            <Modal.Header id='collectionModalHeader' closeButton><h3>Collection</h3></Modal.Header>
            <CollectionPage />
          </Modal>






        <div id='homebg'>

     

          <script src="/__/firebase/8.9.1/firebase-app.js"></script>


          <script src="/__/firebase/8.9.1/firebase-analytics.js"></script>


          <script src="/__/firebase/init.js"></script>
          

    </div>
  
     


        </div>
        </div>
        </BrowserView>
        </div>
     


       
    </> 
  );

                    }
                  }
export default ProvinceDesktopNavbar;

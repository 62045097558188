
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch, } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment, } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText, ShieldShaded, Pen, People, Collection, PlayCircle, PauseCircle, HeartFill, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, Coin, Tree, Globe2, ChevronRight } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Axios from 'axios'
import { render } from '@testing-library/react';
import FullBillFeed from './FullBillFeed';
import FullWhiteHouseFeed from './FullWhiteHouseFeed';
import FullUSCourtNews from './FullUSCourtNews';
import FullWhiteHouseDocuments from './FullWhiteHouseDocuments';
import DailyDigest from './DailyDigest';
import State from './State';
import Illinois from './state/Illinois';
import CollectionPage from './CollectionPage';
import NewLaws from './NewLaws';
import BillsEnrolled from './BillsEnrolled';
import DepartmentOfState from './DepartmentOfState';
import DepartmentOfTreasury from './DepartmentOfTreasury';
import CourtAppeals from './CourtAppeals';
import Reports from './Reports';
import DepartmentOfDefense from './DepartmentOfDefense';
import DepartmentOfJustice from './DepartmentOfJustice';
import DepartmentOfTheInterior from './DepartmentOfTheInterior';
import DepartmentOfAgriculture from './DepartmentOfAgriculture';
import DepartmentOfCommerce from './DepartmentOfCommerce';
import DepartmentOfLabor from './DepartmentOfLabor';
import DepartmentOfHealthAndHumanServices from './DepartmentOfHealthAndHumanServices';
import DepartmentOfHousingAndUrbanDevelopment from './DepartmentOfHousingAndUrbanDevelopment';
import DepartmentOfTransportation from './DepartmentOfTransportation';
import DepartmentOfEnergy from './DepartmentOfEnergy';
import DepartmentOfEducation from './DepartmentOfEducation';
import DepartmentOfVeteranAffairs from './DepartmentOfVeteranAffairs';
import DepartmentOfHomelandSecurity from './DepartmentOfHomelandSecurity';
import EnvironmentalProtectionAgency from './EnvironmentalProtectionAgency';
import NationalAeronauticsAndSpaceAdministration from './NationalAeronauticsAndSpaceAdministration';
import TrendingBills from './TrendingBills';
import GAOReports from './GAOReports';
import LegislativeBranch from './LegislativeBranch';
import HouseComitteeOnAgriculture from './HouseComitteeOnAgriculture';
import HouseCommitteeOnAppropriations from './HouseCommitteeOnArmedServices';

import App from './App';
import Overlay from 'react-overlays/esm/Overlay';
import SecuritiesAndExchangeCommissionPressReleases from './SecuritiesAndExchangeCommissionPressReleases';
import SecuritiesAndExchangeCommissionSpeechesAndStatments from './SecuritiesAndExchangeCommissionSpeechesAndStatments';
import BureauOfEconomicAnalysis from './BureauOfEconomicAnalysis';
import EconomicIndicators from './EconomicIndicators';
import HouseCommitteeOnTheBudget from './HouseCommitteeOnTheBudget';
import HouseCommitteeOnEnergyAndCommerce from './HouseCommitteeOnEnergyAndCommerce';
import HouseCommitteeOnEducationAndLabor from './HouseCommitteeOnEducationAndLabor';
import HouseCommitteeOnFinacialServices from './HouseCommitteeOnFinacialServices';
import CongressionalBudgetOffice from './CongressionalBudgetOffice';
import HouseCommitteeOnIntelligence from './HouseCommitteeOnIntelligence';
import HouseCommitteeOnArmedServices from './HouseCommitteeOnArmedServices';
import UNTopStories from './UNTopStories';
import UNJournal from './UNJournal';
import UNNewsAmericas from './UNNewsAmericas';
import ArmsControl from './ArmsControl';
import { CreateFeedAPI, CreateFeedItemBillWithDescription, CreateFeedItemMaterials, Speak } from './Functions';
import DemocracyHumanRightsLabor from './DemocracyHumanRightsLabor';
import CounterTerrorism from './CounterTerrorism';
import DoSPress from './DoSPress';
import DiplomaticSecurity from './DiplomaticSecurity';
import DesktopNavbar from './DesktopNavbar';
import { createFederalRegisterDefenseDepartment, createFederalRegisterStateDepartment, createDepartmentOfState, 
  createDepartmentOfDefense, createHouseCommitteeOnArmedServices, createUNTopStories, createUNJournal, 
  createUNNewsAmericas, createDemocracyHumanRightsLabor, createDoSPress, createDiplomaticSecurity, 
  createCounterterrorism, createArmsControl, createHouseCommitteeOnIntelligence, CreateFeedItem} from './Functions';
import FederalRegisterDefenseDepartment from './FederalRegisterDefenseDepartment';
import FederalRegisterStateDepartment from './FederalRegisterStateDepartment';
import AddNoteModal from './AddNoteModal';
import MobileOffCanvas from './MobileOffCanvas';
import MobileTopNavbar from './MobileTopNavbar';
import SenateArmedServicesCommittee from './SenateArmedServicesCommittee';
import SenateForeignRelationsCommittee from './SenateForeignRelationsCommittee';
import SenateHomelandSecCommittee from './SenateHomelandSecCommittee';
import SenateIntelligenceCommittee from './SenateIntelligenceCommittee';
import HouseCommitteeOnArmedServicesMaterials from './HouseCommitteeOnArmedServicesMaterials';
import HouseCommitteeOnForeignAffairs from './HouseCommitteeOnForeignAffairs';
import HouseCommitteeOnForeignAffairsMaterials from './HouseCommitteeOnForeignAffairsMaterials';
import HouseCommitteeOnHomelandSecurityMaterials from './HouseCommitteeOnHomelandSecurityMaterials';
import HouseCommitteeOnHomelandSecurity from './HouseCommitteeOnHomelandSecurity';
import HouseCommitteeOnIntelligenceMaterials from './HouseCommitteeOnIntelligenceMaterials';

import MarinesPressReleases from './MarinesPressReleases';
import NavyDocuments from './NavyDocuments';
import ArmyDocuments from './ArmyDocuments';
import GenerateFeed from './GenerateFeed';


var carouselInterval = 30000;
var mobileCarouselInterval = 60000;

export const ForeignAffairsMobile= () =>
 {
   render()
   
   {
  const [show, setShow] = useState(true);

  
  const [fullscreen, setFullScreen] = useState(true);
  const [statesModalIsOpen, setStatesModalIsOpen] = useState(false)

  const [fullBillModalIsOpen, setFullBillModalIsOpen] = useState(false)

  const [reportModalIsOpen, setReportModalIsOpen] = useState(false)


  const [whiteHouseFeedModalIsOpen, setWhiteHouseFeedModalIsOpen] = useState(false)
  const [whiteHouseDocsModalIsOpen, setWhiteHouseDocsModalIsOpen] = useState(false)


  const [courtNewsModalIsOpen, setCourtNewsModalIsOpen] = useState(false)
  const [courtModalIsOpen, setCourtModalIsOpen] = useState(false)

  const [courtAppealsModalIsOpen, setCourtAppealsModalIsOpen] = useState(false)

  const [newLawsModalIsOpen, setNewLawsModalIsOpen] = useState(false)

  const [billsEnrolledModalIsOpen, setBillsEnrolledModalIsOpen] = useState(false)

  const [legislativePageBillsEnrolledModalIsOpen, setLegislativePageBillsEnrolledModalIsOpen] = useState(false)


  const [departmentOfStateModalIsOpen, setDepartmentOfStateModalIsOpen] = useState(false)
  const [departmentOfTreasuryModalIsOpen, setDepartmentOfTreasuryModalIsOpen] = useState(false)
  const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
  const [departmentOfJusticeModalIsOpen, setDepartmentOfJusticeModalIsOpen] = useState(false)
  const [departmentOfTheInteriorModalIsOpen, setDepartmentOfTheInteriorModalIsOpen] = useState(false)
  const [departmentOfAgricultureModalIsOpen, setDepartmentOfAgricultureModalIsOpen] = useState(false)
  const [departmentOfCommerceModalIsOpen, setDepartmentOfCommerceModalIsOpen] = useState(false)
  const [departmentOfLaborModalIsOpen, setDepartmentOfLaborModalIsOpen] = useState(false)
  const [departmentOfHealthAndHumanServicesModalIsOpen, setDepartmentOfHealthAndHumanServicesModalIsOpen] = useState(false)
  const [departmentOfHousingAndUrbanDevelopmentModalIsOpen, setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen] = useState(false)
  const [departmentOfTransportationModalIsOpen, setDepartmentOfTransportationModalIsOpen] = useState(false)
  const [departmentOfEnergyModalIsOpen, setDepartmentOfEnergyModalIsOpen] = useState(false)
  const [departmentOfEducationModalIsOpen, setDepartmentOfEducationModalIsOpen] = useState(false)
  const [departmentOfVeteransAffairsModalIsOpen, setDepartmentOfVeteransAffairsModalIsOpen] = useState(false)
  const [departmentOfHomelandSecurityModalIsOpen, setDepartmentOfHomelandSecurityModalIsOpen] = useState(false)
  const [epaModalIsOpen, setEPAModalIsOpen] = useState(false)
  const [nasaModalIsOpen, setNASAModalIsOpen] = useState(false)
  const [beaModalIsOpen, setBEAModalIsOpen] = useState(false)
  const [secPressModalIsOpen, setSECPressModalIsOpen] = useState(false)
  const [unTopStoriesModalIsOpen, setUNTopStoriesModalIsOpen] = useState(false)
  const [unJournalModalIsOpen, setUNJournalModalIsOpen] = useState(false)
  const [unNewsAmericasModalIsOpen, setNewsAmericasModalIsOpen] = useState(false)
  const [armsControlModalIsOpen, setArmsControlModalIsOpen] = useState(false)
    const [secSAndSModalIsOpen, setSECSAndSModalIsOpen] = useState(false)
  const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
  const [goaReportsModalIsOpen, setGAOReportsModalIsOpen] = useState(false)
  const [armyModalIsOpen, setArmyModalIsOpen] = useState(false)
  const [navyModalIsOpen, setNavyModalIsOpen] = useState(false)
  const [marinesModalIsOpen, setMarinesModalIsOpen] = useState(false)
  const [houseComitteeOnAgricultureModalIsOpen, setHouseComitteeOnAgricultureModalIsOpen] = useState(false)
  const [houseCommitteeOnAppropriationsModalIsOpen, setHouseCommitteeOnAppropriationsModalIsOpen] = useState(false)
  const [federalRegisterDefenseDepartmentModalIsOpen, setFederalRegisterDefenseDepartmentModalIsOpen] = useState(false)
  const [federalRegisterStateDepartmentModalIsOpen, setFederalRegisterStateDepartmentModalIsOpen] = useState(false)
  const [houseCommitteeOnTheBudgetModalIsOpen, setHouseCommitteeOnTheBudgetModalIsOpen] = useState(false)
  const [houseCommitteeOnEnergyAndCommerceModalIsOpen, setHouseCommitteeOnEnergyAndCommerceModalIsOpen] = useState(false)
  const [houseCommitteeOnEducationAndLaborModalIsOpen, setHouseCommitteeOnEducationAndLaborModalIsOpen] = useState(false)
  const [houseCommitteeOnEthicsModalIsOpen, setHouseCommitteeOnEthicsModalIsOpen] = useState(false)
  const [houseCommitteeOnFinancialServicesModalIsOpen, setHouseCommitteeOnFinancialServicesModalIsOpen] = useState(false)
  const [houseCommitteeOnForeignAffairsModalIsOpen, setHouseCommitteeOnForeignAffairsModalIsOpen] = useState(false)
  const [houseCommitteeOnHomelandSecurityModalIsOpen, setHouseCommitteeOnHomelandSecurityModalIsOpen] = useState(false)
  const [houseCommitteeOnHouseAdministrationModalIsOpen, setHouseCommitteeOnHouseAdministrationModalIsOpen] = useState(false)
  const [houseCommitteeOnTheJudiciaryModalIsOpen, setHouseCommitteeOnTheJudiciaryModalIsOpen] = useState(false)
  const [houseCommitteeOnNaturalResourcesModalIsOpen, setHouseCommitteeOnNaturalResourcesModalIsOpen] = useState(false)
  const [congressionalBudgetOfficeModalIsOpen, setCongressionalBudgetOfficeModalIsOpen] = useState(false)
  const [houseCommitteeOnIntelligenceModalIsOpen, setHouseCommitteeOnIntelligenceModalIsOpen] = useState(false)
  const [houseCommitteeOnArmedServicesModalIsOpen, setHouseCommitteeOnOnArmedServicesModalIsOpen] = useState(false)


  const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false)
  const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)
  const [counterterrorismModalIsOpen, setCounterterrorismModalIsOpen] = useState(false)
  const [democracyHumanRightsLaborModalIsOpen, setDemocracyHumanRightsLaborModalIsOpen] = useState(false)
  const [dOSPressModalIsOpen, setDOSPressModalIsOpen] = useState(false)
  const [diplomaticSecurityModalIsOpen, setDiplomaticSecurityModalIsOpen] = useState(false)

  const [declarationModalIsOpen, setDeclarationModalIsOpen] = useState(false)
  const [constitutionModalIsOpen, setConstitutionModalIsOpen] = useState(false)
  const [billOfRightsModalIsOpen, setBillOfRightsModalIsOpen] = useState(false)

  const [departmentOfStateDocsModalIsOpen, setDepartmentOfStateDocumentsModalIsOpen] = useState(false)
  const [departmentOfDefenseDocsModalIsOpen, setDepartmentOfDefenseDocumentsModalIsOpen] = useState(false)
  const [foreignAffairsMaterialsModalIsOpen, setForeignAffairsMaterialsModalIsOpen] = useState(false)
  const [homelandSecurityMaterialsModalIsOpen, setHomelandSecurityMaterialsModalIsOpen] = useState(false)
  const [intelligenceMatsModalIsOpen, setIntelligenceMatsModalIsOpen] = useState(false)
  const [armedServicesMatsModalIsOpen, setArmedServicesMatsModalIsOpen] = useState(false)
  const [senateHomelandModalIsOpen, setSenateHomelandModalIsOpen] = useState(false)
  const [senateForeignModalIsOpen, setSenateForeignModalIsOpen] = useState(false)
  const [senateIntelligenceModalIsOpen, setSenateIntelligenceModalIsOpen] = useState(false)
  const [senateArmedServicesModalIsOpen, setSenateArmedServicesModalIsOpen] = useState(false)
  const [stateDocumentsModalIsOpen, setStateDocumentsModalIsOpen] = useState(false)
  const [defenseDocumentsModalIsOpen, setDefenseDocumentsModalIsOpen] = useState(false)


  const [ModalIsOpen, setModalIsOpen] = useState(false)

  return(
    <>
      

              <MobileView>

              <MobileTopNavbar/>




        <div id='homebg'>
   

            <h5 id='branchHeadingMobile' ><Globe2 id= 'electionIconPadding'></Globe2><b> Foreign Affairs</b> </h5>
            

            <h5 onClick={() => window.open('/us/departmentofstate', "_self")} id='presidentialFeedHeadingMobile'>Department of State<ChevronRight id='clickChevron'></ChevronRight></h5>
                        
                        <Col>
                        <Carousel controls={false} >
                        
                  
                        
                        <Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>

                <h5 id='presidentialFeedHeadingMobile' onClick={() => setDepartmentOfStateModalIsOpen(true)}>News</h5>
                


               

                  <div class='courtNewsMobile' id='stateDepartment'></div>

                  {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=state&order_by=created_at', 
                  'stateDepartment', 'Department of State News', 'Executive', 
                            'N/A', '', GenerateFeed)}
        <Modal id='whiteHouseDocsModal' show={departmentOfStateModalIsOpen} 
        size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() =>setDepartmentOfStateModalIsOpen(false)}>
          <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>News</h3></Modal.Header>
          <GenerateFeed url='&skip=0&schema=united_states_of_america&table=department_of_state&order_by=created_at' 
                            name='Department of State News' branch='Executive' topic='N/A' buttonName='' />
        </Modal>
                </Col>


                
             

    
  
    </div>
   


</Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setStateDocumentsModalIsOpen(true)}>Documents</h5>


  <div class='courtNewsMobile' id='frState'></div>

  {CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=state-department', 'frState', 'Department of State Documents', 'Executive', 'N/A', FederalRegisterStateDepartment)}

</Col>

<Modal id='whiteHouseDocsModal' show={stateDocumentsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setStateDocumentsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of State Documents</h3></Modal.Header>
                  <FederalRegisterStateDepartment/>
                </Modal>
              
                <Modal id='whiteHouseDocsModal' show={defenseDocumentsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDefenseDocumentsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Defense Documents</h3></Modal.Header>
                  <FederalRegisterDefenseDepartment />
                </Modal>


    
  
    </div>
   


</Carousel.Item>


<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setDOSPressModalIsOpen(true)}>Press Briefing</h5>




<div class='courtNewsMobile' id='dosPress'></div>


{CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/foreignAffairs-xml/department-press-briefings.xml', 'dosPress', 'Department of State Press Briefing', 'N/A', 'Foreign Affairs', DoSPress)}

</Col>

<Modal id='whiteHouseDocsModal' show={dOSPressModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDOSPressModalIsOpen(false)}>
    <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of State Press Briefing</h3></Modal.Header>
    <DoSPress/>
  </Modal>




    
  
    </div>
   


</Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setArmsControlModalIsOpen(true)}>Arms Control and International Security</h5>




    <div class='courtNewsMobile' id='armsControl'></div>
          

    {CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/foreignAffairs-xml/arms-control-and-international-security.xml', 'armsControl', 'Arms Control and International Security', 'N/A', 'Foreign Affairs', ArmsControl)}

  </Col>


 
  <Modal id='whiteHouseDocsModal' show={armsControlModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setArmsControlModalIsOpen(false)}>
    <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Arms Control and International Security</h3></Modal.Header>
    <ArmsControl/>
  </Modal>




    
  
    </div>
   


</Carousel.Item>



<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>
<div id='viewFullPageButton'>
<Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/departmentofstate' >View all Department of State news</Button>
</div>
</Col>

</div>



</Carousel.Item>

                        
                        
                        </Carousel>
                        
                        </Col>




<Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setCounterterrorismModalIsOpen(true)}>Counterterrorism</h5>




    <div class='courtNewsMobile' id='counterterrorism'></div>
          

    {CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/foreignAffairs-xml/counterTerrorism.xml', 'counterterrorism', 'Counterterrorism', 'N/A', 'Foreign Affairs', CounterTerrorism)}

  </Col>



  <Modal id='whiteHouseDocsModal' show={counterterrorismModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCounterterrorismModalIsOpen(false)}>
    <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Counterterrorism</h3></Modal.Header>
    <CounterTerrorism/>
  </Modal>



<Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setDemocracyHumanRightsLaborModalIsOpen(true)}>Democracy, Human Rights, and Labor</h5>




    <div class='courtNewsMobile' id='democracyHumanRightsLabor'></div>
          

    {CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/foreignAffairs-xml/democracy-human-rights-and-labor.xml', 'democracyHumanRightsLabor', 'Democracy, Human Rights, and Labor', 'N/A', 'Foreign Affairs', DemocracyHumanRightsLabor)}

  </Col>



  <Modal id='whiteHouseDocsModal' show={democracyHumanRightsLaborModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDemocracyHumanRightsLaborModalIsOpen(false)}>
    <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Democracy, Human Rights, and Labor</h3></Modal.Header>
    <DemocracyHumanRightsLabor/>
  </Modal>










<Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setDiplomaticSecurityModalIsOpen(true)}>Diplomatic Security</h5>




    <div class='courtNewsMobile' id='diplomaticSecurity'></div>
          

    {CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/foreignAffairs-xml/diplomatic-security.xml', 'diplomaticSecurity', 'Diplomatic Security', 'N/A', 'Foreign Affairs', DiplomaticSecurity)}

  </Col>

  <Modal id='whiteHouseDocsModal' show={diplomaticSecurityModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDiplomaticSecurityModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Diplomatic Security</h3></Modal.Header>
                  <DiplomaticSecurity/>
                </Modal>

                <h5 onClick={() => window.open('/us/departmentofdefense', "_self")} id='presidentialFeedHeadingMobile'>Department of Defense<ChevronRight id='clickChevron'></ChevronRight></h5>
                        
                        <Col>
                        <Carousel controls={false} >
                        
                  
                        
                        <Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setDepartmentOfDefenseModalIsOpen(true)}>News</h5>




  <div class='courtNewsMobile' id='defenseDepartment'></div>
  {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=defense&order_by=created_at', 'defenseDepartment', 'Department of Defense', 'Executive', 'Foreign Affairs', GenerateFeed)}

<Modal id='whiteHouseDocsModal' show={departmentOfDefenseModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfDefenseModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Defense</h3></Modal.Header>
                  <GenerateFeed url='&skip=0&schema=united_states_of_america&table=defense&order_by=created_at' 
                            name='Department of Defense News' branch='Executive' topic='N/A' buttonName='' />
                </Modal>

  </Col>


    
  
    </div>
   


</Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setDefenseDocumentsModalIsOpen(true)}>Documents</h5>


  <div class='courtNewsMobile' id='frDefense'></div>

  {CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=defense-department', 'frDefense', 'Department of Defense Documents', 'Executive', 'Foreign Affairs', FederalRegisterDefenseDepartment)}

</Col>
              
                <Modal id='whiteHouseDocsModal' show={defenseDocumentsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDefenseDocumentsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Defense Documents</h3></Modal.Header>
                  <FederalRegisterDefenseDepartment />
                </Modal>


    
  
    </div>
   


</Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setArmyModalIsOpen(true)}>Army Documents</h5>


  <div class='courtNewsMobile' id='armyDocs'></div>

  {CreateFeedItemBillWithDescription('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=army-department', 'armyDocs', 'Army Documents', 'Executive', 'N/A', ArmyDocuments)}

</Col>
              
                <Modal id='whiteHouseDocsModal' show={armyModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setArmyModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Army Documents</h3></Modal.Header>
                  <ArmyDocuments />
                </Modal>


    
  
    </div>
   


</Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setNavyModalIsOpen(true)}>Navy Documents</h5>


  <div class='courtNewsMobile' id='navyDocs'></div>

  {CreateFeedItemBillWithDescription('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=navy-department', 'navyDocs', 'Navy Documents', 'Executive', 'N/A', NavyDocuments)}

</Col>
              
                <Modal id='whiteHouseDocsModal' show={navyModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setNavyModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Navy Documents</h3></Modal.Header>
                  <NavyDocuments />
                </Modal>


    
  
    </div>
   


</Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setMarinesModalIsOpen(true)}>Marines Press Releases</h5>


  <div class='courtNewsMobile' id='marinesPress'></div>

  {CreateFeedItemBillWithDescription('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/executive-xml/marine.xml', 'marinesPress', 'Marines Press Releases', 'Executive', 'N/A', MarinesPressReleases)}

</Col>
              
                <Modal id='whiteHouseDocsModal' show={marinesModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setMarinesModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Marines Press Releases</h3></Modal.Header>
                  <MarinesPressReleases />
                </Modal>


    
  
    </div>
   


</Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>
<div id='viewFullPageButton'>
<Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/departmentofdefense' >View all Department of Defense news</Button>
</div>
</Col>

</div>



</Carousel.Item>

                        
                        
                        </Carousel>
                        
                        </Col>
           
         

                  <Modal id='whiteHouseDocsModal' show={departmentOfDefenseModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfDefenseModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Defense</h3></Modal.Header>
                  <DepartmentOfDefense />
                </Modal>


                <Col>
                <h5 id='presidentialFeedHeadingMobile' onClick={() => setHouseCommitteeOnForeignAffairsModalIsOpen(true)}>House Committee on Foreign Affairs</h5>
                        <div class='courtNewsMobile'id='houseCommitteeOnForeignAffairs'></div>
                     



                        {CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=FA00', 'houseCommitteeOnForeignAffairs', 'House Committee on Foreign Affairs', 'Legislative', 'Foreign Affairs', HouseCommitteeOnForeignAffairs)}


                        <Modal id='whiteHouseDocsModal' show={houseCommitteeOnForeignAffairsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHouseCommitteeOnForeignAffairsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>House Committee on Foreign Affairs</h3></Modal.Header>
                  <HouseCommitteeOnForeignAffairs/>
                </Modal>

                </Col>


                <Col>
                <h5 id='presidentialFeedHeadingMobile' onClick={() => setForeignAffairsMaterialsModalIsOpen(true)}>House Committee on Foreign Affairs Materials</h5>
                        <div class='courtNewsMobile'id='houseCommitteeOnForeignAffairsMaterials'></div>
                     



                        {CreateFeedItemMaterials('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/houseCommittees/Committee%20on%20Foreign%20Affairs.xml', 'houseCommitteeOnForeignAffairsMaterials', 'House Committee on Foreign Affairs Materials', 'Legislative', 'Foreign Affairs', HouseCommitteeOnForeignAffairsMaterials)}



                        <Modal id='whiteHouseDocsModal' show={foreignAffairsMaterialsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setForeignAffairsMaterialsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>House Committee on Foreign Affairs Materials</h3></Modal.Header>
                  <HouseCommitteeOnForeignAffairsMaterials/>
                </Modal>

                </Col>


                <Col>
                <h5 id='presidentialFeedHeadingMobile' onClick={() => setHouseCommitteeOnHomelandSecurityModalIsOpen(true)}>House Committee on Homeland Security</h5>
                        <div class='courtNewsMobile'id='houseCommitteeOnHomelandSecurity'></div>
                    


                        {CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=HM00', 'houseCommitteeOnHomelandSecurity', 'House Committee on Homeland Security', 'Legislative', 'Foreign Affairs', HouseCommitteeOnHomelandSecurity)}


                        <Modal id='whiteHouseDocsModal' show={houseCommitteeOnHomelandSecurityModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHouseCommitteeOnHomelandSecurityModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>House Committee on Homeland Security</h3></Modal.Header>
                  <HouseCommitteeOnHomelandSecurity/>
                </Modal>

                </Col>

                <Col>
                <h5 id='presidentialFeedHeadingMobile' onClick={() => setHomelandSecurityMaterialsModalIsOpen(true)}>House Committee on Homeland Security Materials</h5>
                        <div class='courtNewsMobile'id='houseCommitteeOnHomelandSecurityMaterials'></div>
                    


                        {CreateFeedItemMaterials('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/houseCommittees/Committee%20on%20Homeland%20Security.xml', 'houseCommitteeOnHomelandSecurityMaterials', 'House Committee on Homeland Security Materials', 'Legislative', 'Foreign Affairs', HouseCommitteeOnHomelandSecurityMaterials)}


                        <Modal id='whiteHouseDocsModal' show={homelandSecurityMaterialsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHomelandSecurityMaterialsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>House Committee on Homeland Security Materials</h3></Modal.Header>
                  <HouseCommitteeOnHomelandSecurityMaterials/>
                </Modal>

                </Col>
          
 


<Col>
                          <h5 id='presidentialFeedHeadingMobile' onClick={() => setHouseCommitteeOnIntelligenceModalIsOpen(true)}>Permanent Select Committee on Intelligence</h5>
                        <div class='courtNewsMobile'id='houseCommitteeOnIntelligence'></div>
                      


                        {CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=IG00', 'houseCommitteeOnIntelligence', 'Permanent Select Committee on Intelligence', 'Legislative', 'N/A', HouseCommitteeOnIntelligence)}

      
                          </Col>


                          <Col>
                          <h5 id='presidentialFeedHeadingMobile' onClick={() => setIntelligenceMatsModalIsOpen(true)}>Permanent Select Committee on Intelligence Materials</h5>
                        <div class='courtNewsMobile'id='houseCommitteeOnIntelligenceMaterials'></div>
                      


                        {CreateFeedItemMaterials('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/houseCommittees/Permanent%20Select%20Committee%20on%20Intelligence.xml', 'houseCommitteeOnIntelligenceMaterials', 'Permanent Select Committee on Intelligence Materials', 'Legislative', 'N/A', HouseCommitteeOnIntelligenceMaterials)}

      

                        <Modal id='whiteHouseDocsModal' show={intelligenceMatsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setIntelligenceMatsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Permanent Select Committee on Intelligence Materials</h3></Modal.Header>
                  <HouseCommitteeOnIntelligenceMaterials/>
                </Modal>

                          </Col>
                          

                          <Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setHouseCommitteeOnOnArmedServicesModalIsOpen(true)}>House Committee on Armed Services</h5>




     <div class='courtNewsMobile' id='houseComitteeOnArmedServices'></div>

     {CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/house_committee-xml/Committee_on_Armed_Services_Meeting_Feed.xml	', 'houseComitteeOnArmedServices', 'House Committee on Armed Services', 'N/A', 'Foreign Affairs', HouseCommitteeOnArmedServices)}




   </Col>

   <Col>
           <div id='columnRightPadding'>
           <h5 id='presidentialFeedHeadingMobile' onClick={() => setArmedServicesMatsModalIsOpen(true)}>House Committee on Armed Services Materials</h5>
             <div id='houseCommitteeOnArmedServicesMaterials'></div>
             
             {CreateFeedItemMaterials('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/houseCommittees/Committee%20on%20Armed%20Services.xml', 'houseCommitteeOnArmedServicesMaterials', 'House Committee on Armed Services Materials', 'Legislative', 'N/A', HouseCommitteeOnArmedServicesMaterials)}


           </div>

           <Modal id='whiteHouseDocsModal' show={armedServicesMatsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setArmedServicesMatsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>House Committee on Armed Services Materials</h3></Modal.Header>
                  <HouseCommitteeOnArmedServicesMaterials/>
                </Modal>
         </Col>


                  

                          <Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setSenateHomelandModalIsOpen(true)}>Senate Committee on Homeland Security and Governance Materials</h5>
                      <div class='courtNewsMobile'id='senateHomelandCommittee'></div>
                
                        {CreateFeedItemMaterials('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/senateCommittees/Committee%20on%20Homeland%20Security%20and%20Governmental%20Affairs.xml', 'senateHomelandCommittee', 'Senate Committee on Homeland Security and Governance', 'Legislative', 'N/A', SenateHomelandSecCommittee)}

                        <Modal id='whiteHouseDocsModal' show={senateHomelandModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setSenateHomelandModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Senate Committee on Homeland Security and Governance Materials</h3></Modal.Header>
                  <SenateHomelandSecCommittee/>
                </Modal>

</Col>

          
            <Col>
<h5 id='presidentialFeedHeadingMobile' onClick={() => setSenateForeignModalIsOpen(true)}>Senate Committee on Foreign Relations Materials</h5>
                      <div class='courtNewsMobile'id='senateForeignRelationsCommittee'></div>
                
                        {CreateFeedItemMaterials('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/senateCommittees/Committee%20on%20Foreign%20Relations.xml', 'senateForeignRelationsCommittee', 'Senate Committee on Foreign Relations', 'Legislative', 'N/A', SenateForeignRelationsCommittee)}



                        <Modal id='whiteHouseDocsModal' show={senateForeignModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setSenateForeignModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Senate Committee on Foreign Relations Materials</h3></Modal.Header>
                  <SenateForeignRelationsCommittee/>
                </Modal>

</Col>


                <Col>

<h5 id='presidentialFeedHeadingMobile' onClick={() => setSenateIntelligenceModalIsOpen(true)}>Senate Select Committee on Intelligence Materials</h5>
                      <div class='courtNewsMobile'id='senateIntelligenceCommittee'></div>
                
                        {CreateFeedItemMaterials('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/senateCommittees/Select%20Committee%20on%20Intelligence.xml', 'senateIntelligenceCommittee', 'Select Committee on Intelligence', 'Legislative', 'N/A', SenateIntelligenceCommittee)}


                        <Modal id='whiteHouseDocsModal' show={senateIntelligenceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setSenateIntelligenceModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Senate Committee Intelligence Materials</h3></Modal.Header>
                  <SenateIntelligenceCommittee/>
                </Modal>


</Col>


              
                <Modal id='fullBillModal' show={houseCommitteeOnIntelligenceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHouseCommitteeOnIntelligenceModalIsOpen(false)}>
                  <Modal.Header id='billModalHeader' closeButton><h3>Permanent Select Committee on Intelligence</h3></Modal.Header>
                  <HouseCommitteeOnIntelligence/>
                </Modal>

   
            

                <Col>
              
              
              <h5 id='presidentialFeedHeadingMobile' onClick={() => setSenateArmedServicesModalIsOpen(true)}>Senate Committee on Armed Services Materials</h5>
                      <div class='courtNewsMobile'id='senateArmedServicesCommittee'></div>
                
                        {CreateFeedItemMaterials('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/senateCommittees/Committee%20on%20Armed%20Services.xml', 'senateArmedServicesCommittee', 'Senate Committee on Armed Services', 'Legislative', 'Foreign Affairs', SenateArmedServicesCommittee)}

                        <Modal id='whiteHouseDocsModal' show={senateArmedServicesModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setSenateArmedServicesModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Senate Committee on Armed Services Materials</h3></Modal.Header>
                  <SenateArmedServicesCommittee/>
                </Modal>

              
              </Col>


   
                <Modal id='whiteHouseDocsModal' show={houseCommitteeOnArmedServicesModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHouseCommitteeOnOnArmedServicesModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>House Committee on Armed Services</h3></Modal.Header>
                  <HouseCommitteeOnArmedServices />
                </Modal>

            



  <Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setUNTopStoriesModalIsOpen(true)}>United Nations News</h5>




    <div class='courtNewsMobile' id='unTopStories'></div>

    {CreateFeedItem('https://news.un.org/feed/subscribe/en/news/all/rss.xml', 'unTopStories', 'United Nations News', 'N/A', 'Foreign Affairs', UNTopStories)}

  </Col>

  <Modal id='whiteHouseDocsModal' show={unTopStoriesModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setUNTopStoriesModalIsOpen(false)}>
    <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>United Nations News</h3></Modal.Header>
    <UNTopStories />
  </Modal>





<Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setUNJournalModalIsOpen(true)}>United Nations Journal</h5>



    <div class='courtNewsMobile' id='unJournal'></div>
          

    {CreateFeedItem('https://undocs.org/rss/unjournal.xml', 'unJournal', 'United Nations Journal', 'N/A', 'Foreign Affairs', UNJournal)}

  </Col>


  
  <Modal id='whiteHouseDocsModal' show={unJournalModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setUNJournalModalIsOpen(false)}>
    <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>United Nations Journal</h3></Modal.Header>
    <UNJournal />
  </Modal>




<Col>


 <h5 id='presidentialFeedHeadingMobile' onClick={() => setNewsAmericasModalIsOpen(true)}>UN News - Americas</h5>




    <div class='courtNewsMobile' id='unNewsAmericas'></div>
          

    {CreateFeedItem('https://news.un.org/feed/subscribe/en/news/region/americas/feed/rss.xml', 'unNewsAmericas', 'UN News - Americas', 'N/A', 'Foreign Affairs', UNNewsAmericas)}

  </Col>


  
  <Modal id='whiteHouseDocsModal' show={unNewsAmericasModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setNewsAmericasModalIsOpen(false)}>
    <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>UN News - Americas</h3></Modal.Header>
    <UNNewsAmericas />
  </Modal>






          
       
            <br></br><br></br><br></br><br></br><br></br><br></br><Navbar id='navbarMobileBottom' fixed="bottom">    

          


       
<div id='changeBranchDropdownMobile'>

           <MobileOffCanvas/>

            </div>

           


           
 
             
 
 
 
 
 
 
</Navbar>
          </div>

              </MobileView>
              
    </>
  )
}
 }



  






export default ForeignAffairsMobile;

import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch, } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment, } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText, ShieldShaded, Pen, PauseCircle, PlayCircle, People, HeartFill, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, ChevronRight } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Axios from 'axios'
import { render } from '@testing-library/react';
import FullBillFeed from './FullBillFeed';
import FullWhiteHouseFeed from './FullWhiteHouseFeed';
import FullUSCourtNews from './FullUSCourtNews';
import FullWhiteHouseDocuments from './FullWhiteHouseDocuments';
import DailyDigest from './DailyDigest';
import State from './State';
import Illinois from './state/Illinois';
import CollectionPage from './CollectionPage';
import NewLaws from './NewLaws';
import BillsEnrolled from './BillsEnrolled';
import DepartmentOfState from './DepartmentOfState';
import DepartmentOfTreasury from './DepartmentOfTreasury';
import CourtAppeals from './CourtAppeals';
import Reports from './Reports';
import DepartmentOfDefense from './DepartmentOfDefense';
import DepartmentOfJustice from './DepartmentOfJustice';
import DepartmentOfTheInterior from './DepartmentOfTheInterior';
import DepartmentOfAgriculture from './DepartmentOfAgriculture';
import DepartmentOfCommerce from './DepartmentOfCommerce';
import DepartmentOfLabor from './DepartmentOfLabor';
import DepartmentOfHealthAndHumanServices from './DepartmentOfHealthAndHumanServices';
import DepartmentOfHousingAndUrbanDevelopment from './DepartmentOfHousingAndUrbanDevelopment';
import DepartmentOfTransportation from './DepartmentOfTransportation';
import DepartmentOfEnergy from './DepartmentOfEnergy';
import DepartmentOfEducation from './DepartmentOfEducation';
import DepartmentOfVeteranAffairs from './DepartmentOfVeteranAffairs';
import DepartmentOfHomelandSecurity from './DepartmentOfHomelandSecurity';
import EnvironmentalProtectionAgency from './EnvironmentalProtectionAgency';
import NationalAeronauticsAndSpaceAdministration from './NationalAeronauticsAndSpaceAdministration';
import TrendingBills from './TrendingBills';
import GAOReports from './GAOReports';
import LegislativeBranch from './LegislativeBranch';
import HouseComitteeOnAgriculture from './HouseComitteeOnAgriculture';
import HouseCommitteeOnAppropriations from './HouseCommitteeOnArmedServices';
import HouseCommitteeOnIntelligence from './HouseCommitteeOnIntelligence'
import App from './App';
import Overlay from 'react-overlays/esm/Overlay';
import ExecutiveOrders from './ExecutiveOrders';
import PresidentialProclamations from './PresidentialProclamations';
import NationalScienceFoundation from './NationalScienceFoundation';

import{ whiteHouseSearch, createPresidentialProclamations, createExecutiveOrders, createWhiteHouseDocuments, createWhiteHouseFeed,
  createWhiteHouseFeedMobile,createDepartmentOfState,
  createDepartmentOfDefense, 
  createDepartmentOfJustice, createDepartmentOfTheInterior,
  createDepartmentOfAgriculture, createDepartmentOfCommerce,
  createDepartmentOfCommerceMobile, createDepartmentOfLabor,
  createDepartmentOfHealthAndHumanServices, createDepartmentOfHousingAndUrbanDevelopment, createDepartmentOfEducation
  ,  createDepartmentOfVeteransAffairs, createDepartmentOfHomelandSecurity
  , createEnvironmentalProtectionAgency,
  createDepartmentOfTreasury, createNationalScienceFoundation, 
   createEnvironmentalProtectionAgencyMobile, createNationalAeronauticsAndSpaceAdministration, Speak, createFederalRegisterDefenseDepartment, createFederalRegisterStateDepartment, createFederalRegisterTreasuryDepartment, createFederalRegisterJusticeDepartment, createFederalRegisterInteriorDepartment, createFederalRegisterAgricultureDepartment, createFederalRegisterCommerceDepartment, createFederalRegisterLaborDepartment, createFederalRegisterHealthAndHumanServicesDepartment, createFederalRegisterHousingAndUrbanDevelopmentDepartment, createFederalRegisterHomelandSecurityDepartment, createFederalRegisterEducationDepartment, createFederalRegisterVeteransAffairsDepartment,
   CreateFeedItem, CreateFeedItemBillUpdates, CreateFeedItemWithDocButton,
   CreateFeedItemPresidentialDocuments, CreateFeedItemExecutiveOrders, CreateFeedItemCongressionalReports, CreateFeedItemBuild } from './Functions'
import ExecutiveBranchMobile from './ExecutiveBranchMobile';
import DesktopNavbar from './DesktopNavbar';
import FederalRegisterDefenseDepartment from './FederalRegisterDefenseDepartment';
import FederalRegisterStateDepartment from './FederalRegisterStateDepartment';
import FederalRegisterTreasuryDepartment from './FederalRegisterTreasuryDepartment';
import FederalRegisterJusticeDepartment from './FederalRegisterJusticeDepartment';
import FederalRegisterInteriorDepartment from './FederalRegisterInteriorDepartment';
import FederalRegisterAgricultureDepartment from './FederalRegisterAgricultureDepartment';
import FederalRegisterCommerceDepartment from './FederalRegisterCommerceDepartment';
import FederalRegisterLaborDepartment from './FederalRegisterLaborDepartment';
import FederalRegisterHealthAndHumanServicesDepartment from './FederalRegisterHealthAndHumanServicesDepartment';
import FederalRegisterHousingAndUrbanDevelopmentDepartment from './FederalRegisterHousingAndUrbanDevelopmentDepartment';
import FederalRegisterVeteransAffairsDepartment from './FederalRegisterVeteransAffairsDepartment';
import FederalRegisterHomelandSecurityDepartment from './FederalRegisterHomelandSecurityDepartment';
import FederalRegisterEducationDepartment from './FederalRegisterEducationDepartment';
import CouncilOfEconomicAdvisers from './CouncilOfEconomicAdvisers';
import CouncilOnEnvironmentQuality from './CouncilOnEnvironmentQuality';
import DomesticPolicyCouncil from './DomesticPolicyCouncil';
import GenderPolicyCouncil from './GenderPolicyCouncil';
import NationalEconomicCouncil from './NationalEconomicCouncil';
import NationalSecurityCouncil from './NationalSecurityCouncil';
import OfficeOfIntergovernmentalAffairs from './OfficeOfIntergovernmentalAffairs';
import OfficeOfManagmentAndBudget from './OfficeOfManagmentAndBudget';
import OfficeOfNationalDrugControlPolicy from './OfficeOfNationalDrugControlPolicy';
import OfficeOfPublicEngagement from './OfficeOfPublicEngagement';
import OfficeOfScienceAndTechnologyPolicy from './OfficeOfScienceAndTechnologyPolicy';
import OfficeOfNationalCyberDirector from './OfficeOfNationalCyberDirector';
import PresidentialPersonnelOffice from './PresidentialPersonnelOffice';
import ExecutiveOfficeMobile from './ExecutiveOfficeMobile';
import HouseMobile from './HouseMobile';
import HouseCommitteeOnArmedServices from './HouseCommitteeOnArmedServices';
import HouseCommitteeOnEducationAndLabor from './HouseCommitteeOnEducationAndLabor';
import HouseCommitteeOnEnergyAndCommerce from './HouseCommitteeOnEnergyAndCommerce';
import HouseCommitteeOnEthics from './HouseCommitteeOnEthics';
import HouseCommitteeOnFinacialServices from './HouseCommitteeOnFinacialServices';
import HouseCommitteeOnForeignAffairs from './HouseCommitteeOnForeignAffairs';
import HouseCommitteeOnHomelandSecurity from './HouseCommitteeOnHomelandSecurity';
import HouseCommitteeOnHouseAdministration from './HouseCommitteeOnHouseAdministration';
import HouseCommitteeOnNaturalResources from './HouseCommitteeOnNaturalResources';
import HouseCommitteeOnOversightAndReform from './HouseCommitteeOnOversightAndReform';
import HouseCommitteeOnRules from './HouseCommitteeOnRules';
import HouseCommitteeOnScienceSpaceAndTechnology from './HouseCommitteeOnScienceSpaceAndTechnology';
import HouseCommitteeOnSmallBusiness from './HouseCommitteeOnSmallBusiness';
import HouseCommitteeOnTransportationAndInfrastructure from './HouseCommitteeOnTransportationAndInfrastructure';
import HouseCommitteeOnVeteransAffairs from './HouseCommitteeOnVeteransAffairs';
import HouseCommitteeOnTheBudget from './HouseCommitteeOnTheBudget';
import HouseCommitteeOnTheJudiciary from './HouseCommitteeOnTheJudiciary';
import BuildMaterialsFeed from './BuildMaterialsFeed';
import HouseComitteeOnAgricultureMaterials from './HouseComitteeOnAgricultureMaterials';
import HouseCommitteeOnAppropriationsMaterials from './HouseCommitteeOnAppropriationsMaterials';
import HouseCommitteeOnArmedServicesMaterials from './HouseCommitteeOnArmedServicesMaterials';
import HouseCommitteeOnEducationAndLaborMaterials from './HouseCommitteeOnEducationAndLaborMaterials';
import HouseCommitteeOnEnergyAndCommerceMaterials from './HouseCommitteeOnEnergyAndCommerceMaterials';
import HouseCommitteeOnEthicsMaterials from './HouseCommitteeOnEthicsMaterials';
import HouseCommitteeOnFinacialServicesMaterials from './HouseCommitteeOnFinacialServicesMaterials';
import HouseCommitteeOnForeignAffairsMaterials from './HouseCommitteeOnForeignAffairsMaterials';
import HouseCommitteeOnHomelandSecurityMaterials from './HouseCommitteeOnHomelandSecurityMaterials';
import HouseCommitteeOnHouseAdministrationMaterials from './HouseCommitteeOnHouseAdministrationMaterials';
import HouseCommitteeOnOversightAndReformMaterials from './HouseCommitteeOnOversightAndReformMaterials';
import HouseCommitteeOnRulesMaterials from './HouseCommitteeOnRulesMaterials';
import HouseCommitteeOnScienceSpaceAndTechnologyMaterials from './HouseCommitteeOnScienceSpaceAndTechnologyMaterials';
import HouseCommitteeOnSmallBusinessMaterials from './HouseCommitteeOnSmallBusinessMaterials';
import HouseCommitteeOnVeteransAffairsMaterials from './HouseCommitteeOnVeteransAffairsMaterials';
import HouseCommitteeOnWaysAndMeans from './HouseCommitteeOnWaysAndMeans';
import HouseCommitteeOnWaysAndMeansMaterials from './HouseCommitteeOnWaysAndMeansMaterials';
import HouseCommitteeOnTheBudgetMaterials from './HouseCommitteeOnTheBudgetMaterials';
import HouseCommitteeOnTheJudiciaryMaterials from './HouseCommitteeOnTheJudiciaryMaterials';
import HouseCommitteeOnIntelligenceMaterials from './HouseCommitteeOnIntelligenceMaterials';
import HouseCommitteeToInvestigateTheCapitol from './HouseCommitteeToInvestigateTheCapitol';
import HouseCommitteeToInvestigateTheCapitolMaterials from './HouseCommitteeToInvestigateTheCapitolMaterials';
import HouseFeed from './HouseFeed';
import HouseFloor from './HouseFloor';
import HouseCommitteeOnInterstateAndForeignCommerceMaterials from './HouseCommitteeOnInterstateAndForeignCommerceMaterials';
import HouseCommitteeOnTransportationAndInfrastructureMaterials from './HouseCommitteeOnTransportationAndInfrastructureMaterials';
import HouseOfRepsDisplay from './HouseOfRepsDisplay';
import HouseOfRepsDisplayOnPage from './HouseOfRepsDisplayOnPage';





var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 60000;





  

 


  


export const HousePage = () =>
 {
   render()
   
   {
  const [show, setShow] = useState(true);

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [fullscreen, setFullScreen] = useState(true);


  const [fullBillModalIsOpen, setFullBillModalIsOpen] = useState(false)

  const [reportModalIsOpen, setReportModalIsOpen] = useState(false)


  const [whiteHouseFeedModalIsOpen, setWhiteHouseFeedModalIsOpen] = useState(false)
  const [whiteHouseDocsModalIsOpen, setWhiteHouseDocsModalIsOpen] = useState(false)


  const [courtNewsModalIsOpen, setCourtNewsModalIsOpen] = useState(false)
  const [courtModalIsOpen, setCourtModalIsOpen] = useState(false)

  const [courtAppealsModalIsOpen, setCourtAppealsModalIsOpen] = useState(false)

  const [newLawsModalIsOpen, setNewLawsModalIsOpen] = useState(false)

  const [billsEnrolledModalIsOpen, setBillsEnrolledModalIsOpen] = useState(false)

  const [legislativePageBillsEnrolledModalIsOpen, setLegislativePageBillsEnrolledModalIsOpen] = useState(false)


  const [departmentOfStateModalIsOpen, setDepartmentOfStateModalIsOpen] = useState(false)
  const [departmentOfTreasuryModalIsOpen, setDepartmentOfTreasuryModalIsOpen] = useState(false)
  const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
  const [departmentOfJusticeModalIsOpen, setDepartmentOfJusticeModalIsOpen] = useState(false)
  const [departmentOfTheInteriorModalIsOpen, setDepartmentOfTheInteriorModalIsOpen] = useState(false)
  const [departmentOfAgricultureModalIsOpen, setDepartmentOfAgricultureModalIsOpen] = useState(false)
  const [departmentOfCommerceModalIsOpen, setDepartmentOfCommerceModalIsOpen] = useState(false)
  const [departmentOfLaborModalIsOpen, setDepartmentOfLaborModalIsOpen] = useState(false)
  const [departmentOfHealthAndHumanServicesModalIsOpen, setDepartmentOfHealthAndHumanServicesModalIsOpen] = useState(false)
  const [departmentOfHousingAndUrbanDevelopmentModalIsOpen, setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen] = useState(false)
  const [departmentOfTransportationModalIsOpen, setDepartmentOfTransportationModalIsOpen] = useState(false)
  const [departmentOfEnergyModalIsOpen, setDepartmentOfEnergyModalIsOpen] = useState(false)
  const [departmentOfEducationModalIsOpen, setDepartmentOfEducationModalIsOpen] = useState(false)
  const [departmentOfVeteransAffairsModalIsOpen, setDepartmentOfVeteransAffairsModalIsOpen] = useState(false)
  const [departmentOfHomelandSecurityModalIsOpen, setDepartmentOfHomelandSecurityModalIsOpen] = useState(false)
  const [epaModalIsOpen, setEPAModalIsOpen] = useState(false)
  const [nsfModalIsOpen, setNSFModalIsOpen] = useState(false)
  const [nasaModalIsOpen, setNASAModalIsOpen] = useState(false)
  const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
  const [goaReportsModalIsOpen, setGAOReportsModalIsOpen] = useState(false)
  const [executiveOrdersModalIsOpen, setExecutiveOrdersModalIsOpen] = useState(false)
  const [presidentialProclamationsModalIsOpen, setPresidentialProclamationsModalIsOpen] = useState(false)

  const [federalRegisterDefenseDepartmentModalIsOpen, setFederalRegisterDefenseDepartmentModalIsOpen] = useState(false)
  const [federalRegisterStateDepartmentModalIsOpen, setFederalRegisterStateDepartmentModalIsOpen] = useState(false)
  const [federalRegisterTreasuryDepartmentModalIsOpen, setFederalRegisterTreasuryDepartmentModalIsOpen] = useState(false)
  const [federalRegisterJusticeDepartmentModalIsOpen, setFederalRegisterJusticeDepartmentModalIsOpen] = useState(false)
  const [federalRegisterInteriorDepartmentModalIsOpen, setFederalRegisterInteriorDepartmentModalIsOpen] = useState(false)
  const [federalRegisterAgricultureDepartmentModalIsOpen, setFederalRegisterAgricultureDepartmentModalIsOpen] = useState(false)
  const [federalRegisterVeteransAffairsDepartmentModalIsOpen, setFederalRegisterVeteransAffairsDepartmentModalIsOpen] = useState(false)
  const [federalRegisterHomelandSecurityDepartmentModalIsOpen, setFederalRegisterHomelandSecurityDepartmentModalIsOpen] = useState(false)
  const [federalRegisterCommerceDepartmentModalIsOpen, setFederalRegisterCommerceDepartmentModalIsOpen] = useState(false)
  const [federalRegisterLaborDepartmentModalIsOpen, setFederalRegisterLaborDepartmentModalIsOpen] = useState(false)
  const [federalRegisterEducationDepartmentModalIsOpen, setFederalRegisterEducationDepartmentModalIsOpen] = useState(false)
  const [federalRegisterHealthAndHumanServicesDepartmentModalIsOpen, setFederalRegisterHealthAndHumanServicesDepartmentModalIsOpen] = useState(false)
  const [federalRegisterHousingAndUrbanDevelopmentDepartmentModalIsOpen, setFederalRegisterHousingAndUrbanDevelopmentDepartmentModalIsOpen] = useState(false)
  const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false)
  const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)



  const [declarationModalIsOpen, setDeclarationModalIsOpen] = useState(false)
  const [constitutionModalIsOpen, setConstitutionModalIsOpen] = useState(false)
  const [billOfRightsModalIsOpen, setBillOfRightsModalIsOpen] = useState(false)


  return(
    <>
      <BrowserView>
        <div id='homePage'>

     

        <div id='homebg'>

          <DesktopNavbar/>
              <div id='sectionHeading'>
            
            <h3 id='branchHeading'  onClick={() => window.open('/us/executiveoffice', "_self")} >House </h3>
            
            </div>

            <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>

                        <h4 id='feedHeadingBillsEnrolled'><b>House of Representatives</b></h4>
                          
                       
                          <HouseOfRepsDisplayOnPage/>
                        
                        
                  
                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>

                    
                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
                </div>

            <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>

                        <h4 id='feedHeadingBillsEnrolled'><b>Recent Materials</b></h4>
                          <div id='fullHouseFeed'></div>
                       
                          {CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/chamber/House_Feed.xml', 'fullHouseFeed', 'House Feed', 'Legislative', 'N/A', HouseFeed)}
                        
                        
                  
                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>

                        <h4 id='billFeedHeading'><b>House Floor Activity Today</b></h4>
                  <div class='billsDesktop' id='houseFloor'></div>
                      
                      {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
             {CreateFeedItem('https://clerk.house.gov/Home/Feed', 'houseFloor', 'House Floor Activity Today', 'Legislative', 'N/A', HouseFloor)}

                    
                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
                </div>
                     
           
            <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>

                        <h4 id='feedHeadingBillsEnrolled'><b>Committee on Agriculture</b></h4>
                          <div id='houseComitteeOnAgriculture'></div>
                       
                          {CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=AG00', 'houseComitteeOnAgriculture', 'House Committee on Agriculture', 'Legislative', 'Environment', HouseComitteeOnAgriculture)}
                        
                        
                  
                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                          <h4 id='presidentialFeedHeading'><b>Committee on Agriculture Materials</b></h4>
                          <div id='houseComitteeOnAgricultueMaterials'></div>
                          
                          {CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/houseCommittees/Committee%20on%20Agriculture.xml', 'houseComitteeOnAgricultueMaterials', 'House Committee on Agriculture Materials', 'Legislative', 'N/A', HouseComitteeOnAgricultureMaterials)}

     
                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
                </div>

                <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>
                        <h4 id='feedHeadingBillsEnrolled'><b>Committee on Appropriations</b></h4>

<div id='houseCommitteeOnAppropriations'></div>


{CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=AP00', 'houseCommitteeOnAppropriations', 'House Committee on Appropriations', 'Legislative', 'N/A', HouseCommitteeOnAppropriations)}


                  
                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                          <h4 id='presidentialFeedHeading'><b>Committee on Appropriations Materials</b></h4>
                          <div id='houseCommitteeOnAppropriationsMaterials'></div>
                          
                          {CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/houseCommittees/Committee%20on%20Appropriations.xml', 'houseCommitteeOnAppropriationsMaterials', 'House Committee on Appropriations Materials', 'Legislative', 'N/A', HouseCommitteeOnAppropriationsMaterials)}

     
                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
                </div>

                <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>
                        <h4 id='feedHeadingBillsEnrolled'><b>Committee on Armed Services</b></h4>
                        <div id='houseComitteeOnArmedServices'></div>
                  
                        {CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=AS00', 'houseComitteeOnArmedServices', 'House Committee on Armed Services', 'Legislative', 'Foreign Affairs', HouseCommitteeOnArmedServices)}

                  
                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                        <h4 id='presidentialFeedHeading'><b>Committee on Armed Services Materials</b></h4>
                          <div id='houseCommitteeOnArmedServicesMaterials'></div>
                          
                          {CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/houseCommittees/Committee%20on%20Armed%20Services.xml', 'houseCommitteeOnArmedServicesMaterials', 'House Committee on Armed Services Materials', 'Legislative', 'N/A', HouseCommitteeOnArmedServicesMaterials)}

     
                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
                </div>

                <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>
                        <h4 id='feedHeadingBillsEnrolled'><b>Committee on Education and Labor</b></h4>
                        <div id='houseCommitteeOnEducationAndLabor'></div>
                        
                        {CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=ED00', 'houseCommitteeOnEducationAndLabor', 'House Committee on Education and Labor', 'Legislative', 'Economy', HouseCommitteeOnEducationAndLabor)}

                  
                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                          <h4 id='presidentialFeedHeading'><b> Committee on Education and Labor Materials</b></h4>
                          <div id='houseCommitteeOnEducationAndLaborMaterials'></div>
                          
                          {CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/houseCommittees/Committee%20on%20Education%20and%20the%20Workforce.xml', 'houseCommitteeOnEducationAndLaborMaterials', 'House Committee on Education and Labor Materials', 'Legislative', 'N/A', HouseCommitteeOnEducationAndLaborMaterials)}

     
                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
                </div>

                <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>
                    
                        <h4 id='feedHeadingBillsEnrolled'><b>Committee on Energy and Commerce</b></h4>
                        <div id='houseCommitteeOnEnergyAndCommerce'></div>
                        
                        {CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=IF00', 'houseCommitteeOnEnergyAndCommerce', 'House Committee on Energy and Commerce', 'Legislative', 'N/A', HouseCommitteeOnEnergyAndCommerce)}

                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                        <h4 id='feedHeadingBillsEnrolled'><b>Committee on Energy and Commerce Materials</b></h4>
                        <div id='houseCommitteeOnEnergyAndCommerceMaterials'></div>
                        
                        {CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/houseCommittees/Committee%20on%20Energy%20and%20Commerce.xml', 'houseCommitteeOnEnergyAndCommerceMaterials', 'House Committee on Energy and Commerce Materials', 'Legislative', 'N/A', HouseCommitteeOnEnergyAndCommerceMaterials)}

                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
                </div>

                <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>
                    
                        <h4 id='feedHeadingBillsEnrolled'><b>Committee on Ethics</b></h4>
                        <div id='houseCommitteeOnEthics'></div>

                        {CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=SO00', 'houseCommitteeOnEthics', 'House Committee on Ethics', 'Legislative', 'N/A', HouseCommitteeOnEthics)}


                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                        <h4 id='feedHeadingBillsEnrolled'><b>Committee on Ethics Materials</b></h4>
                        <div id='houseCommitteeOnEthicsMaterials'></div>

                        {CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/houseCommittees/Committee%20on%20Ethics.xml', 'houseCommitteeOnEthicsMaterials', 'House Committee on Ethics Materials', 'Legislative', 'N/A', HouseCommitteeOnEthicsMaterials)}

     
                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
                </div>


          


                <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>
                    
                        <h4 id='feedHeadingBillsEnrolled'><b>Committee on Financial Services</b></h4>
                        <div id='houseCommitteeOnFinancialServices'></div>
                      



                        {CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=BA00', 'houseCommitteeOnFinancialServices', 'House Committee on Financial Services', 'Legislative', 'Economy', HouseCommitteeOnFinacialServices)}


                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                        <h4 id='feedHeadingBillsEnrolled'><b>Committee on Financial Services Materials</b></h4>
                        <div id='houseCommitteeOnFinancialServicesMaterials'></div>
                      



                        {CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/houseCommittees/Committee%20on%20Financial%20Services.xml', 'houseCommitteeOnFinancialServicesMaterials', 'House Committee on Financial Services Materials', 'Legislative', 'Economy', HouseCommitteeOnFinacialServicesMaterials)}

     
                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
                </div>

                <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>
                    
                        <h4 id='feedHeadingBillsEnrolled'><b>Committee on Foreign Affairs</b></h4>
                        <div id='houseCommitteeOnForeignAffairs'></div>
                     



                        {CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=FA00', 'houseCommitteeOnForeignAffairs', 'House Committee on Foreign Affairs', 'Legislative', 'Foreign Affairs', HouseCommitteeOnForeignAffairs)}


                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                        <h4 id='feedHeadingBillsEnrolled'><b>Committee on Foreign Affairs Materials</b></h4>
                        <div id='houseCommitteeOnForeignAffairsMaterials'></div>
                     



                        {CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/houseCommittees/Committee%20on%20Foreign%20Affairs.xml', 'houseCommitteeOnForeignAffairsMaterials', 'House Committee on Foreign Affairs Materials', 'Legislative', 'Foreign Affairs', HouseCommitteeOnForeignAffairsMaterials)}

     
                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
                </div>


                <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>
                    
                        <h4 id='feedHeadingBillsEnrolled'><b>Committee on Homeland Security</b></h4>
                        <div id='houseCommitteeOnHomelandSecurity'></div>
                    


                        {CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=HM00', 'houseCommitteeOnHomelandSecurity', 'House Committee on Homeland Security', 'Legislative', 'Foreign Affairs', HouseCommitteeOnHomelandSecurity)}



                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                        <h4 id='feedHeadingBillsEnrolled'><b>Committee on Homeland Security Materials</b></h4>
                        <div id='houseCommitteeOnHomelandSecurityMaterials'></div>
                    


                        {CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/houseCommittees/Committee%20on%20Homeland%20Security.xml', 'houseCommitteeOnHomelandSecurityMaterials', 'House Committee on Homeland Security Materials', 'Legislative', 'Foreign Affairs', HouseCommitteeOnHomelandSecurityMaterials)}


                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
                </div>

                <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>
                    
                        <h4 id='feedHeadingBillsEnrolled'><b>Committee on House Administration</b></h4>
                        <div id='houseCommitteeOnHouseAdministration'></div>
                      


                        {CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=HA00', 'houseCommitteeOnHouseAdministration', 'House Committee on House Administration', 'Legislative', 'N/A', HouseCommitteeOnHouseAdministration)}



                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                        <h4 id='feedHeadingBillsEnrolled'><b>Committee on House Administration Materials</b></h4>
                        <div id='houseCommitteeOnHouseAdministrationMaterials'></div>
                      


                        {CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/houseCommittees/Committee%20on%20House%20Administration.xml', 'houseCommitteeOnHouseAdministrationMaterials', 'House Committee on House Administration Materials', 'Legislative', 'N/A', HouseCommitteeOnHouseAdministrationMaterials)}


     
                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
                </div>


                <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>
                    
                        <h4 id='feedHeadingBillsEnrolled'><b>Committee on Interstate and Foreign Commerce</b></h4>
                        <div id='houseCommitteeOnInterstateAndForeignCommerce'></div>
                      

                     
                   

         

                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                        <h4 id='feedHeadingBillsEnrolled'><b>Committee on Interstate and Foreign Commerce Materials</b></h4>
                        <div id='houseCommitteeOnInterstateAndForeignCommerceMaterials'></div>
                      


                        {CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/houseCommittees/Committee%20on%20Interstate%20and%20Foreign%20Commerce.xml', 'houseCommitteeOnInterstateAndForeignCommerceMaterials', 'House Committee on Interstate and Foreign Commerce Materials', 'Legislative', 'N/A', HouseCommitteeOnInterstateAndForeignCommerceMaterials)}


                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
                </div>


                <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>
                    
                        <h4 id='feedHeadingBillsEnrolled'><b>Committee on Natural Resources</b></h4>
                        <div id='houseCommitteeOnNaturalResources'></div>
                  



                        {CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=II00', 'houseCommitteeOnNaturalResources', 'House Committee on Natural Resources', 'Legislative', 'Environment', HouseCommitteeOnNaturalResources)}



                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                        <h4 id='feedHeadingBillsEnrolled'><b>Committee on Natural Resources Materials</b></h4>
                        <div id='houseCommitteeOnNaturalResourcesMaterials'></div>
                  



                        {CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/houseCommittees/Committee%20on%20Natural%20Resources.xml', 'houseCommitteeOnNaturalResourcesMaterials', 'House Committee on Natural Resources Materials', 'Legislative', 'Environment', HouseCommitteeOnNaturalResources)}

     
                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
                </div>

                <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>
                    
                        <h4 id='feedHeadingBillsEnrolled'><b>Committee on Oversight and Reform</b></h4>
                        <div id='houseCommitteeOnOversightAndReform'></div>
                  



                        {CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=GO00', 'houseCommitteeOnOversightAndReform', 'House Committee on Oversight and Reform', 'Legislative', 'N/A', HouseCommitteeOnOversightAndReform)}



                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                        <h4 id='feedHeadingBillsEnrolled'><b>Committee on Oversight and Reform Materials</b></h4>
                        <div id='houseCommitteeOnOversightAndReformMaterials'></div>
                  



                        {CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/houseCommittees/Committee%20on%20Oversight%20and%20Accountability.xml', 'houseCommitteeOnOversightAndReformMaterials', 'House Committee on Oversight and Reform Materials', 'Legislative', 'N/A', HouseCommitteeOnOversightAndReformMaterials)}

                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
                </div>


                <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>
                    
                        <h4 id='feedHeadingBillsEnrolled'><b>Committee on Rules</b></h4>
                        <div id='houseCommitteeOnRules'></div>
                      



                        {CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=RU00', 'houseCommitteeOnRules', 'House Committee on Rules', 'Legislative', 'N/A', HouseCommitteeOnRules)}


                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                        <h4 id='feedHeadingBillsEnrolled'><b>Committee on Rules Materials</b></h4>
                        <div id='houseCommitteeOnRulesMaterials'></div>
                      



                        {CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/houseCommittees/Committee%20on%20Rules.xml', 'houseCommitteeOnRulesMaterials', 'House Committee on Rules Materials', 'Legislative', 'N/A', HouseCommitteeOnRulesMaterials)}

     
                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
                </div>



                <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>
                        <h4 id='feedHeadingBillsEnrolled'><b>Committee on Science, Space, and Technology</b></h4>
                        <div id='houseCommitteeOnScienceSpaceAndTechnology'></div>
                       
                        {CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=SY00', 'houseCommitteeOnScienceSpaceAndTechnology', 'House Committee on Science, Space, and Technology', 'Legislative', 'Science & Tech', HouseCommitteeOnScienceSpaceAndTechnology)}



                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                        <h4 id='feedHeadingBillsEnrolled'><b>Committee on Science, Space, and Technology Materials</b></h4>
                        <div id='houseCommitteeOnScienceSpaceAndTechnologyMaterials'></div>
                       
                        {CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/houseCommittees/Committee%20on%20Science,%20Space,%20and%20Technology.xml', 'houseCommitteeOnScienceSpaceAndTechnologyMaterials', 'House Committee on Science, Space, and Technology Materials', 'Legislative', 'Science & Tech', HouseCommitteeOnScienceSpaceAndTechnologyMaterials)}

                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
                </div>



                <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>
                      
        <h4 id='feedHeadingBillsEnrolled'><b>Committee on Small Business</b></h4>
                        <div id='houseCommitteeOnSmallBusiness'></div>
           
                        {CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=SM00', 'houseCommitteeOnSmallBusiness', 'House Committee on Small Business', 'Legislative', 'Economy', HouseCommitteeOnSmallBusiness)}


                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                        <h4 id='feedHeadingBillsEnrolled'><b>Committee on Small Business Materials</b></h4>
                        <div id='houseCommitteeOnSmallBusinessMaterials'></div>
           
                        {CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/houseCommittees/Committee%20on%20Small%20Business.xml', 'houseCommitteeOnSmallBusinessMaterials', 'House Committee on Small Business Materials', 'Legislative', 'Economy', HouseCommitteeOnSmallBusinessMaterials)}

                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
                </div>


                <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>
                      
                        <h4 id='feedHeadingBillsEnrolled'><b>Committee on Transportation and Infrastructure</b></h4>
                        <div id='houseCommitteeOnTransportationAndInfrastructure'></div>
                       

                        {CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=PW00', 'houseCommitteeOnTransportationAndInfrastructure', 'House Committee on Transportation and Infrastructure', 'Legislative', 'Environment', HouseCommitteeOnTransportationAndInfrastructure)}

                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                        <h4 id='feedHeadingBillsEnrolled'><b>Committee on Transportation and Infrastructure Materials</b></h4>
                        <div id='houseCommitteeOnTransportationAndInfrastructureMaterials'></div>
                       

                        {CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/houseCommittees/Committee%20on%20Transportation%20and%20Infrastructure.xml', 'houseCommitteeOnTransportationAndInfrastructureMaterials', 'House Committee on Transportation and Infrastructure Materials', 'Legislative', 'Environment', HouseCommitteeOnTransportationAndInfrastructureMaterials)}

     
                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
                </div>


                <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>
                        <h4 id='feedHeadingBillsEnrolled'><b> Committee on Veterans' Affairs</b></h4>
                        <div id='houseCommitteeOnVeteransAffairs'></div>
                       



                        {CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=VR00', 'houseCommitteeOnVeteransAffairs', 'House Committee on Veterans Affairs', 'Legislative', 'N/A', HouseCommitteeOnVeteransAffairs)}

                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                        <h4 id='feedHeadingBillsEnrolled'><b> Committee on Veterans' Affairs Materials</b></h4>
                        <div id='houseCommitteeOnVeteransAffairsMaterials'></div>
                       



                        {CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/houseCommittees/Committee%20on%20Veterans%27%20Affairs.xml', 'houseCommitteeOnVeteransAffairsMaterials', 'House Committee on Veterans Affairs Materials' , 'Legislative', 'N/A', HouseCommitteeOnVeteransAffairsMaterials)}

                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
                </div>



                <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>
                        <h4 id='feedHeadingBillsEnrolled'><b>Committee on Ways and Means</b></h4>
                        <div id='houseCommitteeOnWaysAndMeans'></div>
                       
                        {CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=WM00', 'houseCommitteeOnWaysAndMeans', 'House Committee on Ways and Means', 'Legislative', 'N/A', HouseCommitteeOnWaysAndMeans)}



                 
                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                        <h4 id='feedHeadingBillsEnrolled'><b>Committee on Ways and Means Materials</b></h4>
                        <div id='houseCommitteeOnWaysAndMeansMaterials'></div>
                       
                        {CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/houseCommittees/Committee%20on%20Ways%20and%20Means.xml', 'houseCommitteeOnWaysAndMeansMaterials', 'House Committee on Ways and Means Materials', 'Legislative', 'N/A', HouseCommitteeOnWaysAndMeansMaterials)}


     
                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
                </div>



                <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>
                  
                        <h4 id='feedHeadingBillsEnrolled'><b>Committee on the Budget</b></h4>
                        <div id='houseCommitteeOnTheBudget'></div>
                       
                        {CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=BU00', 'houseCommitteeOnTheBudget', 'House Committee on the Budget', 'Legislative', 'Economy', HouseCommitteeOnTheBudget)}



                 
                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                        <h4 id='feedHeadingBillsEnrolled'><b>Committee on the Budget Materials</b></h4>
                        <div id='houseCommitteeOnTheBudgetMaterials'></div>
                       
                        {CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/houseCommittees/Committee%20on%20the%20Budget.xml', 'houseCommitteeOnTheBudgetMaterials', 'House Committee on the Budget Materials', 'Legislative', 'Economy', HouseCommitteeOnTheBudgetMaterials)}

     
                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
                </div>



                <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>
                  
                        <h4 id='feedHeadingBillsEnrolled'><b>Committee on the Judiciary</b></h4>
                        <div id='houseCommitteeOnTheJudiciary'></div>
                      

                        {CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=JU00', 'houseCommitteeOnTheJudiciary', 'House Committee on the Judiciary', 'Legislative', 'N/A', HouseCommitteeOnTheJudiciary)}



                 
                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                          
                        <h4 id='feedHeadingBillsEnrolled'><b>Committee on the Judiciary Materials</b></h4>
                        <div id='houseCommitteeOnTheJudiciaryMaterials'></div>
                      

                        {CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/houseCommittees/Committee%20on%20the%20Judiciary.xml', 'houseCommitteeOnTheJudiciaryMaterials', 'House Committee on the Judiciary Materials', 'Legislative', 'N/A', HouseCommitteeOnTheJudiciaryMaterials)}


                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
                </div>


                <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>
                  
                        <h4 id='feedHeadingBillsEnrolled'><b>Permanent Select Committee on Intelligence</b></h4>
                        <div id='houseCommitteeOnIntelligence'></div>
                      


                        {CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=IG00', 'houseCommitteeOnIntelligence', 'Permanent Select Committee on Intelligence', 'Legislative', 'N/A', HouseCommitteeOnIntelligence)}

                     

                 
                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                        <h4 id='feedHeadingBillsEnrolled'><b>Permanent Select Committee on Intelligence Materials</b></h4>
                        <div id='houseCommitteeOnIntelligenceMaterials'></div>
                      


                        {CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/houseCommittees/Permanent%20Select%20Committee%20on%20Intelligence.xml', 'houseCommitteeOnIntelligenceMaterials', 'Permanent Select Committee on Intelligence Materials', 'Legislative', 'N/A', HouseCommitteeOnIntelligenceMaterials)}

                     
                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
                </div>


                <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>
                  
                        <h4 id='feedHeadingBillsEnrolled'><b>Select Committee to Investigate the January 6th Attack on the United States Capitol</b></h4>
                        <div id='selectCommitteeToInvestigate'></div>
                      


                        {CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=IJ00', 'selectCommitteeToInvestigate', 'Select Committee to Investigate the January 6th Attack on the United States Capitol', 'Legislative', 'N/A', HouseCommitteeToInvestigateTheCapitol)}



                     

                 
                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                        <h4 id='feedHeadingBillsEnrolled'><b>Select Committee to Investigate the January 6th Attack on the United States Capitol Materials</b></h4>
                        <div id='selectCommitteeToInvestigateMaterials'></div>
                      


                        {CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/houseCommittees/Select%20Committee%20to%20Investigate%20the%20January%206th%20Attack%20on%20the%20United%20States%20Capitol.xml', 'selectCommitteeToInvestigateMaterials', 'Select Committee to Investigate the January 6th Attack on the United States Capitol Materials', 'Legislative', 'N/A', HouseCommitteeToInvestigateTheCapitolMaterials)}


                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
                </div>





      



            <Modal id='aboutModal' show={aboutModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAboutModalIsOpen(false)}>
            <div id='removedAlert'></div>
            <Modal.Header id='aboutModalHeader' closeButton><h3>About</h3></Modal.Header>
  <ModalBody id='aboutInfo'><p id='aboutParagraph'>Gov Glance was created to drive civic engagement and support a 
              healthier democracy by helping citizens get official Government news in an easy-to-navigate centralized location. 
              There will never be any opinion or political analysis(That's your job), only information from official Government 
              sources. Gov Glance is structured after the U.S. government that is broken into three branches Legislative, 
              Executive, and Judicial. To learn more about the structure and functions of The U.S. Government go to <a href='https://www.usa.gov/branches-of-government'>https://www.usa.gov/branches-of-government</a>.
              </p>

              <p id='aboutParagraph'>
              Gov Glance Foundation Inc. is a non-profit that was created to operate govglance.org and continue to create educational tools that help people learn about the workings of society
              </p>
            <br></br>

            <h3>Privacy</h3>

            <br></br>

              <p id='aboutParagraph'>Gov Glance does not collect any personal user information. 
              Features like collection save news items directly to your browser's local storage for your convenience
              and is not shared with Gov Glance servers. We do not collect, store, or send personal information to Google LLC, 
              but we do use some Google services to deliver our app to you. For any questions into Google’s privacy practices 
              here is a link to their privacy policy: <a href='https://policies.google.com/privacy?hl=en-US#intro'>https://policies.google.com/privacy?hl=en-US#intro</a>

              <br></br><br></br>

              For site analytics we use plausible.io an open source privacy friendly analytics provider. <a href="https://plausible.io/about" target="_blank">https://plausible.io/about</a>
            
            </p>
            <br></br>


            <h3>Contact</h3>

            <p id='aboutParagraph'>
                      Contact us via email: <a href='mailto:contact@govglance.org'>contact@govglance.org</a>

            </p>

            <br></br>


            
            </ModalBody>
          </Modal>
 
          <footer id='homeFooter'>
      <Navbar>
        <Row id='footerNav'>
      <Nav variant='pills'>
        
     <Nav.Link id='footerContent'>Gov Glance Foundation Inc.
</Nav.Link>
<Nav.Item>
<Nav.Link id='footerContent' href='mailto:contact@govglance.org'>Contact</Nav.Link>
</Nav.Item>
<Nav.Item>
<Nav.Link id='footerContent' onClick={(e) => {
                   e.preventDefault();
                   window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                 }}> <HeartFill id='donateIcon'/>    Donate</Nav.Link>
</Nav.Item>
<Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/about', "_self")}} id='footerContent'>Privacy</Nav.Link>
    
     
 </Nav>
 </Row>
 </Navbar>
      </footer>



</div>
         </div>
          

              </BrowserView>

              <MobileView>

    <HouseMobile/>

              </MobileView>
              
    </>
  )
}
 }



  






export default HousePage;
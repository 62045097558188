
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch, NavLink,  } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment,  } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image, ModalFooter } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText, FileMedicalFill, Coin, Tree, Globe2,  ShieldShaded, CircleFill, Pen, People, ChatSquareQuote, ChatLeftQuote, BlockquoteRight, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, CaretRight, ChevronRight, ChevronCompactRight, CaretRightFill, ArrowRightShort,  ArrowRightCircle, PlayCircle, PauseCircle, GeoAlt, HeartFill, QuestionCircleFill, MicFill, ReceiptCutoff } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import '../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import { render } from '@testing-library/react';

import
 {CreateFeedItem, 
CreateFeedItemCanada,
CreateFeedItemFuckLife} from '../../Functions'

import CanadaDesktopNavbar from '../CanadaDesktopNavbar';
import JudicialAppointmentsCanada from './JudicialAppointmentsCanada';
import CanadaJudicialBranchMobile from './CanadaJudicialBranchMobile';
import SupremeCourtNews from './SupremeCourtNews';
import SupremeCourtJudgements from './SupremeCourtJudgements';
import SupremeCourtReports from './SupremeCourtReports';
import SupremeCourtBullitins from './SupremeCourtBullitins';
import SupremeCourtLeave from './SupremeCourtLeave';

var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 60000;

export const CanadaJudicial = () =>   {
  render()
  {

  const [trendingBillsOptionOne, setTrendingBillsOptionOneModalIsOpen ] = useState(false)
  const [trendingBillsOptionTwo, setTrendingBillsOptionTwoModalIsOpen ] = useState(false)
  const [trendingBillsOptionThree, setTrendingBillsOptionThreeModalIsOpen ] = useState(false)
  const [trendingBillsOptionFour, setTrendingBillsOptionFourModalIsOpen ] = useState(false)
  const [trendingBillsOptionFive, setTrendingBillsOptionFiveModalIsOpen ] = useState(false)
  const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)
  const [billsEnrolledModalIsOpen, setBillsEnrolledModalIsOpen] = useState(false)
  const [showBioModal, setBioModal] = useState(false);
  return (
    <>
      <div className="App">
      <BrowserView>
      <div id='homePage'>

        <div id='homebg'>
         <CanadaDesktopNavbar/>
         <div style={{ position: 'relative' }}>

<div id='profileImage' style={{ width: '150px', height: '150px', borderRadius: '100%', overflow: 'hidden',  marginLeft: '40px' }}>
<Image id='profileImage-image' src='/canadaCourt.png' />
</div>


<div style={{ position: 'absolute', top: '0', left: '250px', display: 'flex', flexDirection: 'column' }}>
<div style={{ color: 'white', fontSize: '24px', fontWeight: 'bold' }}>

</div>
<div  style={{ color: 'white', fontSize: '20px' }}> 
<span> <h3  >Judicial Branch </h3></span>
<br></br>
<div style={{ color: 'white', fontSize: '20px' }}>
<span> 
The judicial branch is made up of a system of courts, administrative boards and tribunals at the federal, provincial and territorial levels. It is independent from the legislative and executive branches.</span>
<br></br>
<div style={{ color: 'white', fontSize: '18px' }}>

  <span><Link style={{color: '#21cff5'}} onClick={(e) => {
     e.preventDefault();
     window.open("https://www.scc-csc.ca/home-accueil/index-eng.aspx", "_blank");
   }}>
     https://www.scc-csc.ca/home-accueil/index-eng.aspx
     </Link></span> 

     <Button id='whDocsViewMoreButton' onClick={() =>  setBioModal(true)}>  <Files size={20}></Files> Read More</Button>
</div>
</div>

</div>
</div>



<Modal id='fullBillModal'  size="xl" aria-labelledby="contained-modal-title-vcenter" show={showBioModal} onHide={() => setBioModal(false)}>
         <Modal.Header id='billModalHeader' closeButton><h3> The Judicial Branch</h3></Modal.Header>
       
         <div id='fullBillFeed' >
      
         <p> The judicial branch is made up of a system of courts, administrative boards and tribunals at the federal, provincial and territorial levels. It is independent from the legislative and executive branches.</p>

<p>The Supreme Court of Canada is the highest court in Canada, and the final court of appeal. This means it has the final say on important cases of private and public law. It hears appeals related to decisions made by the federal, provincial and territorial courts of appeal, and also considers important questions of law referred to it by the Governor in Council (Cabinet).</p>


<p>The federal court system specializes in federal laws, such as intellectual property, maritime law and tax assessments. It includes the Federal Court, the Tax Court and the Federal Court of Appeal.</p>



<p>Provincial and territorial courts make up most of the courts in Canada and are created by provincial and territorial legislatures. These courts deal with most criminal offences, as well as civil and family law and other matters. Almost all provinces and territories have three levels of courts: lower, superior and appeal. (Nunavut has only one level of trial court.)</p>

<br></br>
<Link onClick={(e) => {
     e.preventDefault();
     window.open("https://www.scc-csc.ca/home-accueil/index-eng.aspx", "_blank");
   }}>
     https://www.scc-csc.ca/home-accueil/index-eng.aspx
     </Link>
 </div>

       </Modal>

</div>
<br></br>
<br></br><br></br>

            <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                    <Row>
                    <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>
                          <h4 id='feedHeadingBillsEnrolled' onClick={() => setTrendingBillsOptionOneModalIsOpen(true)}><b>Supreme Court News</b></h4>
<div id='courtNews'></div>
            
            {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
{CreateFeedItemCanada('https://decisions.scc-csc.ca/scc-csc/news/en/rss.do', 'courtNews', 'Supreme Court News', 'Legislative', 'N/A', SupremeCourtNews)}

<Modal id='mobileFullBillModal' show={trendingBillsOptionOne} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionOneModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Supreme Court News</h3></Modal.Header>
<SupremeCourtNews/>
</Modal>
                        </div>
                      </Col>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>

                          <h4 id='feedHeadingBillsEnrolled' onClick={() => setBillsEnrolledModalIsOpen(true)}><b>Judicial Appointments</b></h4>
              <div id='judicialApts2'></div>
              
                        {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
  {CreateFeedItem('https://gov-glance-canada.nyc3.digitaloceanspaces.com/judicial-xml/judicial_appointments.xml', 'judicialApts2', 'Judicial Appointments', 'Judicial', 'N/A', JudicialAppointmentsCanada)}

                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Container>
              </div>
           
              <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                    <Row>
                    <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>

                          <h4 id='feedHeadingBillsEnrolled' onClick={() => setTrendingBillsOptionTwoModalIsOpen(true)}><b>Supreme Court Judgements</b></h4>
          <div id='courtJudgements'></div>
        {CreateFeedItemCanada('https://decisions.scc-csc.ca/scc-csc/scc-csc/en/rss.do', 'courtJudgements', 'Supreme Court Judgements', 'Legislative', 'N/A', SupremeCourtJudgements)}
        

          <Modal id='mobileFullBillModal' show={trendingBillsOptionTwo} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionTwoModalIsOpen(false)}>
        <Modal.Header id='mobileBillModalHeader' closeButton><h3>Supreme Court Judgements</h3></Modal.Header>
        <SupremeCourtJudgements/>
        </Modal>
                        </div>
                      </Col>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>

                          <h4 id='feedHeadingBillsEnrolled' onClick={() => setTrendingBillsOptionThreeModalIsOpen(true)}><b>Supreme Court Reports</b></h4>

<div id='courtReports'></div>
            
            {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
{CreateFeedItemCanada('https://decisions.scc-csc.ca/scc-csc/scr/en/rss.do', 'courtReports', 'Supreme Court Reports', 'Legislative', 'N/A', SupremeCourtReports)}

<Modal id='mobileFullBillModal' show={trendingBillsOptionThree} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionThreeModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Supreme Court Reports</h3></Modal.Header>
<SupremeCourtReports/>
</Modal>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Container>
              </div>
        
              <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                    <Row>
                    <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>
                          <h4 id='feedHeadingBillsEnrolled' onClick={() => setTrendingBillsOptionFourModalIsOpen(true)}>
                            <b>Supreme Court Bullitins</b></h4>
<div id='courtBullitins'></div>
          
            {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
{CreateFeedItemCanada('https://decisions.scc-csc.ca/scc-csc/bulletins/en/rss.do', 'courtBullitins', 'Supreme Court Bullitins', 'Legislative', 'N/A', SupremeCourtBullitins)}

<Modal id='mobileFullBillModal' show={trendingBillsOptionFour} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionFourModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Supreme Court Bullitins</h3></Modal.Header>
<SupremeCourtBullitins/>
</Modal>
                        </div>
                      </Col>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>
                          <h4 id='feedHeadingBillsEnrolled' onClick={() => setTrendingBillsOptionFiveModalIsOpen(true)}><b>Supreme Court Leave</b></h4>

<div id='courtLeave'></div>
            
            {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
{CreateFeedItemCanada('https://decisions.scc-csc.ca/scc-csc/scc-l-csc-a/en/rss.do', 'courtLeave', 'Supreme Court Leave', 'Legislative', 'N/A', SupremeCourtLeave)}

<Modal id='mobileFullBillModal' show={trendingBillsOptionFive} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionFiveModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Supreme Court Leave</h3></Modal.Header>
<SupremeCourtLeave/>
</Modal>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Container>
              </div>

          <Modal id='aboutModal' show={aboutModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAboutModalIsOpen(false)}>
            <div id='removedAlert'></div>
            <Modal.Header id='aboutModalHeader' closeButton><h3>About</h3></Modal.Header>
  <ModalBody id='aboutInfo'><p id='aboutParagraph'>Gov Glance was created to drive civic engagement and support a 
              healthier democracy by helping citizens get official Government news in an easy-to-navigate centralized location. 
              There will never be any opinion or political analysis(That's your job), only information from official Government 
              sources. Gov Glance is structured after the U.S. government that is broken into three branches Legislative, 
              Executive, and Judicial. To learn more about the structure and functions of The U.S. Government go to <a href='https://www.usa.gov/branches-of-government'>https://www.usa.gov/branches-of-government</a>.
              </p>

              <p id='aboutParagraph'>
              Gov Glance Foundation Inc. is a non-profit that was created to operate govglance.org and continue to create educational tools that help people learn about the workings of society
              </p>
            <br></br>

            <h3>Privacy</h3>

            <br></br>

              <p id='aboutParagraph'>Gov Glance does not collect any personal user information. 
              Features like collection save news items directly to your browser's local storage for your convenience
              and is not shared with Gov Glance servers. We do not collect, store, or send personal information to Google LLC, 
              but we do use some Google services to deliver our app to you. For any questions into Google’s privacy practices 
              here is a link to their privacy policy: <a href='https://policies.google.com/privacy?hl=en-US#intro'>https://policies.google.com/privacy?hl=en-US#intro</a>

              <br></br><br></br>

              For site analytics we use plausible.io an open source privacy friendly analytics provider. <a href="https://plausible.io/about" target="_blank">https://plausible.io/about</a>
            
            </p>
            <br></br>


            <h3>Contact</h3>

            <p id='aboutParagraph'>
                      Contact us via email: <a href='mailto:contact@govglance.org'>contact@govglance.org</a>

            </p>

            <br></br>


            
            </ModalBody>
          </Modal>
 
     <footer id='homeFooter'>
      <Navbar>
        <Row id='footerNav'>
      <Nav variant='pills'>
     <Nav.Link id='footerContent'>Gov Glance Foundation Inc.
</Nav.Link>
<Nav.Item>
<Nav.Link id='footerContent' href='mailto:contact@govglance.org'>Contact</Nav.Link>
</Nav.Item>
<Nav.Item>
<Nav.Link id='footerContent' onClick={(e) => {
                   e.preventDefault();
                   window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                 }}> <HeartFill id='donateIcon'/>    Donate</Nav.Link>
</Nav.Item>
<Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/about', "_self")}} id='footerContent'>Privacy</Nav.Link>
 </Nav>
 </Row>
 </Navbar>
      </footer>
          </div>
    </div>
      </BrowserView>
      <MobileView> 
                      <CanadaJudicialBranchMobile/>
      </MobileView>
        </div>
    </>
  );
}
}


export default CanadaJudicial;

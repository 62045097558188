
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch, } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment, } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image } from 'react-bootstrap';
import { ArrowsAngleExpand,  ArrowsFullscreen, InfoCircle, FileText, ShieldShaded, PlayCircle, PauseCircle, HeartFill, Pen, People, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, Tree, FileMedical, FileMedicalFill, ChevronRight, Bricks  } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import { render } from '@testing-library/react';
import {createDepartmentOfHealthAndHumanServices, 
  createCDCNewsroom, createCDCOutbreaks, 
  createCDCTravelNotices, createFoodSafety, 
  createFoodRecalls, createMedWatch, 
  createHealthyLivingFeatures, 
  createPreventingChronicDiseases, 
  createCovid19, createNovelCoronavirus, 
  createVaccineUpdates, createSeasonalFlu, Speak, CreateFeedItem, CreateFeedItemMaterials, CreateFeedAPI, CreateFeedItemBillWithDescription} from "./Functions";
  import FederalRegisterDefenseDepartment from './FederalRegisterDefenseDepartment';
import MobileTopNavbar from './MobileTopNavbar';
import ArmyDocuments from './ArmyDocuments';
import NavyDocuments from './NavyDocuments';
import SpaceForceNews from './SpaceForceNews';
import AirForceDocuments from './AirForceDocuments';
import NationalGuardNews from './NationalGuardNews';
import NSADocuments from './NSADocuments';
import CIADocuments from './CIADocuments';
import ArmyCorpsEngineers from './ArmyCorpsEngineers';
import MobileOffCanvas from './MobileOffCanvas';
import GenerateFeed from './GenerateFeed';
import DepartmentOfDefense from './DepartmentOfDefense';
import HouseCommitteeOnArmedServices from './HouseCommitteeOnArmedServices';
import HouseCommitteeOnArmedServicesMaterials from './HouseCommitteeOnArmedServicesMaterials';
import HouseCommitteeOnIntelligenceMaterials from './HouseCommitteeOnIntelligenceMaterials';
import HouseCommitteeOnIntelligence from './HouseCommitteeOnIntelligence';
import HouseCommitteeOnHomelandSecurityMaterials from './HouseCommitteeOnHomelandSecurityMaterials';
import HouseCommitteeOnHomelandSecurity from './HouseCommitteeOnHomelandSecurity';
import HouseCommitteeOnForeignAffairsMaterials from './HouseCommitteeOnForeignAffairsMaterials';
import HouseCommitteeOnForeignAffairs from './HouseCommitteeOnForeignAffairs';
import HomelandSecurityDocuments from './HomelandSecurityDocuments';
import SecretService from './SecretService';
import TSADocuments from './TSADocuments';
import FEMADocuments from './FEMADocuments';
import CoastGuardDocuments from './CoastGuardDocuments';
import CounterTerrorism from './CounterTerrorism';
import MarinesPressReleases from './MarinesPressReleases';

var mobileCarouselInterval = 60000;

export const DefenseTopicMobile = () =>
 {
   render()
   {

    const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
    const [defenseDocumentsModalIsOpen, setDefenseDocumentsModalIsOpen] = useState(false)
    const [armyModalIsOpen, setArmyModalIsOpen] = useState(false)
    const [navyModalIsOpen, setNavyModalIsOpen] = useState(false)
    const [marinesModalIsOpen, setMarinesModalIsOpen] = useState(false)
    const [spaceForceModalIsOpen, setSpaceForceModalIsOpen] = useState(false)
    const [airForceModalIsOpen, setAirForceModalIsOpen] = useState(false)
    const [nationalGuardModalIsOpen, setNationalGuardModalIsOpen] = useState(false)
    const [ciaModalIsOpen, setCIAModalIsOpen] = useState(false)
    const [nsaModalIsOpen, setNSAModalIsOpen] = useState(false)
    const [armyCorpsModalIsOpen, setArmyCorpsModalIsOpen] = useState(false)
    const [armedServicesMaterialsModalIsOpen, setArmedServicesMaterialsModalIsOpen] = useState(false)
    const [committeeOnArmedServicesModalIsOpen, setCommitteeOnArmedServicesModalIsOpen] = useState(false)
    const [committeeOnIntelligenceModalIsOpen, setCommitteeOnIntelligenceModalIsOpen] = useState(false)
    const [intelligenceMaterialsModalIsOpen, setIntelligenceMaterialsModalIsOpen] = useState(false)
    const [committeeOnHomelandSecurityModalIsOpen, setCommitteeOnHomelandSecurityModalIsOpen] = useState(false)
    const [homelandSecurityMaterialsModalIsOpen, setHomelandSecurityMaterialsModalIsOpen] = useState(false)
    const [committeeOnForeignAffairsModalIsOpen, setCommitteeOnForeignAffairsModalIsOpen] = useState(false)
    const [foreignAffairsMaterialsModalIsOpen, setForeignAffairsMaterialsModalIsOpen] = useState(false)
    const [homelandDocsModalIsOpen, setHomelandDocsModalIsOpen] = useState(false)
    const [coastGuardModalIsOpen, setCoastGuardModalIsOpen] = useState(false)
    const [femaModalIsOpen, setFEMAModalIsOpen] = useState(false)
    const [secretServiceModalIsOpen, setSecretServiceModalIsOpen] = useState(false)
    const [tsaModalIsOpen, setTSAModalIsOpen] = useState(false)
    const [immigrationModalIsOpen, setImmigrationModalIsOpen] = useState(false)
    const [usCustomsModalIsOpen, setUSCustomsModalIsOpen] = useState(false)
    const [enforcementsModalIsOpen, setEnforcemnetsModalIsOpen] = useState(false)
    const [counterterrorismModalIsOpen, setCounterterrorismModalIsOpen] = useState(false)
    
  return(
    <>

      


<MobileTopNavbar/>


        <div id='homebg'>
    

            <h5 id='branchHeadingMobile' ><ShieldShaded/> Defense</h5>

            <br></br>

            <h5 onClick={() => window.open('/us/departmentofdefense', "_self")} id='presidentialFeedHeadingMobile'>Department of Defense<ChevronRight id='clickChevron'></ChevronRight></h5>
                        
                        <Col>
                        <Carousel controls={false} >
                        
                  
                        
                        <Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setDepartmentOfDefenseModalIsOpen(true)}>News</h5>




<div class='courtNewsMobile' id='defenseDepartment'></div>

  {CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/executive-xml/defense.xml', 'defenseDepartment', 'Department of Defense News', 'Executive', 'Foreign Affairs', DepartmentOfDefense)}

  <Modal id='whiteHouseDocsModal' show={departmentOfDefenseModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfDefenseModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Defense News</h3></Modal.Header>
                  <DepartmentOfDefense />
                </Modal>

  </Col>


    
  
    </div>
   


</Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setDefenseDocumentsModalIsOpen(true)}>Documents</h5>


  <div class='courtNewsMobile' id='frDefense'></div>

  {CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=defense-department', 'frDefense', 'Department of Defense Documents', 'Executive', 'Foreign Affairs', FederalRegisterDefenseDepartment)}

</Col>
              
                <Modal id='whiteHouseDocsModal' show={defenseDocumentsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDefenseDocumentsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Defense Documents</h3></Modal.Header>
                  <FederalRegisterDefenseDepartment />
                </Modal>


    
  
    </div>
   


</Carousel.Item>
<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>
<div id='viewFullPageButton'>
<Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/departmentofdefense' >View all Department of Defense news</Button>
</div>
</Col>

</div>



</Carousel.Item>

                        
                        
                        </Carousel>
                        
                        </Col>

                        <Col>


<h5 onClick={() => window.open('/us/departmentofhomeland', "_self")} id='presidentialFeedHeadingMobile'>Department of Homeland Security<ChevronRight id='clickChevron'></ChevronRight></h5>
             
                <Carousel controls={false} >

                <Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() => setHomelandDocsModalIsOpen(true)}>Documents</h5>
  <div class='courtNewsMobile' id='homelandDocs'></div>
                
                {CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=homeland-security-department', 'homelandDocs', 'Homeland Security Documents', 'Executive', 'N/A', HomelandSecurityDocuments)}

 
    </Col>

    <Modal id='whiteHouseDocsModal' show={homelandDocsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHomelandDocsModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Homeland Security Documents</h3></Modal.Header>
              <HomelandSecurityDocuments />
            </Modal>

            


    
  
    </div>
   


</Carousel.Item>
<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>
<div id='viewFullPageButton'>
<Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/departmentofhomeland' >View all Homeland Security news</Button>
</div>
</Col>

</div>



</Carousel.Item>


</Carousel>

</Col> 

 


            <Col>
<h5 id='presidentialFeedHeadingMobile' onClick={() => setCounterterrorismModalIsOpen(true)}>Counterterrorism</h5>
    <div class='courtNewsMobile' id='counterterrorism'></div>
    {CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/foreignAffairs-xml/counterTerrorism.xml', 'counterterrorism', 'Counterterrorism', 'N/A', 'Foreign Affairs', CounterTerrorism)}
  </Col>



  <Modal id='whiteHouseDocsModal' show={counterterrorismModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCounterterrorismModalIsOpen(false)}>
    <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Counterterrorism</h3></Modal.Header>
    <CounterTerrorism/>
  </Modal>


                <Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setArmyModalIsOpen(true)}>Army Documents</h5>


  <div class='courtNewsMobile' id='armyDocs'></div>

  {CreateFeedItemBillWithDescription('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=army-department', 'armyDocs', 'Army Documents', 'Executive', 'N/A', ArmyDocuments)}

</Col>
              
                <Modal id='whiteHouseDocsModal' show={armyModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setArmyModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Army Documents</h3></Modal.Header>
                  <ArmyDocuments />
                </Modal>

                <Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setMarinesModalIsOpen(true)}>Marines Press Releases</h5>


  <div class='courtNewsMobile' id='marinesPress'></div>

  {CreateFeedItemBillWithDescription('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/executive-xml/marine.xml', 'marinesPress', 'Marines Press Releases', 'Executive', 'N/A', MarinesPressReleases)}

</Col>
              
                <Modal id='whiteHouseDocsModal' show={marinesModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setMarinesModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Marines Press Releases</h3></Modal.Header>
                  <MarinesPressReleases/>
                </Modal>

                <Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setNavyModalIsOpen(true)}>Navy Documents</h5>


  <div class='courtNewsMobile' id='navyDocs'></div>

  {CreateFeedItemBillWithDescription('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=navy-department', 'navyDocs', 'Navy Documents', 'Executive', 'N/A', NavyDocuments)}

</Col>
              
                <Modal id='whiteHouseDocsModal' show={navyModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setNavyModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Navy Documents</h3></Modal.Header>
                  <NavyDocuments />
                </Modal>



                <Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setAirForceModalIsOpen(true)}>Air Force Documents</h5>


  <div class='courtNewsMobile' id='airForce'></div>

  {CreateFeedItemBillWithDescription('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=air-force-department', 'airForce', 'Air Force Documents', 'Executive', 'N/A', SpaceForceNews)}

</Col>
              
                <Modal id='whiteHouseDocsModal' show={airForceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAirForceModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Air Force Documents</h3></Modal.Header>
                  <AirForceDocuments/>
                </Modal>



                <Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setSpaceForceModalIsOpen(true)}>Space Force News</h5>


  <div class='courtNewsMobile' id='spaceForce'></div>

  {CreateFeedItemBillWithDescription('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/executive-xml/space.xml', 'spaceForce', 'Space Force News', 'Executive', 'N/A', SpaceForceNews)}

</Col>
              
                <Modal id='whiteHouseDocsModal' show={spaceForceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setSpaceForceModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Space Force News</h3></Modal.Header>
                  <SpaceForceNews/>
                </Modal>


                <Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setNationalGuardModalIsOpen(true)}>National Guard News</h5>


  <div class='courtNewsMobile' id='nationalGuard'></div>

  {CreateFeedItemBillWithDescription('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/executive-xml/national_guard.xml', 'nationalGuard', 'National Guard News', 'Executive', 'N/A', NationalGuardNews)}

</Col>
              
                <Modal id='whiteHouseDocsModal' show={nationalGuardModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setNationalGuardModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>National Guard News</h3></Modal.Header>
                  <NationalGuardNews/>
                </Modal>

                <Col>
      <h5 id='presidentialFeedHeadingMobile' onClick={() => setCoastGuardModalIsOpen(true)}>Coast Guard Documents</h5>
      <div class='courtNewsMobile' id='coastGuard'></div>
                    
                    {CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=coast-guard', 'coastGuard', 'Coast Guard', 'Executive', 'N/A', CoastGuardDocuments)}

     
        </Col>

        <Modal id='whiteHouseDocsModal' show={coastGuardModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCoastGuardModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Coast Guard Documents</h3></Modal.Header>
                  <CoastGuardDocuments />
                </Modal>


                <Col>
      <h5 id='presidentialFeedHeadingMobile' onClick={() => setFEMAModalIsOpen(true)}>FEMA Documents</h5>
      <div class='courtNewsMobile' id='fema'></div>
                    
                    {CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=federal-emergency-management-agency', 'fema', 'FEMA Documents', 'Executive', 'N/A', FEMADocuments)}

     
        </Col>

        <Modal id='whiteHouseDocsModal' show={femaModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFEMAModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>FEMA Documents</h3></Modal.Header>
                  <FEMADocuments />
                </Modal>

                <Col>
      <h5 id='presidentialFeedHeadingMobile' onClick={() => setSecretServiceModalIsOpen(true)}>Secret Service Documents</h5>
      <div class='courtNewsMobile' id='secretService'></div>
                    
                    {CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=secret-service', 'secretService', 'Secret Service Documents', 'Executive', 'N/A', SecretService)}

     
        </Col>

        <Modal id='whiteHouseDocsModal' show={secretServiceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setSecretServiceModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Secret Service Documents</h3></Modal.Header>
                  <SecretService />
                </Modal>

                <Col>
      <h5 id='presidentialFeedHeadingMobile' onClick={() => setTSAModalIsOpen(true)}>TSA Documents</h5>
      <div class='courtNewsMobile' id='tsa'></div>
                    
                    {CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=secret-service', 'tsa', 'TSA Documents', 'Executive', 'N/A', TSADocuments)}

     
        </Col>

        <Modal id='whiteHouseDocsModal' show={tsaModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTSAModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>TSA Documents</h3></Modal.Header>
                  <TSADocuments />
                </Modal>

                <Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setNSAModalIsOpen(true)}>NSA Documents</h5>


  <div class='courtNewsMobile' id='nsa'></div>

  {CreateFeedItemBillWithDescription('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=national-security-agency-central-security-service', 'nsa', 'NSA Documents', 'Executive', 'N/A', NSADocuments)}

</Col>
              
                <Modal id='whiteHouseDocsModal' show={nsaModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setNSAModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>NSA Documents</h3></Modal.Header>
                  <NSADocuments/>
                </Modal>

                <Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setArmyCorpsModalIsOpen(true)}>Army Corps of Engineers Documents</h5>


  <div class='courtNewsMobile' id='armyCorps'></div>

  {CreateFeedItemBillWithDescription('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=engineers-corps', 'armyCorps', 'Army Corps of Engineers Documents', 'Executive', 'N/A', ArmyCorpsEngineers)}

</Col>
              
                <Modal id='whiteHouseDocsModal' show={armyCorpsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setArmyCorpsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Army Corps of Engineers Documents</h3></Modal.Header>
                  <ArmyCorpsEngineers/>
                </Modal>

                <Col>
                <h5 id='presidentialFeedHeadingMobile'onClick={() => setCommitteeOnArmedServicesModalIsOpen(true)}>Committee on Armed Services</h5>
                        <div class='courtNewsMobile' id='houseComitteeOnArmedServices'></div>
                  
                        {CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/house_committee-xml/Committee_on_Armed_Services_Meeting_Feed.xml	', 'houseComitteeOnArmedServices', 'House Committee on Armed Services', 'Legislative', 'Foreign Affairs', HouseCommitteeOnArmedServices)}

                </Col>

                <Modal id='fullBillModal' show={committeeOnArmedServicesModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCommitteeOnArmedServicesModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on Armed Services</h3></Modal.Header>

<HouseCommitteeOnArmedServices/>
</Modal>

                <Col>
                        <div id='columnRightPadding'>
                        <h5 id='presidentialFeedHeadingMobile' onClick={() => setArmedServicesMaterialsModalIsOpen(true)}>Committee on Armed Services Materials</h5>
                          <div id='houseCommitteeOnArmedServicesMaterials'></div>
                          
                          {CreateFeedItemMaterials('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/houseCommittees/Committee%20on%20Armed%20Services.xml', 'houseCommitteeOnArmedServicesMaterials', 'House Committee on Armed Services Materials', 'Legislative', 'N/A', HouseCommitteeOnArmedServicesMaterials)}

     
                        </div>
                      </Col>

                      <Modal id='fullBillModal' show={armedServicesMaterialsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setArmedServicesMaterialsModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on Armed Services Materials</h3></Modal.Header>
<HouseCommitteeOnArmedServicesMaterials/>
</Modal>

<Col>
                          <h5 id='presidentialFeedHeadingMobile' onClick={() => setCommitteeOnIntelligenceModalIsOpen(true)}>Permanent Select Committee on Intelligence</h5>
                        <div class='courtNewsMobile'id='houseCommitteeOnIntelligence'></div>
                      


                        {CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=IG00', 'houseCommitteeOnIntelligence', 'Permanent Select Committee on Intelligence', 'Legislative', 'N/A', HouseCommitteeOnIntelligence)}

      
                          </Col>

                          <Modal id='fullBillModal' show={committeeOnIntelligenceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCommitteeOnIntelligenceModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Permanent Select Committee on Intelligence</h3></Modal.Header>

<HouseCommitteeOnIntelligence/>
</Modal>


                          <Col>
                          <h5 id='presidentialFeedHeadingMobile' onClick={() => setIntelligenceMaterialsModalIsOpen(true)}>Permanent Select Committee on Intelligence Materials</h5>
                        <div class='courtNewsMobile'id='houseCommitteeOnIntelligenceMaterials'></div>
                      


                        {CreateFeedItemMaterials('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/houseCommittees/Permanent%20Select%20Committee%20on%20Intelligence.xml', 'houseCommitteeOnIntelligenceMaterials', 'Permanent Select Committee on Intelligence Materials', 'Legislative', 'N/A', HouseCommitteeOnIntelligenceMaterials)}

      
                          </Col>

                          <Modal id='fullBillModal' show={intelligenceMaterialsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setIntelligenceMaterialsModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Permanent Select Committee on Intelligence Materials</h3></Modal.Header>

<HouseCommitteeOnIntelligenceMaterials/>
</Modal>

<Col>
                <h5 id='presidentialFeedHeadingMobile' onClick={() => setCommitteeOnForeignAffairsModalIsOpen(true)}>Committee on Foreign Affairs</h5>
                        <div class='courtNewsMobile'id='houseCommitteeOnForeignAffairs'></div>

                        {CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=FA00', 'houseCommitteeOnForeignAffairs', 'House Committee on Foreign Affairs', 'Legislative', 'Foreign Affairs', HouseCommitteeOnForeignAffairs)}

                </Col>

                <Modal id='fullBillModal' show={committeeOnForeignAffairsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCommitteeOnForeignAffairsModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on Foreign Affairs</h3></Modal.Header>

<HouseCommitteeOnForeignAffairs/>
</Modal>

                <Col>
                <h5 id='presidentialFeedHeadingMobile' onClick={() => setForeignAffairsMaterialsModalIsOpen(true)}>Committee on Foreign Affairs Materials</h5>
                        <div class='courtNewsMobile'id='houseCommitteeOnForeignAffairsMaterials'></div>
                     



                        {CreateFeedItemMaterials('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/houseCommittees/Committee%20on%20Foreign%20Affairs.xml', 'houseCommitteeOnForeignAffairsMaterials', 'House Committee on Foreign Affairs Materials', 'Legislative', 'Foreign Affairs', HouseCommitteeOnForeignAffairsMaterials)}

                </Col>

                <Modal id='fullBillModal' show={foreignAffairsMaterialsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setForeignAffairsMaterialsModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on Foreign Affairs Materials</h3></Modal.Header>

<HouseCommitteeOnForeignAffairsMaterials/>
</Modal>

<Col>
                <h5 id='presidentialFeedHeadingMobile' onClick={() => setCommitteeOnHomelandSecurityModalIsOpen(true)}>Committee on Homeland Security</h5>
                        <div class='courtNewsMobile'id='houseCommitteeOnHomelandSecurity'></div>
                    


                        {CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=HM00', 'houseCommitteeOnHomelandSecurity', 'House Committee on Homeland Security', 'Legislative', 'Foreign Affairs', HouseCommitteeOnHomelandSecurity)}


                </Col>

                <Modal id='fullBillModal' show={committeeOnHomelandSecurityModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCommitteeOnHomelandSecurityModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on Homeland Security</h3></Modal.Header>

<HouseCommitteeOnHomelandSecurity/>
</Modal>




                <Col>
                <h5 id='presidentialFeedHeadingMobile' onClick={() => setHomelandSecurityMaterialsModalIsOpen(true)}>Committee on Homeland Security Materials</h5>
                        <div class='courtNewsMobile'id='houseCommitteeOnHomelandSecurityMaterials'></div>
                    


                        {CreateFeedItemMaterials('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/houseCommittees/Committee%20on%20Homeland%20Security.xml', 'houseCommitteeOnHomelandSecurityMaterials', 'House Committee on Homeland Security Materials', 'Legislative', 'Foreign Affairs', HouseCommitteeOnHomelandSecurityMaterials)}


                </Col>

                <Modal id='fullBillModal' show={homelandSecurityMaterialsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHomelandSecurityMaterialsModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on Homeland Security Materials</h3></Modal.Header>
<HouseCommitteeOnHomelandSecurityMaterials/>
</Modal>

           
     
        
          <Col>

            </Col>


            <Col>
            <br></br><br></br><br></br><br></br>
<br></br>
<br></br>
<h5 id='headingSelectHomeCountry'>Support the Gov Glance Foundation  <Button id='donateButton' onClick={(e) => {
                        e.preventDefault();
                        window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                      }}><HeartFill id='donateIcon'/>    Donate</Button></h5>

</Col>
            <br></br><br></br><br></br><br></br><br></br><br></br><Navbar id='navbarMobileBottom' fixed="bottom">    

          


       
<div id='changeBranchDropdownMobile'>

           <MobileOffCanvas/>

            </div>

           


           
 
             
 
 
 
 
 
 
</Navbar>
</div>




             
              
    </>
  )
}
 }



  






export default DefenseTopicMobile;
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch,  } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment,  } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image, ModalFooter } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText,  ShieldShaded, CircleFill, Pen, People, ChatSquareQuote, ChatLeftQuote, BlockquoteRight, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, CaretRight, ChevronRight, ChevronCompactRight, CaretRightFill, ArrowRightShort,  ArrowRightCircle, PlayCircle, PauseCircle } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Axios from 'axios'
import { render } from '@testing-library/react';
import FullBillFeed from './FullBillFeed';
import FullWhiteHouseFeed from './FullWhiteHouseFeed';
import FullUSCourtNews from './FullUSCourtNews';
import FullWhiteHouseDocuments from './FullWhiteHouseDocuments';
import DailyDigest from './DailyDigest';
import State from './State';
import Illinois from './state/Illinois';
import CollectionPage from './CollectionPage';
import NewLaws from './NewLaws';
import BillsEnrolled from './BillsEnrolled';
import DepartmentOfState from './DepartmentOfState';
import DepartmentOfTreasury from './DepartmentOfTreasury';
import CourtAppeals from './CourtAppeals';
import Reports from './Reports';
import DepartmentOfDefense from './DepartmentOfDefense';
import DepartmentOfJustice from './DepartmentOfJustice';
import DepartmentOfTheInterior from './DepartmentOfTheInterior';
import DepartmentOfAgriculture from './DepartmentOfAgriculture';
import DepartmentOfCommerce from './DepartmentOfCommerce';
import DepartmentOfLabor from './DepartmentOfLabor';
import DepartmentOfHealthAndHumanServices from './DepartmentOfHealthAndHumanServices';
import DepartmentOfHousingAndUrbanDevelopment from './DepartmentOfHousingAndUrbanDevelopment';
import DepartmentOfTransportation from './DepartmentOfTransportation';
import DepartmentOfEnergy from './DepartmentOfEnergy';
import DepartmentOfEducation from './DepartmentOfEducation';
import DepartmentOfVeteranAffairs from './DepartmentOfVeteranAffairs';
import DepartmentOfHomelandSecurity from './DepartmentOfHomelandSecurity';
import EnvironmentalProtectionAgency from './EnvironmentalProtectionAgency';
import NationalAeronauticsAndSpaceAdministration from './NationalAeronauticsAndSpaceAdministration';
import TrendingBills from './TrendingBills';
import GAOReports from './GAOReports';
import LegislativeBranch from './LegislativeBranch';
import HouseComitteeOnAgriculture from './HouseComitteeOnAgriculture';
import HouseCommitteeOnAppropriations from './HouseCommitteeOnArmedServices';
import HouseCommitteeOnOversightAndReform from './HouseCommitteeOnOversightAndReform';

import App from './App';
import Overlay from 'react-overlays/esm/Overlay';
import HouseCommitteeOnArmedServices from './HouseCommitteeOnArmedServices';
import HouseCommitteeOnTheBudget from './HouseCommitteeOnTheBudget';
import HouseCommitteeOnEducationAndLabor from './HouseCommitteeOnEducationAndLabor';
import HouseFloor from './HouseFloor';
import HouseCommitteeOnEnergyAndCommerce from './HouseCommitteeOnEnergyAndCommerce';
import HouseCommitteeOnEthics from './HouseCommitteeOnEthics';
import CongressionalHearings from './CongressionalHearings';
import HouseCommitteeOnFinacialServices from './HouseCommitteeOnFinacialServices';
import HouseCommitteeOnForeignAffairs from './HouseCommitteeOnForeignAffairs';
import HouseCommitteeOnHomelandSecurity from './HouseCommitteeOnHomelandSecurity';
import HouseCommitteeOnTheJudiciary from './HouseCommitteeOnTheJudiciary';
import HouseCommitteeOnNaturalResources from './HouseCommitteeOnNaturalResources';
import HouseCommitteeOnHouseAdministration from './HouseCommitteeOnHouseAdministration';
import CongressionalBudgetOffice from './CongressionalBudgetOffice';
import PresidentialProclamations from './PresidentialProclamations';
import ExecutiveOrders from './ExecutiveOrders';
import{ whiteHouseSearch, createPresidentialProclamations, createExecutiveOrders, createWhiteHouseDocuments, createWhiteHouseFeed,
    createWhiteHouseFeedMobile,createDepartmentOfState,
    createDepartmentOfDefense, 
    createDepartmentOfJustice, createDepartmentOfTheInterior,
    createDepartmentOfAgriculture, createDepartmentOfCommerce,
    createDepartmentOfCommerceMobile, createDepartmentOfLabor,
    createDepartmentOfHealthAndHumanServices, createDepartmentOfHousingAndUrbanDevelopment, createDepartmentOfEducation
    ,  createDepartmentOfVeteransAffairs, createDepartmentOfHomelandSecurity
    , createEnvironmentalProtectionAgency,
    createDepartmentOfTreasury, createNationalScienceFoundation, 
     createEnvironmentalProtectionAgencyMobile, createNationalAeronauticsAndSpaceAdministration, Speak, createFederalRegisterDefenseDepartment, 
     createFederalRegisterStateDepartment, createFederalRegisterDefense,
    CreateFeedItem, CreateFeedItemPresidentialDocuments, CreateExecutiveOrders,
    CreateFeedItemExecutiveOrders,
    CreateFeedItemBillUpdates,
    CreateFeedItemExecutiveOffice,
    CreateFeedAPI,  } from './Functions'
import NationalScienceFoundation from './NationalScienceFoundation';
import FederalRegisterDefenseDepartment from './FederalRegisterDefenseDepartment';
import FederalRegisterStateDepartment from './FederalRegisterStateDepartment';
import FederalRegisterTreasuryDepartment from './FederalRegisterTreasuryDepartment';
import MobileOffCanvas from './MobileOffCanvas';
import MobileTopNavbar from './MobileTopNavbar';
import BillUpdates from './BillUpdates';
import CouncilOfEconomicAdvisers from './CouncilOfEconomicAdvisers';
import CouncilOnEnvironmentQuality from './CouncilOnEnvironmentQuality';
import DomesticPolicyCouncil from './DomesticPolicyCouncil';
import GenderPolicyCouncil from './GenderPolicyCouncil';
import NationalEconomicCouncil from './NationalEconomicCouncil';
import NationalSecurityCouncil from './NationalSecurityCouncil';
import OfficeOfIntergovernmentalAffairs from './OfficeOfIntergovernmentalAffairs';
import OfficeOfManagmentAndBudget from './OfficeOfManagmentAndBudget';
import OfficeOfNationalDrugControlPolicy from './OfficeOfNationalDrugControlPolicy';
import OfficeOfPublicEngagement from './OfficeOfPublicEngagement';
import OfficeOfScienceAndTechnologyPolicy from './OfficeOfScienceAndTechnologyPolicy';
import OfficeOfNationalCyberDirector from './OfficeOfNationalCyberDirector';
import PresidentialPersonnelOffice from './PresidentialPersonnelOffice';
import GenerateFeed from './GenerateFeed';


var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 30000;

export const ExecutiveOfficeMobile = () =>   {
    render()

    {
        const [show, setShow] = useState(true);

        const [modalIsOpen, setModalIsOpen] = useState(false)
        const [fullscreen, setFullScreen] = useState(true);
      
      
        const [fullBillModalIsOpen, setFullBillModalIsOpen] = useState(false)
      
        const [reportModalIsOpen, setReportModalIsOpen] = useState(false)
      
      
        const [whiteHouseFeedModalIsOpen, setWhiteHouseFeedModalIsOpen] = useState(false)
        const [whiteHouseDocsModalIsOpen, setWhiteHouseDocsModalIsOpen] = useState(false)
      
      
        const [courtNewsModalIsOpen, setCourtNewsModalIsOpen] = useState(false)
        const [courtModalIsOpen, setCourtModalIsOpen] = useState(false)
      
        const [courtAppealsModalIsOpen, setCourtAppealsModalIsOpen] = useState(false)
      
        const [newLawsModalIsOpen, setNewLawsModalIsOpen] = useState(false)
      
        const [billsEnrolledModalIsOpen, setBillsEnrolledModalIsOpen] = useState(false)
      
        const [legislativePageBillsEnrolledModalIsOpen, setLegislativePageBillsEnrolledModalIsOpen] = useState(false)
      
      
        const [departmentOfStateModalIsOpen, setDepartmentOfStateModalIsOpen] = useState(false)
        const [departmentOfTreasuryModalIsOpen, setDepartmentOfTreasuryModalIsOpen] = useState(false)
        const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
        const [departmentOfJusticeModalIsOpen, setDepartmentOfJusticeModalIsOpen] = useState(false)
        const [departmentOfTheInteriorModalIsOpen, setDepartmentOfTheInteriorModalIsOpen] = useState(false)
        const [departmentOfAgricultureModalIsOpen, setDepartmentOfAgricultureModalIsOpen] = useState(false)
        const [departmentOfCommerceModalIsOpen, setDepartmentOfCommerceModalIsOpen] = useState(false)
        const [departmentOfLaborModalIsOpen, setDepartmentOfLaborModalIsOpen] = useState(false)
        const [departmentOfHealthAndHumanServicesModalIsOpen, setDepartmentOfHealthAndHumanServicesModalIsOpen] = useState(false)
        const [departmentOfHousingAndUrbanDevelopmentModalIsOpen, setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen] = useState(false)
        const [departmentOfTransportationModalIsOpen, setDepartmentOfTransportationModalIsOpen] = useState(false)
        const [departmentOfEnergyModalIsOpen, setDepartmentOfEnergyModalIsOpen] = useState(false)
        const [departmentOfEducationModalIsOpen, setDepartmentOfEducationModalIsOpen] = useState(false)
        const [departmentOfVeteransAffairsModalIsOpen, setDepartmentOfVeteransAffairsModalIsOpen] = useState(false)
        const [departmentOfHomelandSecurityModalIsOpen, setDepartmentOfHomelandSecurityModalIsOpen] = useState(false)
        const [epaModalIsOpen, setEPAModalIsOpen] = useState(false)
        const [nsfModalIsOpen, setNSFModalIsOpen] = useState(false)
        const [nasaModalIsOpen, setNASAModalIsOpen] = useState(false)
        const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
        const [goaReportsModalIsOpen, setGAOReportsModalIsOpen] = useState(false)
        const [executiveOrdersModalIsOpen, setExecutiveOrdersModalIsOpen] = useState(false)
        const [presidentialProclamationsModalIsOpen, setPresidentialProclamationsModalIsOpen] = useState(false)
        const [statesModalIsOpen, setStatesModalIsOpen] = useState(false)
      
      
        const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false)
        const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)
      
      
        const [federalRegisterDefenseDepartmentModalIsOpen, setFederalRegisterDefenseDepartmentModalIsOpen] = useState(false)
  const [federalRegisterStateDepartmentModalIsOpen, setFederalRegisterStateDepartmentModalIsOpen] = useState(false)
        const [declarationModalIsOpen, setDeclarationModalIsOpen] = useState(false)
        const [constitutionModalIsOpen, setConstitutionModalIsOpen] = useState(false)
        const [billOfRightsModalIsOpen, setBillOfRightsModalIsOpen] = useState(false)
        const [economicAdModalIsOpen, setEconomicAdModalIsOpen] = useState(false)
        const [environmentQualityModalIsOpen, setEnvironmentQualityModalIsOpen] = useState(false)
        const [domesticPolicyModalIsOpen, setDomesticPolicyModalIsOpen] = useState(false)
        const [genderPolicyModalIsOpen, setGenderPolicyModalIsOpen] = useState(false)
        const [nationalEconomicModalIsOpen, setNationalEconomicModalIsOpen] = useState(false)
        const [securityModalIsOpen, setSecurityModalIsOpen] = useState(false)
        const [budgetModalIsOpen, setBudgetModalIsOpen] = useState(false)
        const [drugModalIsOpen, setDrugModalIsOpen] = useState(false)
        const [publicModalIsOpen, setPublicModalIsOpen] = useState(false)
        const [scienceModalIsOpen, setScienceModalIsOpen] = useState(false)
        const [cyberModalIsOpen, setCyberModalIsOpen] = useState(false)
        const [presidentialModalIsOpen, setPresidentialModalIsOpen] = useState(false)
        const [
          number1ModalIsOpen, setNumber1ModalIsOpen,
          number2ModalIsOpen, setNumber2ModalIsOpen,
          number3ModalIsOpen, setNumber3ModalIsOpen,
          number4ModalIsOpen, setNumber4ModalIsOpen,
          number5ModalIsOpen, setNumber5ModalIsOpen,
          number6ModalIsOpen, setNumber6ModalIsOpen,
          number7ModalIsOpen, setNumber7ModalIsOpen,
          number8ModalIsOpen, setNumber8ModalIsOpen,
          number9ModalIsOpen, setNumber9ModalIsOpen,
          number10ModalIsOpen, setNumber10ModalIsOpen,
          number11ModalIsOpen, setNumber11ModalIsOpen,
          number12ModalIsOpen, setNumber12ModalIsOpen,
          number13ModalIsOpen, setNumber13ModalIsOpen,
          number14ModalIsOpen, setNumber14ModalIsOpen,
          number15ModalIsOpen, setNumber15ModalIsOpen,
          number16ModalIsOpen, setNumber16ModalIsOpen,
          number17ModalIsOpen, setNumber17ModalIsOpen,
          number18ModalIsOpen, setNumber18ModalIsOpen,
          number19ModalIsOpen, setNumber19ModalIsOpen,
          number20ModalIsOpen, setNumber20ModalIsOpen,
          number21ModalIsOpen, setNumber21ModalIsOpen,
          number22ModalIsOpen, setNumber22ModalIsOpen,
          number23ModalIsOpen, setNumber23ModalIsOpen,
          number24ModalIsOpen, setNumber24ModalIsOpen,
          number25ModalIsOpen, setNumber25ModalIsOpen,
        ] = useState(false); 
    
    var branch = "all";
  
    return(
       <>
   <MobileTopNavbar/>
  
  
  
  
  <div id='homebg'>

               
 
            <h5 id='branchHeadingMobile' onClick={() => window.open('/us/executiveoffice', "_self")}> Executive Office of the President</h5>

       
              

                <Col>



                  <h5 id='presidentialFeedHeadingMobile' onClick={() => setNumber1ModalIsOpen(true)}>Council of Economic Advisers</h5>


                  <div class='courtNewsMobile' id='councilOfEconomicAd'></div>
                    
                  {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=council_of_economic_advisers&order_by=created_at', 
                          'councilOfEconomicAd', 
                            'Council of Economic Advisers', 'Executive', 
                            'Economy', 'Document', GenerateFeed)}

                          <Modal id='whiteHouseDocsModal' show={number1ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
                          centered onHide={() => setNumber1ModalIsOpen(false)}>
                            <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Council of Economic Advisers</h3></Modal.Header>
                            <GenerateFeed url='&skip=0&schema=united_states_of_america&table=council_of_economic_advisers&order_by=created_at' 
                            name='Council of Economic Advisers' branch='Executive' topic='Economy' buttonName='Document' />
                          </Modal>
                  </Col>
                 

              
      
                      <Col>

                <h5 id='presidentialFeedHeadingMobile' onClick={() => setEnvironmentQualityModalIsOpen(true)}>Council on Environment Quality</h5>


                <div class='courtNewsMobile' id='councilOnEnvironment'></div>
                     
                     {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=council_on_environmental_quality&order_by=created_at', 
                     'councilOnEnvironment', 
                       'Council on Environmental Quality', 'Executive', 
                       'Environment', 'Document', GenerateFeed)}

                     <Modal id='whiteHouseDocsModal' show={environmentQualityModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
                     centered onHide={() => setEnvironmentQualityModalIsOpen(false)}>
                       <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Council on Environmental Quality</h3></Modal.Header>
                       <GenerateFeed url='&skip=0&schema=united_states_of_america&table=council_on_environmental_quality&order_by=created_at' 
                       name='Council on Environmental Quality' branch='Executive' topic='Environment' buttonName='Document' />
                     </Modal>
                </Col>
  



              
    
            <Col>


               <h5 id='presidentialFeedHeadingMobile' onClick={() => setNumber2ModalIsOpen(true)}>Domestic Policy Council</h5> 


               
                <div class='courtNewsMobile' id='domesticPolicyCouncil'></div>
                          
                          {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=domestic_policy_council&order_by=created_at', 
                                             'domesticPolicyCouncil', 
                                               'Domestic Policy Council', 'Executive', 
                                               'N/A', 'Document', GenerateFeed)}
                        
                                             <Modal id='whiteHouseDocsModal' show={number2ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
                                             centered onHide={() =>  setNumber2ModalIsOpen(false)}>
                                               <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Domestic Policy Council</h3></Modal.Header>
                                               <GenerateFeed url='&skip=0&schema=united_states_of_america&table=domestic_policy_council&order_by=created_at' 
                                               name='Domestic Policy Council' branch='Executive' topic='N/A' buttonName='Document' />
                                             </Modal>
        </Col>
               




             
       
              
            <Col>

                <h5 id='presidentialFeedHeadingMobile' onClick={() => setNumber3ModalIsOpen(true)}>Gender Policy Council</h5>


              
                <div class='courtNewsMobile' id='genderPolicyCouncil'></div>
                          
                          {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=gender_policy_council&order_by=created_at', 
                                             'genderPolicyCouncil', 
                                               'Gender Policy Council', 'Executive', 
                                               'N/A', 'Document', GenerateFeed)}
                        
                                             <Modal id='whiteHouseDocsModal' show={number3ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
                                             centered onHide={() => setNumber3ModalIsOpen(false)}>
                                               <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Gender Policy Council</h3></Modal.Header>
                                               <GenerateFeed url='&skip=0&schema=united_states_of_america&table=gender_policy_council&order_by=created_at' 
                                               name='Gender Policy Council' branch='Executive' topic='N/A' buttonName='Document' />
                                             </Modal>
                         
        </Col>
               




           
       
              
<Col>

                <h5 id='presidentialFeedHeadingMobile'>National Economic Council</h5>
                


               

                  <div class='courtNewsMobile' id='nationalEconomicCouncil'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
                            {CreateFeedItemExecutiveOffice('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/executive_office_of_the_president/National_Economic_Council.xml', 'nationalEconomicCouncil', 'National Economic Council', 'Executive', 'N/A', NationalEconomicCouncil)}

                </Col>


                
                <Modal id='whiteHouseDocsModal' show={departmentOfStateModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfStateModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of State</h3></Modal.Header>
                  <DepartmentOfState />
                </Modal>


                <Col>


<h5 id='presidentialFeedHeadingMobile'>National Security Council</h5>


  <div class='courtNewsMobile' id='nationalSecurityCouncil'></div>
                          
                          {CreateFeedItemExecutiveOffice('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/executive_office_of_the_president/National_Security_Council.xml', 'nationalSecurityCouncil', 'National Security Council', 'Executive', 'N/A', NationalSecurityCouncil)}

     
</Col>
             
  
            <Col>


                <h5 id='presidentialFeedHeadingMobile'>Office of Intergovernmental Affairs</h5>


               

                  <div class='courtNewsMobile' id='officeOfIntergovernmentalAffairs'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
                            {CreateFeedItemExecutiveOffice('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/executive_office_of_the_president/Office_of_Intergovernmental_Affairs.xml', 'officeOfIntergovernmentalAffairs', 'Office of Intergovernmental Affairs', 'Executive', 'N/A', OfficeOfIntergovernmentalAffairs)}


                 </Col>
                
                <Modal id='whiteHouseDocsModal' show={departmentOfTreasuryModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfTreasuryModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Treasury</h3></Modal.Header>
                  <DepartmentOfTreasury />
                </Modal>



           
           
              <Col>


                <h5 id='presidentialFeedHeadingMobile'>Office of Management and Budget</h5>


                

                  <div class='courtNewsMobile' id='officeOfManagmentAndBudget'></div>
                          
                          {CreateFeedItemExecutiveOffice('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/executive_office_of_the_president/Office_of_Management_and_Budget.xml', 'officeOfManagmentAndBudget', 'Office of Management and Budget', 'Executive', 'N/A', OfficeOfManagmentAndBudget)}

                

                  </Col>

                  <Col>


<h5 id='presidentialFeedHeadingMobile'>Office of National Drug Control Policy</h5>


  <div class='courtNewsMobile' id='officeOfNationalDrugControl'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
                            {CreateFeedItemExecutiveOffice('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/executive_office_of_the_president/Office_of_National_Drug_Control_Policy.xml', 'officeOfNationalDrugControl', 'Office of National Drug Control Policy', 'Executive', 'N/A', OfficeOfNationalDrugControlPolicy)}

</Col>
              
                <Modal id='whiteHouseDocsModal' show={departmentOfDefenseModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfDefenseModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Defense</h3></Modal.Header>
                  <DepartmentOfDefense />
                </Modal>



              
 
            <Col>


                <h5 id='presidentialFeedHeadingMobile'>Office of Public Engagement</h5>


              

                  <div class='courtNewsMobile' id='officeOfPublicEngagement'></div>
                          
                          {CreateFeedItemExecutiveOffice('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/executive_office_of_the_president/Office_of_Public_Engagement.xml', 'officeOfPublicEngagement', 'Office of Public Engagement', 'Executive', 'N/A', OfficeOfPublicEngagement)}

                </Col>


                
                <Modal id='whiteHouseDocsModal' show={departmentOfJusticeModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfJusticeModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Justice</h3></Modal.Header>
                  <DepartmentOfJustice />
                </Modal>



              
       
            <Col>


                <h5 id='presidentialFeedHeadingMobile'>Office of Science and Technology Policy</h5>


          

                  <div class='courtNewsMobile' id='officeOfScienceAndTechnology'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
                            {CreateFeedItemExecutiveOffice('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/executive_office_of_the_president/Office_of_Science_and_Technology_Policy.xml', 'officeOfScienceAndTechnology', 'Office of Science and Technology Policy', 'Executive', 'N/A', OfficeOfScienceAndTechnologyPolicy)}



                
                <Modal id='whiteHouseDocsModal' show={departmentOfTheInteriorModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfTheInteriorModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of the Interior</h3></Modal.Header>
                  <DepartmentOfTheInterior />
                </Modal>
</Col>




            <Col >


                <h5 id='presidentialFeedHeadingMobile'>Office of the National Cyber Director</h5>


                

                  <div class='courtNewsMobile' id='officeOfTheNationalCyberDirector'></div>
                          
                          {CreateFeedItemExecutiveOffice('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/executive_office_of_the_president/Office_of_the_National_Cyber_Director.xml', 'officeOfTheNationalCyberDirector', 'Office of the National Cyber Director', 'Executive', 'N/A', OfficeOfNationalCyberDirector)}


                </Col>


              
                <Modal id='whiteHouseDocsModal' show={departmentOfAgricultureModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfAgricultureModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Agriculture</h3></Modal.Header>
                  <DepartmentOfAgriculture />
                </Modal>



 
          
            <Col>


                <h5 id='presidentialFeedHeadingMobile'>Presidential Personnel Office</h5>




                  <div class='courtNewsMobile' id='presidentialPersonnelOffice'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
                            {CreateFeedItemExecutiveOffice('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/executive_office_of_the_president/Presidential_Personnel_Office.xml', 'presidentialPersonnelOffice', 'Presidential Personnel Office', 'Executive', 'N/A', PresidentialPersonnelOffice)}


                </Col>


        
                <Modal id='whiteHouseDocsModal' show={departmentOfCommerceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfCommerceModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Commerce</h3></Modal.Header>
                  <DepartmentOfCommerce />
                </Modal>



    
        

               
                <Modal id='whiteHouseDocsModal' show={nasaModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setNASAModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>National Aeronautics and Space Administration (NASA)</h3></Modal.Header>
                  <NationalAeronauticsAndSpaceAdministration />
                </Modal>



       



         
                <Modal id='whiteHouseDocsModal' show={federalRegisterDefenseDepartmentModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFederalRegisterDefenseDepartmentModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Federal Register Defense Department</h3></Modal.Header>
                  <FederalRegisterDefenseDepartment />
                </Modal>



         



            



          


            <Col>

            </Col>

<br></br>
          
          
            <Navbar id='navbarMobileBottom' fixed="bottom">  

          


       
<div id='changeBranchDropdownMobile'>

           <MobileOffCanvas/>
              
            </div>

           


           
 
             
 
 
 
 
 
 
</Navbar>
          </div>

          </>
  )
}
 }



  






export default ExecutiveOfficeMobile;


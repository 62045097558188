
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch, } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment, } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText, ShieldShaded, Pen, PauseCircle, PlayCircle, People, HeartFill, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, ChevronRight } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Axios from 'axios'
import { render } from '@testing-library/react';
import FullBillFeed from './FullBillFeed';
import FullWhiteHouseFeed from './FullWhiteHouseFeed';
import FullUSCourtNews from './FullUSCourtNews';
import FullWhiteHouseDocuments from './FullWhiteHouseDocuments';
import DailyDigest from './DailyDigest';
import State from './State';
import Illinois from './state/Illinois';
import CollectionPage from './CollectionPage';
import NewLaws from './NewLaws';
import BillsEnrolled from './BillsEnrolled';
import DepartmentOfState from './DepartmentOfState';
import DepartmentOfTreasury from './DepartmentOfTreasury';
import CourtAppeals from './CourtAppeals';
import Reports from './Reports';
import DepartmentOfDefense from './DepartmentOfDefense';
import DepartmentOfJustice from './DepartmentOfJustice';
import DepartmentOfTheInterior from './DepartmentOfTheInterior';
import DepartmentOfAgriculture from './DepartmentOfAgriculture';
import DepartmentOfCommerce from './DepartmentOfCommerce';
import DepartmentOfLabor from './DepartmentOfLabor';
import DepartmentOfHealthAndHumanServices from './DepartmentOfHealthAndHumanServices';
import DepartmentOfHousingAndUrbanDevelopment from './DepartmentOfHousingAndUrbanDevelopment';
import DepartmentOfTransportation from './DepartmentOfTransportation';
import DepartmentOfEnergy from './DepartmentOfEnergy';
import DepartmentOfEducation from './DepartmentOfEducation';
import DepartmentOfVeteranAffairs from './DepartmentOfVeteranAffairs';
import DepartmentOfHomelandSecurity from './DepartmentOfHomelandSecurity';
import EnvironmentalProtectionAgency from './EnvironmentalProtectionAgency';
import NationalAeronauticsAndSpaceAdministration from './NationalAeronauticsAndSpaceAdministration';
import TrendingBills from './TrendingBills';
import GAOReports from './GAOReports';
import LegislativeBranch from './LegislativeBranch';
import HouseComitteeOnAgriculture from './HouseComitteeOnAgriculture';
import HouseCommitteeOnAppropriations from './HouseCommitteeOnArmedServices';

import App from './App';
import Overlay from 'react-overlays/esm/Overlay';
import ExecutiveOrders from './ExecutiveOrders';
import PresidentialProclamations from './PresidentialProclamations';
import NationalScienceFoundation from './NationalScienceFoundation';

import{ whiteHouseSearch, createPresidentialProclamations, createExecutiveOrders, createWhiteHouseDocuments, createWhiteHouseFeed,
  createWhiteHouseFeedMobile,createDepartmentOfState,
  createDepartmentOfDefense, 
  createDepartmentOfJustice, createDepartmentOfTheInterior,
  createDepartmentOfAgriculture, createDepartmentOfCommerce,
  createDepartmentOfCommerceMobile, createDepartmentOfLabor,
  createDepartmentOfHealthAndHumanServices, createDepartmentOfHousingAndUrbanDevelopment, createDepartmentOfEducation
  ,  createDepartmentOfVeteransAffairs, createDepartmentOfHomelandSecurity
  , createEnvironmentalProtectionAgency,
  createDepartmentOfTreasury, createNationalScienceFoundation, 
   createEnvironmentalProtectionAgencyMobile, createNationalAeronauticsAndSpaceAdministration, Speak, createFederalRegisterDefenseDepartment, createFederalRegisterStateDepartment, createFederalRegisterTreasuryDepartment, createFederalRegisterJusticeDepartment, createFederalRegisterInteriorDepartment, createFederalRegisterAgricultureDepartment, createFederalRegisterCommerceDepartment, createFederalRegisterLaborDepartment, createFederalRegisterHealthAndHumanServicesDepartment, createFederalRegisterHousingAndUrbanDevelopmentDepartment, createFederalRegisterHomelandSecurityDepartment, createFederalRegisterEducationDepartment, createFederalRegisterVeteransAffairsDepartment,
   CreateFeedItem, CreateFeedItemBillUpdates, CreateFeedItemWithDocButton,
   CreateFeedItemPresidentialDocuments, CreateFeedItemExecutiveOrders, CreateFeedItemCongressionalReports, CreateFeedItemExecutiveOffice, CreateFeedItemBillWithDescription, CreateFeedAPIDepartmentUS } from './Functions'
import ExecutiveBranchMobile from './ExecutiveBranchMobile';
import DesktopNavbar from './DesktopNavbar';
import DepartmentOfTheInteriorDocs from './DepartmentOfTheInteriorDocs';
import FishingAndWildlifeService from './FishingAndWildlifeService';
import GeologicalSurvey from './GeologicalSurvey';
import InteriorDeptHearings from './InteriorDeptHearings';
import IndianAffairsBureau from './IndianAffairsBureau';
import LandManagementBureau from './LandManagementBureau';
import NationalIndianGamingCommission from './NationalIndianGamingCommission';
import NationalParkService from './NationalParkService';
import ReclamationBureau from './ReclamationBureau';
import SpecialTrusteeForAmericanIndians from './SpecialTrusteeForAmericanIndians';
import SurfaceMiningReclamation from './SurfaceMiningReclamation';
import OceanEnergyManagmentBureau from './OceanEnergyManagmentBureau';
import SafetyAndEnvironmentEnforcement from './SafetyAndEnvironmentEnforcement';
import NaturalResourcesRevenueOffice from './NaturalResourcesRevenueOffice';
import TransportationMobile from './TransportationMobile';
import InteriorMobile from './InteriorMobile';
import GenerateFeedDepartment from './GenerateFeedDepartment';






var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 60000;





  

 


  


export const DepartmentOfInteriorDesk = () =>
 {
   render()
   
   {
    const [show, setShow] = useState(true);

        
        const [fullscreen, setFullScreen] = useState(true);
      
      
        const [fullBillModalIsOpen, setFullBillModalIsOpen] = useState(false)
      
        const [reportModalIsOpen, setReportModalIsOpen] = useState(false)
      
      
        const [whiteHouseFeedModalIsOpen, setWhiteHouseFeedModalIsOpen] = useState(false)
        const [whiteHouseDocsModalIsOpen, setWhiteHouseDocsModalIsOpen] = useState(false)
      
      
        const [courtNewsModalIsOpen, setCourtNewsModalIsOpen] = useState(false)
        const [courtModalIsOpen, setCourtModalIsOpen] = useState(false)
      
        const [courtAppealsModalIsOpen, setCourtAppealsModalIsOpen] = useState(false)
      
        const [newLawsModalIsOpen, setNewLawsModalIsOpen] = useState(false)
      
        const [billsEnrolledModalIsOpen, setBillsEnrolledModalIsOpen] = useState(false)
      
        const [legislativePageBillsEnrolledModalIsOpen, setLegislativePageBillsEnrolledModalIsOpen] = useState(false)
      
      
        const [departmentOfStateModalIsOpen, setDepartmentOfStateModalIsOpen] = useState(false)
        const [departmentOfTreasuryModalIsOpen, setDepartmentOfTreasuryModalIsOpen] = useState(false)
     
        const [departmentOfJusticeModalIsOpen, setDepartmentOfJusticeModalIsOpen] = useState(false)
        const [departmentOfTheInteriorModalIsOpen, setDepartmentOfTheInteriorModalIsOpen] = useState(false)
        const [departmentOfAgricultureModalIsOpen, setDepartmentOfAgricultureModalIsOpen] = useState(false)
        const [departmentOfCommerceModalIsOpen, setDepartmentOfCommerceModalIsOpen] = useState(false)
        const [departmentOfLaborModalIsOpen, setDepartmentOfLaborModalIsOpen] = useState(false)
        const [departmentOfHealthAndHumanServicesModalIsOpen, setDepartmentOfHealthAndHumanServicesModalIsOpen] = useState(false)
        const [departmentOfHousingAndUrbanDevelopmentModalIsOpen, setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen] = useState(false)
        const [departmentOfTransportationModalIsOpen, setDepartmentOfTransportationModalIsOpen] = useState(false)
        const [departmentOfEnergyModalIsOpen, setDepartmentOfEnergyModalIsOpen] = useState(false)
        const [departmentOfEducationModalIsOpen, setDepartmentOfEducationModalIsOpen] = useState(false)
        const [departmentOfVeteransAffairsModalIsOpen, setDepartmentOfVeteransAffairsModalIsOpen] = useState(false)
        const [departmentOfHomelandSecurityModalIsOpen, setDepartmentOfHomelandSecurityModalIsOpen] = useState(false)
        const [epaModalIsOpen, setEPAModalIsOpen] = useState(false)
        const [nsfModalIsOpen, setNSFModalIsOpen] = useState(false)
        const [nasaModalIsOpen, setNASAModalIsOpen] = useState(false)
        const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
        const [goaReportsModalIsOpen, setGAOReportsModalIsOpen] = useState(false)
        const [executiveOrdersModalIsOpen, setExecutiveOrdersModalIsOpen] = useState(false)
        const [presidentialProclamationsModalIsOpen, setPresidentialProclamationsModalIsOpen] = useState(false)
        const [statesModalIsOpen, setStatesModalIsOpen] = useState(false)
      
      
        const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false)
        const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)
      
      
        const [federalRegisterDefenseDepartmentModalIsOpen, setFederalRegisterDefenseDepartmentModalIsOpen] = useState(false)
  const [federalRegisterStateDepartmentModalIsOpen, setFederalRegisterStateDepartmentModalIsOpen] = useState(false)
        const [declarationModalIsOpen, setDeclarationModalIsOpen] = useState(false)
        const [constitutionModalIsOpen, setConstitutionModalIsOpen] = useState(false)
        const [billOfRightsModalIsOpen, setBillOfRightsModalIsOpen] = useState(false)

        const [economicAdModalIsOpen, setEconomicAdModalIsOpen] = useState(false)
        const [environmentQualityModalIsOpen, setEnvironmentQualityModalIsOpen] = useState(false)
        const [domesticPolicyModalIsOpen, setDomesticPolicyModalIsOpen] = useState(false)
        const [genderPolicyModalIsOpen, setGenderPolicyModalIsOpen] = useState(false)
        const [nationalEconomicModalIsOpen, setNationalEconomicModalIsOpen] = useState(false)
        const [securityModalIsOpen, setSecurityModalIsOpen] = useState(false)
        const [budgetModalIsOpen, setBudgetModalIsOpen] = useState(false)
        const [drugModalIsOpen, setDrugModalIsOpen] = useState(false)
        const [publicModalIsOpen, setPublicModalIsOpen] = useState(false)
        const [scienceModalIsOpen, setScienceModalIsOpen] = useState(false)
        const [cyberModalIsOpen, setCyberModalIsOpen] = useState(false)
        const [presidentialModalIsOpen, setPresidentialModalIsOpen] = useState(false)
        const [showBioModal, setBioModal] = useState(false);
        const [showMissionModal, setMissionModal] = useState(false);
        const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
        const [defenseDocumentsModalIsOpen, setDefenseDocumentsModalIsOpen] = useState(false)
        const [armyModalIsOpen, setArmyModalIsOpen] = useState(false)
        const [navyModalIsOpen, setNavyModalIsOpen] = useState(false)
        const [marinesModalIsOpen, setMarinesModalIsOpen] = useState(false)
        const [spaceForceModalIsOpen, setSpaceForceModalIsOpen] = useState(false)
        const [airForceModalIsOpen, setAirForceModalIsOpen] = useState(false)
        const [nationalGuardModalIsOpen, setNationalGuardModalIsOpen] = useState(false)
        const [ciaModalIsOpen, setCIAModalIsOpen] = useState(false)
        const [nsaModalIsOpen, setNSAModalIsOpen] = useState(false)
        const [armyCorpsModalIsOpen, setArmyCorpsModalIsOpen] = useState(false)
        const [interiorModalIsOpen, setInteriorModalIsOpen] = useState(false)
  return(
    <>
      <BrowserView>
        <div id='homePage'>

     

        <div id='homebg'>

          <DesktopNavbar/>
          <div id='feedDisplay' 
                  style={{ padding: '20px', borderRadius: '18px', marginTop: '0em'}}>

<div style={{ position: 'relative' }}>

     <div id='profileImage' style={{ width: '150px', height: '150px', borderRadius: '100%', overflow: 'hidden',  marginLeft: '40px' }}>
     <Image id='profileImage-image' src='/departmentOfInterior.png' />
   </div>


   <div style={{ position: 'absolute', top: '0', left: '250px', display: 'flex', flexDirection: 'column' }}>
   <div style={{ color: 'white', fontSize: '24px', fontWeight: 'bold' }}>

</div>
<div  style={{ color: 'white', fontSize: '20px' }}> 
<span> <h3  >Department of the Interior </h3></span>
<br></br>
<div style={{ color: 'white', fontSize: '20px' }}>
<span> The Department of the Interior (DOI) is the nation’s principal conservation agency. Its mission is to protect America’s natural resources, offer recreation opportunities, conduct scientific research, conserve and protect fish and wildlife, and honor the U.S. government’s responsibilities to American Indians, Alaskan Natives, and to island communities.</span>
<br></br>
<div style={{ color: 'white', fontSize: '18px' }}>

       <span><Link style={{color: '#21cff5'}} onClick={(e) => {
          e.preventDefault();
          window.open("https://www.doi.gov/", "_blank");
        }}>
          https://www.doi.gov/
          </Link></span> 
<br></br>
<Button id='whDocsViewMoreButton' onClick={() =>  setMissionModal(true)}>  <Files size={20}></Files> Read More</Button>
</div>
  </div>

     </div>
     </div>


     </div>

           </div>


           <Modal id='fullBillModal'  size="xl" aria-labelledby="contained-modal-title-vcenter" show={showMissionModal} onHide={() => setMissionModal(false)}>
         <Modal.Header id='billModalHeader' closeButton><h3> Department of the Interior</h3></Modal.Header>
       
         <div id='fullBillFeed' >
      
         <p>The Department of the Interior (DOI) is the nation’s principal conservation agency. Its mission is to protect America’s natural resources, offer recreation opportunities, conduct scientific research, conserve and protect fish and wildlife, and honor the U.S. government’s responsibilities to American Indians, Alaskan Natives, and island communities.</p>

<p>DOI manages approximately 500 million acres of surface land, about one-fifth of the land in the United States, and oversees hundreds of dams and reservoirs. Agencies within DOI include the Bureau of Indian Affairs, the Fish and Wildlife Service, and the U.S. Geological Survey. DOI also manages national parks and is responsible for protecting endangered species.</p>

<p>The Secretary of the Interior oversees about 70,000 employees and 200,000 volunteers with a budget of approximately $16 billion. Every year, DOI raises billions in revenue from energy, mineral, grazing, and timber leases, as well as recreational permits and land sales.</p>

<br></br>
<Link style={{color: '#21cff5'}} onClick={(e) => {
     e.preventDefault();
     window.open("https://www.whitehouse.gov/about-the-white-house/our-government/the-executive-branch/#:~:text=approximately%20%2440%20billion.-,DEPARTMENT%20OF%20THE%20INTERIOR,-The%20Department%20of", "_blank");
   }}>
     https://www.whitehouse.gov/about-the-white-house/our-government/the-executive-branch/#:~:text=approximately%20%2440%20billion.-,DEPARTMENT%20OF%20THE%20INTERIOR,-The%20Department%20of
     </Link>
 </div>

       </Modal>
           <br></br> <br></br> <br></br>
            <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>
                        <h4 id='whBreifingFeedHeading' onClick={() => setDepartmentOfDefenseModalIsOpen(true)}><b>News</b></h4>


<div  id='defenseDepartment'></div>

{CreateFeedItem('https://www.doi.gov/feeds/list/22038/rss.xml', 'defenseDepartment', 'Department of the Interior', 'Executive', 'N/A', DepartmentOfTheInterior)}

<Modal id='whiteHouseDocsModal' show={departmentOfDefenseModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfDefenseModalIsOpen(false)}>
                <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of the Interior</h3></Modal.Header>
                <DepartmentOfTheInterior/>
              </Modal>
                        </div>
                      </Col>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>
                        <h4 id='whBreifingFeedHeading' onClick={() => setDefenseDocumentsModalIsOpen(true)}><b>Documents</b></h4>


<div  id='frDefense'></div>

{CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=interior-department', 'frDefense', 'Department of the Interior Documents', 'Executive', 'N/A', DepartmentOfTheInteriorDocs)}

<Modal id='whiteHouseDocsModal' show={defenseDocumentsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDefenseDocumentsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of the Interior Documents</h3></Modal.Header>
                  <DepartmentOfTheInteriorDocs/>
                </Modal>
                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
                </div>    
           
                <div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/geological-survey', "_self")}}><b>Geological Survey</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defGs'></div>
            {CreateFeedAPIDepartmentUS('&table_name=interior&agency=Geological%20Survey', 'defGs',
              'Geological Survey Documents', 'Executive',
              'Environment', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={interiorModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setInteriorModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Geological Survey</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=interior&agency=Geological%20Survey'
                name='Geological Survey' branch='Executive' topic='Environment' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/hearings-appeals', "_self")}}><b>Hearings and Appeals Office, Interior Department</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defHaoid'></div>
            {CreateFeedAPIDepartmentUS('&table_name=interior&agency=Hearings%20and%20Appeals%20Office%2C%20Interior%20Department', 'defHaoid',
              'Hearings and Appeals Office, Interior Department Documents', 'Executive',
              'Environment', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={interiorModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setInteriorModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Hearings and Appeals Office, Interior Department</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=interior&agency=Hearings%20and%20Appeals%20Office%2C%20Interior%20Department'
                name='Hearings and Appeals Office, Interior Department' branch='Executive' topic='Environment' buttonName='Doc' />
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/indian-affairs', "_self")}}><b>Indian Affairs Bureau</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defIab'></div>
            {CreateFeedAPIDepartmentUS('&table_name=interior&agency=Indian%20Affairs%20Bureau', 'defIab',
              'Indian Affairs Bureau Documents', 'Executive',
              'Environment', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={interiorModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setInteriorModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Indian Affairs Bureau</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=interior&agency=Indian%20Affairs%20Bureau'
                name='Indian Affairs Bureau' branch='Executive' topic='Environment' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/indian-trust-transition', "_self")}}><b>Indian Trust Transition Office</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defItto'></div>
            {CreateFeedAPIDepartmentUS('&table_name=interior&agency=Indian%20Trust%20Transition%20Office', 'defItto',
              'Indian Trust Transition Office Documents', 'Executive',
              'Environment', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={interiorModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setInteriorModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Indian Trust Transition Office</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=interior&agency=Indian%20Trust%20Transition%20Office'
                name='Indian Trust Transition Office' branch='Executive' topic='Environment' buttonName='Doc' />
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/land-management', "_self")}}><b>Land Management Bureau</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defLmb'></div>
            {CreateFeedAPIDepartmentUS('&table_name=interior&agency=Land%20Management%20Bureau', 'defLmb',
              'Land Management Bureau Documents', 'Executive',
              'Environment', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={interiorModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setInteriorModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Land Management Bureau</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=interior&agency=Land%20Management%20Bureau'
                name='Land Management Bureau' branch='Executive' topic='Environment' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/minerals-management', "_self")}}><b>Minerals Management Service</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defMms'></div>
            {CreateFeedAPIDepartmentUS('&table_name=interior&agency=Minerals%20Management%20Service', 'defMms',
              'Minerals Management Service Documents', 'Executive',
              'Environment', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={interiorModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setInteriorModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Minerals Management Service</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=interior&agency=Minerals%20Management%20Service'
                name='Minerals Management Service' branch='Executive' topic='Environment' buttonName='Doc' />
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/mines-bureau', "_self")}}><b>Mines Bureau</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defMb'></div>
            {CreateFeedAPIDepartmentUS('&table_name=interior&agency=Mines%20Bureau', 'defMb',
              'Mines Bureau Documents', 'Executive',
              'Environment', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={interiorModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setInteriorModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Mines Bureau</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=interior&agency=Mines%20Bureau'
                name='Mines Bureau' branch='Executive' topic='Environment' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/national-biological', "_self")}}><b>National Biological Service</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defNbs'></div>
            {CreateFeedAPIDepartmentUS('&table_name=interior&agency=National%20Biological%20Service', 'defNbs',
              'National Biological Service Documents', 'Executive',
              'Environment', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={interiorModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setInteriorModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>National Biological Service</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=interior&agency=National%20Biological%20Service'
                name='National Biological Service' branch='Executive' topic='Environment' buttonName='Doc' />
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/nccc', "_self")}}><b>National Civilian Community Corps</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defNccc'></div>
            {CreateFeedAPIDepartmentUS('&table_name=interior&agency=National%20Civilian%20Community%20Corps', 'defNccc',
              'National Civilian Community Corps Documents', 'Executive',
              'Environment', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={interiorModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setInteriorModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>National Civilian Community Corps</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=interior&agency=National%20Civilian%20Community%20Corps'
                name='National Civilian Community Corps' branch='Executive' topic='Environment' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/indian-gaming', "_self")}}><b>National Indian Gaming Commission</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defNigc'></div>
            {CreateFeedAPIDepartmentUS('&table_name=interior&agency=National%20Indian%20Gaming%20Commission', 'defNigc',
              'National Indian Gaming Commission Documents', 'Executive',
              'Environment', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={interiorModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setInteriorModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>National Indian Gaming Commission</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=interior&agency=National%20Indian%20Gaming%20Commission'
                name='National Indian Gaming Commission' branch='Executive' topic='Environment' buttonName='Doc' />
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/national-park-service', "_self")}}><b>National Park Service</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defNps'></div>
            {CreateFeedAPIDepartmentUS('&table_name=interior&agency=National%20Park%20Service', 'defNps',
              'National Park Service Documents', 'Executive',
              'Environment', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={interiorModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setInteriorModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>National Park Service</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=interior&agency=National%20Park%20Service'
                name='National Park Service' branch='Executive' topic='Environment' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/natural-resources-revenue', "_self")}}><b>Natural Resources Revenue Office</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defNrro'></div>
            {CreateFeedAPIDepartmentUS('&table_name=interior&agency=Natural%20Resources%20Revenue%20Office', 'defNrro',
              'Natural Resources Revenue Office Documents', 'Executive',
              'Environment', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={interiorModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setInteriorModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Natural Resources Revenue Office</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=interior&agency=Natural%20Resources%20Revenue%20Office'
                name='Natural Resources Revenue Office' branch='Executive' topic='Environment' buttonName='Doc' />
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/ocean-energy-management', "_self")}}><b>Ocean Energy Management Bureau</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defOemb'></div>
            {CreateFeedAPIDepartmentUS('&table_name=interior&agency=Ocean%20Energy%20Management%20Bureau', 'defOemb',
              'Ocean Energy Management Bureau Documents', 'Executive',
              'Environment', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={interiorModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setInteriorModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Ocean Energy Management Bureau</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=interior&agency=Ocean%20Energy%20Management%20Bureau'
                name='Ocean Energy Management Bureau' branch='Executive' topic='Environment' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/ocean-energy-enforcement', "_self")}}><b>Ocean Energy Management, Regulation, and Enforcement Bureau</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defOemre'></div>
            {CreateFeedAPIDepartmentUS('&table_name=interior&agency=Ocean%20Energy%20Management%2C%20Regulation%2C%20and%20Enforcement%20Bureau', 'defOemre',
              'Ocean Energy Management, Regulation, and Enforcement Bureau Documents', 'Executive',
              'Environment', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={interiorModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setInteriorModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Ocean Energy Management, Regulation, and Enforcement Bureau</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=interior&agency=Ocean%20Energy%20Management%2C%20Regulation%2C%20and%20Enforcement%20Bureau'
                name='Ocean Energy Management, Regulation, and Enforcement Bureau' branch='Executive' topic='Environment' buttonName='Doc' />
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/reclamation', "_self")}}><b>Reclamation Bureau</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defRb'></div>
            {CreateFeedAPIDepartmentUS('&table_name=interior&agency=Reclamation%20Bureau', 'defRb',
              'Reclamation Bureau Documents', 'Executive',
              'Environment', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={interiorModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setInteriorModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Reclamation Bureau</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=interior&agency=Reclamation%20Bureau'
                name='Reclamation Bureau' branch='Executive' topic='Environment' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/safety-environmental-enforcement', "_self")}}><b>Safety and Environmental Enforcement Bureau</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defSeeb'></div>
            {CreateFeedAPIDepartmentUS('&table_name=interior&agency=Safety%20and%20Environmental%20Enforcement%20Bureau', 'defSeeb',
              'Safety and Environmental Enforcement Bureau Documents', 'Executive',
              'Environment', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={interiorModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setInteriorModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Safety and Environmental Enforcement Bureau</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=interior&agency=Safety%20and%20Environmental%20Enforcement%20Bureau'
                name='Safety and Environmental Enforcement Bureau' branch='Executive' topic='Environment' buttonName='Doc' />
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/special-trustee-american-indians', "_self")}}><b>Special Trustee for American Indians Office</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defStoai'></div>
            {CreateFeedAPIDepartmentUS('&table_name=interior&agency=Special%20Trustee%20for%20American%20Indians%20Office', 'defStoai',
              'Special Trustee for American Indians Office Documents', 'Executive',
              'Environment', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={interiorModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setInteriorModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Special Trustee for American Indians Office</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=interior&agency=Special%20Trustee%20for%20American%20Indians%20Office'
                name='Special Trustee for American Indians Office' branch='Executive' topic='Environment' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/surface-mining', "_self")}}><b>Surface Mining Reclamation and Enforcement Office</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defSmreo'></div>
            {CreateFeedAPIDepartmentUS('&table_name=interior&agency=Surface%20Mining%20Reclamation%20and%20Enforcement%20Office', 'defSmreo',
              'Surface Mining Reclamation and Enforcement Office Documents', 'Executive',
              'Environment', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={interiorModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setInteriorModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Surface Mining Reclamation and Enforcement Office</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=interior&agency=Surface%20Mining%20Reclamation%20and%20Enforcement%20Office'
                name='Surface Mining Reclamation and Enforcement Office' branch='Executive' topic='Environment' buttonName='Doc' />
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>



   

            <Modal id='aboutModal' show={aboutModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAboutModalIsOpen(false)}>
            <div id='removedAlert'></div>
            <Modal.Header id='aboutModalHeader' closeButton><h3>About</h3></Modal.Header>
  <ModalBody id='aboutInfo'><p id='aboutParagraph'>Gov Glance was created to drive civic engagement and support a 
              healthier democracy by helping citizens get official Government news in an easy-to-navigate centralized location. 
              There will never be any opinion or political analysis(That's your job), only information from official Government 
              sources. Gov Glance is structured after the U.S. government that is broken into three branches Legislative, 
              Executive, and Judicial. To learn more about the structure and functions of The U.S. Government go to <a href='https://www.usa.gov/branches-of-government'>https://www.usa.gov/branches-of-government</a>.
              </p>

              <p id='aboutParagraph'>
              Gov Glance Foundation Inc. is a non-profit that was created to operate govglance.org and continue to create educational tools that help people learn about the workings of society
              </p>
            <br></br>

            <h3>Privacy</h3>

            <br></br>

              <p id='aboutParagraph'>Gov Glance does not collect any personal user information. 
              Features like collection save news items directly to your browser's local storage for your convenience
              and is not shared with Gov Glance servers. We do not collect, store, or send personal information to Google LLC, 
              but we do use some Google services to deliver our app to you. For any questions into Google’s privacy practices 
              here is a link to their privacy policy: <a href='https://policies.google.com/privacy?hl=en-US#intro'>https://policies.google.com/privacy?hl=en-US#intro</a>

              <br></br><br></br>

              For site analytics we use plausible.io an open source privacy friendly analytics provider. <a href="https://plausible.io/about" target="_blank">https://plausible.io/about</a>
            
            </p>
            <br></br>


            <h3>Contact</h3>

            <p id='aboutParagraph'>
                      Contact us via email: <a href='mailto:contact@govglance.org'>contact@govglance.org</a>

            </p>

            <br></br>


            
            </ModalBody>
          </Modal>
 
          <footer id='homeFooter'>
      <Navbar>
        <Row id='footerNav'>
      <Nav variant='pills'>
        
     <Nav.Link id='footerContent'>Gov Glance Foundation Inc.
</Nav.Link>
<Nav.Item>
<Nav.Link id='footerContent' href='mailto:contact@govglance.org'>Contact</Nav.Link>
</Nav.Item>
<Nav.Item>
<Nav.Link id='footerContent' onClick={(e) => {
                   e.preventDefault();
                   window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                 }}> <HeartFill id='donateIcon'/>    Donate</Nav.Link>
</Nav.Item>
<Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/about', "_self")}} id='footerContent'>Privacy</Nav.Link>
    
     
 </Nav>
 </Row>
 </Navbar>
      </footer>



</div>
         </div>
          

              </BrowserView>

              <MobileView>

    <InteriorMobile/>

              </MobileView>
              
    </>
  )
}
 }



  






export default DepartmentOfInteriorDesk;
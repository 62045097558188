
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch, } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment, } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText, ShieldShaded, Pen, People, Collection, PlayCircle, PauseCircle, HeartFill, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, Coin, Tree, Globe2, ChevronRight } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Axios from 'axios'
import { render } from '@testing-library/react';
import FullBillFeed from './FullBillFeed';
import FullWhiteHouseFeed from './FullWhiteHouseFeed';
import FullUSCourtNews from './FullUSCourtNews';
import FullWhiteHouseDocuments from './FullWhiteHouseDocuments';
import DailyDigest from './DailyDigest';
import State from './State';
import Illinois from './state/Illinois';
import CollectionPage from './CollectionPage';
import NewLaws from './NewLaws';
import BillsEnrolled from './BillsEnrolled';
import DepartmentOfState from './DepartmentOfState';
import DepartmentOfTreasury from './DepartmentOfTreasury';
import CourtAppeals from './CourtAppeals';
import Reports from './Reports';
import DepartmentOfDefense from './DepartmentOfDefense';
import DepartmentOfJustice from './DepartmentOfJustice';
import DepartmentOfTheInterior from './DepartmentOfTheInterior';
import DepartmentOfAgriculture from './DepartmentOfAgriculture';
import DepartmentOfCommerce from './DepartmentOfCommerce';
import DepartmentOfLabor from './DepartmentOfLabor';
import DepartmentOfHealthAndHumanServices from './DepartmentOfHealthAndHumanServices';
import DepartmentOfHousingAndUrbanDevelopment from './DepartmentOfHousingAndUrbanDevelopment';
import DepartmentOfTransportation from './DepartmentOfTransportation';
import DepartmentOfEnergy from './DepartmentOfEnergy';
import DepartmentOfEducation from './DepartmentOfEducation';
import DepartmentOfVeteranAffairs from './DepartmentOfVeteranAffairs';
import DepartmentOfHomelandSecurity from './DepartmentOfHomelandSecurity';
import EnvironmentalProtectionAgency from './EnvironmentalProtectionAgency';
import NationalAeronauticsAndSpaceAdministration from './NationalAeronauticsAndSpaceAdministration';
import TrendingBills from './TrendingBills';
import GAOReports from './GAOReports';
import LegislativeBranch from './LegislativeBranch';
import HouseComitteeOnAgriculture from './HouseComitteeOnAgriculture';
import HouseCommitteeOnAppropriations from './HouseCommitteeOnArmedServices';

import App from './App';
import Overlay from 'react-overlays/esm/Overlay';
import SecuritiesAndExchangeCommissionPressReleases from './SecuritiesAndExchangeCommissionPressReleases';
import SecuritiesAndExchangeCommissionSpeechesAndStatments from './SecuritiesAndExchangeCommissionSpeechesAndStatments';
import BureauOfEconomicAnalysis from './BureauOfEconomicAnalysis';
import EconomicIndicators from './EconomicIndicators';
import HouseCommitteeOnTheBudget from './HouseCommitteeOnTheBudget';
import HouseCommitteeOnEnergyAndCommerce from './HouseCommitteeOnEnergyAndCommerce';
import HouseCommitteeOnEducationAndLabor from './HouseCommitteeOnEducationAndLabor';
import HouseCommitteeOnFinacialServices from './HouseCommitteeOnFinacialServices';
import CongressionalBudgetOffice from './CongressionalBudgetOffice';
import HouseCommitteeOnIntelligence from './HouseCommitteeOnIntelligence';
import HouseCommitteeOnArmedServices from './HouseCommitteeOnArmedServices';
import UNTopStories from './UNTopStories';
import UNJournal from './UNJournal';
import UNNewsAmericas from './UNNewsAmericas';
import ArmsControl from './ArmsControl';
import { Speak, CreateFeedItem, CreateFeedItemBillWithDescription, CreateFeedAPI } from './Functions';
import DemocracyHumanRightsLabor from './DemocracyHumanRightsLabor';
import CounterTerrorism from './CounterTerrorism';
import DoSPress from './DoSPress';
import DiplomaticSecurity from './DiplomaticSecurity';
import DesktopNavbar from './DesktopNavbar';
import { createFederalRegisterDefenseDepartment, createFederalRegisterStateDepartment, createDepartmentOfState, 
  createDepartmentOfDefense, createHouseCommitteeOnArmedServices, createUNTopStories, createUNJournal, 
  createUNNewsAmericas, createDemocracyHumanRightsLabor, createDoSPress, createDiplomaticSecurity, 
  createCounterterrorism, createArmsControl, createHouseCommitteeOnIntelligence} from './Functions';
import FederalRegisterDefenseDepartment from './FederalRegisterDefenseDepartment';
import FederalRegisterStateDepartment from './FederalRegisterStateDepartment';
import AddNoteModal from './AddNoteModal';
import ForeignAffairsMobile from './ForeignAffairsMobile';
import ArmyDocuments from './ArmyDocuments';
import GenerateFeed from './GenerateFeed';



var carouselInterval = 30000;
var mobileCarouselInterval = 60000;












export const ForeignAffairs= () =>
 {
   render()
   
   {
  const [show, setShow] = useState(true);

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [fullscreen, setFullScreen] = useState(true);
  const [statesModalIsOpen, setStatesModalIsOpen] = useState(false)

  const [fullBillModalIsOpen, setFullBillModalIsOpen] = useState(false)

  const [reportModalIsOpen, setReportModalIsOpen] = useState(false)


  const [whiteHouseFeedModalIsOpen, setWhiteHouseFeedModalIsOpen] = useState(false)
  const [whiteHouseDocsModalIsOpen, setWhiteHouseDocsModalIsOpen] = useState(false)


  const [courtNewsModalIsOpen, setCourtNewsModalIsOpen] = useState(false)
  const [courtModalIsOpen, setCourtModalIsOpen] = useState(false)

  const [courtAppealsModalIsOpen, setCourtAppealsModalIsOpen] = useState(false)

  const [newLawsModalIsOpen, setNewLawsModalIsOpen] = useState(false)

  const [billsEnrolledModalIsOpen, setBillsEnrolledModalIsOpen] = useState(false)

  const [legislativePageBillsEnrolledModalIsOpen, setLegislativePageBillsEnrolledModalIsOpen] = useState(false)


  const [departmentOfStateModalIsOpen, setDepartmentOfStateModalIsOpen] = useState(false)
  const [departmentOfTreasuryModalIsOpen, setDepartmentOfTreasuryModalIsOpen] = useState(false)
  const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
  const [departmentOfJusticeModalIsOpen, setDepartmentOfJusticeModalIsOpen] = useState(false)
  const [departmentOfTheInteriorModalIsOpen, setDepartmentOfTheInteriorModalIsOpen] = useState(false)
  const [departmentOfAgricultureModalIsOpen, setDepartmentOfAgricultureModalIsOpen] = useState(false)
  const [departmentOfCommerceModalIsOpen, setDepartmentOfCommerceModalIsOpen] = useState(false)
  const [departmentOfLaborModalIsOpen, setDepartmentOfLaborModalIsOpen] = useState(false)
  const [departmentOfHealthAndHumanServicesModalIsOpen, setDepartmentOfHealthAndHumanServicesModalIsOpen] = useState(false)
  const [departmentOfHousingAndUrbanDevelopmentModalIsOpen, setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen] = useState(false)
  const [departmentOfTransportationModalIsOpen, setDepartmentOfTransportationModalIsOpen] = useState(false)
  const [departmentOfEnergyModalIsOpen, setDepartmentOfEnergyModalIsOpen] = useState(false)
  const [departmentOfEducationModalIsOpen, setDepartmentOfEducationModalIsOpen] = useState(false)
  const [departmentOfVeteransAffairsModalIsOpen, setDepartmentOfVeteransAffairsModalIsOpen] = useState(false)
  const [departmentOfHomelandSecurityModalIsOpen, setDepartmentOfHomelandSecurityModalIsOpen] = useState(false)
  const [epaModalIsOpen, setEPAModalIsOpen] = useState(false)
  const [nasaModalIsOpen, setNASAModalIsOpen] = useState(false)
  const [beaModalIsOpen, setBEAModalIsOpen] = useState(false)
  const [secPressModalIsOpen, setSECPressModalIsOpen] = useState(false)
  const [unTopStoriesModalIsOpen, setUNTopStoriesModalIsOpen] = useState(false)
  const [unJournalModalIsOpen, setUNJournalModalIsOpen] = useState(false)
  const [unNewsAmericasModalIsOpen, setNewsAmericasModalIsOpen] = useState(false)
  const [armsControlModalIsOpen, setArmsControlModalIsOpen] = useState(false)
    const [secSAndSModalIsOpen, setSECSAndSModalIsOpen] = useState(false)
  const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
  const [goaReportsModalIsOpen, setGAOReportsModalIsOpen] = useState(false)

  const [houseComitteeOnAgricultureModalIsOpen, setHouseComitteeOnAgricultureModalIsOpen] = useState(false)
  const [houseCommitteeOnAppropriationsModalIsOpen, setHouseCommitteeOnAppropriationsModalIsOpen] = useState(false)
  const [federalRegisterDefenseDepartmentModalIsOpen, setFederalRegisterDefenseDepartmentModalIsOpen] = useState(false)
  const [federalRegisterStateDepartmentModalIsOpen, setFederalRegisterStateDepartmentModalIsOpen] = useState(false)
  const [houseCommitteeOnTheBudgetModalIsOpen, setHouseCommitteeOnTheBudgetModalIsOpen] = useState(false)
  const [houseCommitteeOnEnergyAndCommerceModalIsOpen, setHouseCommitteeOnEnergyAndCommerceModalIsOpen] = useState(false)
  const [houseCommitteeOnEducationAndLaborModalIsOpen, setHouseCommitteeOnEducationAndLaborModalIsOpen] = useState(false)
  const [houseCommitteeOnEthicsModalIsOpen, setHouseCommitteeOnEthicsModalIsOpen] = useState(false)
  const [houseCommitteeOnFinancialServicesModalIsOpen, setHouseCommitteeOnFinancialServicesModalIsOpen] = useState(false)
  const [houseCommitteeOnForeignAffairsModalIsOpen, setHouseCommitteeOnForeignAffairsModalIsOpen] = useState(false)
  const [houseCommitteeOnHomelandSecurityModalIsOpen, setHouseCommitteeOnHomelandSecurityModalIsOpen] = useState(false)
  const [houseCommitteeOnHouseAdministrationModalIsOpen, setHouseCommitteeOnHouseAdministrationModalIsOpen] = useState(false)
  const [houseCommitteeOnTheJudiciaryModalIsOpen, setHouseCommitteeOnTheJudiciaryModalIsOpen] = useState(false)
  const [houseCommitteeOnNaturalResourcesModalIsOpen, setHouseCommitteeOnNaturalResourcesModalIsOpen] = useState(false)
  const [congressionalBudgetOfficeModalIsOpen, setCongressionalBudgetOfficeModalIsOpen] = useState(false)
  const [houseCommitteeOnIntelligenceModalIsOpen, setHouseCommitteeOnIntelligenceModalIsOpen] = useState(false)
  const [houseCommitteeOnArmedServicesModalIsOpen, setHouseCommitteeOnOnArmedServicesModalIsOpen] = useState(false)


  const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false)
  const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)
  const [counterterrorismModalIsOpen, setCounterterrorismModalIsOpen] = useState(false)
  const [democracyHumanRightsLaborModalIsOpen, setDemocracyHumanRightsLaborModalIsOpen] = useState(false)
  const [dOSPressModalIsOpen, setDOSPressModalIsOpen] = useState(false)
  const [diplomaticSecurityModalIsOpen, setDiplomaticSecurityModalIsOpen] = useState(false)

  const [declarationModalIsOpen, setDeclarationModalIsOpen] = useState(false)
  const [constitutionModalIsOpen, setConstitutionModalIsOpen] = useState(false)
  const [billOfRightsModalIsOpen, setBillOfRightsModalIsOpen] = useState(false)

  const [stateDocumentsModalIsOpen, setStateDocumentsModalIsOpen] = useState(false)
    const [defenseDocumentsModalIsOpen, setDefenseDocumentsModalIsOpen] = useState(false)
  return(
    <>
      <BrowserView>
        <div id='homePage'>

 
        <div id='homebg'>

        <DesktopNavbar/>       
            <div id='sectionHeading'>
             <Row>
            
              <h3 id='branchHeading' ><Globe2></Globe2> <b>Foreign Affairs</b></h3>
       
            </Row>
            </div>
                     
            <div id='branchPage'>
              <Container class='bgcolor' >
                <Container id='carouselBufferThree'>

                  <Row>

                    <Col id='legislativeColLeft'>
                      <div id='columnLefttPadding'>
                      <h4 id='departmentOfDefenseHeadingInCol' onClick={() => window.open('/us/departmentofstate', "_self")}><b>Department of State</b><ChevronRight id='clickChevron'></ChevronRight></h4>

<Carousel id='carouselInColumn' controls={false}  >



<Carousel.Item interval={carouselInterval}>



<h4 id='departmentOfJusticeHeading' onClick={() => setDepartmentOfStateModalIsOpen(true)}><b>News</b></h4>





<div id='stateDepartment'></div>

{CreateFeedAPI('&skip=0&schema=united_states_of_america&table=state&order_by=created_at', 
                  'stateDepartment', 'Department of State News', 'Executive', 
                            'N/A', '', GenerateFeed)}
        <Modal id='whiteHouseDocsModal' show={departmentOfStateModalIsOpen} 
        size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() =>setDepartmentOfStateModalIsOpen(false)}>
          <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>News</h3></Modal.Header>
          <GenerateFeed url='&skip=0&schema=united_states_of_america&table=department_of_state&order_by=created_at' 
                            name='Department of State News' branch='Executive' topic='N/A' buttonName='' />
        </Modal>








</Carousel.Item>

<Carousel.Item interval={carouselInterval}>




<h4 id='departmentOfJusticeHeading' onClick={() => setStateDocumentsModalIsOpen(true)}><b>Documents</b></h4>


<div id='frState'></div>

{CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=state-department', 'frState', 'Department of State Documents', 'Executive', 'N/A', FederalRegisterStateDepartment)}



<Modal id='whiteHouseDocsModal' show={stateDocumentsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setStateDocumentsModalIsOpen(false)}>
<Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of State Documents</h3></Modal.Header>
<FederalRegisterStateDepartment/>
</Modal>

<Modal id='whiteHouseDocsModal' show={defenseDocumentsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDefenseDocumentsModalIsOpen(false)}>
<Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Defense Documents</h3></Modal.Header>
<FederalRegisterDefenseDepartment />
</Modal>




</Carousel.Item>


<Carousel.Item interval={carouselInterval}>



<h4 id='departmentOfJusticeHeading' onClick={() => setDOSPressModalIsOpen(true)}><b>Press Briefing</b></h4>




<div id='dosPress'></div>


{CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/foreignAffairs-xml/department-press-briefings.xml', 'dosPress', 'Department of State Press Briefing', 'N/A', 'Foreign Affairs', DoSPress)}


<Modal id='whiteHouseDocsModal' show={dOSPressModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDOSPressModalIsOpen(false)}>
<Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of State Press Briefing</h3></Modal.Header>
<DoSPress/>
</Modal>


</Carousel.Item>

<Carousel.Item interval={carouselInterval}>



<h4 id='departmentOfJusticeHeading' onClick={() => setArmsControlModalIsOpen(true)}><b>Arms Control and International Security</b></h4>




<div id='armsControl'></div>


{CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/foreignAffairs-xml/arms-control-and-international-security.xml', 'armsControl', 'Arms Control and International Security', 'N/A', 'Foreign Affairs', ArmsControl)}





<Modal id='whiteHouseDocsModal' show={armsControlModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setArmsControlModalIsOpen(false)}>
<Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Arms Control and International Security</h3></Modal.Header>
<ArmsControl/>
</Modal>



</Carousel.Item>



<Carousel.Item interval={carouselInterval}>

<div id='legislativeConInCol'>

<Col>
<div id='viewFullPageButton'>
<Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/departmentofstate' >View all Department of State news</Button>
</div>
</Col>

</div>



</Carousel.Item>



</Carousel>

                      </div>
                    </Col>

                    <Col id='legislativeColRight'>
                      <div id='columnRightPadding'>

        
                      <h4 id='departmentOfStateHeading'><b>Counterterrorism</b></h4>

<div class='whdocs' id='counterterrorism'></div>


{CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/foreignAffairs-xml/counterTerrorism.xml', 'counterterrorism', 'Counterterrorism', 'N/A', 'Foreign Affairs', CounterTerrorism)}

<Modal id='whiteHouseDocsModal' show={counterterrorismModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCounterterrorismModalIsOpen(false)}>
  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Counterterrorism</h3></Modal.Header>
  <CounterTerrorism />
</Modal>


                      </div>
                    </Col>





                  </Row>



                </Container>
              </Container>
            </div>

            <div id='branchPage'>
              <Container class='bgcolor' >
                <Container id='carouselBufferThree'>

                  <Row>

                    <Col id='legislativeColLeft'>
                      <div id='columnLefttPadding'>

                      <h4 id='departmentOfStateHeading'><b>Democracy, Human Rights, and Labor</b></h4>

<div class='whdocs' id='democracyHumanRightsLabor'></div>



{CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/foreignAffairs-xml/democracy-human-rights-and-labor.xml', 'democracyHumanRightsLabor', 'Democracy, Human Rights, and Labor', 'N/A', 'Foreign Affairs', DemocracyHumanRightsLabor)}

<Modal id='whiteHouseDocsModal' show={democracyHumanRightsLaborModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDemocracyHumanRightsLaborModalIsOpen(false)}>
  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Democracy, Human Rights, and Labor</h3></Modal.Header>
  <DemocracyHumanRightsLabor />
</Modal>



                      


                      </div>
                    </Col>

                    <Col id='legislativeColRight'>
                      <div id='columnRightPadding'>
                      <h4 id='departmentOfStateHeading'><b>Diplomatic Security</b></h4>

<div class='whdocs' id='diplomaticSecurity'></div>


{CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/foreignAffairs-xml/diplomatic-security.xml', 'diplomaticSecurity', 'Diplomatic Security', 'N/A', 'Foreign Affairs', DiplomaticSecurity)}


<Modal id='whiteHouseDocsModal' show={diplomaticSecurityModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDiplomaticSecurityModalIsOpen(false)}>
  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Diplomatic Security</h3></Modal.Header>
  <DiplomaticSecurity />
</Modal>
                 
                    </div>
                    </Col>





                  </Row>



                </Container>
              </Container>
            </div>


            <div id='branchPage'>
              <Container class='bgcolor' >


                <Row>
                  <Col id='legislativeColLeft'>
                    <div id='columnLefttPadding'>

                    <h4 id='departmentOfDefenseHeadingInCol' onClick={() => window.open('/us/departmentofdefense', "_self")}><b>Department of Defense</b><ChevronRight id='clickChevron'></ChevronRight></h4>


<Carousel id='carouselInColumn' controls={false}  >


<Carousel.Item interval={carouselInterval}>

<h4 id='departmentOfJusticeHeading' onClick={() => setDepartmentOfDefenseModalIsOpen(true)}><b>News</b></h4>

<div id='defenseDepartment'></div>

{CreateFeedAPI('&skip=0&schema=united_states_of_america&table=defense&order_by=created_at', 'defenseDepartment', 'Department of Defense', 'Executive', 'Foreign Affairs', GenerateFeed)}

<Modal id='whiteHouseDocsModal' show={departmentOfDefenseModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfDefenseModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Defense</h3></Modal.Header>
                  <GenerateFeed url='&skip=0&schema=united_states_of_america&table=defense&order_by=created_at' 
                            name='Department of Defense News' branch='Executive' topic='N/A' buttonName='' />
                </Modal>

</Carousel.Item>


<Carousel.Item interval={carouselInterval}>

<h4 id='departmentOfJusticeHeading'><b>Documents</b></h4>

<div id='frDefense'></div>

{CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=defense-department', 'frDefense', 'Department of Defense Documents', 'Executive', 'Foreign Affairs', FederalRegisterDefenseDepartment)}


<Modal id='departmentOfStateModal' show={federalRegisterDefenseDepartmentModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFederalRegisterDefenseDepartmentModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Department of Defense Documents</h3></Modal.Header>
<FederalRegisterDefenseDepartment/>
</Modal>

</Carousel.Item>
<Carousel.Item interval={carouselInterval}>

<h4 id='departmentOfJusticeHeading'><b> Army Documents</b></h4>

<div id='armyDocs'></div>

{CreateFeedItemBillWithDescription('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=army-department', 'armyDocs', 'Army Documents', 'Executive', 'N/A', ArmyDocuments)}



</Carousel.Item>

<Carousel.Item interval={carouselInterval} >





<div id='legislativeConInCol'>


<Button id='viewFullLegislativePageButton' size='lg' href='/us/departmentofdefense' >View all Department of Defense news</Button>

</div>


</Carousel.Item>


</Carousel>



                      
                    </div>
                  </Col>

                 

                  
                  <Col id='legislativeColRight'>
                      <div id='columnRightPadding'>

                      <h4 id='presidentialFeedHeading'><b>House Committee On Intelligence</b></h4>
                        <div class='whdocs' id='houseCommitteeOnIntelligence'></div>
                     

                        {CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=IG00', 'houseCommitteeOnIntelligence', 'House Committee On Intelligence', 'N/A', 'Foreign Affairs', HouseCommitteeOnIntelligence)}

                        
                        <Modal id='fullBillModal' show={houseCommitteeOnIntelligenceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHouseCommitteeOnIntelligenceModalIsOpen(false)}>
                          <Modal.Header id='billModalHeader' closeButton><h3>House Committee On Intelligence</h3></Modal.Header>
                          <HouseCommitteeOnIntelligence />
                        </Modal>

                       


                      </div>

                  </Col>







                </Row>
              </Container>

            </div>
            <div id='branchPage'>
              <Container class='bgcolor' >
                <Container id='carouselBufferThree'>

                  <Row>

                    <Col id='legislativeColLeft'>
                      <div id='columnLefttPadding'>
                      <h4 id='feedHeadingBillsEnrolled'><b>House Committee on Armed Services</b></h4>
                        <div id='houseComitteeOnArmedServices'></div>
                    
                        {CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=AS00', 'houseComitteeOnArmedServices', 'House Committee on Armed Services', 'N/A', 'Foreign Affairs', HouseCommitteeOnArmedServices)}



                        <Modal id='fullBillModal' show={houseCommitteeOnArmedServicesModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHouseCommitteeOnOnArmedServicesModalIsOpen(false)}>
                          <Modal.Header id='billModalHeader' closeButton><h3>House Committee on Armed Services</h3></Modal.Header>
                          <HouseCommitteeOnArmedServices />
                        </Modal>

                      </div>
                    </Col>

                    <Col id='legislativeColRight'>
                      <div id='columnRightPadding'>

              
                      <h4 id='departmentOfStateHeading'><b>United Nations News</b></h4>

<div class='whdocs' id='unTopStories'></div>


{CreateFeedItem('https://news.un.org/feed/subscribe/en/news/all/rss.xml', 'unTopStories', 'United Nations News', 'N/A', 'Foreign Affairs', UNTopStories)}


<Modal id='whiteHouseDocsModal' show={unTopStoriesModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setUNTopStoriesModalIsOpen(false)}>
  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>United Nations News</h3></Modal.Header>
  <UNTopStories/>
</Modal>
                        




                      </div>
                    </Col>





                  </Row>



                </Container>
              </Container>
            </div>


            <div id='branchPage'>
              <Container class='bgcolor' >
                <Container id='carouselBufferThree'>

                  <Row>

                    <Col id='legislativeColLeft'>
                      <div id='columnLefttPadding'>
                      <h4 id='presidentialFeedHeading'><b>United Nations Journal</b></h4>
                        <div class='whdocs' id='unJournal'></div>
                        


                        {CreateFeedItem('https://undocs.org/rss/unjournal.xml', 'unJournal', 'United Nations Journal', 'N/A', 'Foreign Affairs', UNJournal)}

                        <Modal id='whiteHouseDocsModal' show={unJournalModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setUNJournalModalIsOpen(false)}>
                          <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>United Nations Journal</h3></Modal.Header>
                          <UNJournal />
                        </Modal>
                 
                       


                      </div>
                    </Col>

                    <Col id='legislativeColRight'>
                      <div id='columnRightPadding'>

                  

                       
                      <h4 id='departmentOfStateHeading'><b>UN News - Americas</b></h4>

<div class='whdocs' id='unNewsAmericas'></div>


{CreateFeedItem('https://news.un.org/feed/subscribe/en/news/region/americas/feed/rss.xml', 'unNewsAmericas', 'UN News - Americas', 'N/A', 'Foreign Affairs', UNNewsAmericas)}


<Modal id='whiteHouseDocsModal' show={unNewsAmericasModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setNewsAmericasModalIsOpen(false)}>
  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>UN News - Americas</h3></Modal.Header>
  <UNNewsAmericas />
</Modal>




                      </div>
                    </Col>





                  </Row>



                </Container>
              </Container>
            </div>

        

       






 

           
            <Modal id='aboutModal' show={aboutModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAboutModalIsOpen(false)}>
            <div id='removedAlert'></div>
            <Modal.Header id='aboutModalHeader' closeButton><h3>About</h3></Modal.Header>
  <ModalBody id='aboutInfo'><p id='aboutParagraph'>Gov Glance was created to drive civic engagement and support a 
              healthier democracy by helping citizens get official Government news in an easy-to-navigate centralized location. 
              There will never be any opinion or political analysis(That's your job), only information from official Government 
              sources. Gov Glance is structured after the U.S. government that is broken into three branches Legislative, 
              Executive, and Judicial. To learn more about the structure and functions of The U.S. Government go to <a href='https://www.usa.gov/branches-of-government'>https://www.usa.gov/branches-of-government</a>.
              </p>

              <p id='aboutParagraph'>
              Gov Glance Foundation Inc. is a non-profit that was created to operate govglance.org and continue to create educational tools that help people learn about the workings of society
              </p>
            <br></br>

            <h3>Privacy</h3>

            <br></br>

              <p id='aboutParagraph'>Gov Glance does not collect any personal user information. 
              Features like collection save news items directly to your browser's local storage for your convenience
              and is not shared with Gov Glance servers. We do not collect, store, or send personal information to Google LLC, 
              but we do use some Google services to deliver our app to you. For any questions into Google’s privacy practices 
              here is a link to their privacy policy: <a href='https://policies.google.com/privacy?hl=en-US#intro'>https://policies.google.com/privacy?hl=en-US#intro</a>

              <br></br><br></br>

              For site analytics we use plausible.io an open source privacy friendly analytics provider. <a href="https://plausible.io/about" target="_blank">https://plausible.io/about</a>
            
            </p>
            <br></br>


            <h3>Contact</h3>

            <p id='aboutParagraph'>
                      Contact us via email: <a href='mailto:contact@govglance.org'>contact@govglance.org</a>

            </p>

            <br></br>


            
            </ModalBody>
          </Modal>
          
          <footer id='homeFooter'>
      <Navbar>
        <Row id='footerNav'>
      <Nav variant='pills'>
        
     <Nav.Link id='footerContent'>Gov Glance Foundation Inc.
</Nav.Link>
<Nav.Item>
<Nav.Link id='footerContent' href='mailto:contact@govglance.org'>Contact</Nav.Link>
</Nav.Item>
<Nav.Item>
<Nav.Link id='footerContent' onClick={(e) => {
                   e.preventDefault();
                   window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                 }}> <HeartFill id='donateIcon'/>    Donate</Nav.Link>
</Nav.Item>
<Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/about', "_self")}} id='footerContent'>Privacy</Nav.Link>
    
     
 </Nav>
 </Row>
 </Navbar>
      </footer>





</div>
         </div>
          

              </BrowserView>

              <MobileView>

       <ForeignAffairsMobile/>

              </MobileView>
              
    </>
  )
}
 }



  






export default ForeignAffairs;

import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch, } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment, } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText, ShieldShaded, Pen, PauseCircle, PlayCircle, People, HeartFill, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, ChevronRight } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Axios from 'axios'
import { render } from '@testing-library/react';
import FullBillFeed from './FullBillFeed';
import FullWhiteHouseFeed from './FullWhiteHouseFeed';
import FullUSCourtNews from './FullUSCourtNews';
import FullWhiteHouseDocuments from './FullWhiteHouseDocuments';
import DailyDigest from './DailyDigest';
import State from './State';
import Illinois from './state/Illinois';
import CollectionPage from './CollectionPage';
import NewLaws from './NewLaws';
import BillsEnrolled from './BillsEnrolled';
import DepartmentOfState from './DepartmentOfState';
import DepartmentOfTreasury from './DepartmentOfTreasury';
import CourtAppeals from './CourtAppeals';
import Reports from './Reports';
import DepartmentOfDefense from './DepartmentOfDefense';
import DepartmentOfJustice from './DepartmentOfJustice';
import DepartmentOfTheInterior from './DepartmentOfTheInterior';
import DepartmentOfAgriculture from './DepartmentOfAgriculture';
import DepartmentOfCommerce from './DepartmentOfCommerce';
import DepartmentOfLabor from './DepartmentOfLabor';
import DepartmentOfHealthAndHumanServices from './DepartmentOfHealthAndHumanServices';
import DepartmentOfHousingAndUrbanDevelopment from './DepartmentOfHousingAndUrbanDevelopment';
import DepartmentOfTransportation from './DepartmentOfTransportation';
import DepartmentOfEnergy from './DepartmentOfEnergy';
import DepartmentOfEducation from './DepartmentOfEducation';
import DepartmentOfVeteranAffairs from './DepartmentOfVeteranAffairs';
import DepartmentOfHomelandSecurity from './DepartmentOfHomelandSecurity';
import EnvironmentalProtectionAgency from './EnvironmentalProtectionAgency';
import NationalAeronauticsAndSpaceAdministration from './NationalAeronauticsAndSpaceAdministration';
import TrendingBills from './TrendingBills';
import GAOReports from './GAOReports';
import LegislativeBranch from './LegislativeBranch';
import HouseComitteeOnAgriculture from './HouseComitteeOnAgriculture';
import HouseCommitteeOnAppropriations from './HouseCommitteeOnArmedServices';

import App from './App';
import Overlay from 'react-overlays/esm/Overlay';
import ExecutiveOrders from './ExecutiveOrders';
import PresidentialProclamations from './PresidentialProclamations';
import NationalScienceFoundation from './NationalScienceFoundation';

import{ whiteHouseSearch, createPresidentialProclamations, createExecutiveOrders, createWhiteHouseDocuments, createWhiteHouseFeed,
  createWhiteHouseFeedMobile,createDepartmentOfState,
  createDepartmentOfDefense, 
  createDepartmentOfJustice, createDepartmentOfTheInterior,
  createDepartmentOfAgriculture, createDepartmentOfCommerce,
  createDepartmentOfCommerceMobile, createDepartmentOfLabor,
  createDepartmentOfHealthAndHumanServices, createDepartmentOfHousingAndUrbanDevelopment, createDepartmentOfEducation
  ,  createDepartmentOfVeteransAffairs, createDepartmentOfHomelandSecurity
  , createEnvironmentalProtectionAgency,
  createDepartmentOfTreasury, createNationalScienceFoundation, 
   createEnvironmentalProtectionAgencyMobile, createNationalAeronauticsAndSpaceAdministration, Speak, createFederalRegisterDefenseDepartment, createFederalRegisterStateDepartment, createFederalRegisterTreasuryDepartment, createFederalRegisterJusticeDepartment, createFederalRegisterInteriorDepartment, createFederalRegisterAgricultureDepartment, createFederalRegisterCommerceDepartment, createFederalRegisterLaborDepartment, createFederalRegisterHealthAndHumanServicesDepartment, createFederalRegisterHousingAndUrbanDevelopmentDepartment, createFederalRegisterHomelandSecurityDepartment, createFederalRegisterEducationDepartment, createFederalRegisterVeteransAffairsDepartment,
   CreateFeedItem, CreateFeedItemBillUpdates, CreateFeedItemWithDocButton,
   CreateFeedItemPresidentialDocuments, CreateFeedItemExecutiveOrders, CreateFeedItemCongressionalReports, CreateFeedItemExecutiveOffice } from './Functions'
import ExecutiveBranchMobile from './ExecutiveBranchMobile';
import DesktopNavbar from './DesktopNavbar';
import FederalRegisterDefenseDepartment from './FederalRegisterDefenseDepartment';
import FederalRegisterStateDepartment from './FederalRegisterStateDepartment';
import FederalRegisterTreasuryDepartment from './FederalRegisterTreasuryDepartment';
import FederalRegisterJusticeDepartment from './FederalRegisterJusticeDepartment';
import FederalRegisterInteriorDepartment from './FederalRegisterInteriorDepartment';
import FederalRegisterAgricultureDepartment from './FederalRegisterAgricultureDepartment';
import FederalRegisterCommerceDepartment from './FederalRegisterCommerceDepartment';
import FederalRegisterLaborDepartment from './FederalRegisterLaborDepartment';
import FederalRegisterHealthAndHumanServicesDepartment from './FederalRegisterHealthAndHumanServicesDepartment';
import FederalRegisterHousingAndUrbanDevelopmentDepartment from './FederalRegisterHousingAndUrbanDevelopmentDepartment';
import FederalRegisterVeteransAffairsDepartment from './FederalRegisterVeteransAffairsDepartment';
import FederalRegisterHomelandSecurityDepartment from './FederalRegisterHomelandSecurityDepartment';
import FederalRegisterEducationDepartment from './FederalRegisterEducationDepartment';
import CouncilOfEconomicAdvisers from './CouncilOfEconomicAdvisers';
import CouncilOnEnvironmentQuality from './CouncilOnEnvironmentQuality';
import DomesticPolicyCouncil from './DomesticPolicyCouncil';
import GenderPolicyCouncil from './GenderPolicyCouncil';
import NationalEconomicCouncil from './NationalEconomicCouncil';
import NationalSecurityCouncil from './NationalSecurityCouncil';
import OfficeOfIntergovernmentalAffairs from './OfficeOfIntergovernmentalAffairs';
import OfficeOfManagmentAndBudget from './OfficeOfManagmentAndBudget';
import OfficeOfNationalDrugControlPolicy from './OfficeOfNationalDrugControlPolicy';
import OfficeOfPublicEngagement from './OfficeOfPublicEngagement';
import OfficeOfScienceAndTechnologyPolicy from './OfficeOfScienceAndTechnologyPolicy';
import OfficeOfNationalCyberDirector from './OfficeOfNationalCyberDirector';
import PresidentialPersonnelOffice from './PresidentialPersonnelOffice';
import ExecutiveOfficeMobile from './ExecutiveOfficeMobile';
import SenateMobile from './SenateMobile';
import SenateAgricultureNutritionAndForestryCommittee from './SenateAgricultureNutritionAndForestryCommittee';
import SenateAppropriationCommittee from './SenateAppropriationCommittee';
import SenateArmedServicesCommittee from './SenateArmedServicesCommittee';
import SenateBankingHousingUrbanCommittee from './SenateBankingHousingUrbanCommittee';
import SenateCommerceScienceCommittee from './SenateCommerceScienceCommittee';
import SenateEnergyAndNRCommittee from './SenateEnergyAndNRCommittee';
import SenateEnvironmentAndPublicCommittee from './SenateEnvironmentAndPublicCommittee';
import SenateFinanceCommittee from './SenateFinanceCommittee';
import SenateForeignRelationsCommittee from './SenateForeignRelationsCommittee';
import SenateHealthEducationLaborCommittee from './SenateHealthEducationLaborCommittee';
import SenateHomelandSecCommittee from './SenateHomelandSecCommittee';
import SenateIndianAffairsCommittee from './SenateIndianAffairsCommittee';
import SenateRulesAndAdminCommittee from './SenateRulesAndAdminCommittee';
import SenateSmallBusinessCommittee from './SenateSmallBusinessCommittee';
import SenateVeteransAffairsCommittee from './SenateVeteransAffairsCommittee';
import SenateBudgetCommittee from './SenateBudgetCommittee';
import SenateJudiciaryCommittee from './SenateJudiciaryCommittee';
import SenateIntelligenceCommittee from './SenateIntelligenceCommittee';
import SenateFeed from './SenateFeed';
import SenateDisplay from './SenateDisplay';
import SenateDisplayOnPage from './SenateDisplayOnPage';





var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 60000;





  

 


  


export const Senate = () =>
 {
   render()
   
   {
  const [show, setShow] = useState(true);

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [fullscreen, setFullScreen] = useState(true);


  const [fullBillModalIsOpen, setFullBillModalIsOpen] = useState(false)

  const [reportModalIsOpen, setReportModalIsOpen] = useState(false)


  const [whiteHouseFeedModalIsOpen, setWhiteHouseFeedModalIsOpen] = useState(false)
  const [whiteHouseDocsModalIsOpen, setWhiteHouseDocsModalIsOpen] = useState(false)


  const [courtNewsModalIsOpen, setCourtNewsModalIsOpen] = useState(false)
  const [courtModalIsOpen, setCourtModalIsOpen] = useState(false)

  const [courtAppealsModalIsOpen, setCourtAppealsModalIsOpen] = useState(false)

  const [newLawsModalIsOpen, setNewLawsModalIsOpen] = useState(false)

  const [billsEnrolledModalIsOpen, setBillsEnrolledModalIsOpen] = useState(false)

  const [legislativePageBillsEnrolledModalIsOpen, setLegislativePageBillsEnrolledModalIsOpen] = useState(false)


  const [departmentOfStateModalIsOpen, setDepartmentOfStateModalIsOpen] = useState(false)
  const [departmentOfTreasuryModalIsOpen, setDepartmentOfTreasuryModalIsOpen] = useState(false)
  const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
  const [departmentOfJusticeModalIsOpen, setDepartmentOfJusticeModalIsOpen] = useState(false)
  const [departmentOfTheInteriorModalIsOpen, setDepartmentOfTheInteriorModalIsOpen] = useState(false)
  const [departmentOfAgricultureModalIsOpen, setDepartmentOfAgricultureModalIsOpen] = useState(false)
  const [departmentOfCommerceModalIsOpen, setDepartmentOfCommerceModalIsOpen] = useState(false)
  const [departmentOfLaborModalIsOpen, setDepartmentOfLaborModalIsOpen] = useState(false)
  const [departmentOfHealthAndHumanServicesModalIsOpen, setDepartmentOfHealthAndHumanServicesModalIsOpen] = useState(false)
  const [departmentOfHousingAndUrbanDevelopmentModalIsOpen, setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen] = useState(false)
  const [departmentOfTransportationModalIsOpen, setDepartmentOfTransportationModalIsOpen] = useState(false)
  const [departmentOfEnergyModalIsOpen, setDepartmentOfEnergyModalIsOpen] = useState(false)
  const [departmentOfEducationModalIsOpen, setDepartmentOfEducationModalIsOpen] = useState(false)
  const [departmentOfVeteransAffairsModalIsOpen, setDepartmentOfVeteransAffairsModalIsOpen] = useState(false)
  const [departmentOfHomelandSecurityModalIsOpen, setDepartmentOfHomelandSecurityModalIsOpen] = useState(false)
  const [epaModalIsOpen, setEPAModalIsOpen] = useState(false)
  const [nsfModalIsOpen, setNSFModalIsOpen] = useState(false)
  const [nasaModalIsOpen, setNASAModalIsOpen] = useState(false)
  const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
  const [goaReportsModalIsOpen, setGAOReportsModalIsOpen] = useState(false)
  const [executiveOrdersModalIsOpen, setExecutiveOrdersModalIsOpen] = useState(false)
  const [presidentialProclamationsModalIsOpen, setPresidentialProclamationsModalIsOpen] = useState(false)

  const [federalRegisterDefenseDepartmentModalIsOpen, setFederalRegisterDefenseDepartmentModalIsOpen] = useState(false)
  const [federalRegisterStateDepartmentModalIsOpen, setFederalRegisterStateDepartmentModalIsOpen] = useState(false)
  const [federalRegisterTreasuryDepartmentModalIsOpen, setFederalRegisterTreasuryDepartmentModalIsOpen] = useState(false)
  const [federalRegisterJusticeDepartmentModalIsOpen, setFederalRegisterJusticeDepartmentModalIsOpen] = useState(false)
  const [federalRegisterInteriorDepartmentModalIsOpen, setFederalRegisterInteriorDepartmentModalIsOpen] = useState(false)
  const [federalRegisterAgricultureDepartmentModalIsOpen, setFederalRegisterAgricultureDepartmentModalIsOpen] = useState(false)
  const [federalRegisterVeteransAffairsDepartmentModalIsOpen, setFederalRegisterVeteransAffairsDepartmentModalIsOpen] = useState(false)
  const [federalRegisterHomelandSecurityDepartmentModalIsOpen, setFederalRegisterHomelandSecurityDepartmentModalIsOpen] = useState(false)
  const [federalRegisterCommerceDepartmentModalIsOpen, setFederalRegisterCommerceDepartmentModalIsOpen] = useState(false)
  const [federalRegisterLaborDepartmentModalIsOpen, setFederalRegisterLaborDepartmentModalIsOpen] = useState(false)
  const [federalRegisterEducationDepartmentModalIsOpen, setFederalRegisterEducationDepartmentModalIsOpen] = useState(false)
  const [federalRegisterHealthAndHumanServicesDepartmentModalIsOpen, setFederalRegisterHealthAndHumanServicesDepartmentModalIsOpen] = useState(false)
  const [federalRegisterHousingAndUrbanDevelopmentDepartmentModalIsOpen, setFederalRegisterHousingAndUrbanDevelopmentDepartmentModalIsOpen] = useState(false)
  const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false)
  const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)



  const [declarationModalIsOpen, setDeclarationModalIsOpen] = useState(false)
  const [constitutionModalIsOpen, setConstitutionModalIsOpen] = useState(false)
  const [billOfRightsModalIsOpen, setBillOfRightsModalIsOpen] = useState(false)


  return(
    <>
      <BrowserView>
        <div id='homePage'>

     

        <div id='homebg'>

          <DesktopNavbar/>
              <div id='sectionHeading'>
            
            <h3 id='branchHeading'  onClick={() => window.open('/us/senate', "_self")} >Senate</h3>
            
            </div>

            <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>

                        <h4 id='feedHeadingBillsEnrolled'><b>Senators</b></h4>
                          
                       
                          <SenateDisplayOnPage/>
                        
                        
                  
                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>

                    
                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
                </div>


            <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>

                          <h4 id='whBreifingFeedHeading'><b>Recent Senate Materials</b></h4>
                          <div id='senateFullFeed'></div>
                    
                            {CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/chamber/Senate_Feed.xml', 'senateFullFeed', 'Recent Senate Materials', 'Legislative', 'N/A', SenateFeed)}

                  
                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                      <h4 id='whBreifingFeedHeading'><b>Senate Committee on Agriculture, Nutrition, and Forestry Materials</b></h4>
                          <div id='senateAgricultureNutritionAndForestCommittee'></div>
                    
                            {CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/senateCommittees/Committee%20on%20Agriculture,%20Nutrition,%20and%20Forestry.xml', 'senateAgricultureNutritionAndForestCommittee', 'Senate Committee on Agriculture, Nutrition, and Forestry', 'Legislative', 'Environment', SenateAgricultureNutritionAndForestryCommittee)}

                  
                   
                      </Col>


                    </Row>


                  </Container>
                </Container>
                </div>
                     
           
            <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>

                        <h4 id='whBreifingFeedHeading'><b>Senate Committee on Appropriations Materials</b></h4>
                          <div id='senateAppropriationsCommittee'></div>
                    
                            {CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/senateCommittees/Committee%20on%20Appropriations.xml', 'senateAppropriationsCommittee', 'Senate Committee on Appropriations', 'Legislative', 'N/A', SenateAppropriationCommittee)}

                  
                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                        <h4 id='whBreifingFeedHeading'><b>Senate Committee on Armed Services Materials</b></h4>
                          <div id='senateArmedServicesCommittee'></div>
                    
                            {CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/senateCommittees/Committee%20on%20Armed%20Services.xml', 'senateArmedServicesCommittee', 'Senate Committee on Armed Services', 'Legislative', 'Foreign Affairs', SenateArmedServicesCommittee)}

</div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
                </div>

                <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>

                        <h4 id='whBreifingFeedHeading'><b>Senate Committee on Banking, Housing, and Urban Affairs	 Materials</b></h4>
                          <div id='senateBankingHousingUrbanCommittee'></div>
                    
                            {CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/senateCommittees/Committee%20on%20Appropriations.xml', 'senateBankingHousingUrbanCommittee', 'Senate Committee on Banking, Housing, and Urban Affairs', 'Legislative', 'N/A', SenateBankingHousingUrbanCommittee)}

                  
                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                        <h4 id='whBreifingFeedHeading'><b>Senate Committee on Commerce, Science, and Transportation Materials</b></h4>
                          <div id='senateCommerceScienceAndTransportationCommittee'></div>
                    
                            {CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/senateCommittees/Committee%20on%20Commerce,%20Science,%20and%20Transportation.xml', 'senateCommerceScienceAndTransportationCommittee', 'Senate Committee on Commerce, Science, and Transportation', 'Legislative', 'N/A', SenateCommerceScienceCommittee)}

</div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
                </div>


            


                <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>

                        <h4 id='whBreifingFeedHeading'><b>Senate Committee on Energy and Natural Resources Materials</b></h4>
                          <div id='senateEnergyCommittee'></div>
                    
                            {CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/senateCommittees/Committee%20on%20Energy%20and%20Natural%20Resources.xml', 'senateEnergyCommittee', 'Senate Committee on Energy and Natural Resources', 'Legislative', 'N/A', SenateEnergyAndNRCommittee)}

                  
                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                        <h4 id='whBreifingFeedHeading'><b>Senate Committee on Environment and Public Work Materials</b></h4>
                          <div id='senateEnvironmentAndPublicCommittee'></div>
                    
                            {CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/senateCommittees/Committee%20on%20Environment%20and%20Public%20Works.xml', 'senateEnvironmentAndPublicCommittee', 'Senate Committee on Environment and Public Work', 'Legislative', 'N/A', SenateEnvironmentAndPublicCommittee)}

</div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
                </div>

                <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>

                        <h4 id='whBreifingFeedHeading'><b>Senate Committee on Finance Materials</b></h4>
                          <div id='senateFinanceCommittee'></div>
                    
                            {CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/senateCommittees/Committee%20on%20Finance.xml', 'senateFinanceCommittee', 'Senate Committee on Finance', 'Legislative', 'N/A', SenateFinanceCommittee)}

                  
                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                        <h4 id='whBreifingFeedHeading'><b>Senate Committee on Foreign Relations Materials</b></h4>
                          <div id='senateForeignRelationsCommittee'></div>
                    
                            {CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/senateCommittees/Committee%20on%20Foreign%20Relations.xml', 'senateForeignRelationsCommittee', 'Senate Committee on Foreign Relations', 'Legislative', 'N/A', SenateForeignRelationsCommittee)}


</div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
                </div>


            


                <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>

                        <h4 id='whBreifingFeedHeading'><b>Senate Committee on Health, Education, Labor, and Pensions Materials</b></h4>
                          <div id='senateHealthEducationLaborAndPensionsCommittee'></div>
                    
                            {CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/senateCommittees/Committee%20on%20Health,%20Education,%20Labor,%20and%20Pensions.xml', 'senateHealthEducationLaborAndPensionsCommittee', 'Senate Committee on Health, Education, Labor, and Pensions', 'Legislative', 'N/A', SenateHealthEducationLaborCommittee)}


                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                        <h4 id='whBreifingFeedHeading'><b>Senate Committee on Homeland Security and Governance Materials</b></h4>
                          <div id='senateHomelandCommittee'></div>
                    
                            {CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/senateCommittees/Committee%20on%20Homeland%20Security%20and%20Governmental%20Affairs.xml', 'senateHomelandCommittee', 'Senate Committee on Homeland Security and Governance', 'Legislative', 'N/A', SenateHomelandSecCommittee)}

                  

</div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
                </div>



                <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>

                        <h4 id='whBreifingFeedHeading'><b>Senate Committee on Indian Affairs Materials</b></h4>
                          <div id='senateIndianAffairsCommittee'></div>
                    
                            {CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/senateCommittees/Committee%20on%20Indian%20Affairs.xml', 'senateIndianAffairsCommittee', 'Senate Committee on Indian Affairs', 'Legislative', 'N/A', SenateIndianAffairsCommittee)}

                  
                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                        <h4 id='whBreifingFeedHeading'><b>Senate Committee on Rules and Administration Materials</b></h4>
                          <div id='senateRulesAndAdminCommittee'></div>
                    
                            {CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/senateCommittees/Committee%20on%20Rules%20and%20Administration.xml', 'senateRulesAndAdminCommittee', 'Senate Committee on Rules and Administration', 'Legislative', 'N/A', SenateRulesAndAdminCommittee)}

</div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
                </div>

                <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>

                        <h4 id='whBreifingFeedHeading'><b>Senate Committee on Small Business and Entrepreneurs Materials</b></h4>
                          <div id='senateSmallBusinessCommittee'></div>
                    
                            {CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/senateCommittees/Committee%20on%20Small%20Business%20and%20Entrepreneurship.xml', 'senateSmallBusinessCommittee', 'Senate Committee on Small Business and Entrepreneurs', 'Legislative', 'N/A', SenateSmallBusinessCommittee)}

                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                        <h4 id='whBreifingFeedHeading'><b>Senate Committee on Veterans' Affairs Materials</b></h4>
                          <div id='senateVeteransAffairsCommittee'></div>
                    
                            {CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/senateCommittees/Committee%20on%20Veterans\'%20Affairs.xml', 'senateVeteransAffairsCommittee', 'Senate Committee on Veterans Affairs', 'Legislative', 'N/A', SenateVeteransAffairsCommittee)}

                  
</div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
                </div>


                <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>

                        <h4 id='whBreifingFeedHeading'><b>Senate Committee on the Budget Materials</b></h4>
                          <div id='senateBudgetCommittee'></div>
                    
                            {CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/senateCommittees/Committee%20on%20the%20Budget.xml', 'senateBudgetCommittee', 'Senate Committee on the Budget', 'Legislative', 'N/A', SenateBudgetCommittee)}

                  
                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                        <h4 id='whBreifingFeedHeading'><b>Senate Committee on the Judiciary Materials</b></h4>
                          <div id='senateJudiciaryCommittee'></div>
                    
                            {CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/senateCommittees/Committee%20on%20the%20Judiciary.xml', 'senateJudiciaryCommittee', 'Senate Committee on the Judiciary', 'Legislative', 'N/A', SenateJudiciaryCommittee)}

</div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
                </div>


                <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>

                        <h4 id='whBreifingFeedHeading'><b>Select Committee on Intelligence Materials</b></h4>
                          <div id='senateIntelligenceCommittee'></div>
                    
                            {CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/senateCommittees/Select%20Committee%20on%20Intelligence.xml', 'senateIntelligenceCommittee', 'Select Committee on Intelligence', 'Legislative', 'N/A', SenateIntelligenceCommittee)}

                  
                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                        
                    
</div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
                </div>

      



            <Modal id='aboutModal' show={aboutModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAboutModalIsOpen(false)}>
            <div id='removedAlert'></div>
            <Modal.Header id='aboutModalHeader' closeButton><h3>About</h3></Modal.Header>
  <ModalBody id='aboutInfo'><p id='aboutParagraph'>Gov Glance was created to drive civic engagement and support a 
              healthier democracy by helping citizens get official Government news in an easy-to-navigate centralized location. 
              There will never be any opinion or political analysis(That's your job), only information from official Government 
              sources. Gov Glance is structured after the U.S. government that is broken into three branches Legislative, 
              Executive, and Judicial. To learn more about the structure and functions of The U.S. Government go to <a href='https://www.usa.gov/branches-of-government'>https://www.usa.gov/branches-of-government</a>.
              </p>

              <p id='aboutParagraph'>
              Gov Glance Foundation Inc. is a non-profit that was created to operate govglance.org and continue to create educational tools that help people learn about the workings of society
              </p>
            <br></br>

            <h3>Privacy</h3>

            <br></br>

              <p id='aboutParagraph'>Gov Glance does not collect any personal user information. 
              Features like collection save news items directly to your browser's local storage for your convenience
              and is not shared with Gov Glance servers. We do not collect, store, or send personal information to Google LLC, 
              but we do use some Google services to deliver our app to you. For any questions into Google’s privacy practices 
              here is a link to their privacy policy: <a href='https://policies.google.com/privacy?hl=en-US#intro'>https://policies.google.com/privacy?hl=en-US#intro</a>

              <br></br><br></br>

              For site analytics we use plausible.io an open source privacy friendly analytics provider. <a href="https://plausible.io/about" target="_blank">https://plausible.io/about</a>
            
            </p>
            <br></br>


            <h3>Contact</h3>

            <p id='aboutParagraph'>
                      Contact us via email: <a href='mailto:contact@govglance.org'>contact@govglance.org</a>

            </p>

            <br></br>


            
            </ModalBody>
          </Modal>
 
          <footer id='homeFooter'>
      <Navbar>
        <Row id='footerNav'>
      <Nav variant='pills'>
        
     <Nav.Link id='footerContent'>Gov Glance Foundation Inc.
</Nav.Link>
<Nav.Item>
<Nav.Link id='footerContent' href='mailto:contact@govglance.org'>Contact</Nav.Link>
</Nav.Item>
<Nav.Item>
<Nav.Link id='footerContent' onClick={(e) => {
                   e.preventDefault();
                   window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                 }}> <HeartFill id='donateIcon'/>    Donate</Nav.Link>
</Nav.Item>
<Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/about', "_self")}} id='footerContent'>Privacy</Nav.Link>
    
     
 </Nav>
 </Row>
 </Navbar>
      </footer>



</div>
         </div>
          

              </BrowserView>

              <MobileView>

    <SenateMobile/>

              </MobileView>
              
    </>
  )
}
 }



  






export default Senate;

import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch, } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment, } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText, ShieldShaded, Pen, PauseCircle, PlayCircle, People, HeartFill, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, ChevronRight } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Axios from 'axios'
import { render } from '@testing-library/react';

import DepartmentOfTreasury from './DepartmentOfTreasury';



import App from './App';
import Overlay from 'react-overlays/esm/Overlay';
import ExecutiveOrders from './ExecutiveOrders';
import PresidentialProclamations from './PresidentialProclamations';
import NationalScienceFoundation from './NationalScienceFoundation';

import{ whiteHouseSearch, createPresidentialProclamations, createExecutiveOrders, createWhiteHouseDocuments, createWhiteHouseFeed,
  createWhiteHouseFeedMobile,createDepartmentOfState,
  createDepartmentOfDefense, 
  createDepartmentOfJustice, createDepartmentOfTheInterior,
  createDepartmentOfAgriculture, createDepartmentOfCommerce,
  createDepartmentOfCommerceMobile, createDepartmentOfLabor,
  createDepartmentOfHealthAndHumanServices, createDepartmentOfHousingAndUrbanDevelopment, createDepartmentOfEducation
  ,  createDepartmentOfVeteransAffairs, createDepartmentOfHomelandSecurity
  , createEnvironmentalProtectionAgency,
  createDepartmentOfTreasury, createNationalScienceFoundation, 
   createEnvironmentalProtectionAgencyMobile, createNationalAeronauticsAndSpaceAdministration, Speak, createFederalRegisterDefenseDepartment, createFederalRegisterStateDepartment, createFederalRegisterTreasuryDepartment, createFederalRegisterJusticeDepartment, createFederalRegisterInteriorDepartment, createFederalRegisterAgricultureDepartment, createFederalRegisterCommerceDepartment, createFederalRegisterLaborDepartment, createFederalRegisterHealthAndHumanServicesDepartment, createFederalRegisterHousingAndUrbanDevelopmentDepartment, createFederalRegisterHomelandSecurityDepartment, createFederalRegisterEducationDepartment, createFederalRegisterVeteransAffairsDepartment,
   CreateFeedItem, CreateFeedItemBillUpdates, CreateFeedItemWithDocButton,
   CreateFeedItemPresidentialDocuments, CreateFeedItemExecutiveOrders, CreateFeedItemCongressionalReports, CreateFeedItemExecutiveOffice, CreateFeedItemBillWithDescription, CreateFeedAPIDepartmentUS } from './Functions'
import ExecutiveBranchMobile from './ExecutiveBranchMobile';
import DesktopNavbar from './DesktopNavbar';

import DepartmentOfTreasuryDocs from './DepartmentOfTreasuryDocs';
import AlcoholAndTobaccoTax from './AlcoholAndTobaccoTax';
import CommunityDevelopmentFinancialInstitutions from './CommunityDevelopmentFinancialInstitutions';
import ComptrollerOfTheCurrency from './ComptrollerOfTheCurrency';
import FinancialCrimesEnforcement from './FinancialCrimesEnforcement';
import BureauOfTheFiscalService from './BureauOfTheFiscalService';
import ForeignAssetControlOffice from './ForeignAssetControlOffice';
import InternalRevenueService from './InternalRevenueService';
import UnitedStatesMint from './UnitedStatesMint';
import FinancialResearchOffice from './FinancialResearchOffice';
import FiscalService from './FiscalService';
import InvestmentSecurityOffice from './InvestmentSecurityOffice';
import DepartmentOfTreasuryMobile from './DepartmentOfTreasuryMobile';
import EngravingAndPrintingBureau from './EngravingAndPrintingBureau';
import DepartmentOfHealthAndHumanServicesDocs from './DepartmentOfHealthAndHumanServicesDocs';
import AgencyForHealthcareResearchAndQuality from './AgencyForHealthcareResearchAndQuality';
import AgencyForToxicSubstancesAndDiseaseRegistry from './AgencyForToxicSubstancesAndDiseaseRegistry';
import AgingAdministration from './AgingAdministration';
import CentersForDiseaseControlAndPrevention from './CentersForDiseaseControlAndPrevention';
import CentersForMedicareAndMedicaid from './CentersForMedicareAndMedicaid';
import ChildrenAndFamiliesAdministration from './ChildrenAndFamiliesAdministration';
import FoodAndDrugAdministration from './FoodAndDrugAdministration';
import HealthResourcesAndServicesAdmin from './HealthResourcesAndServicesAdmin';
import IndianHealthService from './IndianHealthService';
import NationalInstitutesOfHealth from './NationalInstitutesOfHealth';
import PublicHealthService from './PublicHealthService';
import RefugeeResettlementOffice from './RefugeeResettlementOffice';
import SubstanceAbuseAndMentalServicesAdmin from './SubstanceAbuseAndMentalServicesAdmin';
import HealthCareFinanceAdministration from './HealthCareFinanceAdministration';
import CommunityLivingAdmin from './CommunityLivingAdmin';
import DepartmentOfHealthAndHumanServices from './DepartmentOfHealthAndHumanServices';
import HealthAndHumanServicesMobile from './HealthAndHumanServicesMobile';
import GenerateFeedDepartment from './GenerateFeedDepartment';




var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 60000;





  

 


  


export const DepartmentOfHealthDesk = () =>
 {
   render()
   
   {
    const [show, setShow] = useState(true);

        
    const [fullscreen, setFullScreen] = useState(true);
  
  
    const [fullBillModalIsOpen, setFullBillModalIsOpen] = useState(false)
  
    const [reportModalIsOpen, setReportModalIsOpen] = useState(false)
  
  
    const [whiteHouseFeedModalIsOpen, setWhiteHouseFeedModalIsOpen] = useState(false)
    const [whiteHouseDocsModalIsOpen, setWhiteHouseDocsModalIsOpen] = useState(false)
  
    const [healthModalIsOpen, setHealthModalIsOpen] = useState(false)
    const [courtNewsModalIsOpen, setCourtNewsModalIsOpen] = useState(false)
    const [courtModalIsOpen, setCourtModalIsOpen] = useState(false)
  
    const [courtAppealsModalIsOpen, setCourtAppealsModalIsOpen] = useState(false)
  
    const [newLawsModalIsOpen, setNewLawsModalIsOpen] = useState(false)
  
    const [billsEnrolledModalIsOpen, setBillsEnrolledModalIsOpen] = useState(false)
  
    const [legislativePageBillsEnrolledModalIsOpen, setLegislativePageBillsEnrolledModalIsOpen] = useState(false)
  
  
    const [departmentOfStateModalIsOpen, setDepartmentOfStateModalIsOpen] = useState(false)
    const [departmentOfTreasuryModalIsOpen, setDepartmentOfTreasuryModalIsOpen] = useState(false)
 
    const [departmentOfJusticeModalIsOpen, setDepartmentOfJusticeModalIsOpen] = useState(false)
    const [departmentOfTheInteriorModalIsOpen, setDepartmentOfTheInteriorModalIsOpen] = useState(false)
    const [departmentOfAgricultureModalIsOpen, setDepartmentOfAgricultureModalIsOpen] = useState(false)
    const [departmentOfCommerceModalIsOpen, setDepartmentOfCommerceModalIsOpen] = useState(false)
    const [departmentOfLaborModalIsOpen, setDepartmentOfLaborModalIsOpen] = useState(false)
    const [departmentOfHealthAndHumanServicesModalIsOpen, setDepartmentOfHealthAndHumanServicesModalIsOpen] = useState(false)
    const [departmentOfHousingAndUrbanDevelopmentModalIsOpen, setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen] = useState(false)
    const [departmentOfTransportationModalIsOpen, setDepartmentOfTransportationModalIsOpen] = useState(false)
    const [departmentOfEnergyModalIsOpen, setDepartmentOfEnergyModalIsOpen] = useState(false)
    const [departmentOfEducationModalIsOpen, setDepartmentOfEducationModalIsOpen] = useState(false)
    const [departmentOfVeteransAffairsModalIsOpen, setDepartmentOfVeteransAffairsModalIsOpen] = useState(false)
    const [departmentOfHomelandSecurityModalIsOpen, setDepartmentOfHomelandSecurityModalIsOpen] = useState(false)
    const [epaModalIsOpen, setEPAModalIsOpen] = useState(false)
    const [nsfModalIsOpen, setNSFModalIsOpen] = useState(false)
    const [nasaModalIsOpen, setNASAModalIsOpen] = useState(false)
    const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
    const [goaReportsModalIsOpen, setGAOReportsModalIsOpen] = useState(false)
    const [executiveOrdersModalIsOpen, setExecutiveOrdersModalIsOpen] = useState(false)
    const [presidentialProclamationsModalIsOpen, setPresidentialProclamationsModalIsOpen] = useState(false)
    const [statesModalIsOpen, setStatesModalIsOpen] = useState(false)
  
  
    const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false)
    const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)
  
  
    const [federalRegisterDefenseDepartmentModalIsOpen, setFederalRegisterDefenseDepartmentModalIsOpen] = useState(false)
const [federalRegisterStateDepartmentModalIsOpen, setFederalRegisterStateDepartmentModalIsOpen] = useState(false)
    const [declarationModalIsOpen, setDeclarationModalIsOpen] = useState(false)
    const [constitutionModalIsOpen, setConstitutionModalIsOpen] = useState(false)
    const [billOfRightsModalIsOpen, setBillOfRightsModalIsOpen] = useState(false)

    const [economicAdModalIsOpen, setEconomicAdModalIsOpen] = useState(false)
    const [environmentQualityModalIsOpen, setEnvironmentQualityModalIsOpen] = useState(false)
    const [domesticPolicyModalIsOpen, setDomesticPolicyModalIsOpen] = useState(false)
    const [genderPolicyModalIsOpen, setGenderPolicyModalIsOpen] = useState(false)
    const [nationalEconomicModalIsOpen, setNationalEconomicModalIsOpen] = useState(false)
    const [securityModalIsOpen, setSecurityModalIsOpen] = useState(false)
    const [budgetModalIsOpen, setBudgetModalIsOpen] = useState(false)
    const [drugModalIsOpen, setDrugModalIsOpen] = useState(false)
    const [publicModalIsOpen, setPublicModalIsOpen] = useState(false)
    const [scienceModalIsOpen, setScienceModalIsOpen] = useState(false)
    const [cyberModalIsOpen, setCyberModalIsOpen] = useState(false)
    const [presidentialModalIsOpen, setPresidentialModalIsOpen] = useState(false)

    const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
    const [defenseDocumentsModalIsOpen, setDefenseDocumentsModalIsOpen] = useState(false)
    const [armyModalIsOpen, setArmyModalIsOpen] = useState(false)
    const [navyModalIsOpen, setNavyModalIsOpen] = useState(false)
    const [marinesModalIsOpen, setMarinesModalIsOpen] = useState(false)
    const [spaceForceModalIsOpen, setSpaceForceModalIsOpen] = useState(false)
    const [airForceModalIsOpen, setAirForceModalIsOpen] = useState(false)
    const [nationalGuardModalIsOpen, setNationalGuardModalIsOpen] = useState(false)
    const [ciaModalIsOpen, setCIAModalIsOpen] = useState(false)
    const [nsaModalIsOpen, setNSAModalIsOpen] = useState(false)
    const [armyCorpsModalIsOpen, setArmyCorpsModalIsOpen] = useState(false)
    const [showBioModal, setBioModal] = useState(false);
const [showMissionModal, setMissionModal] = useState(false);
  return(
    <>
      <BrowserView>
        <div id='homePage'>

     

        <div id='homebg'>

          <DesktopNavbar/>
          <div id='feedDisplay' 
                  style={{ padding: '20px', borderRadius: '18px', marginTop: '0em'}}>

<div style={{ position: 'relative' }}>

     <div id='profileImage' style={{ width: '150px', height: '150px', borderRadius: '100%', overflow: 'hidden',  marginLeft: '40px' }}>
     <Image id='profileImage-image' src='/departmentOfHealth.png' />
   </div>


   <div style={{ position: 'absolute', top: '0', left: '250px', display: 'flex', flexDirection: 'column' }}>
   <div style={{ color: 'white', fontSize: '24px', fontWeight: 'bold' }}>

</div>
<div  style={{ color: 'white', fontSize: '20px' }}> 
<span> <h3  >Department of Health and Human Services </h3></span>
<br></br>
<div style={{ color: 'white', fontSize: '20px' }}>
<span>
  The Department of Health and Human Services (HHS) is the United States government’s principal agency for protecting the health of all Americans and providing essential human services, especially for those who are least able to help themselves. Agencies of HHS conduct health and social science research, work to prevent disease outbreaks, assure food and drug safety, and provide health insurance.</span>
<br></br>
<div style={{ color: 'white', fontSize: '18px' }}>

       <span><Link style={{color: '#21cff5'}} onClick={(e) => {
          e.preventDefault();
          window.open("https://www.hhs.gov/", "_blank");
        }}>
          https://www.hhs.gov/
          </Link></span> 
<br></br>
<Button id='whDocsViewMoreButton' onClick={() =>  setMissionModal(true)}>  <Files size={20}></Files> Read More</Button>
</div>
  </div>

     </div>
     </div>


     </div>

           </div>

           <Modal id='fullBillModal'  size="xl" aria-labelledby="contained-modal-title-vcenter" show={showMissionModal} onHide={() => setMissionModal(false)}>
         <Modal.Header id='billModalHeader' closeButton><h3> Department of Health and Human Services</h3></Modal.Header>
       
         <div id='fullBillFeed' >
      
         <p>The Department of Health and Human Services (HHS) is the United States government’s principal agency for protecting the health of all Americans and providing essential human services, especially for those who are least able to help themselves. Agencies of HHS conduct health and social science research, work to prevent disease outbreaks, assure food and drug safety, and provide health insurance.</p>

<p>In addition to administering Medicare and Medicaid, which together provide health coverage to one in four Americans, HHS also oversees the National Institutes of Health, the Food and Drug Administration, and the Centers for Disease Control.</p>

<p>The Secretary of Health and Human Services oversees a budget of approximately $700 billion and approximately 65,000 employees. The Department’s programs are administered by 11 operating divisions, including eight agencies in the U.S. Public Health Service, two human services agencies, and the Centers for Medicare and Medicaid Services.</p>


<br></br>
<Link style={{color: '#21cff5'}} onClick={(e) => {
     e.preventDefault();
     window.open("https://www.whitehouse.gov/about-the-white-house/our-government/the-executive-branch/#:~:text=and%20contract%20employees.-,DEPARTMENT%20OF%20HEALTH%20AND%20HUMAN%20SERVICES,-The%20Department%20of", "_blank");
   }}>
     https://www.whitehouse.gov/about-the-white-house/our-government/the-executive-branch/#:~:text=and%20contract%20employees.-,DEPARTMENT%20OF%20HEALTH%20AND%20HUMAN%20SERVICES,-The%20Department%20of
     </Link>
 </div>

       </Modal>
           <br></br>    <br></br>    <br></br>
            <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>

                          <h4 id='whBreifingFeedHeading' onClick={() => setDepartmentOfDefenseModalIsOpen(true)}><b>News</b></h4>
                          <div id='defenseDepartment'></div>

{CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/health-xml/health_and_human_services.xml', 'defenseDepartment', 'Department of Health and Human Services', 'Executive', 'N/A', DepartmentOfHealthAndHumanServices)}

                  
<Modal id='whiteHouseDocsModal' show={departmentOfDefenseModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfDefenseModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Health and Human Services</h3></Modal.Header>
                  <DepartmentOfHealthAndHumanServices/>
                </Modal>

                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                          <h4 id='presidentialFeedHeading' onClick={() => setDefenseDocumentsModalIsOpen(true)}><b>Documents</b></h4>
                          <div id='frDefense'></div>

{CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=health-and-human-services-department', 'frDefense', 'Department of Health and Human Services Documents', 'Executive', 'N/A', DepartmentOfHealthAndHumanServicesDocs)}


<Modal id='whiteHouseDocsModal' show={defenseDocumentsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDefenseDocumentsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Health and Human Services Documents</h3></Modal.Header>
                  <DepartmentOfHealthAndHumanServicesDocs/>
                </Modal>


                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
                </div>

            

                <div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/healthcare', "_self")}}><b>Agency for Healthcare Research and Quality</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defAhrq'></div>
            {CreateFeedAPIDepartmentUS('&table_name=health_and_human_services&agency=Agency%20for%20Healthcare%20Research%20and%20Quality', 'defAhrq',
              'Agency for Healthcare Research and Quality Documents', 'Executive',
              'Health', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={healthModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHealthModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Agency for Healthcare Research and Quality</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=health_and_human_services&agency=Agency%20for%20Healthcare%20Research%20and%20Quality'
                name='Agency for Healthcare Research and Quality' branch='Executive' topic='Health' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/toxic-substances', "_self")}}><b>Agency for Toxic Substances and Disease Registry</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defAtsdr'></div>
            {CreateFeedAPIDepartmentUS('&table_name=health_and_human_services&agency=Agency%20for%20Toxic%20Substances%20and%20Disease%20Registry', 'defAtsdr',
              'Agency for Toxic Substances and Disease Registry Documents', 'Executive',
              'Health', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={healthModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHealthModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Agency for Toxic Substances and Disease Registry</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=health_and_human_services&agency=Agency%20for%20Toxic%20Substances%20and%20Disease%20Registry'
                name='Agency for Toxic Substances and Disease Registry' branch='Executive' topic='Health' buttonName='Doc' />
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/aging-administration', "_self")}}><b>Aging Administration</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defAa'></div>
            {CreateFeedAPIDepartmentUS('&table_name=health_and_human_services&agency=Aging%20Administration', 'defAa',
              'Aging Administration Documents', 'Executive',
              'Health', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={healthModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHealthModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Aging Administration</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=health_and_human_services&agency=Aging%20Administration'
                name='Aging Administration' branch='Executive' topic='Health' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/cdc', "_self")}}><b>Centers for Disease Control and Prevention</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defCdc'></div>
            {CreateFeedAPIDepartmentUS('&table_name=health_and_human_services&agency=Centers%20for%20Disease%20Control%20and%20Prevention', 'defCdc',
              'Centers for Disease Control and Prevention Documents', 'Executive',
              'Health', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={healthModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHealthModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Centers for Disease Control and Prevention</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=health_and_human_services&agency=Centers%20for%20Disease%20Control%20and%20Prevention'
                name='Centers for Disease Control and Prevention' branch='Executive' topic='Health' buttonName='Doc' />
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>



       
<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/children-families', "_self")}}><b>Children and Families Administration</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defCfa'></div>
            {CreateFeedAPIDepartmentUS('&table_name=health_and_human_services&agency=Children%20and%20Families%20Administration', 'defCfa',
              'Children and Families Administration Documents', 'Executive',
              'Health', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={healthModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHealthModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Children and Families Administration</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=health_and_human_services&agency=Children%20and%20Families%20Administration'
                name='Children and Families Administration' branch='Executive' topic='Health' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/community-living', "_self")}}><b>Community Living Administration</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defCla'></div>
            {CreateFeedAPIDepartmentUS('&table_name=health_and_human_services&agency=Community%20Living%20Administration', 'defCla',
              'Community Living Administration Documents', 'Executive',
              'Health', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={healthModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHealthModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Community Living Administration</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=health_and_human_services&agency=Community%20Living%20Administration'
                name='Community Living Administration' branch='Executive' topic='Health' buttonName='Doc' />
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/fda', "_self")}}><b>Food and Drug Administration</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defFda'></div>
            {CreateFeedAPIDepartmentUS('&table_name=health_and_human_services&agency=Food%20and%20Drug%20Administration', 'defFda',
              'Food and Drug Administration Documents', 'Executive',
              'Health', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={healthModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHealthModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Food and Drug Administration</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=health_and_human_services&agency=Food%20and%20Drug%20Administration'
                name='Food and Drug Administration' branch='Executive' topic='Health' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/health-care-finance', "_self")}}><b>Health Care Finance Administration</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defHcfa'></div>
            {CreateFeedAPIDepartmentUS('&table_name=health_and_human_services&agency=Health%20Care%20Finance%20Administration', 'defHcfa',
              'Health Care Finance Administration Documents', 'Executive',
              'Health', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={healthModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHealthModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Health Care Finance Administration</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=health_and_human_services&agency=Health%20Care%20Finance%20Administration'
                name='Health Care Finance Administration' branch='Executive' topic='Health' buttonName='Doc' />
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/health-resources', "_self")}}><b>Health Resources and Services Administration</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defHrsa'></div>
            {CreateFeedAPIDepartmentUS('&table_name=health_and_human_services&agency=Health%20Resources%20and%20Services%20Administration', 'defHrsa',
              'Health Resources and Services Administration Documents', 'Executive',
              'Health', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={healthModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHealthModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Health Resources and Services Administration</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=health_and_human_services&agency=Health%20Resources%20and%20Services%20Administration'
                name='Health Resources and Services Administration' branch='Executive' topic='Health' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/indian-health', "_self")}}><b>Indian Health Service</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defIhs'></div>
            {CreateFeedAPIDepartmentUS('&table_name=health_and_human_services&agency=Indian%20Health%20Service', 'defIhs',
              'Indian Health Service Documents', 'Executive',
              'Health', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={healthModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHealthModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Indian Health Service</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=health_and_human_services&agency=Indian%20Health%20Service'
                name='Indian Health Service' branch='Executive' topic='Health' buttonName='Doc' />
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/nih', "_self")}}><b>National Institutes of Health</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defNih'></div>
            {CreateFeedAPIDepartmentUS('&table_name=health_and_human_services&agency=National%20Institutes%20of%20Health', 'defNih',
              'National Institutes of Health Documents', 'Executive',
              'Health', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={healthModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHealthModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>National Institutes of Health</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=health_and_human_services&agency=National%20Institutes%20of%20Health'
                name='National Institutes of Health' branch='Executive' topic='Health' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/national-library-medicine', "_self")}}><b>National Library of Medicine</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defNlm'></div>
            {CreateFeedAPIDepartmentUS('&table_name=health_and_human_services&agency=National%20Library%20of%20Medicine', 'defNlm',
              'National Library of Medicine Documents', 'Executive',
              'Health', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={healthModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHealthModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>National Library of Medicine</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=health_and_human_services&agency=National%20Library%20of%20Medicine'
                name='National Library of Medicine' branch='Executive' topic='Health' buttonName='Doc' />
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/program-support-center', "_self")}}><b>Program Support Center</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defPsc'></div>
            {CreateFeedAPIDepartmentUS('&table_name=health_and_human_services&agency=Program%20Support%20Center', 'defPsc',
              'Program Support Center Documents', 'Executive',
              'Health', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={healthModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHealthModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Program Support Center</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=health_and_human_services&agency=Program%20Support%20Center'
                name='Program Support Center' branch='Executive' topic='Health' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/public-health-service', "_self")}}><b>Public Health Service</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defPhs'></div>
            {CreateFeedAPIDepartmentUS('&table_name=health_and_human_services&agency=Public%20Health%20Service', 'defPhs',
              'Public Health Service Documents', 'Executive',
              'Health', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={healthModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHealthModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Public Health Service</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=health_and_human_services&agency=Public%20Health%20Service'
                name='Public Health Service' branch='Executive' topic='Health' buttonName='Doc' />
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/refugee-resettlement-office', "_self")}}><b>Refugee Resettlement Office</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defRro'></div>
            {CreateFeedAPIDepartmentUS('&table_name=health_and_human_services&agency=Refugee%20Resettlement%20Office', 'defRro',
              'Refugee Resettlement Office Documents', 'Executive',
              'Health', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={healthModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHealthModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Refugee Resettlement Office</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=health_and_human_services&agency=Refugee%20Resettlement%20Office'
                name='Refugee Resettlement Office' branch='Executive' topic='Health' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/strategic-preparedness-response-administration', "_self")}}><b>Strategic Preparedness and Response Administration</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defSpra'></div>
            {CreateFeedAPIDepartmentUS('&table_name=health_and_human_services&agency=Strategic%20Preparedness%20and%20Response%20Administration', 'defSpra',
              'Strategic Preparedness and Response Administration Documents', 'Executive',
              'Health', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={healthModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHealthModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Strategic Preparedness and Response Administration</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=health_and_human_services&agency=Strategic%20Preparedness%20and%20Response%20Administration'
                name='Strategic Preparedness and Response Administration' branch='Executive' topic='Health' buttonName='Doc' />
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/substance-abuse-mental-health-services-administration', "_self")}}><b>Substance Abuse and Mental Health Services Administration</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defSamhsa'></div>
            {CreateFeedAPIDepartmentUS('&table_name=health_and_human_services&agency=Substance%20Abuse%20and%20Mental%20Health%20Services%20Administration', 'defSamhsa',
              'Substance Abuse and Mental Health Services Administration Documents', 'Executive',
              'Health', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={healthModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHealthModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Substance Abuse and Mental Health Services Administration</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=health_and_human_services&agency=Substance%20Abuse%20and%20Mental%20Health%20Services%20Administration'
                name='Substance Abuse and Mental Health Services Administration' branch='Executive' topic='Health' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
        </Col>
      </Row>
    </Container>
  </Container>
</div>    

           

      



            <Modal id='aboutModal' show={aboutModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAboutModalIsOpen(false)}>
            <div id='removedAlert'></div>
            <Modal.Header id='aboutModalHeader' closeButton><h3>About</h3></Modal.Header>
  <ModalBody id='aboutInfo'><p id='aboutParagraph'>Gov Glance was created to drive civic engagement and support a 
              healthier democracy by helping citizens get official Government news in an easy-to-navigate centralized location. 
              There will never be any opinion or political analysis(That's your job), only information from official Government 
              sources. Gov Glance is structured after the U.S. government that is broken into three branches Legislative, 
              Executive, and Judicial. To learn more about the structure and functions of The U.S. Government go to <a href='https://www.usa.gov/branches-of-government'>https://www.usa.gov/branches-of-government</a>.
              </p>

              <p id='aboutParagraph'>
              Gov Glance Foundation Inc. is a non-profit that was created to operate govglance.org and continue to create educational tools that help people learn about the workings of society
              </p>
            <br></br>

            <h3>Privacy</h3>

            <br></br>

              <p id='aboutParagraph'>Gov Glance does not collect any personal user information. 
              Features like collection save news items directly to your browser's local storage for your convenience
              and is not shared with Gov Glance servers. We do not collect, store, or send personal information to Google LLC, 
              but we do use some Google services to deliver our app to you. For any questions into Google’s privacy practices 
              here is a link to their privacy policy: <a href='https://policies.google.com/privacy?hl=en-US#intro'>https://policies.google.com/privacy?hl=en-US#intro</a>

              <br></br><br></br>

              For site analytics we use plausible.io an open source privacy friendly analytics provider. <a href="https://plausible.io/about" target="_blank">https://plausible.io/about</a>
            
            </p>
            <br></br>


            <h3>Contact</h3>

            <p id='aboutParagraph'>
                      Contact us via email: <a href='mailto:contact@govglance.org'>contact@govglance.org</a>

            </p>

            <br></br>


            
            </ModalBody>
          </Modal>
 
          <footer id='homeFooter'>
      <Navbar>
        <Row id='footerNav'>
      <Nav variant='pills'>
        
     <Nav.Link id='footerContent'>Gov Glance Foundation Inc.
</Nav.Link>
<Nav.Item>
<Nav.Link id='footerContent' href='mailto:contact@govglance.org'>Contact</Nav.Link>
</Nav.Item>
<Nav.Item>
<Nav.Link id='footerContent' onClick={(e) => {
                   e.preventDefault();
                   window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                 }}> <HeartFill id='donateIcon'/>    Donate</Nav.Link>
</Nav.Item>
<Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/about', "_self")}} id='footerContent'>Privacy</Nav.Link>
    
     
 </Nav>
 </Row>
 </Navbar>
      </footer>



</div>
         </div>
          

              </BrowserView>

              <MobileView>

    <HealthAndHumanServicesMobile/>

              </MobileView>
              
    </>
  )
}
 }



  






export default DepartmentOfHealthDesk;
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch,  } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment,  } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image, ModalFooter } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText,  ShieldShaded, CircleFill, Pen, People, ChatSquareQuote, ChatLeftQuote, BlockquoteRight, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, CaretRight, ChevronRight, ChevronCompactRight, CaretRightFill, ArrowRightShort,  ArrowRightCircle, PlayCircle, PauseCircle, Link45deg } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Axios from 'axios'
import { render } from '@testing-library/react';

import DepartmentOfTreasury from './DepartmentOfTreasury';

import{ whiteHouseSearch, createPresidentialProclamations, createExecutiveOrders, createWhiteHouseDocuments, createWhiteHouseFeed,
    createWhiteHouseFeedMobile,createDepartmentOfState,
    createDepartmentOfDefense, 
    createDepartmentOfJustice, createDepartmentOfTheInterior,
    createDepartmentOfAgriculture, createDepartmentOfCommerce,
    createDepartmentOfCommerceMobile, createDepartmentOfLabor,
    createDepartmentOfHealthAndHumanServices, createDepartmentOfHousingAndUrbanDevelopment, createDepartmentOfEducation
    ,  createDepartmentOfVeteransAffairs, createDepartmentOfHomelandSecurity
    , createEnvironmentalProtectionAgency,
    createDepartmentOfTreasury, createNationalScienceFoundation, 
     createEnvironmentalProtectionAgencyMobile, createNationalAeronauticsAndSpaceAdministration, Speak, createFederalRegisterDefenseDepartment, 
     createFederalRegisterStateDepartment, createFederalRegisterDefense,
    CreateFeedItem, CreateFeedItemPresidentialDocuments, CreateExecutiveOrders,
    CreateFeedItemExecutiveOrders,
    CreateFeedItemBillUpdates,
    CreateFeedItemExecutiveOffice,
    CreateFeedItemBillWithDescription,
    CreateFeedItemNoDate,
    CreateFeedAPIDepartmentUS,  } from './Functions'

import MobileOffCanvas from './MobileOffCanvas';
import MobileTopNavbar from './MobileTopNavbar';

import DepartmentOfTreasuryDocs from './DepartmentOfTreasuryDocs';
import AlcoholAndTobaccoTax from './AlcoholAndTobaccoTax';
import CommunityDevelopmentFinancialInstitutions from './CommunityDevelopmentFinancialInstitutions';
import ComptrollerOfTheCurrency from './ComptrollerOfTheCurrency';
import EngravingAndPrintingBureau from './EngravingAndPrintingBureau';
import FinancialCrimesEnforcement from './FinancialCrimesEnforcement';
import BureauOfTheFiscalService from './BureauOfTheFiscalService';
import ForeignAssetControlOffice from './ForeignAssetControlOffice';
import InternalRevenueService from './InternalRevenueService';
import UnitedStatesMint from './UnitedStatesMint';
import FinancialResearchOffice from './FinancialResearchOffice';
import FiscalService from './FiscalService';
import InvestmentSecurityOffice from './InvestmentSecurityOffice';
import GenerateFeedDepartment from './GenerateFeedDepartment';


var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 30000;



  

 


  

export const DepartmentOfTreasuryMobile = () =>   {
    render()
    
    {
        const [show, setShow] = useState(true);

        
        const [fullscreen, setFullScreen] = useState(true);
      
      
        const [fullBillModalIsOpen, setFullBillModalIsOpen] = useState(false)
      
        const [reportModalIsOpen, setReportModalIsOpen] = useState(false)
      
      
        const [whiteHouseFeedModalIsOpen, setWhiteHouseFeedModalIsOpen] = useState(false)
        const [whiteHouseDocsModalIsOpen, setWhiteHouseDocsModalIsOpen] = useState(false)
      
      
        const [courtNewsModalIsOpen, setCourtNewsModalIsOpen] = useState(false)
        const [courtModalIsOpen, setCourtModalIsOpen] = useState(false)
      
        const [courtAppealsModalIsOpen, setCourtAppealsModalIsOpen] = useState(false)
      
        const [newLawsModalIsOpen, setNewLawsModalIsOpen] = useState(false)
      
        const [billsEnrolledModalIsOpen, setBillsEnrolledModalIsOpen] = useState(false)
      
        const [legislativePageBillsEnrolledModalIsOpen, setLegislativePageBillsEnrolledModalIsOpen] = useState(false)
      
      
        const [departmentOfStateModalIsOpen, setDepartmentOfStateModalIsOpen] = useState(false)
        const [departmentOfTreasuryModalIsOpen, setDepartmentOfTreasuryModalIsOpen] = useState(false)
     
        const [departmentOfJusticeModalIsOpen, setDepartmentOfJusticeModalIsOpen] = useState(false)
        const [departmentOfTheInteriorModalIsOpen, setDepartmentOfTheInteriorModalIsOpen] = useState(false)
        const [departmentOfAgricultureModalIsOpen, setDepartmentOfAgricultureModalIsOpen] = useState(false)
        const [departmentOfCommerceModalIsOpen, setDepartmentOfCommerceModalIsOpen] = useState(false)
        const [departmentOfLaborModalIsOpen, setDepartmentOfLaborModalIsOpen] = useState(false)
        const [departmentOfHealthAndHumanServicesModalIsOpen, setDepartmentOfHealthAndHumanServicesModalIsOpen] = useState(false)
        const [departmentOfHousingAndUrbanDevelopmentModalIsOpen, setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen] = useState(false)
        const [departmentOfTransportationModalIsOpen, setDepartmentOfTransportationModalIsOpen] = useState(false)
        const [departmentOfEnergyModalIsOpen, setDepartmentOfEnergyModalIsOpen] = useState(false)
        const [departmentOfEducationModalIsOpen, setDepartmentOfEducationModalIsOpen] = useState(false)
        const [departmentOfVeteransAffairsModalIsOpen, setDepartmentOfVeteransAffairsModalIsOpen] = useState(false)
        const [departmentOfHomelandSecurityModalIsOpen, setDepartmentOfHomelandSecurityModalIsOpen] = useState(false)
        const [epaModalIsOpen, setEPAModalIsOpen] = useState(false)
        const [nsfModalIsOpen, setNSFModalIsOpen] = useState(false)
        const [nasaModalIsOpen, setNASAModalIsOpen] = useState(false)
        const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
        const [goaReportsModalIsOpen, setGAOReportsModalIsOpen] = useState(false)
        const [executiveOrdersModalIsOpen, setExecutiveOrdersModalIsOpen] = useState(false)
        const [presidentialProclamationsModalIsOpen, setPresidentialProclamationsModalIsOpen] = useState(false)
        const [statesModalIsOpen, setStatesModalIsOpen] = useState(false)
      
      
        const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false)
        const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)
      
      
        const [federalRegisterDefenseDepartmentModalIsOpen, setFederalRegisterDefenseDepartmentModalIsOpen] = useState(false)
  const [federalRegisterStateDepartmentModalIsOpen, setFederalRegisterStateDepartmentModalIsOpen] = useState(false)
        const [declarationModalIsOpen, setDeclarationModalIsOpen] = useState(false)
        const [constitutionModalIsOpen, setConstitutionModalIsOpen] = useState(false)
        const [billOfRightsModalIsOpen, setBillOfRightsModalIsOpen] = useState(false)

        const [economicAdModalIsOpen, setEconomicAdModalIsOpen] = useState(false)
        const [environmentQualityModalIsOpen, setEnvironmentQualityModalIsOpen] = useState(false)
        const [domesticPolicyModalIsOpen, setDomesticPolicyModalIsOpen] = useState(false)
        const [genderPolicyModalIsOpen, setGenderPolicyModalIsOpen] = useState(false)
        const [nationalEconomicModalIsOpen, setNationalEconomicModalIsOpen] = useState(false)
        const [securityModalIsOpen, setSecurityModalIsOpen] = useState(false)
        const [budgetModalIsOpen, setBudgetModalIsOpen] = useState(false)
        const [drugModalIsOpen, setDrugModalIsOpen] = useState(false)
        const [publicModalIsOpen, setPublicModalIsOpen] = useState(false)
        const [scienceModalIsOpen, setScienceModalIsOpen] = useState(false)
        const [cyberModalIsOpen, setCyberModalIsOpen] = useState(false)
        const [presidentialModalIsOpen, setPresidentialModalIsOpen] = useState(false)

        const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
        const [defenseDocumentsModalIsOpen, setDefenseDocumentsModalIsOpen] = useState(false)
        const [armyModalIsOpen, setArmyModalIsOpen] = useState(false)
        const [navyModalIsOpen, setNavyModalIsOpen] = useState(false)
        const [marinesModalIsOpen, setMarinesModalIsOpen] = useState(false)
        const [spaceForceModalIsOpen, setSpaceForceModalIsOpen] = useState(false)
        const [airForceModalIsOpen, setAirForceModalIsOpen] = useState(false)
        const [nationalGuardModalIsOpen, setNationalGuardModalIsOpen] = useState(false)
        const [ciaModalIsOpen, setCIAModalIsOpen] = useState(false)
        const [nsaModalIsOpen, setNSAModalIsOpen] = useState(false)
        const [armyCorpsModalIsOpen, setArmyCorpsModalIsOpen] = useState(false)
        const [showBioModal, setBioModal] = useState(false);
const [showMissionModal, setMissionModal] = useState(false);
        const [modalInfo, setModalInfo] = useState({
          isOpen: false,
          title: '',
          content: null
        });
      
        const openModal = (title, content) => {
          setModalInfo({
            isOpen: true,
            title,
            content
          });
        };
      
        const closeModal = () => {
          setModalInfo({
            isOpen: false,
            title: '',
            content: null
          });
        };

        var mobileCarouselInterval = 60000;
        const CustomModal = ({ isOpen, onClose, title, content }) => (
          <Modal id='fullBillModal' show={isOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={onClose}>
            <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
            {content}
          </Modal>
        );
    var branch = "all";
  
    return(
       <>
   <MobileTopNavbar/>
  
  
  
  
  <div id='homebg'>

               
  <CustomModal isOpen={modalInfo.isOpen} onClose={closeModal} title={modalInfo.title} content={modalInfo.content} />
  
  <div id="feedDisplayDepartment" style={{ padding: '10px', borderRadius: '18px' }}>
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div id="profileImageOnPageDepartment">
                <Image id="profileImage-imageOnPageDepartment" src="/departmentOfTreasury.png" />
              </div>
              <div style={{ marginLeft: '10px', color: 'white', fontSize: '18px', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                <h5 id="branchHeadingMobile" > Department of Treasury</h5>
              </div>
            </div>
            <Dropdown drop="down-centered">
              <Dropdown.Toggle id="searchCountry"><Link45deg /></Dropdown.Toggle>
              <Dropdown.Menu id="docsDropdown" drop="down-centered">
                <Dropdown.Item id="docsDropdownItem" onClick={(e) => { e.preventDefault(); window.open("https://home.treasury.gov/", "_blank") }}>
                  Official Site
                </Dropdown.Item>
               
                
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div id="billText" style={{ color: 'white', fontSize: '16px', marginTop: '10px' }}>
          
          The Department of the Treasury is responsible for promoting inclusive economic prosperity for all Americans.
          </div>
</div>
          <Button id='presidentViewMoreButton' onClick={() =>  setBioModal(true)}>  <Files size={20}></Files> Read More</Button>


       
<Modal id='fullBillModal'  size="xl" aria-labelledby="contained-modal-title-vcenter" show={showBioModal} onHide={() => setBioModal(false)}>
<Modal.Header id='billModalHeader' closeButton><h3> Department of Treasury</h3></Modal.Header>
       
       <div id='fullBillFeed' >
       <p>The Department of the Treasury is responsible for promoting inclusive economic prosperity for all Americans.</p>

<p>The Department advances U.S. and global economic growth to raise American standards of living, support communities, promote racial justice, combat climate change, and foster financial stability. The Department operates systems that are critical to the nation’s financial infrastructure, such as the production of coin and currency, the disbursement of payments owed to the American public, the collection of necessary taxes, and the borrowing of funds required by congressional enactments to run the federal government. The Treasury Department also performs a critical role in enhancing national security by safeguarding our financial systems, implementing economic sanctions against foreign threats to the U.S., and identifying and targeting financial support networks that threaten our national security.</p>

<p>The Secretary of the Treasury oversees a budget of approximately $13 billion and a staff of more than 100,000 employees.</p>


<br></br>
<Link style={{color: '#21cff5'}} onClick={(e) => {
   e.preventDefault();
   window.open("https://www.whitehouse.gov/about-the-white-house/our-government/the-executive-branch/#:~:text=approximately%20%2470%20billion.-,DEPARTMENT%20OF%20THE%20TREASURY,-The%20Department%20of", "_blank");
 }}>
   https://www.whitehouse.gov/about-the-white-house/our-government/the-executive-branch/#:~:text=approximately%20%2470%20billion.-,DEPARTMENT%20OF%20THE%20TREASURY,-The%20Department%20of
   </Link>
</div>

     </Modal>
 
            <Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setDepartmentOfDefenseModalIsOpen(true)}>News</h5>




  <div class='courtNewsMobile' id='laborDepartment'></div>

  {CreateFeedItem('https://home.treasury.gov/system/files/126/ofac.xml', 'laborDepartment', 'Department of Treasury', 'Executive', 'Economy', DepartmentOfTreasury)}

  <Modal id='whiteHouseDocsModal' show={departmentOfDefenseModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfDefenseModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Treasury</h3></Modal.Header>
                  <DepartmentOfTreasury/>
                </Modal>

  </Col>


  <Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setDefenseDocumentsModalIsOpen(true)}>Documents</h5>


  <div class='courtNewsMobile' id='frDefense'></div>

  {CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=treasury-department', 'frDefense', 'Department of Treasury Documents', 'Executive', 'N/A', DepartmentOfTreasuryDocs)}

</Col>
              
                <Modal id='whiteHouseDocsModal' show={defenseDocumentsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDefenseDocumentsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Treasury Documents</h3></Modal.Header>
                  <DepartmentOfTreasuryDocs/>
                </Modal>


                <Col>
  <Link  id='pageLink' to="/us/alcohol-tobacco">
    <h5 id='presidentialFeedHeadingMobile'>Alcohol and Tobacco Tax and Trade Bureau<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Alcohol and Tobacco Tax and Trade Bureau',
                <GenerateFeedDepartment url='&table_name=treasury&agency=Alcohol%20and%20Tobacco%20Tax%20and%20Trade%20Bureau'
                  name='Alcohol and Tobacco Tax and Trade Bureau' branch='Executive' topic='Economy' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defAttb'></div>
          {CreateFeedAPIDepartmentUS('&table_name=treasury&agency=Alcohol%20and%20Tobacco%20Tax%20and%20Trade%20Bureau', 'defAttb',
            'Alcohol and Tobacco Tax and Trade Bureau Documents', 'Executive',
            'Economy', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/alcohol-tobacco' >View all Alcohol and Tobacco Tax and Trade Bureau news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/fiscal-service">
    <h5 id='presidentialFeedHeadingMobile'>Bureau of the Fiscal Service<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Bureau of the Fiscal Service',
                <GenerateFeedDepartment url='&table_name=treasury&agency=Bureau%20of%20the%20Fiscal%20Service'
                  name='Bureau of the Fiscal Service' branch='Executive' topic='Economy' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defBfs'></div>
          {CreateFeedAPIDepartmentUS('&table_name=treasury&agency=Bureau%20of%20the%20Fiscal%20Service', 'defBfs',
            'Bureau of the Fiscal Service Documents', 'Executive',
            'Economy', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/fiscal-service' >View all Bureau of the Fiscal Service news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/cdfi-fund">
    <h5 id='presidentialFeedHeadingMobile'>Community Development Financial Institutions Fund<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Community Development Financial Institutions Fund',
                <GenerateFeedDepartment url='&table_name=treasury&agency=Community%20Development%20Financial%20Institutions%20Fund'
                  name='Community Development Financial Institutions Fund' branch='Executive' topic='Economy' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defCdif'></div>
          {CreateFeedAPIDepartmentUS('&table_name=treasury&agency=Community%20Development%20Financial%20Institutions%20Fund', 'defCdif',
            'Community Development Financial Institutions Fund Documents', 'Executive',
            'Economy', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/cdfi-fund' >View all Community Development Financial Institutions Fund news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/comptroller-currency">
    <h5 id='presidentialFeedHeadingMobile'>Comptroller of the Currency<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Comptroller of the Currency',
                <GenerateFeedDepartment url='&table_name=treasury&agency=Comptroller%20of%20the%20Currency'
                  name='Comptroller of the Currency' branch='Executive' topic='Economy' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defOcc'></div>
          {CreateFeedAPIDepartmentUS('&table_name=treasury&agency=Comptroller%20of%20the%20Currency', 'defOcc',
            'Comptroller of the Currency Documents', 'Executive',
            'Economy', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/comptroller-currency' >View all Comptroller of the Currency news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/customs-service">
    <h5 id='presidentialFeedHeadingMobile'>Customs Service<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Customs Service',
                <GenerateFeedDepartment url='&table_name=treasury&agency=Customs%20Service'
                  name='Customs Service' branch='Executive' topic='Economy' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defCs'></div>
          {CreateFeedAPIDepartmentUS('&table_name=treasury&agency=Customs%20Service', 'defCs',
            'Customs Service Documents', 'Executive',
            'Economy', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/customs-service' >View all Customs Service news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/engraving-printing">
    <h5 id='presidentialFeedHeadingMobile'>Engraving and Printing Bureau<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Engraving and Printing Bureau',
                <GenerateFeedDepartment url='&table_name=treasury&agency=Engraving%20and%20Printing%20Bureau'
                  name='Engraving and Printing Bureau' branch='Executive' topic='Economy' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defEpb'></div>
          {CreateFeedAPIDepartmentUS('&table_name=treasury&agency=Engraving%20and%20Printing%20Bureau', 'defEpb',
            'Engraving and Printing Bureau Documents', 'Executive',
            'Economy', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/engraving-printing' >View all Engraving and Printing Bureau news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/finCEN">
    <h5 id='presidentialFeedHeadingMobile'>Financial Crimes Enforcement Network<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Financial Crimes Enforcement Network',
                <GenerateFeedDepartment url='&table_name=treasury&agency=Financial%20Crimes%20Enforcement%20Network'
                  name='Financial Crimes Enforcement Network' branch='Executive' topic='Economy' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defFcen'></div>
          {CreateFeedAPIDepartmentUS('&table_name=treasury&agency=Financial%20Crimes%20Enforcement%20Network', 'defFcen',
            'Financial Crimes Enforcement Network Documents', 'Executive',
            'Economy', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/finCEN' >View all Financial Crimes Enforcement Network news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/financial-research">
    <h5 id='presidentialFeedHeadingMobile'>Financial Research Office<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Financial Research Office',
                <GenerateFeedDepartment url='&table_name=treasury&agency=Financial%20Research%20Office'
                  name='Financial Research Office' branch='Executive' topic='Economy' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defFro'></div>
          {CreateFeedAPIDepartmentUS('&table_name=treasury&agency=Financial%20Research%20Office', 'defFro',
            'Financial Research Office Documents', 'Executive',
            'Economy', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/financial-research' >View all Financial Research Office news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/fiscal-service">
    <h5 id='presidentialFeedHeadingMobile'>Fiscal Service<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Fiscal Service',
                <GenerateFeedDepartment url='&table_name=treasury&agency=Fiscal%20Service'
                  name='Fiscal Service' branch='Executive' topic='Economy' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defFs'></div>
          {CreateFeedAPIDepartmentUS('&table_name=treasury&agency=Fiscal%20Service', 'defFs',
            'Fiscal Service Documents', 'Executive',
            'Economy', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/fiscal-service' >View all Fiscal Service news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/foreign-assets-control">
    <h5 id='presidentialFeedHeadingMobile'>Foreign Assets Control Office<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Foreign Assets Control Office',
                <GenerateFeedDepartment url='&table_name=treasury&agency=Foreign%20Assets%20Control%20Office'
                  name='Foreign Assets Control Office' branch='Executive' topic='Economy' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defFaco'></div>
          {CreateFeedAPIDepartmentUS('&table_name=treasury&agency=Foreign%20Assets%20Control%20Office', 'defFaco',
            'Foreign Assets Control Office Documents', 'Executive',
            'Economy', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/foreign-assets' >View all Foreign Assets Control Office news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/irs">
    <h5 id='presidentialFeedHeadingMobile'>Internal Revenue Service<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Internal Revenue Service',
                <GenerateFeedDepartment url='&table_name=treasury&agency=Internal%20Revenue%20Service'
                  name='Internal Revenue Service' branch='Executive' topic='Economy' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defIrs'></div>
          {CreateFeedAPIDepartmentUS('&table_name=treasury&agency=Internal%20Revenue%20Service', 'defIrs',
            'Internal Revenue Service Documents', 'Executive',
            'Economy', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/irs' >View all Internal Revenue Service news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/international-investment">
    <h5 id='presidentialFeedHeadingMobile'>International Investment Office<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('International Investment Office',
                <GenerateFeedDepartment url='&table_name=treasury&agency=International%20Investment%20Office'
                  name='International Investment Office' branch='Executive' topic='Economy' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defIio'></div>
          {CreateFeedAPIDepartmentUS('&table_name=treasury&agency=International%20Investment%20Office', 'defIio',
            'International Investment Office Documents', 'Executive',
            'Economy', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/international-investment' >View all International Investment Office news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/investment-security">
    <h5 id='presidentialFeedHeadingMobile'>Investment Security Office<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Investment Security Office',
                <GenerateFeedDepartment url='&table_name=treasury&agency=Investment%20Security%20Office'
                  name='Investment Security Office' branch='Executive' topic='Economy' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defIso'></div>
          {CreateFeedAPIDepartmentUS('&table_name=treasury&agency=Investment%20Security%20Office', 'defIso',
            'Investment Security Office Documents', 'Executive',
            'Economy', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/investment-security' >View all Investment Security Office news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/monetary-offices">
    <h5 id='presidentialFeedHeadingMobile'>Monetary Offices<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Monetary Offices',
                <GenerateFeedDepartment url='&table_name=treasury&agency=Monetary%20Offices'
                  name='Monetary Offices' branch='Executive' topic='Economy' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defMo'></div>
          {CreateFeedAPIDepartmentUS('&table_name=treasury&agency=Monetary%20Offices', 'defMo',
            'Monetary Offices Documents', 'Executive',
            'Economy', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/monetary-offices' >View all Monetary Offices news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/public-debt">
    <h5 id='presidentialFeedHeadingMobile'>Public Debt Bureau<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Public Debt Bureau',
                <GenerateFeedDepartment url='&table_name=treasury&agency=Public%20Debt%20Bureau'
                  name='Public Debt Bureau' branch='Executive' topic='Economy' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defPdb'></div>
          {CreateFeedAPIDepartmentUS('&table_name=treasury&agency=Public%20Debt%20Bureau', 'defPdb',
            'Public Debt Bureau Documents', 'Executive',
            'Economy', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/public-debt' >View all Public Debt Bureau news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/thrift-supervision">
    <h5 id='presidentialFeedHeadingMobile'>Thrift Supervision Office<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Thrift Supervision Office',
                <GenerateFeedDepartment url='&table_name=treasury&agency=Thrift%20Supervision%20Office'
                  name='Thrift Supervision Office' branch='Executive' topic='Economy' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defTso'></div>
          {CreateFeedAPIDepartmentUS('&table_name=treasury&agency=Thrift%20Supervision%20Office', 'defTso',
            'Thrift Supervision Office Documents', 'Executive',
            'Economy', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/thrift-supervision' >View all Thrift Supervision Office news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/us-mint">
    <h5 id='presidentialFeedHeadingMobile'>United States Mint<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('United States Mint',
                <GenerateFeedDepartment url='&table_name=treasury&agency=United%20States%20Mint'
                  name='United States Mint' branch='Executive' topic='Economy' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defUsm'></div>
          {CreateFeedAPIDepartmentUS('&table_name=treasury&agency=United%20States%20Mint', 'defUsm',
            'United States Mint Documents', 'Executive',
            'Economy', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/us-mint' >View all United States Mint news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>







<br></br>
          
          
            <br></br><br></br><br></br><br></br><br></br><br></br><Navbar id='navbarMobileBottom' fixed="bottom">    

          


       
<div id='changeBranchDropdownMobile'>

           <MobileOffCanvas/>
              
            </div>

           


           
 
             
 
 
 
 
 
 
</Navbar>
          </div>

          </>
  )
}
 }



  






export default DepartmentOfTreasuryMobile;


import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch,  } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment,  } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image, ModalFooter } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText,  ShieldShaded, CircleFill, Pen, People, ChatSquareQuote, ChatLeftQuote, BlockquoteRight, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, CaretRight, ChevronRight, ChevronCompactRight, CaretRightFill, ArrowRightShort,  ArrowRightCircle, PlayCircle, PauseCircle, HeartFill, Link45deg } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'

import { render } from '@testing-library/react';
import FullBillFeed from './FullBillFeed';
import FullWhiteHouseFeed from './FullWhiteHouseFeed';
import FullUSCourtNews from './FullUSCourtNews';
import FullWhiteHouseDocuments from './FullWhiteHouseDocuments';
import DailyDigest from './DailyDigest';
import State from './State';
import Illinois from './state/Illinois';
import CollectionPage from './CollectionPage';
import NewLaws from './NewLaws';
import BillsEnrolled from './BillsEnrolled';
import DepartmentOfState from './DepartmentOfState';
import DepartmentOfTreasury from './DepartmentOfTreasury';
import CourtAppeals from './CourtAppeals';
import Reports from './Reports';
import DepartmentOfDefense from './DepartmentOfDefense';
import DepartmentOfJustice from './DepartmentOfJustice';
import DepartmentOfTheInterior from './DepartmentOfTheInterior';
import DepartmentOfAgriculture from './DepartmentOfAgriculture';
import DepartmentOfCommerce from './DepartmentOfCommerce';
import DepartmentOfLabor from './DepartmentOfLabor';
import DepartmentOfHealthAndHumanServices from './DepartmentOfHealthAndHumanServices';
import DepartmentOfHousingAndUrbanDevelopment from './DepartmentOfHousingAndUrbanDevelopment';
import DepartmentOfTransportation from './DepartmentOfTransportation';
import DepartmentOfEnergy from './DepartmentOfEnergy';
import DepartmentOfEducation from './DepartmentOfEducation';
import DepartmentOfVeteranAffairs from './DepartmentOfVeteranAffairs';
import DepartmentOfHomelandSecurity from './DepartmentOfHomelandSecurity';
import EnvironmentalProtectionAgency from './EnvironmentalProtectionAgency';
import NationalAeronauticsAndSpaceAdministration from './NationalAeronauticsAndSpaceAdministration';
import TrendingBills from './TrendingBills';
import GAOReports from './GAOReports';
import LegislativeBranch from './LegislativeBranch';
import HouseComitteeOnAgriculture from './HouseComitteeOnAgriculture';
import HouseCommitteeOnAppropriations from './HouseCommitteeOnArmedServices';
import HouseCommitteeOnOversightAndReform from './HouseCommitteeOnOversightAndReform';

import App from './App';
import Overlay from 'react-overlays/esm/Overlay';
import HouseCommitteeOnArmedServices from './HouseCommitteeOnArmedServices';
import HouseCommitteeOnTheBudget from './HouseCommitteeOnTheBudget';
import HouseCommitteeOnEducationAndLabor from './HouseCommitteeOnEducationAndLabor';
import HouseFloor from './HouseFloor';
import HouseCommitteeOnEnergyAndCommerce from './HouseCommitteeOnEnergyAndCommerce';
import HouseCommitteeOnEthics from './HouseCommitteeOnEthics';
import CongressionalHearings from './CongressionalHearings';
import HouseCommitteeOnFinacialServices from './HouseCommitteeOnFinacialServices';
import HouseCommitteeOnForeignAffairs from './HouseCommitteeOnForeignAffairs';
import HouseCommitteeOnHomelandSecurity from './HouseCommitteeOnHomelandSecurity';
import HouseCommitteeOnTheJudiciary from './HouseCommitteeOnTheJudiciary';
import HouseCommitteeOnNaturalResources from './HouseCommitteeOnNaturalResources';
import HouseCommitteeOnHouseAdministration from './HouseCommitteeOnHouseAdministration';
import CongressionalBudgetOffice from './CongressionalBudgetOffice';
import PresidentialProclamations from './PresidentialProclamations';
import ExecutiveOrders from './ExecutiveOrders';
import{ whiteHouseSearch, createPresidentialProclamations, createExecutiveOrders, createWhiteHouseDocuments, createWhiteHouseFeed,
    createWhiteHouseFeedMobile,createDepartmentOfState,
    createDepartmentOfDefense, 
    createDepartmentOfJustice, createDepartmentOfTheInterior,
    createDepartmentOfAgriculture, createDepartmentOfCommerce,
    createDepartmentOfCommerceMobile, createDepartmentOfLabor,
    createDepartmentOfHealthAndHumanServices, createDepartmentOfHousingAndUrbanDevelopment, createDepartmentOfEducation
    ,  createDepartmentOfVeteransAffairs, createDepartmentOfHomelandSecurity
    , createEnvironmentalProtectionAgency,
    createDepartmentOfTreasury, createNationalScienceFoundation, 
     createEnvironmentalProtectionAgencyMobile, createNationalAeronauticsAndSpaceAdministration, Speak, createFederalRegisterDefenseDepartment, 
     createFederalRegisterStateDepartment, createFederalRegisterDefense,
    CreateFeedItem, CreateFeedItemPresidentialDocuments, CreateExecutiveOrders,
    CreateFeedItemExecutiveOrders,
    CreateFeedItemBillUpdates,
    CreateFeedItemExecutiveOffice,
    CreateFeedItemNoDate,
    CreateFeedItemBillWithDescription,
    CreateFeedAPI,
    CreateFeedItemExecutiveOrdersCache,  } from './Functions'
import NationalScienceFoundation from './NationalScienceFoundation';
import FederalRegisterDefenseDepartment from './FederalRegisterDefenseDepartment';
import FederalRegisterStateDepartment from './FederalRegisterStateDepartment';
import FederalRegisterTreasuryDepartment from './FederalRegisterTreasuryDepartment';
import MobileOffCanvas from './MobileOffCanvas';
import MobileTopNavbar from './MobileTopNavbar';
import BillUpdates from './BillUpdates';
import CouncilOfEconomicAdvisers from './CouncilOfEconomicAdvisers';
import CouncilOnEnvironmentQuality from './CouncilOnEnvironmentQuality';
import DomesticPolicyCouncil from './DomesticPolicyCouncil';
import GenderPolicyCouncil from './GenderPolicyCouncil';
import NationalEconomicCouncil from './NationalEconomicCouncil';
import ArmyDocuments from './ArmyDocuments';
import NavyDocuments from './NavyDocuments';

import CIADocuments from './CIADocuments';
import TSADocuments from './TSADocuments';
import SecretService from './SecretService';
import FEMADocuments from './FEMADocuments';
import CoastGuardDocuments from './CoastGuardDocuments';
import HomelandSecurityDocuments from './HomelandSecurityDocuments';
import DepartmentOfCommerceDocuments from './DepartmentOfCommerceDocuments';
import CensusBureau from './CensusBureau';
import EconomicAnalysisBureau from './EconomicAnalysisBureau';
import EconomicDevelopmentAdministration from './EconomicDevelopmentAdministration';
import DoSPress from './DoSPress';
import ArmsControl from './ArmsControl';
import DepartmentOfAgricultureDocuments from './DepartmentOfAgricultureDocuments';
import DepartmentOfEducationDocs from './DepartmentOfEducationDocs';
import DepartmentOfEnergyDocs from './DepartmentOfEnergyDocs';
import DepartmentOfHousingAndUrbanDevelopmentDocs from './DepartmentOfHousingAndUrbanDevelopmentDocs';
import DepartmentOfHealthAndHumanServicesDocs from './DepartmentOfHealthAndHumanServicesDocs';
import DepartmentOfTheInteriorDocs from './DepartmentOfTheInteriorDocs';
import DepartmentOfJusticeDocs from './DepartmentOfJusticeDocs';
import DepartmentOfLaborDocs from './DepartmentOfLaborDocs';
import DepartmentOfTreasuryDocs from './DepartmentOfTreasuryDocs';
import DepartmentOfVeteransAffairsDocs from './DepartmentOfVeteransAffairsDocs';
import DepartmentOfTransportationDocs from './DepartmentOfTransportationDocs';
import CommercialSpaceTransportationOffice from './CommercialSpaceTransportationOffice';
import EnergyInformationAdministration from './EnergyInformationAdministration';
import FederalEnergyRegulatoryCommission from './FederalEnergyRegulatoryCommission';
import MobileBackButton from './MobileBackButton';

import GenerateFeed from './GenerateFeed';

import PresidentDisplay from './PresidentDisplay';
import VicePresidentDisplay from './VicePresidentDisplay';
import PresidentDisplayOnPage from './PresidentDisplayOnPage';
import DesktopNavbar from './DesktopNavbar';
import VicePresidentDisplayOnPage from './VicePresidentDisplayOnPage';



var response = '';

var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 30000;


export const WhiteHousePageMobile = () =>   {
    render()

    {
        const [show, setShow] = useState(true);

        const [fullscreen, setFullScreen] = useState(true);
      
      
        const [fullBillModalIsOpen, setFullBillModalIsOpen] = useState(false)
      
        const [reportModalIsOpen, setReportModalIsOpen] = useState(false)
      
      
        const [whiteHouseFeedModalIsOpen, setWhiteHouseFeedModalIsOpen] = useState(false)
        const [whiteHouseDocsModalIsOpen, setWhiteHouseDocsModalIsOpen] = useState(false)
      
      
        const [courtNewsModalIsOpen, setCourtNewsModalIsOpen] = useState(false)
        const [courtModalIsOpen, setCourtModalIsOpen] = useState(false)
      
        const [courtAppealsModalIsOpen, setCourtAppealsModalIsOpen] = useState(false)
      
        const [newLawsModalIsOpen, setNewLawsModalIsOpen] = useState(false)
      
        const [billsEnrolledModalIsOpen, setBillsEnrolledModalIsOpen] = useState(false)
      
        const [legislativePageBillsEnrolledModalIsOpen, setLegislativePageBillsEnrolledModalIsOpen] = useState(false)
      
      
        const [departmentOfStateModalIsOpen, setDepartmentOfStateModalIsOpen] = useState(false)
        const [departmentOfTreasuryModalIsOpen, setDepartmentOfTreasuryModalIsOpen] = useState(false)
        const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
        const [departmentOfJusticeModalIsOpen, setDepartmentOfJusticeModalIsOpen] = useState(false)
        const [departmentOfTheInteriorModalIsOpen, setDepartmentOfTheInteriorModalIsOpen] = useState(false)
        const [departmentOfAgricultureModalIsOpen, setDepartmentOfAgricultureModalIsOpen] = useState(false)
        const [departmentOfCommerceModalIsOpen, setDepartmentOfCommerceModalIsOpen] = useState(false)
        const [departmentOfLaborModalIsOpen, setDepartmentOfLaborModalIsOpen] = useState(false)
        const [departmentOfHealthAndHumanServicesModalIsOpen, setDepartmentOfHealthAndHumanServicesModalIsOpen] = useState(false)
        const [departmentOfHousingAndUrbanDevelopmentModalIsOpen, setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen] = useState(false)
        const [departmentOfTransportationModalIsOpen, setDepartmentOfTransportationModalIsOpen] = useState(false)
        const [departmentOfEnergyModalIsOpen, setDepartmentOfEnergyModalIsOpen] = useState(false)
        const [departmentOfEducationModalIsOpen, setDepartmentOfEducationModalIsOpen] = useState(false)
        const [departmentOfVeteransAffairsModalIsOpen, setDepartmentOfVeteransAffairsModalIsOpen] = useState(false)
        const [departmentOfHomelandSecurityModalIsOpen, setDepartmentOfHomelandSecurityModalIsOpen] = useState(false)
        const [epaModalIsOpen, setEPAModalIsOpen] = useState(false)
        const [nsfModalIsOpen, setNSFModalIsOpen] = useState(false)
        const [nasaModalIsOpen, setNASAModalIsOpen] = useState(false)
        const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
        const [goaReportsModalIsOpen, setGAOReportsModalIsOpen] = useState(false)
        const [executiveOrdersModalIsOpen, setExecutiveOrdersModalIsOpen] = useState(false)
        const [presidentialProclamationsModalIsOpen, setPresidentialProclamationsModalIsOpen] = useState(false)
        const [statesModalIsOpen, setStatesModalIsOpen] = useState(false)
      
      
        const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false)
        const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)
      
      
        const [federalRegisterDefenseDepartmentModalIsOpen, setFederalRegisterDefenseDepartmentModalIsOpen] = useState(false)
  const [federalRegisterStateDepartmentModalIsOpen, setFederalRegisterStateDepartmentModalIsOpen] = useState(false)
        const [declarationModalIsOpen, setDeclarationModalIsOpen] = useState(false)
        const [constitutionModalIsOpen, setConstitutionModalIsOpen] = useState(false)
        const [billOfRightsModalIsOpen, setBillOfRightsModalIsOpen] = useState(false)
        const [whiteHouseBreifingModalIsOpen, setWhiteHouseBreifingModalIsOpen] = useState(false)
        const [presidentialDocumentsModalIsOpen, setPresidentialDocumentsModalIsOpen] = useState(false)
        const [stateDocumentsModalIsOpen, setStateDocumentsModalIsOpen] = useState(false)
        const [defenseDocumentsModalIsOpen, setDefenseDocumentsModalIsOpen] = useState(false)
        const [economicAdModalIsOpen, setEconomicAdModalIsOpen] = useState(false)
        const [environmentQualityModalIsOpen, setEnvironmentQualityModalIsOpen] = useState(false)
        const [domesticPolicyModalIsOpen, setDomesticPolicyModalIsOpen] = useState(false)
        const [genderPolicyModalIsOpen, setGenderPolicyModalIsOpen] = useState(false)
        const [nationalEconomicModalIsOpen, setNationalEconomicModalIsOpen] = useState(false)
        const [armyModalIsOpen, setArmyModalIsOpen] = useState(false)
        const [navyModalIsOpen, setNavyModalIsOpen] = useState(false)
        const [marinesModalIsOpen, setMarinesModalIsOpen] = useState(false)
        const [spaceForceModalIsOpen, setSpaceForceModalIsOpen] = useState(false)
        const [airForceModalIsOpen, setAirForceModalIsOpen] = useState(false)
        const [nationalGuardModalIsOpen, setNationalGuardModalIsOpen] = useState(false)
        const [ciaModalIsOpen, setCIAModalIsOpen] = useState(false)
        const [nsaModalIsOpen, setNSAModalIsOpen] = useState(false)

        const [coastGuardModalIsOpen, setCoastGuardModalIsOpen] = useState(false)
        const [femaModalIsOpen, setFEMAModalIsOpen] = useState(false)
        const [secretServiceModalIsOpen, setSecretServiceModalIsOpen] = useState(false)
        const [tsaModalIsOpen, setTSAModalIsOpen] = useState(false)
        const [immigrationModalIsOpen, setImmigrationModalIsOpen] = useState(false)
        const [usCustomsModalIsOpen, setUSCustomsModalIsOpen] = useState(false)
        const [enforcementsModalIsOpen, setEnforcemnetsModalIsOpen] = useState(false)
        const [homelandDocsModalIsOpen, setHomelandDocsModalIsOpen] = useState(false)

        
        // const [ModalIsOpen, setModalIsOpen] = useState(false)
        const [commerceDocsModalIsOpen, setCommerceDocsModalIsOpen] = useState(false)
        const [censusBureauModalIsOpen, setCensusBureauModalIsOpen] = useState(false)
        const [economicAnalysisModalIsOpen, setEconomicAnalysisModalIsOpen] = useState(false)
        const [ecoDevelopmentModalIsOpen, setEconDevelopmentModalIsOpen] = useState(false)
        const [counterterrorismModalIsOpen, setCounterterrorismModalIsOpen] = useState(false)
        const [democracyHumanRightsLaborModalIsOpen, setDemocracyHumanRightsLaborModalIsOpen] = useState(false)
        const [dOSPressModalIsOpen, setDOSPressModalIsOpen] = useState(false)
        const [diplomaticSecurityModalIsOpen, setDiplomaticSecurityModalIsOpen] = useState(false)
        const [armsControlModalIsOpen, setArmsControlModalIsOpen] = useState(false)
        const [agDocsModalIsOpen, setAgDocsModalIsOpen] = useState(false)
        const [eduDocumentsModalIsOpen, setEduDocumentsModalIsOpen] = useState(false)

   
    
    var branch = "all";
  
    return(

       <>

       <MobileView>
   <MobileTopNavbar/>
  
  
  
  
  <div id='homebg'>

  <div id="feedDisplayDepartment" style={{ padding: '10px', borderRadius: '18px' }}>
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div id="profileImageOnPageDepartment">
                <Image id="profileImage-imageOnPageDepartment" src="/whiteHouse.png" />
              </div>
              <div style={{ marginLeft: '10px', color: 'white', fontSize: '18px', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                <h5 id="branchHeadingMobile" > White House</h5>
              </div>
            </div>
            <Dropdown drop="down-centered">
              <Dropdown.Toggle id="searchCountry"><Link45deg /></Dropdown.Toggle>
              <Dropdown.Menu id="docsDropdown" drop="down-centered">
                <Dropdown.Item id="docsDropdownItem" onClick={(e) => { e.preventDefault(); window.open("https://www.whitehouse.gov/about-the-white-house/", "_blank") }}>
                  Official Site
                </Dropdown.Item>

             
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div id="billText" style={{ color: 'white', fontSize: '16px', marginTop: '10px' }}>
          
          The White House is where the President and First Family of the United States live and work — but it’s also the People’s House, where we hope all Americans feel a sense of inclusion and belonging.
          </div>
</div>




 
           

       
            <Col>                                    
                                      <Link  id='pageLink' to="/us/presidents"> <h5 id='presidentialFeedHeadingMobile'>President<ChevronRight id='clickChevron'></ChevronRight></h5></Link>
                          

                          
                          <PresidentDisplay/>
                     
             </Col>

             <Col>                                    
                                      <Link  id='pageLink' to="/us/vicepresidents"> <h5 id='presidentialFeedHeadingMobile'>Vice President<ChevronRight id='clickChevron'></ChevronRight></h5></Link>
                          

                          
                          <VicePresidentDisplay/>
                     
             </Col>
              

                <Col>



                  <h5 id='presidentialFeedHeadingMobile' onClick={() => setWhiteHouseBreifingModalIsOpen(true)}>Briefing Room</h5>


                  <div class='courtNewsMobile' id='wh'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
                            {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=the_white_house&order_by=created_at', 'wh', 
                            'White House Briefing Room', 'Executive', 
                            'N/A', '', GenerateFeed)}

                          <Modal id='whiteHouseFeedModal' show={whiteHouseBreifingModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setWhiteHouseBreifingModalIsOpen(false)}>
                            <Modal.Header id='whiteHouseFeedModalHeader' closeButton><h3>White House Briefing Room</h3></Modal.Header>
                            <GenerateFeed url='&skip=0&schema=united_states_of_america&table=the_white_house&order_by=created_at' 
                            name='White House Briefing Room' branch='Executive' topic='N/A' buttonName='' />
                          </Modal>


      
                  </Col>

                 
                      <Col>

                <h5 id='presidentialFeedHeadingMobile' onClick={() => setPresidentialDocumentsModalIsOpen(true)}>Presidential Documents</h5>


                <div class='courtNewsMobile' id='whdocs'></div>
                          
                          {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=presidential_documents&order_by=created_at', 'whdocs', 
                            'Presidential Documents', 'Executive', 
                            'N/A', 'Document', GenerateFeed)}

                          <Modal id='whiteHouseDocsModal' show={presidentialDocumentsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setPresidentialDocumentsModalIsOpen(false)}>
                            <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Presidential Documents</h3></Modal.Header>
                            <GenerateFeed url='&skip=0&schema=united_states_of_america&table=compilation_of_presidential_documents&order_by=created_at' 
                            name='Presidential Documents' branch='Executive' topic='N/A' buttonName='Document' />
                          </Modal>

                </Col>
            <Col>


               <h5 id='presidentialFeedHeadingMobile' onClick={() => setExecutiveOrdersModalIsOpen(true)}>Executive Orders</h5> 


               
                <div class='courtNewsMobile' id='executiveOrders'></div>
        
        {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=president_executive_orders&order_by=created_at', 'executiveOrders', 
                            'Executive Orders', 'Executive', 
                            'N/A', '', GenerateFeed)}
        <Modal id='whiteHouseDocsModal' show={executiveOrdersModalIsOpen} 
        size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() =>setExecutiveOrdersModalIsOpen(false)}>
          <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Executive Orders</h3></Modal.Header>
          <GenerateFeed url='&skip=0&schema=united_states_of_america&table=president_executive_orders&order_by=created_at' 
                            name='Executive Orders' branch='Executive' topic='N/A' buttonName='' />
        </Modal>
        </Col>

            <Col>

                <h5 id='presidentialFeedHeadingMobile' onClick={() => setPresidentialProclamationsModalIsOpen(true)}> Presidential Proclamations</h5>


              
                <div class='courtNewsMobile' id='presidentialProclomations'>
                </div>
                {CreateFeedItemExecutiveOrdersCache('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bcorrection%5D=0&conditions%5Bpresident%5D=&conditions%5Bpresidential_document_type%5D=proclamation&conditions%5Bsigning_date%5D%5Byear%5D=&conditions%5Btype%5D%5B%5D=PRESDOCU', 'presidentialProclomations', 'Presidential Proclamations', 'Executive', 'N/A', PresidentialProclamations)}


        </Col>
               
<Modal id='whiteHouseDocsModal' show={presidentialProclamationsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() =>setPresidentialProclamationsModalIsOpen(false)}>
  <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Presidential Proclamations</h3></Modal.Header>
  <PresidentialProclamations />
</Modal>


<Col>


<Link  id='pageLink' to="/us/executiveoffice"><h5 id='presidentialFeedHeadingMobile'>Executive Office of the President<ChevronRight id='clickChevron'></ChevronRight></h5></Link>
             
                <Carousel controls={false} >


  <Carousel.Item interval={mobileCarouselInterval}>

    <div id='legislativeConMobile'>

  <Col>
  <Row id = 'mobileFeedHeadingRow'> <h5 id='billFeedHeadingMobileHome' onClick={() => setEconomicAdModalIsOpen(true)}>Council of Economic Advisers</h5></Row>
      <div class='courtNewsMobile' id='councilOfEconomicAd'></div>
                    
                    {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=council_of_economic_advisers&order_by=created_at', 
                    'councilOfEconomicAd', 
                      'Council of Economic Advisers', 'Executive', 
                      'Economy', 'Document', GenerateFeed)}

                    <Modal id='whiteHouseDocsModal' show={economicAdModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
                    centered onHide={() => setEconomicAdModalIsOpen(false)}>
                      <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Council of Economic Advisers</h3></Modal.Header>
                      <GenerateFeed url='&skip=0&schema=united_states_of_america&table=council_of_economic_advisers&order_by=created_at' 
                      name='Council of Economic Advisers' branch='Executive' topic='Economy' buttonName='Document' />
                    </Modal>
        </Col>

     

                


        
      
        </div>
       


</Carousel.Item>


<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>
<Row id = 'mobileFeedHeadingRow'><h5 id='billFeedHeadingMobileHome' onClick={() => setEnvironmentQualityModalIsOpen(true)}>Council on Environment Quality</h5></Row>
  <div class='courtNewsMobile' id='councilOnEnvironment'></div>
                     
                     {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=council_on_environmental_quality&order_by=created_at', 
                     'councilOnEnvironment', 
                       'Council on Environmental Quality', 'Executive', 
                       'Environment', 'Document', GenerateFeed)}

                     <Modal id='whiteHouseDocsModal' show={environmentQualityModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
                     centered onHide={() => setEnvironmentQualityModalIsOpen(false)}>
                       <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Council on Environmental Quality</h3></Modal.Header>
                       <GenerateFeed url='&skip=0&schema=united_states_of_america&table=council_on_environmental_quality&order_by=created_at' 
                       name='Council on Environmental Quality' branch='Executive' topic='Environment' buttonName='Document' />
                     </Modal>
    </Col>

   


    
  
    </div>
   


</Carousel.Item>


<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>
<Row id = 'mobileFeedHeadingRow'> <h5 id='billFeedHeadingMobileHome' onClick={() => setDomesticPolicyModalIsOpen(true)}>Domestic Policy Council</h5></Row>
  <div class='courtNewsMobile' id='domesticPolicyCouncil'></div>
                          
  {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=domestic_policy_council&order_by=created_at', 
                     'domesticPolicyCouncil', 
                       'Domestic Policy Council', 'Executive', 
                       'N/A', 'Document', GenerateFeed)}

                     <Modal id='whiteHouseDocsModal' show={domesticPolicyModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
                     centered onHide={() => setDomesticPolicyModalIsOpen(false)}>
                       <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Domestic Policy Council</h3></Modal.Header>
                       <GenerateFeed url='&skip=0&schema=united_states_of_america&table=domestic_policy_council&order_by=created_at' 
                       name='Domestic Policy Council' branch='Executive' topic='N/A' buttonName='Document' />
                     </Modal>

    </Col>


    
  
    </div>
   


</Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>
<Row id = 'mobileFeedHeadingRow'><h5 id='billFeedHeadingMobileHome' onClick={() => setGenderPolicyModalIsOpen(true)}>Gender Policy Council</h5></Row>
  <div class='courtNewsMobile' id='genderPolicyCouncil'></div>
                          
  {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=gender_policy_council&order_by=created_at', 
                     'genderPolicyCouncil', 
                       'Gender Policy Council', 'Executive', 
                       'N/A', 'Document', GenerateFeed)}

                     <Modal id='whiteHouseDocsModal' show={genderPolicyModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
                     centered onHide={() => setGenderPolicyModalIsOpen(false)}>
                       <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Gender Policy Council</h3></Modal.Header>
                       <GenerateFeed url='&skip=0&schema=united_states_of_america&table=gender_policy_council&order_by=created_at' 
                       name='Gender Policy Council' branch='Executive' topic='N/A' buttonName='Document' />
                     </Modal>
 
    </Col>


    
  
    </div>
   


</Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>
<Row id = 'mobileFeedHeadingRow'><h5 id='billFeedHeadingMobileHome' onClick={() => setNationalEconomicModalIsOpen(true)}>National Economic Council</h5></Row>
  <div class='courtNewsMobile' id='nationalEconomicCouncil'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
                            {CreateFeedItemExecutiveOffice('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/executive_office_of_the_president/National_Economic_Council.xml', 'nationalEconomicCouncil', 'National Economic Council', 'Executive', 'N/A', NationalEconomicCouncil)}

                            <Modal id='whiteHouseDocsModal' show={nationalEconomicModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setNationalEconomicModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>National Economic Council</h3></Modal.Header>
                  <NationalEconomicCouncil/>
                </Modal>
 
    </Col>


    
  
    </div>
   


</Carousel.Item>


<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>
<div id='viewFullPageButton'>
<Button id='viewFullLegislativePageButtonMobile' size='lg' href='/executiveoffice' >View all Executive Office news</Button>
</div>
</Col>

</div>



</Carousel.Item>


</Carousel>

</Col>


            <Col>

            </Col>

            <Col>
            <br></br><br></br><br></br><br></br>
<br></br>
<br></br>
<h5 id='headingSelectHomeCountry'>Support the Gov Glance Foundation  <Button id='donateButton' onClick={(e) => {
                        e.preventDefault();
                        window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288')
                      }}><HeartFill id='donateIcon'/>    Donate</Button></h5>

</Col>
          
            <br></br><br></br><br></br><br></br><br></br><br></br>
            <Navbar id='navbarMobileBottom' fixed="bottom">    

<div id='changeBranchDropdownMobile'>
      
           <MobileOffCanvas/>
              
            </div>

</Navbar>
          </div>
          </MobileView>
          </>
  )
}
 }



  






export default WhiteHousePageMobile;


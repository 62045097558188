
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch, NavLink,  } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment,  } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image, ModalFooter } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText, FileMedicalFill, Coin, Tree, Globe2,  ShieldShaded, CircleFill, Pen, People, ChatSquareQuote, ChatLeftQuote, BlockquoteRight, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, CaretRight, ChevronRight, ChevronCompactRight, CaretRightFill, ArrowRightShort,  ArrowRightCircle, PlayCircle, PauseCircle, GeoAlt, HeartFill, QuestionCircleFill, MicFill, ReceiptCutoff } from 'react-bootstrap-icons'

import { BrowserView, MobileView } from 'react-device-detect';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Axios from 'axios'
import { render } from '@testing-library/react';

import
 {supremeCourtSearch, whiteHouseSearch, billFeedModal, 
  createHouseFloorToday, createSenateFloorToday, createTrendingBills,
  createNationalAeronauticsAndSpaceAdministration, 
  createEnvironmentalProtectionAgency, createDepartmentOfHomelandSecurity,
  createDepartmentOfVeteransAffairs, createDepartmentOfEducation,
  createDepartmentOfHousingAndUrbanDevelopment, 
  createDepartmentOfHealthAndHumanServices, createDepartmentOfLabor
, createDepartmentOfCommerce, createDepartmentOfAgriculture,
createDepartmentOfTheInterior, createDepartmentOfJustice,
createDepartmentOfTransportation, createDepartmentOfDefense,
createDepartmentOfTreasury, createDepartmentOfEnergy,
createDepartmentOfState, createBillsEnrolledMobile,
createBillsEnrolled, createNewLaws, createGAOReports,
createExecutiveOrders, createPresidentialProclamations, createCongressionalHearings,
createCongressionalBudgetOfficeMobile, createCongressionalBudgetOffice,
createHouseCommitteeOversightAndReform,
createHouseCommitteeOnNaturalResources,
createHouseCommitteeOnTheJudiciary,
createHouseCommitteeOnHouseAdministration,
createHouseCommitteeOnHomelandSecurity,
createHouseCommitteeOnForeignAffairs,
createHouseCommitteeOnFinancialServices,
createHouseCommitteeOnEthics,
createHouseCommitteeOnEnergyAndCommerce,
createHouseCommitteeOnEducationAndLabor,
createHouseCommitteeOnTheBudget,
createHouseCommitteeOnArmedServices,
createHouseCommitteeOnAppropriations,
createHouseCommitteeOnAgriculture,
createReports,
createBillFeedTwoMobile,
createBillFeedTwo,
createBillFeed, createWhiteHouseFeedMobile
, createWhiteHouseFeed, createWhiteHouseDocuments, 
createBillsPresentedFeed, createCourtAppealsMobile,
createCourtAppeals, createUSCourtNewsFeedMobile,
createUSCourtNewsFeed, saveToCollection,
createFullBillFeed, ListItem, Speak, feedText, variableTest, myMethod, myMethod2, createEconomicIndicators, 
createSecuritiesAndExchangeCommissionPressReleases, createNationWeatherServices, createCDCNewsroom,
createSecuritiesAndExchangeCommissionSpeechesAndStatements, createNationalScienceFoundation, createHouseCommitteeOnIntelligence,
 createUNTopStories, createCDCOutbreaks, createCDCTravelNotices, createFoodRecalls, createFoodSafety, createFederalElectionCommissionNews, createElectionAssistancCommissioneNews, 
 createBillStatus, CreateFeedItem, CreateFeedItemWithDocButton, CreateFeedItemWithLawButton, CreateFeedItemGAOReports,
CreateFeedItemCongressionalReports,
CreateFeedItemPresidentialDocuments,
CreateFeedItemExecutiveOrders,
CreateFeedItemCourtAppeals,
CreateFeedItemEconomicIndicators,
CreateFeedItemBillUpdates,
createBIllItem,
CreateFeedItemSupremeCourtArguments,
CreateFeedItemSupremeCourtOpinions,
CreateFeedItemTest,
CreateFeedItemBillUpdatesFlip,
CreateFeedItemWithDocumentFlip,
CreateFeedItemWithDocButtonDateFix,
CreateFeedItemWithLawButtonFlip,
CreateFeedItemCanadaDateFix,
CreateFeedItemHouseOfCommons,
CreateFeedItemCanada,
CreateFeedItemUK,
CreateFeedItemUKAtom,
CreateFeedAPI,
CreateFeedAPIRecentCacheUK} from '../Functions'
import UKHomeMobile from './UKHomeMobile';
import UKBillUpdates from './Legislative/UKBillUpdates';
import HouseOfLordsResearch from './Legislative/HouseOfLordsResearch';
import UKDesktopNavbar from './UKDesktopNavbar';
import PrimeMinisterNews from './Executive/PrimeMinisterNews';
import AttorneyGeneralNews from './Executive/AttorneyGeneralNews';
import CabinetNews from './Executive/CabinetNews';
import SupremeCourt from './Judicial/SupremeCourt';
import DepartmentForBusiness from './Executive/DepartmentForBusiness';
import DepartmentForHMTreasury from './Topics/Economy/DepartmentForHMTreasury';
import ExportFinance from './Topics/Economy/ExportFinance';
import DepartmentForEnvironment from './Topics/Environment/DepartmentForEnvironment';
import DepartmentForForeignCommonwealth from './Topics/Foreign Affairs/DepartmentForForeignCommonwealth';
import MinistryOfDefense from './Topics/Foreign Affairs/MinistryOfDefense';
import DepartmentCultureMediaAndSport from './Executive/DepartmentCultureMediaAndSport';
import DepartmentForHealth from './Topics/Health/DepartmentForHealth';
import DepartmentForEnergy from './Topics/Science and Tech/DepartmentForEnergy';
import DepartmentForScience from './Topics/Science and Tech/DepartmentForScience';
import DepartmentForTransport from './Topics/DepartmentForTransport';
import GenerateFeed from '../GenerateFeed';
import GenerateFeedRecentUK from '../GenerateFeedRecentUK';
const CustomModal = ({ isOpen, onClose, title, content }) => (
  <Modal id='fullBillModal' show={isOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={onClose}>
    <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
    {content}
  </Modal>
);


var carouselInterval = 30000;
export const UKHome = () =>   {
  render()

  {
    const [reportModalIsOpen, setReportModalIsOpen] = useState(false)
    const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)
    const [lordsResearchModal, setLordsResearchModalIsOpen] = useState(false)
    const [trendingBillsOptionOne, setTrendingBillsOptionOneModalIsOpen] = useState(false)
    const [trendingBillsOptionTen, setTrendingBillsOptionTenModalIsOpen] = useState(false)
    const [attorneyGeneralModal, setAttorneyGeneralModalIsOpen] = useState(false)
    const [modal1, setModal1IsOpen] = useState(false);
    const [modal2, setModal2IsOpen] = useState(false);
    const [modal3, setModal3IsOpen] = useState(false);
    const [modal4, setModal4IsOpen] = useState(false);
    const [modal5, setModal5IsOpen] = useState(false);
    const [modal6, setModal6IsOpen] = useState(false);
    const [modal7, setModal7IsOpen] = useState(false);
    const [modal8, setModal8IsOpen] = useState(false);
    const [modal9, setModal9IsOpen] = useState(false);
    const [modal10, setModal10IsOpen] = useState(false);
    const [modal11, setModal11IsOpen] = useState(false);
    const [modal12, setModal12IsOpen] = useState(false);
    const [modal13, setModal13IsOpen] = useState(false);
    const [modal14, setModal14IsOpen] = useState(false);
    const [modal15, setModal15IsOpen] = useState(false);
    const [modal16, setModal16IsOpen] = useState(false);
    const [modal17, setModal17IsOpen] = useState(false);
    const [modal18, setModal18IsOpen] = useState(false);
    const [modal19, setModal19IsOpen] = useState(false);
    const [modal20, setModal20IsOpen] = useState(false);
    const [modal21, setModal21IsOpen] = useState(false);
    const [modal22, setModal22IsOpen] = useState(false);
    const [modal23, setModal23IsOpen] = useState(false);
    const [modalInfo, setModalInfo] = useState({
      isOpen: false,
      title: '',
      content: null
    });
  
    const openModal = (title, content) => {
      setModalInfo({
        isOpen: true,
        title,
        content
      });
    };
  
    const closeModal = () => {
      setModalInfo({
        isOpen: false,
        title: '',
        content: null
      });
    };
  function BranchesTab() {
  

    return (
      <>
     <div id='branchesTab'>

     <Row id='branchesRow'>

{/*Health Column */}
<Col id='homeColumn'>

<h3 id='branchHeading' onClick={() =>
                            openModal('Mexico Recent',
                              <GenerateFeedRecentUK/>)}> Recents</h3>
<div  id='recent'></div> 
{CreateFeedAPIRecentCacheUK('https://api.govglance.org/posts/recent/schema?limit=1&skip=0&schema=united_kingdom', 'recent', 
                'Recents', 'N/A', 
                'N/A', 'Doc', GenerateFeedRecentUK)}
</Col>

<Col id='homeColumn'>
<h3 id='branchHeading' >  </h3>
<div  id='recent2'></div> 
{CreateFeedAPIRecentCacheUK('https://api.govglance.org/posts/recent/schema?limit=1&skip=1&schema=united_kingdom', 'recent2', 
                'Recents', 'N/A', 
                'N/A', 'Doc', GenerateFeedRecentUK)}
</Col>
<Col id='homeColumn'>
<h3 id='branchHeading' >  </h3>
<div  id='recent3'></div> 
{CreateFeedAPIRecentCacheUK('https://api.govglance.org/posts/recent/schema?limit=1&skip=2&schema=united_kingdom', 'recent3', 
                'Recents', 'N/A', 
                'N/A', 'Doc', GenerateFeedRecentUK)}
</Col>

                



{/*Placeholder2 Column */}

</Row>
        
<Row id='branchesRow'>

    {/*Legislative Column */}
  <Col id='homeColumn'>

      <Row id="homePageBranchRow">

        
   
        <h3 id='branchHeading' onClick={() => window.open('/uk/legislative', "_self")}> <Pen></Pen> <b id='branchHeadingBold'>Legislative</b><ChevronRight id='clickChevron'></ChevronRight></h3>
  
    </Row>

    {/*Legislative Desktop Carousel*/}
    

  <Carousel id='homeCarousel' controls={false}  >




  <Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
                  <div id='legislativeCon'>
  
              <Col id='legislativeColRight'>
                <div id='columnLefttPadding'>



                  <h4 id='feedHeadingBillsEnrolled' onClick={() =>
                            openModal('Bill Updates',
                              <GenerateFeed
                                url='&skip=0&schema=united_kingdom&table=all_bills&order_by=created_at'
                                name='UK Bill Updates' branch='Legislative' topic='N/A' buttonName='Bill' />)}><b>Bill Updates</b></h4>
                  <div id='billUpdates'></div>
                  
                            {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
                            {CreateFeedAPI('&skip=0&schema=united_kingdom&table=all_bills&order_by=created_at', 'billUpdates', 
                            'UK Bill Updates', 'Legislative', 
                            'N/A', 'Bill', GenerateFeed)}




                </div>
              </Col>

             


     




            






      </div>

    </Carousel.Item>

    <Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
                  <div id='legislativeCon'>
  
              <Col id='legislativeColRight'>
                <div id='columnLefttPadding'>



                  <h4 id='feedHeadingBillsEnrolled' onClick={() => setLordsResearchModalIsOpen(true)}><b>House of Lords Research</b></h4>
                  <div id='lordsResearch'></div>

                  {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
                  {CreateFeedItemUK('https://lordslibrary.parliament.uk/type/research-briefing/feed/',
                    'lordsResearch', 'House of Lords Research', 'Legislative', 'N/A', HouseOfLordsResearch)}

                  <Modal id='mobileFullBillModal' show={lordsResearchModal}
                    size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setLordsResearchModalIsOpen(false)}>
                    <Modal.Header id='mobileBillModalHeader' closeButton><h3>House of Lords Research</h3></Modal.Header>
                    <HouseOfLordsResearch />
                  </Modal>


                </div>
              </Col>

             


     




            






      </div>

    </Carousel.Item>

  

    <Carousel.Item interval={carouselInterval} >

<div id='legislativeCon'>



<Col id='legislativeColRight'>
<div id='columnLefttPadding'>



<Button id='viewFullLegislativePageButton' size='lg' href='/uk/legislative' >View all Legislative news</Button>


</div>
</Col>
</div>

</Carousel.Item>


  
        </Carousel>
  </Col>


  {/*Executive Column */}
  <Col id='homeColumn'>

  <Row id="homePageBranchRow">
        <h3 id='branchHeading' onClick={() => window.open('/uk/executive', "_self")}><People></People> <b id='branchHeadingBold'>Executive</b><ChevronRight id='clickChevron'></ChevronRight></h3>
    
   
    </Row>
    
    <Carousel id='homeCarousel' controls={false}  >

    <Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
                  <div id='legislativeCon'>
  
              <Col id='legislativeColRight'>
                <div id='columnLefttPadding'>



                  <h4 id='feedHeadingBillsEnrolled' onClick={() =>
                            openModal('Prime Minister',
                              <GenerateFeed
                                url='&skip=0&schema=united_kingdom&table=prime_minister&order_by=created_at'
                                name='Prime Minister' branch='Executive' topic='N/A' buttonName='Doc' />)}><b>Prime Minister</b></h4>
                  <div id='primeMinister'></div>

{/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
{CreateFeedAPI('&skip=0&schema=united_kingdom&table=courts_and_tribunals&order_by=created_at', 'primeMinister', 
                            'Prime Minister', 'Executive', 
                            'N/A', 'Docs', GenerateFeed)}


                </div>
              </Col>

             


     




            






      </div>

    </Carousel.Item>

    <Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
                  <div id='legislativeCon'>
  
              <Col id='legislativeColRight'>
                <div id='columnLefttPadding'>



                  <h4 id='feedHeadingBillsEnrolled' onClick={() =>
                            openModal('Attorney General',
                              <GenerateFeed
                                url=' &skip=0&schema=united_kingdom&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Attorney%20Generals%20Office'
                                name='Attorney General' branch='Executive' topic='N/A' buttonName='Doc' />)}><b>Attorney General</b></h4>
                  <div id='attorneyGeneral'></div>

{/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
{CreateFeedAPI(' &skip=0&schema=united_kingdom&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Attorney%20Generals%20Office', 'attorneyGeneral', 
                            'Attorney General', 'Executive', 
                            'N/A', 'Docs', GenerateFeed)}
                </div>
              </Col>
      </div>

    </Carousel.Item>

    <Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
                  <div id='legislativeCon'>
  
              <Col id='legislativeColRight'>
                <div id='columnLefttPadding'>



                  <h4 id='feedHeadingBillsEnrolled' onClick={() => setModal1IsOpen(true)}><b>Cabinet</b></h4>
                  <div id='cabinet'></div>

{/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
{CreateFeedItemUKAtom('https://www.gov.uk/government/organisations/cabinet-office.atom',
  'cabinet', 'Cabinet', 'Executive', 'N/A', CabinetNews)}

<Modal id='mobileFullBillModal' show={modal1}
  size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setModal1IsOpen(false)}>
  <Modal.Header id='mobileBillModalHeader' closeButton><h3>Cabinet</h3></Modal.Header>
  <CabinetNews />
</Modal>
                </div>
              </Col>
      </div>

    </Carousel.Item>


    <Carousel.Item interval={carouselInterval} >

<div id='legislativeCon'>



<Col id='legislativeColRight'>
<div id='columnLefttPadding'>



<Button id='viewFullLegislativePageButton' size='lg' href='/uk/executive' >View all Executive news</Button>


</div>
</Col>
















</div>

</Carousel.Item>


  
        </Carousel>
    {/*Executive Desktop Carousel*/}


  </Col>


    {/*Judical Column */}
    <Col id='homeColumn'>


       <Row id="homePageBranchRow">
        <h3 id='branchHeading' onClick={() => window.open('/uk/judicial', "_self")}><svg xmlns="http://www.w3.org/2000/svg" height="27.54px" viewBox="0 -960 960 960" width="27.54px" fill="#FFFFFF"><path d="M160-120v-80h480v80H160Zm226-194L160-540l84-86 228 226-86 86Zm254-254L414-796l86-84 226 226-86 86Zm184 408L302-682l56-56 522 522-56 56Z"/></svg><b id='branchHeadingBold'>Judicial</b><ChevronRight id='clickChevron'></ChevronRight></h3>
      
    
     </Row>
    
    {/*Judical Desktop Carousel*/}
    <Carousel id='homeCarousel' controls={false}  >

    <Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
                  <div id='legislativeCon'>
  
              <Col id='legislativeColRight'>
                <div id='columnLefttPadding'>



                  <h4 id='feedHeadingBillsEnrolled' onClick={() => setModal11IsOpen(true)}><b>Supreme Court</b></h4>
                  <div id='supremeCourt'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
    {CreateFeedItemUKAtom('https://www.gov.uk/government/organisations/supreme-court-of-the-united-kingdom.atom',
     'supremeCourt', 'UK Supreme Court', 'Judicial', 'N/A', SupremeCourt)}

  <Modal id='mobileFullBillModal' show={modal11} 
  size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setModal11IsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Supreme Court</h3></Modal.Header>
<SupremeCourt/>
</Modal>
                </div>
              </Col>
      </div>

    </Carousel.Item>

    <Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
                  <div id='legislativeCon'>
  
              <Col id='legislativeColRight'>
                <div id='columnLefttPadding'>



                  <h4 id='feedHeadingBillsEnrolled' onClick={() =>
                            openModal('Court and Tribunals',
                              <GenerateFeed
                                url='&skip=0&schema=united_kingdom&table=courts_and_tribunals&order_by=created_at'
                                name='Court and Tribunals' branch='Judicial' topic='N/A' buttonName='Doc' />)}><b>Courts and Tribunals</b></h4>
                 <div id='courtTrib'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}

                          {CreateFeedAPI('&skip=0&schema=united_kingdom&table=courts_and_tribunals&order_by=created_at', 'courtTrib', 
                            'Court and Tribunals', 'Judicial', 
                            'N/A', 'Docs', GenerateFeed)}


                </div>
              </Col>

             


     




            






      </div>

    </Carousel.Item>

<Carousel.Item interval={carouselInterval} >

<div id='legislativeCon'>



<Col id='legislativeColRight'>
<div id='columnLefttPadding'>



<Button id='viewFullLegislativePageButton' size='lg' href='/uk/judicial' >View all Judicial news</Button>


</div>
</Col>

</div>

</Carousel.Item>

</Carousel>

  </Col>
  </Row>

  <Row id='topicsRow'>
{/*Economy Column */}
<Col id='topicColumn'>

                <Row id="topicPageBranchRow">

                <h3 id='branchHeading' onClick={() => window.open('/uk/economy', "_self")}> <Coin></Coin> <b id='branchHeadingBold'>Economy</b><ChevronRight id='clickChevron'></ChevronRight></h3>


                  </Row>
<Carousel id='homeCarousel' controls={false} >

<Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
                  <div id='legislativeCon'>
  
              <Col id='legislativeColRight'>
                <div id='columnLefttPadding'>



                  <h4 id='feedHeadingBillsEnrolled' onClick={() => setModal2IsOpen(true)}><b>Department of Business and Trade</b></h4>
                  <div id='deptOfBusinessAndTrade'></div>

                  {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
                  {CreateFeedItemUKAtom('https://www.gov.uk/government/organisations/department-for-business-and-trade.atom',
                    'deptOfBusinessAndTrade', 'Department of Business and Trade', 'Executive', 'Economy', DepartmentForBusiness)}

                  <Modal id='mobileFullBillModal' show={modal2}
                    size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setModal2IsOpen(false)}>
                    <Modal.Header id='mobileBillModalHeader' closeButton><h3>Department for Business and Trade</h3></Modal.Header>
                    <DepartmentForBusiness />
                  </Modal>
                </div>
              </Col>
      </div>

    </Carousel.Item>

    <Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
                  <div id='legislativeCon'>
  
              <Col id='legislativeColRight'>
                <div id='columnLefttPadding'>



                  <h4 id='feedHeadingBillsEnrolled' onClick={() => setModal13IsOpen(true)}><b>HM Treasury</b></h4>
                  <div id='deptOfTreasury'></div>

{/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
{CreateFeedItemUKAtom('https://www.gov.uk/government/organisations/hm-treasury.atom',
  'deptOfTreasury', 'HM Treasury', 'Executive', 'Economy', DepartmentForHMTreasury)}

<Modal id='mobileFullBillModal' show={modal13}
  size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setModal13IsOpen(false)}>
  <Modal.Header id='mobileBillModalHeader' closeButton><h3>HM Treasury</h3></Modal.Header>
  <DepartmentForHMTreasury/>
</Modal>
                </div>
              </Col>
      </div>

    </Carousel.Item>

    <Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
                  <div id='legislativeCon'>
  
              <Col id='legislativeColRight'>
                <div id='columnLefttPadding'>



                  <h4 id='feedHeadingBillsEnrolled' onClick={() => setModal23IsOpen(true)}><b>Export Finance</b></h4>
                  <div id='exportFinance'></div>

{/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
{CreateFeedItemUKAtom('https://www.gov.uk/government/organisations/uk-export-finance.atom',
  'exportFinance', 'Export Finance ', 'Executive', 'Economy', ExportFinance)}

<Modal id='mobileFullBillModal' show={modal23}
  size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setModal23IsOpen(false)}>
  <Modal.Header id='mobileBillModalHeader' closeButton><h3>Export Finance</h3></Modal.Header>
  <ExportFinance />
</Modal>
                </div>
              </Col>
      </div>

    </Carousel.Item>


<Carousel.Item interval={carouselInterval} >

<div id='legislativeCon'>



<Col id='legislativeColRight'>
<div id='columnLefttPadding'>



<Button id='viewFullLegislativePageButton' size='lg' href='/uk/economy' >View all Economy news</Button>
</div>
</Col>
</div>

</Carousel.Item>



</Carousel>
          </Col>

{/*Environment Column */}
<Col id='topicColumn'>

                <Row id="topicPageBranchRow">

                <h3 id='branchHeading' onClick={() => window.open('/uk/environment', "_self")}> <Tree></Tree> <b id='branchHeadingBold'>Environment</b><ChevronRight id='clickChevron'></ChevronRight></h3>


                  </Row>

                  <Carousel id='homeCarousel' controls={false} >

                  <Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
                  <div id='legislativeCon'>
  
              <Col id='legislativeColRight'>
                <div id='columnLefttPadding'>



                  <h4 id='feedHeadingBillsEnrolled' onClick={() => setModal6IsOpen(true)}><b>Department for Environment Food & Rural Affairs</b></h4>
                  <div id='deptOfEnvironmentFood'></div>
                        
                        {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
  {CreateFeedItemUKAtom('https://www.gov.uk/government/organisations/department-for-environment-food-rural-affairs.atom',
   'deptOfEnvironmentFood', 'Department for Environment Food & Rural Affairs', 'Executive', 'Environment', DepartmentForEnvironment)}

<Modal id='mobileFullBillModal' show={modal6} 
size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setModal6IsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Department for Environment Food & Rural Affairs</h3></Modal.Header>
<DepartmentForEnvironment/>
</Modal>
                </div>
              </Col>
      </div>

    </Carousel.Item>

<Carousel.Item interval={carouselInterval} >

<div id='legislativeCon'>



<Col id='legislativeColRight'>
<div id='columnLefttPadding'>



<Button id='viewFullLegislativePageButton' size='lg' href='/uk/environment' >View all Environment news</Button>










</div>
</Col>
















</div>

</Carousel.Item>



</Carousel>
                  </Col>
{/*Foreign Affairs Column */}
<Col id='topicColumn'>

<Row id="topicPageBranchRow">

<h3 id='branchHeading' onClick={() => window.open('/uk/foreignaffairs', "_self")}> <Globe2></Globe2> <b id='branchHeadingBold'>Foreign Affairs</b><ChevronRight id='clickChevron'></ChevronRight></h3>


</Row>
<Carousel id='homeCarousel' controls={false} >

<Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
                  <div id='legislativeCon'>
  
              <Col id='legislativeColRight'>
                <div id='columnLefttPadding'>



                  <h4 id='feedHeadingBillsEnrolled' onClick={() => setModal12IsOpen(true)}><b>Foreign, Commonwealth & Development Office</b></h4>
                  <div id='deptOfForeign'></div>
                        
                        {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
  {CreateFeedItemUKAtom('https://www.gov.uk/government/organisations/foreign-commonwealth-development-office.atom',
   'deptOfForeign', 'Foreign, Commonwealth & Development Office', 'Executive', 'Foreign Affairs', DepartmentForForeignCommonwealth)}

<Modal id='mobileFullBillModal' show={modal12} 
size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setModal12IsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Foreign, Commonwealth & Development Office</h3></Modal.Header>
<DepartmentForForeignCommonwealth/>
</Modal>
                </div>
              </Col>
      </div>

    </Carousel.Item>

    <Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
                  <div id='legislativeCon'>
  
              <Col id='legislativeColRight'>
                <div id='columnLefttPadding'>



                  <h4 id='feedHeadingBillsEnrolled' onClick={() => setModal15IsOpen(true)}><b>Ministry of Defence</b></h4>
                  <div id='deptOfDefence'></div>
                        
                        {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
  {CreateFeedItemUKAtom('https://www.gov.uk/government/organisations/ministry-of-defence.atom',
   'deptOfDefence', 'Ministry of Defence', 'Executive', 'Foreign Affairs', MinistryOfDefense)}

<Modal id='mobileFullBillModal' show={modal15} 
size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setModal15IsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Ministry of Defence</h3></Modal.Header>
<MinistryOfDefense/>
</Modal>
                </div>
              </Col>
      </div>

    </Carousel.Item>


 
<Carousel.Item interval={carouselInterval} >

<div id='legislativeCon'>



<Col id='legislativeColRight'>
<div id='columnLefttPadding'>



<Button id='viewFullLegislativePageButton' size='lg' href='/uk/foreignaffairs' >View all Foreign Affairs news</Button>










</div>
</Col>
















</div>

</Carousel.Item>






</Carousel>
</Col>

    </Row>

    <Row id='topicsRow'>

      {/*Health Column */}
<Col id='topicColumn'>

<Row id="topicPageBranchRow">

<h3 id='branchHeading' > <FileMedicalFill></FileMedicalFill> <b id='branchHeadingBold'>Health</b><ChevronRight id='clickChevron'></ChevronRight></h3>


</Row>
<Carousel  controls={false} >


<Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
                  <div id='legislativeCon'>
  
              <Col id='legislativeColRight'>
                <div id='columnLefttPadding'>



                  <h4 id='feedHeadingBillsEnrolled' onClick={() => setModal3IsOpen(true)}><b>Department for Culture Media and Sport</b></h4>
                  <div id='deptForCulture'></div>
                        
                        {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
  {CreateFeedItemUKAtom('https://www.gov.uk/government/organisations/department-for-culture-media-and-sport.atom',
   'deptForCulture', 'Department for Culture Media and Sport', 'Executive', 'Health', DepartmentCultureMediaAndSport)}

<Modal id='mobileFullBillModal' show={modal3} 
size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setModal3IsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Department for Culture Media and Sport</h3></Modal.Header>
<DepartmentCultureMediaAndSport/>
</Modal>
                </div>
              </Col>
      </div>

    </Carousel.Item>

    <Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
                  <div id='legislativeCon'>
  
              <Col id='legislativeColRight'>
                <div id='columnLefttPadding'>



                  <h4 id='feedHeadingBillsEnrolled' onClick={() => setModal21IsOpen(true)}><b>Department for Health & Social Care</b></h4>
                  <div id='deptOfHealth'></div>
                        
                        {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
  {CreateFeedItemUKAtom('https://www.gov.uk/government/organisations/department-of-health-and-social-care.atom',
   'deptOfHealth', 'Department for Health & Social Care', 'Executive', 'Health', DepartmentForHealth)}

<Modal id='mobileFullBillModal' show={modal21} 
size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setModal21IsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Department for Health & Social Care</h3></Modal.Header>
<DepartmentForHealth/>
</Modal>
                </div>
              </Col>
      </div>

    </Carousel.Item>

<Carousel.Item interval={carouselInterval} >

<div id='legislativeCon'>



<Col id='legislativeColRight'>
<div id='columnLefttPadding'>



<Button id='viewFullLegislativePageButton' size='lg'  >Coming Soon</Button>










</div>
</Col>
















</div>

</Carousel.Item>



</Carousel>



</Col>

<Col id='topicColumn'>

<Row id="topicPageBranchRow">

<h3 id='branchHeading' > <ReceiptCutoff></ReceiptCutoff> <b id='branchHeadingBold'>Elections</b><ChevronRight id='clickChevron'></ChevronRight></h3>


</Row>
<Carousel id='healthCarousel' controls={false} >




<Carousel.Item interval={carouselInterval} >

<div id='legislativeCon'>



<Col id='legislativeColRight'>
<div id='columnLefttPadding'>



<Button id='viewFullLegislativePageButton' size='lg'  >Coming Soon</Button>










</div>
</Col>
















</div>

</Carousel.Item>



</Carousel>






</Col>
<Col id='topicColumn'>

<Row id="topicPageBranchRow">

</Row>
<Carousel id='healthCarousel' controls={false} >
</Carousel>






</Col>



{/*Placeholder2 Column */}

      </Row>


      <Row id="topicPageBranchRow">


<Col id='topicColumn'>










</Col>
</Row>

<Row id='topicsRow'>

{/*Health Column */}
<Col id='topicColumn'>

<Row id="topicPageBranchRow">




</Row>
<Carousel id='placeHolderCarousel' controls={false}>





</Carousel>
</Col>
{/*States Column */}





{/*Placeholder Column */}
<Col id='topicColumn'>

<Row id="topicPageBranchRow">




</Row>
<Carousel id='placeHolderCarousel' controls={false}>





</Carousel>
</Col>

{/*Placeholder2 Column */}
<Col id='topicColumn'>

<Row id="topicPageBranchRow">




</Row>
<Carousel id='placeHolderCarousel' controls={false}>





</Carousel>
</Col>





</Row>

<CustomModal isOpen={modalInfo.isOpen} onClose={closeModal} title={modalInfo.title} content={modalInfo.content} />
<br></br>

<h5 id='branchHeadingMobile' > <GeoAlt/>  Countries <ChevronRight id='clickChevron'></ChevronRight></h5>
<div id='homebg'>  
<Container>
                      <Col>
                      <Row className="d-flex justify-content-center align-items-center">
                  <Button size='sm' id='statesModalButtons'  onClick={()  => {
                        
                        window.open('/uk/england', '_self')
                      }}> <Image id='stateFlagMobile'  src='/englandFlag.png'></Image>England</Button>
                        <Button size='sm' id='statesModalButtons'  onClick={()  => {
                        
                        window.open('/uk/northernIreland', '_self')
                      }}><Image id='stateFlagMobile'  src='/northernIrelandFlag.png'></Image>Northern Ireland</Button>
                       <Button size='sm' id='statesModalButtons'  onClick={()  => {
                        
                        window.open('/uk/scotland', '_self')
                      }}> <Image id='stateFlagMobile'  src='/scotlandFlag.png'></Image>Scotland</Button>
                       <Button size='sm' id='statesModalButtons'  onClick={()  => {
                        
                        window.open('/uk/wales', '_self')
                      }}> <Image id='stateFlagMobile'  src='/walesFlag.png'></Image>Wales</Button>
                      </Row>
               
                
   
                     </Col>
                     </Container>
                     </div>

<br></br>

<h5 id='branchHeadingPicker'><GeoAlt/>   Change country <ChevronRight id='clickChevron'></ChevronRight></h5>

<Col>
<Button id='selectHomeCountryDesktop'   onClick={() => window.open('/canada', "_self")}>
🇨🇦 Canada
</Button>

<Button id='selectHomeCountryDesktop'   onClick={() => window.open('/mexico', "_self")}>
🇲🇽 Mexico
</Button>

<Button id='selectHomeCountryDesktop' onClick={() => window.open('/uk', "_self")}>
🇬🇧 United Kingdom
</Button>


<Button id='selectHomeCountryDesktop' onClick={() => window.open('/us', "_self")}>
🇺🇸 United States
</Button>
</Col>

             <br></br>

</div>

      </>
    );
  }





  
  
  

  

  
  //onClick={linkToBill()}
  return (
    
    <>
    
      <div className="App">
   
    




      <BrowserView>
      <div id='homePage'>
        <div id='homebg'>

<UKDesktopNavbar/>
            <BranchesTab />
            

            {/*onClick={() => } */}
           
           
        
   
         


          
            
              
             
              
            
          
            
            
          <Modal id='aboutModal' show={aboutModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAboutModalIsOpen(false)}>
            <div id='removedAlert'></div>
            <Modal.Header id='aboutModalHeader' closeButton><h3>About</h3></Modal.Header>
  <ModalBody id='aboutInfo'><p id='aboutParagraph'>Gov Glance was created to drive civic engagement and support a 
              healthier democracy by helping citizens get official Government news in an easy-to-navigate centralized location. 
              There will never be any opinion or political analysis(That's your job), only information from official Government 
              sources. Gov Glance is structured after the U.S. government that is broken into three branches Legislative, 
              Executive, and Judicial. To learn more about the structure and functions of The U.S. Government go to <a href='https://www.usa.gov/branches-of-government'>https://www.usa.gov/branches-of-government</a>.
              </p>

              <p id='aboutParagraph'>
              Gov Glance Foundation Inc. is a non-profit that was created to operate govglance.org and continue to create educational tools that help people learn about the workings of society
              </p>
            <br></br>

            <h3>Privacy</h3>

            <br></br>

              <p id='aboutParagraph'>Gov Glance does not collect any personal user information. 
              Features like collection save news items directly to your browser's local storage for your convenience
              and is not shared with Gov Glance servers. We do not collect, store, or send personal information to Google LLC, 
              but we do use some Google services to deliver our app to you. For any questions into Google’s privacy practices 
              here is a link to their privacy policy: <a href='https://policies.google.com/privacy?hl=en-US#intro'>https://policies.google.com/privacy?hl=en-US#intro</a>

              <br></br><br></br>

              For site analytics we use plausible.io an open source privacy friendly analytics provider. <a href="https://plausible.io/about" target="_blank">https://plausible.io/about</a>
            
            </p>
            <br></br>


            <h3>Contact</h3>

            <p id='aboutParagraph'>
                      Contact us via email: <a href='mailto:contact@govglance.org'>contact@govglance.org</a>

            </p>

            <br></br>


            
            </ModalBody>
          </Modal>
 
     <footer id='homeFooter'>
      <Navbar>
        <Row id='footerNav'>
      <Nav variant='pills'>
        
     <Nav.Link id='footerContent'>Gov Glance Foundation Inc.
</Nav.Link>
<Nav.Item>
<Nav.Link id='footerContent' href='mailto:contact@govglance.org'>Contact</Nav.Link>
</Nav.Item>
<Nav.Item>
<Nav.Link id='footerContent' onClick={(e) => {
                   e.preventDefault();
                   window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                 }}> <HeartFill id='donateIcon'/>    Donate</Nav.Link>
</Nav.Item>
<Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/about', "_self")}} id='footerContent'>Privacy</Nav.Link>
    
     
 </Nav>
 </Row>
 </Navbar>
      </footer>

      


    
          </div>
  

    </div>
      </BrowserView>
     

      <MobileView> 
        
        
<UKHomeMobile/>
     
      </MobileView>
        </div>
      


       
    </>

     
    
  );







}
}


export default UKHome;
